//IMPORTAÇÕES
/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Button } from 'react-bootstrap';/*eslint-disable*/

//IMPORTAÇÕES ICONES
import { fecharJanela, fecharBarraFiltro } from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { mudarFiltros, revelarBarraFiltragem, realizouFiltro } from "../../../redux/actions/index";

import "../../../css/componentesModais/barraFiltragem/barraFiltragem.css"

let nomeCampoFiltro = []
const objFiltroVazio = { vazio: 'vazio' };

function BarraFiltragem(props) {

    const despacho = useDispatch();
    let filtrosArrayBarra = useSelector(state => state.filtrosState.filtrosArrayBarra)
    let filtros = useSelector(state => state.filtrosState.filtros)
    let recarregarBarraFiltragem = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let maximoTagsExibidas2 = useSelector(state => state.filtrosState.maximoTagsExibidas)
    let cont = 0
    const [haveFiltros, setHaveFiltros] = useState(false)
    const [tagsExtras, setTagsExtras] = useState([])
    const [monitorarOperadora, setMonitorarOperadora] = useState(false)
    const [monitorarFiltros, setMonitorarFiltros] = useState(false)

    useEffect(() => {
        // temFiltros(filtrosArrayBarra.length)
        let barraPrincipal
        let larguraBarraPrincipal
        let larguraBarraTag
        let tag
        let total
        //pega a largura da barra principal
        if (document.getElementById('elemento-principal')) {
            barraPrincipal = document.getElementById('elemento-principal');
            larguraBarraPrincipal = barraPrincipal.clientWidth
        }
        if (document.getElementById('meu-elemento')) {
            tag = document.getElementById('meu-elemento');
            larguraBarraTag = tag.clientWidth
        }
        //transforma em percentual a largura da div que contem as tags
        if (larguraBarraPrincipal && larguraBarraTag) {
            let percentual = larguraBarraTag * 100
            total = percentual / larguraBarraPrincipal
            total = Math.round(total)
        }
        countTags()
        if (total >= 50) {
            setHaveFiltros(true)
        } else {
            setHaveFiltros(false)
        }
        countTags()
    }, [recarregarBarraFiltragem, monitorarFiltros, monitorarOperadora]);


    function countTags() {
        const divGeral = document.getElementById("meu-elemento");
        if (divGeral) {
            let countMaxWidth = 0;
            let divsOverflow = 0;
            // pega a largura de cada tag de filtros dentro da div
            // se caso a somatoria do tamanho de todas as tags forem maior que a div que compõem as tags
            // o botão de abrir a modal de filtros será ativado ocultando o restante de filtros que ficaram de fora da tela
            const allDivs = divGeral.querySelectorAll('.barra-filtragem-caixa');
            for (const el of allDivs) {
                countMaxWidth += (el.offsetWidth + 17.5);
                if (countMaxWidth > divGeral.offsetWidth) {
                    divsOverflow += 1;
                }
            }
            setTagsExtras(divsOverflow)
            if (divsOverflow <= 0) {
                setHaveFiltros(false)
            }
        }
    }

    function handlerDeleteLastFiltro(id, i, tipoFiltro) {
        let getTipoFiltro = []
        let idOperadora = []
        let numberArr = []
        idOperadora.push(id)
        numberArr.push(parseInt(idOperadora[0]))

        if (numberArr[0] > 0 && tipoFiltro) {
            // caso o usuario feche uma tag de operadora na barra de dispositivos ou solicitações/conectividade
            const temporarioFiltro = JSON.parse(JSON.stringify(filtros));
            getTipoFiltro.push(filtros[tipoFiltro].split(","))
            let removeTipoFiltroOpcao = getTipoFiltro[0].filter(function (objeto) {
                return idOperadora[0].indexOf(objeto) == -1
            });
            filtrosArrayBarra.splice(i, 1)
            delete temporarioFiltro[tipoFiltro]
            temporarioFiltro[tipoFiltro] = removeTipoFiltroOpcao.join(",")
            despacho(mudarFiltros(temporarioFiltro))

            if(tipoFiltro === 'OPERADORA') setMonitorarOperadora(temporarioFiltro[tipoFiltro])

        } else {
            let temporarioFiltro = JSON.parse(JSON.stringify(filtros));
            let TemporarioValor = ''
            let TemporarioData = ''
            id[0].split('_').map((item_id, i) => {
                //caso para o grupo de input, onde verifica se tem tais terminacoes e deleta o valor maximo
                if (item_id == 'MIN' || item_id == 'MAX') {
                    TemporarioValor += '_MAX'
                    //retira o primeiro dash
                    TemporarioValor = TemporarioValor.replace('_', '')
                    //caso especial onde e necessario resetar outro elemento
                    if (TemporarioValor == "CONSUMO_MAX") temporarioFiltro['CONSUMO'] = ''
                    if (TemporarioValor == "CONEXOES_MAX") temporarioFiltro['CONEXOES'] = ''
                    if (TemporarioValor == "PACOTES_MAX") temporarioFiltro['PACOTES'] = ''
                    //reseta o valor
                    temporarioFiltro[TemporarioValor] = ''
                }
                else {
                    TemporarioValor += "_" + item_id
                }
                //caso para o calendario, onde verifica se tem tais terminacoes e deleta a data final
                if (item_id == 'INICIO' || item_id == 'FINAL') {
                    TemporarioData += '_FINAL'
                    //retira o primeiro dash
                    TemporarioData = TemporarioData.replace('_', '')

                    //caso especial onde e necessario resetar outro elemento
                    // if(TemporarioData == "ULTIMA_CONEXAO_FINAL") temporarioFiltro['ULTIMA_CONEXAO'] = ''
                    //reseta o valor
                    temporarioFiltro[TemporarioData] = ''
                }
                else {
                    TemporarioData += "_" + item_id
                }
            })
            //reseta o valor
            if (id == 'CONEXOES_MIN') {
                temporarioFiltro.CONEXOES_MIN = ''
                temporarioFiltro.CONEXOES_MAX = ''
                temporarioFiltro.CONEXOES = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            } else if (id == 'PACOTES_MIN') {
                temporarioFiltro.PACOTES_MIN = ''
                temporarioFiltro.PACOTES_MAX = ''
                temporarioFiltro.PACOTES = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'ULTIMA_CONEXAO_INICIO') {
                temporarioFiltro.ULTIMA_CONEXAO_INICIO = ''
                temporarioFiltro.ULTIMA_CONEXAO_FINAL = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'CONSUMO_MIN') {
                temporarioFiltro.CONSUMO_MIN = ''
                temporarioFiltro.CONSUMO_MAX = ''
                temporarioFiltro.CONSUMO = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'VALOR_MIN') {
                temporarioFiltro.VALOR_MIN = ''
                temporarioFiltro.VALOR_MAX = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'DIAS_USADOS_MIN') {
                temporarioFiltro.DIAS_USADOS_MIN = ''
                temporarioFiltro.DIAS_USADOS_MAX = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'AQUISICAO_INICIO') {
                temporarioFiltro.AQUISICAO_INICIO = ''
                temporarioFiltro.AQUISICAO_FINAL = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'ATIVACAO_INICIO') {
                temporarioFiltro.ATIVACAO_INICIO = ''
                temporarioFiltro.ATIVACAO_FINAL = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'SALDO_MIN') {
                temporarioFiltro.SALDO_MIN = ''
                temporarioFiltro.SALDO_MAX = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else if (id == 'RANGE_ICCID_MIN') {
                temporarioFiltro.RANGE_ICCID_MIN = ''
                temporarioFiltro.RANGE_ICCID_MAX = ''
                filtrosArrayBarra.splice(i, 1)
                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
            else {
                temporarioFiltro[id] = ''
                if(id === 'CD_SITUACAO') temporarioFiltro['STATUS_CONTRATO'] = '';
                filtrosArrayBarra.splice(i, 1)

                temporarioFiltro = removeEmptyProperties(temporarioFiltro)
                if(JSON.stringify(temporarioFiltro) === '{}') {
                    temporarioFiltro = objFiltroVazio;
                }

                despacho(mudarFiltros(temporarioFiltro))
                setMonitorarFiltros(temporarioFiltro)
            }
        }
        // despacho(maximoTagsExibidas(false))
        countTags()
    }

    function removeEmptyProperties(obj) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    //se nao possuir nenhum filtro ativo ela ira fechar
    if (filtrosArrayBarra.length == 0) {
        despacho(revelarBarraFiltragem(false))
    }




    function renderFiltros() {
        nomeCampoFiltro = [];
        let contagem = 0
        let comOperadora = false


        filtrosArrayBarra.map(function (item, i) {
            if (!item.includes('?')) {
                contagem++
            }
        });
        if (contagem >= 0) {
            comOperadora = true
        }
        return filtrosArrayBarra.map(function (item, i) {
            if (item.includes('?')) {
                const valor = item.split('?')
                nomeCampoFiltro.push(valor[1].split(','))
                if (cont === 0) {
                    cont++
                    return (
                        <>
                            <div id={"tag-" + valor[0]} className="barra-filtragem-caixa" key={i}>
                                <div id='testeBox' className="filtragem-caixa">
                                    <span className="fonte-barra-filtro">{valor[0]}</span>
                                    <Button className="botao-fechar-janela" variant="danger" 
                                        onClick={() => handlerDeleteLastFiltro(valor[1], i)}
                                    >
                                        <img className="botao-limpar-item-icone" src={fecharBarraFiltro()} alt="fechar-janela" />
                                    </Button>
                                    <div className="barra-filtragem-caixa-limpar-item">
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                } else if (filtrosArrayBarra.length == i + 1) {
                    return (
                        <>
                            <div id={"tag-" + valor[0]} className="barra-filtragem-caixa">
                                <div className="filtragem-caixa">
                                    <span className="fonte-barra-filtro">{valor[0]}</span>
                                    <Button className="botao-fechar-janela" variant="danger" 
                                        onClick={() => comOperadora ? handlerDeleteLastFiltro(valor[1], i) : handlerDeleteLastFiltro(nomeCampoFiltro[i], i)}
                                    >
                                        <img className="botao-limpar-item-icone" src={fecharBarraFiltro()} alt="fechar-janela" />
                                    </Button>
                                    <div className="barra-filtragem-caixa-limpar-item">
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div id={"tag-" + valor[0]} className="barra-filtragem-caixa">
                                <div className="filtragem-caixa">
                                    <span className="fonte-barra-filtro">{valor[0]}</span>
                                    <Button className="botao-fechar-janela" variant="danger" 
                                        onClick={() => handlerDeleteLastFiltro(valor[1], i)}
                                    >
                                        <img className="botao-limpar-item-icone" src={fecharBarraFiltro()} alt="fechar-janela" />
                                    </Button>
                                    <div className="barra-filtragem-caixa-limpar-item">
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }


            } else {

                //OS IFs ABAIXO SÃO APENAS PARA VALIDAR CHAMADAS ESPECIFICAS EM DISPOSITIVOS OU SOLICITAÇÕES/CONECTIVIDADE
                let auxItem = item;
                let tipoFiltro = 'OPERADORA';

                const filtroPorStatus = auxItem.includes('Status: ')

                if(filtroPorStatus) {
                    auxItem = auxItem.split(': ')[1];
                    tipoFiltro = 'STATUS';
                }

                /* apenas para checkbox nas telas:
                    operadora em disositivos e Status 
                    e Operadora em solicitações/conectividade
                */
                const valor = auxItem.split(' ')
                var resultado = valor.filter(function (v) {
                    return v !== '';
                });

                const cdOperadora = resultado[1]
                let titulo = (valor.length === 3) ? valor[1] : valor[0];

                if(filtroPorStatus) {
                    titulo = `Status: ${titulo}`;
                } else if( tipoFiltro === 'OPERADORA' ) {
                    titulo = `Operadora: ${titulo}`;
                }

                return (
                    <>
                        <div id={"tag-" + titulo} className="barra-filtragem-caixa">
                            <div className="filtragem-caixa">
                                <span className="fonte-barra-filtro">{titulo}</span>
                                <Button className="botao-fechar-janela" variant="danger" 
                                    onClick={() => {
                                        handlerDeleteLastFiltro(cdOperadora, i, tipoFiltro) // chamado em Dispositivo filtro por operadora / Solicitações/Conectividade filtro por Status
                                    }}
                                >
                                    <img className="botao-limpar-item-icone" src={fecharBarraFiltro()} alt="fechar-janela" />
                                </Button>
                                <div className="barra-filtragem-caixa-limpar-item">
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        });
    }

    function abrirFiltros() {
        maximoTagsExibidas2(true)
    }


    return (
        <>
            <div id='elemento-principal' className="barra-filtragem-cliente fundo-cor-5">
                <p className="filtrado-por" >Filtrado por: </p>
                <div id='meu-elemento' className="tags-barra-filtragem">
                    {renderFiltros()}
                </div>
                <button className={haveFiltros ? "mais-filtros" : "menos-filtros"} onClick={() => abrirFiltros()}>
                    + {tagsExtras} Filtros
                </button>
                <div className="barra-filtragem-cliente-fechar fundo-cor-5">
                    <div className="barra-filtragem-caixa-fechar">
                        <p className="limpar-filtro" > {filtrosArrayBarra.length > 1 ? "LIMPAR FILTROS" : "LIMPAR FILTRO"}</p>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => {
                            despacho(mudarFiltros(objFiltroVazio));
                            despacho(revelarBarraFiltragem(false));
                            despacho(realizouFiltro(true));
                        }}>
                            <img className="botao-fechar-janela-icone-barra-filtragem" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </div>
                </div>
            </div>

        </>
    )
}

function mapStateToProps(state) {
    return {
        filtrosArrayBarra: state.filtrosState.filtrosArrayBarra,
        recarregarBarraFiltragem: state.filtrosState.recarregarBarraFiltragem,
    };
}

export default connect(mapStateToProps)(BarraFiltragem);