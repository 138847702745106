//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";
import { Button, Form, Col, InputGroup, FormControl } from 'react-bootstrap';
//ICONES
import {elipseVerde, fecharJanela, olhoDesligado, olho} from '../../utils/alternanciaIcones';
//REDUX
import { useDispatch } from 'react-redux';
import { revelarAlterarSenha } from "../../redux/actions/index";
//MODAIS
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import decryptJWT from '../../utils/decryptJWT' 
// COLETA CLICKSTREAM
import ColetaClickstream from '../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//CSS
import '../../css/perfilUsuario/perfilUsuario.css'
export default function DesativarDemonstracao(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { handleSubmit } = useForm();
    //HOOKS PRINCIPAIS
    const [renderizar, setRenderizar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [habilitarEdicao, setHabilitarEdicao] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordVerificador, setPasswordVerificador] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [iconsNovaSenha, setIconsNovaSenha] = useState(true);
    const [iconsSenhaVerificador, setIconsSenhaVerificador] = useState(true);
    const [icons, setIcons] = useState(true);
    const [alertaSenha, setAlertaSenha] = useState(false);
    const [alertaSenhaInvalida, setAlertaSenhaInvalida] = useState(false);
    const [alertaSenhaIgual, setAlertaSenhaIgual] = useState(false);

    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mostrarEsconder, setMostrarEsconder] = useState("password");
    const [mostrarEsconderConfirmarNovaSenha, setMostrarEsconderConfirmarNovaSenha] = useState("password");
    const [mostrarEsconderVerificarSenha, setMostrarEsconderVerificarSenha] = useState("password");
    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(()=>{
        async function handleCarregarDados(){
            await api.get("/m1/consultar/cliente?cd_pav_cliente="+ decryptJWT("codigoCliente"))
                .then((resolve)=>{
                    if(resolve.data.status === 200 && resolve.data.dados.length != 0){
                    }
                })
        }
        handleCarregarDados()
    },[])

    useEffect(()=>{
        setAlertaSenhaInvalida(false)
        setAlertaSenhaIgual(false)
        if(password !== confirmarSenha && confirmarSenha !== '' && password !== '' && passwordVerificador !== '') 
            setAlertaSenha(true) 
        else 
            setAlertaSenha(false)
        
        if(password === confirmarSenha && confirmarSenha !== '' && password !== '' && passwordVerificador !== '') setHabilitarEdicao(false)
    },[password,confirmarSenha,passwordVerificador])


    async function handleSalvar(){

        if(password === passwordVerificador){
            setAlertaSenhaIgual(true)
        }
        else{
            setSpinAtivo(true)
            await api.get('/m1/consultar/usuario_senha?cd_pav_usuario='+decryptJWT('codigo')+'&ds_senha='+encodeURIComponent(passwordVerificador),{timeout: 20000})
                .then(async (response)=>{
                    if(response.data.status == 200 && response.data.dados.length != 0 ){
                        await api.post('/m1/alterar/usuario', {
                            "dados":{
                                "ds_senha":password,
                            },
                            "condicao":{
                                "ds_email":decryptJWT('email'),
                            }
                        },{timeout: 20000})
                        .then(function (response) {
                            setSpinAtivo(false)
                            handleMensagemSucesso(false)
                        })
                        .catch(function (error) {
                            setSpinAtivo(false)
                            setMensagemErro("Erro no cadastro de nova senha")
                            handleMensagemErro(false)
                        });
                    }
                    else if(response.data.dados.length === 0){
                        setSpinAtivo(false)
                        setAlertaSenhaInvalida(true)
                    }
                    else{
                        setMensagemErro(response.data.dados)
                        handleMensagemErro(false)
                    }
                })
        }
    }

    async function handleCancelar() {
        setSpinAtivo(false)
        despacho(revelarAlterarSenha(false))
    }
    


    useEffect(() => {
        setSpinAtivo(true)
        async function preencherTabela (){
            setSpinAtivo(false)
        }
         preencherTabela()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function iconVizualizar() {
        if(mostrarEsconder === 'text')
            setMostrarEsconder('password')
        return <img alt="olhoAtivo" className="olhoAtivo" src={olho()}/> ;
    }

    function iconNaoVizualizar() {
        if(mostrarEsconder === 'password')
            setMostrarEsconder('text')
        return <img alt="olhoDesativo" className="olhoDesativo" src={olhoDesligado()}/>;
    }

    function iconVizualizarConfirmarNovaSenha() {
        if(mostrarEsconderConfirmarNovaSenha === 'text')
            setMostrarEsconderConfirmarNovaSenha('password')
        return <img alt="olhoAtivo" className="olhoAtivo" src={olho()}/> ;
    }

    function iconNaoVizualizarConfirmarNovaSenha() {
        if(mostrarEsconderConfirmarNovaSenha === 'password')
            setMostrarEsconderConfirmarNovaSenha('text')
        return <img alt="olhoDesativo" className="olhoDesativo" src={olhoDesligado()}/>;
    }

    function iconVizualizarVerificarSenha() {
        if(mostrarEsconderVerificarSenha === 'text')
            setMostrarEsconderVerificarSenha('password')
        return <img  alt="olhoAtivo" className="olhoAtivo" src={olho()}/> ;
    }

    function iconNaoVizualizarVerificarSenha() {
        if(mostrarEsconderVerificarSenha === 'password')
            setMostrarEsconderVerificarSenha('text')
        return <img alt="olhoDesativo" className="olhoDesativo" src={olhoDesligado()}/>;
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-trocar-senha fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Alterar senha</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row className="usuario-dados-corpo-linha-alterar-senha">
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">Sua senha</div> </Form.Label>
                            <InputGroup className="container-senha">
                                <FormControl 
                                    className="campo-texto-cor-3 campo-texto"
                                    placeholder="Senha"
                                    type={mostrarEsconderVerificarSenha}
                                    onChange={e => setPasswordVerificador(e.target.value)}
                                    value = {passwordVerificador}
                                    maxLength="255"
                                />
                                <Button className='btn-olho-visualizar-senha' onClick={() => setIconsSenhaVerificador(!iconsSenhaVerificador)}>
                                        {iconsSenhaVerificador ? iconVizualizarVerificarSenha() : iconNaoVizualizarVerificarSenha()}
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">Nova senha</div> </Form.Label>
                            <InputGroup className="container-senha">
                                <FormControl 
                                    className="campo-texto-cor-3 campo-texto"
                                    placeholder="Senha"
                                    type={mostrarEsconder}
                                    onChange={e => setPassword(e.target.value)}
                                    value = {password}
                                    maxLength="255"
                                />
                                <Button className='btn-olho-visualizar-senha' onClick={() => setIcons(!icons)}>
                                        {icons ? iconVizualizar() : iconNaoVizualizar()}
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col>
                        <div className="corpo-dados-usuario-email">
                            <Form.Label> <div className="fonte-cor-1 label-campo">Confirmar senha</div> </Form.Label>
                            <InputGroup className="container-senha">
                                <FormControl 
                                    className="campo-texto-cor-3 campo-texto"
                                    placeholder="Senha"
                                    type={mostrarEsconderConfirmarNovaSenha}
                                    onChange={e => setConfirmarSenha(e.target.value)}
                                    value = {confirmarSenha}
                                    maxLength="255"
                                />
                                <Button className='btn-olho-visualizar-senha' onClick={() => setIconsNovaSenha(!iconsNovaSenha)}>
                                    {iconsNovaSenha ?iconVizualizarConfirmarNovaSenha() : iconNaoVizualizarConfirmarNovaSenha()}
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                </Form.Row>
                    {alertaSenha && <div className="label-campo fonte-cor-3">Os campos precisam ser iguais</div>}
                    {alertaSenhaInvalida && <div className="label-campo fonte-cor-3">Senha incorreta</div>}
                    {alertaSenhaIgual && <div className="label-campo fonte-cor-3">A nova senha não pode ser igual a senha anterior</div>}
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <Button  type="submit" disabled={habilitarEdicao? true : false} className={habilitarEdicao?"botao-confirmar-desabilitado":"botao-confirmar"} variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua senha foi alterada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}