//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import { Image} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../../utils/formatadorDataTabela'
import decryptJWT from '../../../utils/decryptJWT';
//ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones';
//COMPONENTES
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import ImportacaoFiltro from './importarFiltro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import ModalEditarSolicitacao from "./editarSolicitacao/editarSolicitacao" 
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { exportarLinhas, mudarManejador, desabilitarAcoesCliente, limpadorFiltros, revelarExcluirSolicitacao,limpouBusca, realizouBusca, mudarFiltros, mudarOrdenador,revelarBarraFiltragem, realizouFiltro,separadorLinhaBusca, desabilitarAcoes,limparFiltroPorArquivoFatSolicitacao as limparFiltroPorArquivoFatSolicitacaoFunc } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatContestacao/tabela.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function GridConciliacao(props){

    const [spinAtivo, setSpinAtivo] = useState(true);
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [cdSolicitacao, setCdSolicitacao]= useState()
    const [cdStatus, setCdStatus]= useState()
    const [dsStatus, setDsStatus]= useState()
    const [protocoloContestacao, setProtocoloContestacao]= useState()
    const [dataPrazoConclusao, setDataPrazoConclusao]= useState()
    const [dataFim, setDataFim]= useState()
    const [motivo, setMotivo]= useState()
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(undefined);
    const [filtroPorArquivo, setFiltroPorArquivo] = useState(undefined);

    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let revelarFiltrarSolicitacaoPorArquivo = useSelector(state => state.faturasState.revelarFiltrarSolicitacaoPorArquivo);
    let limparFiltroPorArquivo = useSelector(state => state.faturasState.limparFiltroPorArquivoFatSolicitacao);
    let temFiltros = false;
    let limparFiltros = useSelector(state => state.filtrosState.limparFiltros);
    let editarsolicitacao = useSelector(state => state.faturasState.revelarEditarSolicitacao)
    let excluirSolicitacao = useSelector(state => state.faturasState.revelarExcluirSolicitacao)
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let revelarexportarLinhas =  useSelector(state => state.manejadorState.exportarLinhas);
    let estadoVisualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);

    let itensVar = []

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        setPaginaAtual(1)
    }

    useEffect(()=>{
        restaurarConfigsIniciaisPagina()
    },[ordenador])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
                setFiltroPorArquivo(undefined)
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(() => {
        setFiltroPorArquivo(undefined)
    }, [limparFiltroPorArquivo]);

    useEffect(()=>{
        if(limparFiltros){
            if(!filtros.vazio){
                despacho(mudarFiltros({vazio: 'vazio'}))
                despacho(revelarBarraFiltragem(false))
                despacho(limpadorFiltros(false))
            }
        }
    }, [])

    useEffect(() => {
        async function pegarSeparador(){
            const separador = await api.get('/m1/consultar/preferencias?contestacao=true&ordenador='+ordenador+'&cd_pav_usuario='+decryptJWT('codigo')).then(
                function(resposta){
                    return resposta.data.dados[0];
                }
            ).catch(
                function(erro){
                    throw erro.stack;
                }
            )
            despacho(separadorLinhaBusca(separador.busca_separador))
        }

        pegarSeparador();

    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("fat_contestacao"));
        despacho(desabilitarAcoes(true));

        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            if(conteudoBusca  && !filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens(); 
                    const { data } = await api.get('/m1/consultar/fat_solicitacao?buscar='+conteudoBusca+'&ordenador='+ordenador+'&contestacao=true&paginaAtual='+contador+'&carregarLimit='+valorLimite+'&separador='+separadorLinha+"&cd_pav_usuario="+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }else if(filtros.vazio || filtroPorArquivo){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    separador:separadorLinha,
                    filtros: filtros,
                    filtroArquivo:filtroPorArquivo,
                    ordenador:ordenador,
                    cd_pav_usuario:decryptJWT('codigo'),
                    contestacao: true
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fat_solicitacao", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fat_solicitacao?paginaAtual='+contador+'&ordenador='+ordenador+'&contestacao=true&carregarLimit='+valorLimite+"&cd_pav_usuario="+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador, filtroPorArquivo])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/fat_solicitacao?contar=true&contestacao=true&buscar='+conteudoBusca+'&ordenador='+ordenador+'&separador='+separadorLinha+"&cd_pav_usuario="+decryptJWT('codigo'));
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    separador:separadorLinha,
                    filtros: filtros,
                    filtroArquivo:filtroPorArquivo,
                    ordenador:ordenador,
                    contar: true,
                    cd_pav_usuario:decryptJWT('codigo'),
                    contestacao: true,
                }
                apiData = await api.post("/m1/filtrar/fat_solicitacao", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function alterarTemFiltros(){
       temFiltros = !temFiltros
    }

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }


    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }
    
    const colunas = [
        {
            dataField: 'cd_fat_solicitacao',
            text: 'ID',
            hidden:true,
            sort: true,
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
            headerStyle: (colum, colIndex) => {
                return { width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },

        {
            dataField: 'tipo',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("tipo",'tipo')}>
            Tipo solicitação
            <Image id="tipotipo" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },

    
        {
            dataField: 'ds_protocolo_contestacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_protocolo_contestacao",'ds_protocolo_contestacao')}>
                Protocolo
            <Image id="ds_protocolo_contestacaods_protocolo_contestacao" className='d-none' src={setaDropdown()}/> 
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'cd_fat_solicitacao_status',
            headerClasses: 'nao-selecionavel',
            text: 
            <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_fat_solicitacao_status",'cd_fat_solicitacao_status')}>
                Status
            <Image id="cd_fat_solicitacao_statuscd_fat_solicitacao_status" className='d-none' src={setaDropdown()}/> 
            </button>,
            sort: true,
            formatter: (cell, row) => {
                if(cell === 2){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-verde"/><p className="fonte-cor-1 label-tabela-1">Concluída</p></div>);
                }else if(cell === 3){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-vermelha"/><p className="fonte-cor-1 label-tabela-1">Cancelada</p></div>);
                }else if(cell === 4){
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-azul"/><p className="fonte-cor-1 label-tabela-1">Em Andamento</p></div>);
                }else{
                    return (<div className="tabela-automacao-container-esfera"><div className="tabela-automacao-esfera-amarela"/><p className="fonte-cor-1 label-tabela-1">Aberta</p></div>);
                }
            }
        },
        
        {
            dataField: 'operadora',
            headerClasses: 'nao-selecionavel',
            text:  
            <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("operadora",'operadora')}>
                Operadora
                <Image id="operadoraoperadora" className='d-none' src={setaDropdown()}/> 
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },


        {
            dataField: "dt_inicio",
            headerClasses: 'nao-selecionavel',
            text:  
            <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_inicio",'dt_inicio')}>
                Data da solicitação
                <Image id="dt_iniciodt_inicio" className='d-none' src={setaDropdown()}/> 
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },

        {
            dataField: "dt_prazo_conclusao",
            headerClasses: 'nao-selecionavel',
            text: 
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_prazo_conclusao",'dt_prazo_conclusao')}>
                Previsão de conclusão
                <Image id="dt_prazo_conclusaodt_prazo_conclusao" className='d-none' src={setaDropdown()}/> 
                </button>,
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },

        {
            dataField: "dt_fim",
            headerClasses: 'nao-selecionavel',
            text: 
            <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_fim",'dt_fim')}>
            Data de conclusão
            <Image id="dt_fimdt_fim" className='d-none' src={setaDropdown()}/> 
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },

        {
            dataField: 'ds_motivo',
            headerClasses: 'nao-selecionavel',
            text: 
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_motivo",'ds_motivo')}>
                    Descrição
                    <Image id="ds_motivods_motivo" className='d-none' src={setaDropdown()}/> 
                </button>,
            sort: true,
            formatter: (cell, row) => {
                if(cell != undefined && cell != null)
                    return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
                else
                    return <p className="fonte-cor-1 label-tabela-1">{row.ds_motivo}</p>;
            }
        },

        {
            dataField: 'login_usuario_created',
            headerClasses: 'nao-selecionavel',
            text: 
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("login_usuario_created",'login_usuario_created')}>
                    Responsável
                    <Image id="login_usuario_createdlogin_usuario_created" className='d-none' src={setaDropdown()}/> 
                </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    ]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionar(
                    row.cd_fat_solicitacao, 
                    row.ds_status,
                    row.cd_status,
                    row.ds_protocolo_contestacao,
                    row.dt_prazo_conclusao,
                    row.dt_fim,
                    row.ds_motivo,
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionar(cd_fat_solicitacao, dsStatus, cdStatus, ds_protocolo_contestacao, dt_prazo_conclusao,dt_fim, ds_motivo){
        
        setCdSolicitacao(cd_fat_solicitacao)
        setDsStatus(dsStatus)
        setCdStatus(cdStatus)
        setProtocoloContestacao(ds_protocolo_contestacao)
        setDataPrazoConclusao(dt_prazo_conclusao)
        setDataFim(dt_fim)
        setMotivo(ds_motivo)
        despacho(desabilitarAcoes(false));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setSolicitacaoSelecionada(row)
            despacho(desabilitarAcoes(false));

        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                setRevelarMensagemErro(false)
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            despacho(revelarExcluirSolicitacao(false))
            if(confirmacao === true){
                handleDeletar()
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_solicitacao?cd_fat_solicitacao='+cdSolicitacao+'&ordenador='+ordenador+'&cd_pav_usuario_deleted='+decryptJWT('codigo')+'&cd_fat_solicitacao_tipo=18')
        .then(function (response) {
            setRenderizar(!renderizar)
            despacho(revelarExcluirSolicitacao(false))
            handleMensagemSucesso(false)
            setSpinAtivo(false)

        })
        .catch(function (error) {
            setMensagemErro("Erro ao excluir contestação!")
            despacho(revelarExcluirSolicitacao(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(revelarexportarLinhas){
            async function handleExportarLinhas(){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de Contestações',
                    tipo_arquivo: revelarexportarLinhas,
                    tipo_relatorio: 9,
                    filtros: filtros,
                    separador: separadorLinha,
                    filtroArquivo: filtroPorArquivo,
                    buscar: conteudoBusca,
                    separador: '',
                }
                api.post('/m1/cadastrar/relatorios',dados)
                handleMensagemInformacao(true)
                despacho(exportarLinhas(false))
            }
            handleExportarLinhas()
        }
    },[revelarexportarLinhas])
    
    return(
        <div className='container-tela'>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            {editarsolicitacao ===true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-adicionar-login-container">
                        <ModalEditarSolicitacao
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            tituloJanela={"Editar"}
                            cdSolicitacao={cdSolicitacao}
                            cdStatus={cdStatus}
                            dsStatus={dsStatus}
                            protocoloContestacao={protocoloContestacao}
                            dataPrazoConclusao={dataPrazoConclusao}
                            dataFim={dataFim}
                            motivo={motivo}
                        />
                    </div>
                </div>
            } 
            {estadoVisualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemTitulo={"O arquivo está sendo gerado"}
                            mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                            mensagemBotao={"Voltar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={''}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { excluirSolicitacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemConfirmarExclusao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Deletar contestação "+cdSolicitacao+"?"}
                        />
                    </div>
                </div>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='cd_fat_solicitacao' 
                                data={itens} 
                                columns={colunas} 
                                selectRow={selecaoLinhas}
                                rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            {revelarFiltrarSolicitacaoPorArquivo === true  &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-historico-acesso-container">
                        <ImportacaoFiltro
                            valorLimite={valorLimite}
                            paginaAtual={paginaAtual}
                            setFiltroPorArquivo={setFiltroPorArquivo}
                            alterarTemFiltros={alterarTemFiltros}
                        > </ImportacaoFiltro>
                    </div>
                </div>
            }
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(GridConciliacao));