//IMPORTAÇÕES
/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'; /*eslint-disable*/
import {api, api_papeis} from '../../../conexoes/api';/*eslint-disable*/
import Switch from '@mui/material/Switch'
import { green } from '@mui/material/colors'
import { alpha, styled } from '@mui/material/styles'


import { useForm } from 'react-hook-form';
import { Button, Form, Col, Tooltip, OverlayTrigger, Tabs, Tab } from 'react-bootstrap';
import { elipseVerde, fecharJanela, setaDropdown } from '../../../utils/alternanciaIcones'
//MODAIS
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from "../../componentesModais/mensagens/mensagemErro"
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import { useDispatch } from 'react-redux';
import "../../../css/painelPermissaoRBAC/cadPapeis/estilo.css"
//import '../estilo.css'
import { MascaraCpf, MascaraCnpj, MascaraTelefone, MascaraCep } from '../../../utils/mascarasCampos'
import { revelarNovoCliente } from "../../../redux/actions/index";
import { revelarEditarCliente } from "../../../redux/actions/index";
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js';
//ICONES
import {lixeiraVermelho, importarArquivo, defaultImage} from '../../../utils/alternanciaIcones'

//import MenuCadastroPermissoes from './permissoes'
const axios = require('axios');

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));



export default function CadastroCliente(props) {
    const { register, handleSubmit, errors } = useForm()
    const despacho = useDispatch()
    const modal = useRef()
    const inputImportRef = useRef()
    const [spinAtivo, setSpinAtivo] = useState(true)

    // VARIAVEIS CADASTRO
    const [dsNomeCliente, setDsNomeCliente] = useState()
    const [dsIDCliente, setDsIDCliente] = useState()
    const [dsIDParceiro, setDsIDParceiro] = useState()
    const [dsSubdominio, setSubdominio] = useState()
    const [dsImagem, setDsImagem] = useState()
    const [chatbotAtivo, setChatbotAtivo] = React.useState (false)

    const [form, setForm] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [logoChanged, setLogoChanged] = useState(false)
    const [fileType, setFileType] = useState('')
    const [logoBorder, setLogoBorder] = useState('')

    const [phOperadoras, setPhOperadoras] = useState('Selecione')
    const [cdOperadoras, setCdOperadoras] = useState([])
    const [dsOperadoras, setDsOperadoras] = useState([])

    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [elementoAtivadoPrincipal, setElementoAtivadoPrincipal] = useState(1)
    const [tabAtivadoPrincipal, setTabAtivadoPrincipal] = useState(1)
    const [dsMensagemSucesso, setDsMensagemSucesso] = useState('Cadastro efetuado com sucesso.')
    const [dsMensagemErro, setDsMensagemErro] = useState('Algo deu errado, tente novamente. Se o erro persistir entre em contato com o suporte.')
    const [liberarCadastro, setLiberarCadastro] = useState(true)
    const [flAtivo, setFlAtivo] = useState(true)
    const [tituloJanela, setTituloJanela] = useState("Cadastrar")
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]:value
        })
        if(!!formErrors[field]){
            setFormErrors({
                ...formErrors,
                [field]:null
            })
        }
    }

    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    useEffect( () => {
        if (props.tituloJanela === 'Editar') {
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS

            let partnerPayload = {
                'papel_codigo': props.codigoPapel,
                'papel_nome': props.nomePapel,
                'papel_descricao': props.descricaoPapel,
            }

            setForm(partnerPayload);

            setDsNomeCliente(props.dsNomeCliente)
            setDsIDCliente(props.dsIDCliente)
            setSubdominio(props.dsSubdominio)
            setDsImagem(props.dsImagem)
            setDsIDParceiro(props.dsIDParceiro);
            setChatbotAtivo(Boolean(props.chatbotAtivo))
        }
    }, [])


    async function handleCancelar(){
        setSpinAtivo(false)
        if (tituloJanela === "Cadastrar") {
            ColetaClickstream(1433) // dropAcoesrevelarCadastroClienteNovoCancelar
            despacho(revelarNovoCliente(false))
        } else if (tituloJanela === "Editar") {
            ColetaClickstream(1433)
            despacho(revelarEditarCliente(false))
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true)
        } else {
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if (confirmacao) {
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true)
        } else {
            setRevelarMensagemErro(false)
        }
        if (confirmacao === true) {
            //ALGUMA FUNÇÃO
        }
    }

    function handerAlterarTabPrincipal(k) {
        setElementoAtivadoPrincipal(k)
        setTabAtivadoPrincipal(k)
    }

    function handleCpf(valor) {
        setDsCpf(MascaraCpf(valor))
    }

    function handleCnpj(valor) {
        setDsCnpj(MascaraCnpj(valor))
    }

    function handleTelefone(valor) {
        setDsTelefone(MascaraTelefone(valor))
    }

    function handleCep(valor) {
        setDsCep(MascaraCep(valor))
    }

    useEffect(() => {
        let placeholderAux = false
        cdOperadoras.forEach(function (item, index) {
            if (item === -1) {
                placeholderAux = true
            }
        })
        if (placeholderAux === true) {
            setPhOperadoras(cdOperadoras.length - 1 + " Selecionados")
        }
        else if (cdOperadoras.length === 1) {
            setPhOperadoras(dsOperadoras[0])
        }
        else if (cdOperadoras.length === 0) {
            setPhOperadoras("Selecione")
        }
        else {
            setPhOperadoras(cdOperadoras.length + " Selecionados")
        }
    }, [cdOperadoras.length, cdOperadoras[0], cdOperadoras[cdOperadoras.length - 1]])

    async function handlerConsultarCep(cep) {
        let auxCep = cep.replace('-', '')
        setSpinAtivo(true)
        await api.get('/m1/consultar/cep?cep=' + auxCep)
            .then(function (response) {
                if (response.data.status == 200) {
                    if (response.data.dados == 'Cep Invalido') {
                        setDsMensagemErro('Cep Invalido.')
                        setDsLogradouro('')
                        setDsBairro('')
                        setDsCidade('')
                        setDsEstado('Selecione')
                        setCdEstado('')
                        handleMensagemErro()
                    }
                    else {
                        setDsLogradouro(response.data.dados.logradouro)
                        setDsBairro(response.data.dados.bairro)
                        setDsCidade(response.data.dados.localidade)
                        setDsEstado(response.data.dados.uf)
                        setCdEstado(response.data.dados.cd_pav_uf)
                    }
                }
                setSpinAtivo(false)
                return response
            })
            .catch(function (error) {
                setDsMensagemErro('Algo deu errado ao tentar consultar o CEP. Por favor, tente novamente mais tarde.')
                handleMensagemErro()
                setSpinAtivo(false)
                return error
            })

    }

    async function teste() {
        setFlAtivo(!flAtivo)
    }

    async function handleConsultarCnpj(cnpj) {
        let auxCnpj = cnpj.replaceAll('.', '')
        auxCnpj = auxCnpj.replaceAll('/', '')
        auxCnpj = auxCnpj.replaceAll('-', '')
        setSpinAtivo(true)
        await axios.get(`https://cors-anywhere.herokuapp.com/http://www.receitaws.com.br/v1/cnpj/` + auxCnpj)
            .then(function (resposta) {
                if (!resposta.data.status == "ERRO") {
                    setDsNomeFantasia(resposta.data.fantasia)
                    setDsRazaoSocial(resposta.data.nome)
                    setDsEmail(resposta.data.email)
                    setDsTelefone(resposta.data.telefone)
                    let auxCep = resposta.data.cep
                    auxCep = auxCep.replace("-", "")
                    auxCep = auxCep.replace(".", "")
                    handlerConsultarCep(auxCep)
                }
                setSpinAtivo(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
            });
    }

    async function handleCadastrar(partnerObj) {

        setSpinAtivo(true)

        await api_papeis.post('/api/v2/papers/create', partnerObj
        )
            .then(async function (response) {
                setDsMensagemSucesso('Papel ' + form.papel_codigo + ' cadastrado com sucesso.')
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setDsMensagemErro(error.response.data.erro)
                handleMensagemErro(false)
            });
    }

    function validatePartnerForm(partnerObj){

        let newErrors = {}

        if (!partnerObj?.papel_codigo || partnerObj?.papel_codigo === ''){
            newErrors = {
                ...newErrors,
                ['papel_codigo']: 'O código do papel é obrigatório.',
            }
        }

        if (!partnerObj?.papel_descricao || partnerObj?.papel_descricao === ''){
            newErrors = {
                ...newErrors,
                ['papel_descricao']: 'A descricao do papel é obrigatória'
            }
        }

        if (!partnerObj?.papel_nome || partnerObj?.papel_nome === ''){
            newErrors = {
                ...newErrors,
                ['papel_nome']: 'O nome do papel é obrigatório'
            }
        }

        return newErrors
    }

    function handleSalvar() {

        // let partnerPayload = {
        //     'cd_pav_cliente_parceiro': undefined,
        //     'cd_pav_cliente': dsIDCliente,
        //     'ds_sub_dominio': dsSubdominio,
        //     'nome': dsNomeCliente,
        //     'logo': dsImagem,
        //     'fl_chat': chatbotAtivo,
        // }

       const errors =  validatePartnerForm(form);

        if(Object.keys(errors).length) {
            setFormErrors(errors)

        } else {
            if (tituloJanela === 'Editar') {
                handleAlterar(form)
            } else {
                handleCadastrar(form)
            }
        }
    }

    async function handleAlterar(partnerObj) {
        partnerObj.cd_pav_papel = props.idPapel
        setSpinAtivo(true)

        await api_papeis.post('/api/v2/papers/update',partnerObj)
            .then(function (response) {
                setDsMensagemSucesso('Papel '+form.papel_codigo+' alterado com sucesso')
                setSpinAtivo(false)
                handleMensagemSucesso(false)

            })
            .catch(function (error) {
                setDsMensagemErro(error.response.data.erro)
                setRevelarMensagemErro(true)
                setSpinAtivo(false)
            });
    }

    // useEffect(() => {
    //     if (
    //         papel_nome != '' &&
    //         papel_codigo != '' &&
    //         papel_descricao != ''
    //     ) {
    //         setLiberarCadastro(true)
    //     } else {
    //         setLiberarCadastro(false)
    //     }
    // }, [papel_nome, papel_codigo, papel_descricao])




    function handleImportLogo(data) {
        inputImportRef.current.click();
    }

    function handleRemoveLogo(data) {
        inputImportRef.current.value = '';
        setLogoChanged(true);
        setField('logo', '')
        setLogoBorder('red-logo-border')
    }

    const handleActiveStatus = (event) => {
        setField('fl_chat', event.target.checked)
    }

    const handleFileChange = (event) => {
        let file = event.target.files[0]
        if (file && file.type === 'image/svg+xml'){
            const reader = new FileReader()


            reader.onload = (e) => {
                const base64String = e.target.result;
                setLogoChanged(true);
                setField('logo', base64String);
                setLogoBorder('green-logo-border')

            }
            reader.readAsDataURL(file)
            file = '';
        } else {
            console.error('error', file.type);
            setFileType(file.type)
            setLogoBorder('red-logo-border')
            setField('logo', '');
        }
    }

    function parseButtonText(buttonText) {
        if (buttonText === 'Editar'){
            return 'Salvar Edição'
        } else {
            return buttonText
        }
    }

    return (
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            {spinAtivo && <div className='componente-spinner-overlay'><div className='componente-spinner-container'><SpinerVeye /></div></div>}
            <div className='cadastro-papel fundo-cor-6'>
                <Form.Row>
                    <Col>
                        <img className='icone-label-janela nao-selecionavel' src={elipseVerde()} alt='elipse' />
                        <Form.Label>
                            <div className='fonte-cor-1 label-janela'>{tituloJanela} Papel</div>
                        </Form.Label>
                        <Button className='botao-fechar-janela' variant='danger' onClick={() => handleCancelar()}>
                            <img className='botao-fechar-janela-icone' src={fecharJanela()} alt='fechar-janela' />
                        </Button>
                    </Col>
                </Form.Row>
                <Tabs activeKey={tabAtivadoPrincipal} onSelect={(k) => handerAlterarTabPrincipal(k)} class="backoffice-cadastro-cliente-tabs" id="uncontrolled-tab-example">
                    <Tab onClick={() => setTabAtivadoPrincipal("1")} eventKey={"1"}>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                            <Form.Group className="cadastro-parceiro-cliente">
                                <Form.Label>
                                    <div className='fonte-cor-1 label-campo'>Nome do Papel *</div>
                                </Form.Label>
                                <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                overlay={<Tooltip id={`tooltip-bottom`}>Insira o nome do
                                                    Papel</Tooltip>}>
                                    <>
                                        <Form.Control

                                            value={form.papel_nome}
                                            className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-large'
                                            type='text'
                                            placeholder='Insira o nome do papel'
                                            onChange={e => setField('papel_nome', e.target.value)}
                                            isInvalid={!!formErrors.papel_nome}
                                        />
                                        <Form.Control.Feedback type="invalid">O nome do papel é obrigatório</Form.Control.Feedback>
                                    </>

                                </OverlayTrigger>
                            </Form.Group>

                                <Form.Group className="cadastro-parceiro-cliente">
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Código do Papel *</div>
                                    </Form.Label>
                                    <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                    overlay={<Tooltip id={`tooltip-bottom`}>Insira o código do
                                                        papel</Tooltip>}>
                                        <>
                                            <Form.Control
                                                value={form.papel_codigo}
                                                className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-small'
                                                type='text'
                                                placeholder='Insira o código do papel'
                                                onChange={e => setField('papel_codigo', e.target.value)}
                                                isInvalid={!!formErrors.papel_codigo}
                                            />
                                            <Form.Control.Feedback type="invalid">{formErrors.papel_codigo}</Form.Control.Feedback>
                                        </>

                                    </OverlayTrigger>
                                </Form.Group>


                        </div>
                        <div className='backoffice-cadastro-parceiro-linha-1'>
                                <Form.Group className="cadastro-parceiro-cliente">
                                    <Form.Label>
                                        <div className='fonte-cor-1 label-campo'>Descrição do papel *</div>
                                    </Form.Label>
                                    <OverlayTrigger delay={{show: 500, hide: 250}} placement="bottom"
                                                    overlay={<Tooltip id={`tooltip-bottom`}>Insira a descrição do papel</Tooltip>}>
                                       <>
                                           <Form.Control
                                               value={form.papel_descricao}
                                               className='campo-texto-cor-3 campo-texto-parceiros cad-parceiros-large'
                                               type='text'
                                               placeholder='Descreva o papel'
                                               onChange={e => setField('papel_descricao', e.target.value)}
                                               isInvalid={!!formErrors.papel_descricao}
                                           />
                                           <Form.Control.Feedback type='invalid'>{formErrors.papel_descricao}</Form.Control.Feedback>
                                       </>
                                    </OverlayTrigger>
                                </Form.Group>
                        </div>

                    </Tab>
                </Tabs>
                <div className={'action-buttons-row'}>
                            <div style={{color: '#D32F2F'}}>Campos obrigatórios *</div>
                </div>
                <Form.Row>
                    <Col>
                        {liberarCadastro === false ?
                            <Button type='submit' className={'botao-confirmar-desabilitado'} variant='success' size='sm'>
                                <div className='fonte-cor-4'>{parseButtonText(tituloJanela)}</div>
                            </Button>
                            :
                            <Button type='submit' className={'botao-confirmar'} variant='success' size='sm'>
                                <div className='fonte-cor-4'>{parseButtonText(tituloJanela)} </div>
                            </Button>
                        }
                        <Button className='botao-cancelar' variant='success' size='sm' onClick={() => handleCancelar()}>
                            <div className='fonte-cor-2'>Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            {revelarMensagemSucesso &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={'Sucesso'}
                            mensagemParagrafo1={dsMensagemSucesso}
                            mensagemBotao={'Continuar'}
                        />
                    </div>
                </div>
            }
            {revelarMensagemErro &&
                <div className='componente-modal-overlay'>
                    <div className='componente-modal'>
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={'Erro'}
                            mensagemParagrafo1={dsMensagemErro}
                            mensagemBotao={'Retornar'}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}
