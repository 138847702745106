//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Card, Button, Form, ButtonToolbar, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {Image} from 'react-bootstrap';
//ICONES
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import {lixeiraVermelho, editarVerde, passarPaguinaDireita, passarPaguinaEsquerda} from '../../../utils/alternanciaIcones';
import iconeSucesso from "../../../icones/icone-swal-success.png";
import iconeFalha from "../../../icones/icone-swal-fail.png";
//COMPONENTES
import CadastroCliente from './cadastro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import { elipseVerde, elipseVermelho } from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { visualizarDados, mudarManejador, limpadorFiltros, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, desabilitarAcoes, revelarNovoCliente, revelarEditarCliente, revelarExcluirCliente } from "../../../redux/actions/index";
//SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavCliente/pavCliente.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Grid(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdCliente ,setCdCliente] = useState("");
    const [dsCliente ,setDsCliente] = useState("");
    const [dsCpf ,setDsCpf] = useState("");
    const [dsCnpj ,setDsCnpj] = useState("");
    const [cdPessoa ,setCdPessoa] = useState("");
    const [dsNomeFantasia ,setDsNomeFantasia] = useState("");
    const [dsEmail ,setDsEmail] = useState("");
    const [dsTelefone ,setDsTelefone] = useState("");
    const [cdTipoPessoa ,setCdTipoPessoa] = useState("");
    const [dsTipoPessoa ,setDsTipoPessoa] = useState("");
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENSAGENS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS MODAL
    const [renderizarCadastro, setRenderizarCadastro] = useState(false)
    const [renderizarEdicao, setRenderizarEdicao] = useState(false)
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    const [revelarEdicao, setRevelarEdicao] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let NovoCliente =  useSelector(state => state.manejadorState.revelarNovoCliente);
    let EditarCliente =  useSelector(state => state.manejadorState.revelarEditarCliente);
    let ExcluirCliente =  useSelector(state => state.manejadorState.revelarExcluirCliente);
    let temFiltros = false;
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(113) // 'pavClienteTabelaGrid'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("cliente"));
        despacho(desabilitarAcoes(true));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }  


            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/cliente?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/cliente", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/cliente?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData;
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/cliente?contar=true&buscar='+conteudoBusca);
            } else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/cliente", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const colunas = [{
        dataField: 'cd_pav_cliente',
        text: 'ID',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'cd_pav_pessoa',
        headerClasses: 'nao-selecionavel',
        text: 'ID pessoa',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_nome',
        headerClasses: 'nao-selecionavel',
        text: 'Nome',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'cd_pav_tipo_pessoa',
        headerClasses: 'nao-selecionavel',
        text: 'ID tipo pessoa',
        hidden: false,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_tipo_pessoa',
        headerClasses: 'nao-selecionavel',
        text: 'Tipo pessoa',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_cnpj',
        headerClasses: 'nao-selecionavel',
        text: 'CNPJ',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_cpf',
        headerClasses: 'nao-selecionavel',
        text: 'CPF',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_cliente',
        headerClasses: 'nao-selecionavel',
        text: 'Razão social',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_nome_fantasia',
        headerClasses: 'nao-selecionavel',
        text: 'Nome fantasia',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_email',
        headerClasses: 'nao-selecionavel',
        text: 'E-mail',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_telefone',
        headerClasses: 'nao-selecionavel',
        text: 'Telefone',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'fl_ativo',
        text: 'Ativo',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell || cell == 0 ? 
                    cell == 1 ?
                        <>
                        <Image className="img-eclipse-status" src={elipseVerde()}/>
                        Sim
                        </>
                    :
                    <>
                        <Image className="img-eclipse-status" src={elipseVermelho()}/>
                        Não
                    </> 
                :''}
            </p>;
        }
    },
    {
        dataField: '',
        text: colunaAcoes(),
        headerClasses: 'centralizar tabela-coluna-ultima-header nao-selecionavel',
        classes: 'botoes-barra-acoes',
        formatter: (cell, row) => {
            return (
                <ButtonToolbar className="container-botoes-acoes">
                    <Button variant="info" className="botoes-tabela-acoes" size="sm" onClick={() => handleEditar()}>
                        <img className="icone-botao-acao-tabela" src={editarVerde()} alt="editar-linha"/>
                    </Button>
                    <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                        <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                    </Button>
                </ButtonToolbar>
            )
        }
    }]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdCliente(row.cd_pav_cliente);
                setDsCliente(row.ds_pav_cliente);
                setDsCpf(row.ds_cpf);
                setDsCnpj(row.ds_cnpj);
                setCdPessoa(row.cd_pav_pessoa);
                setDsNomeFantasia(row.ds_nome_fantasia);
                setDsEmail(row.ds_email);
                setDsTelefone(row.ds_telefone);
                setCdTipoPessoa(row.cd_pav_tipo_pessoa);
                setDsTipoPessoa(row.ds_pav_tipo_pessoa)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // setCdCliente(row.cd_pav_cliente);
            // setDsCliente(row.ds_pav_cliente);
            // setDsCpf(row.ds_cpf);
            // setDsCnpj(row.ds_cnpj);
            // setCdPessoa(row.cd_pav_pessoa);
            // setDsNomeFantasia(row.ds_nome_fantasia);
            // setDsEmail(row.ds_email);
            // setDsTelefone(row.ds_telefone);
            // setCdTipoPessoa(row.cd_pav_tipo_pessoa);
            // setDsTipoPessoa(row.ds_pav_tipo_pessoa)
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function colunaAcoes(){
        return(
            <p className="acoes">Ações</p>
        );
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/cliente?cd_pav_cliente='+cdCliente)
        .then(function (response) {
            setRenderizar(!renderizar)
            despacho(revelarExcluirCliente(false))
            handleMensagemSucesso(false)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            despacho(revelarExcluirCliente(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    function handleCadastrar(){
        setRevelarCadastro(true)
        setRenderizarCadastro(!renderizarCadastro)
    }
    
    function handleEditar(){
        // setSpinAtivo(true)
        setRevelarEdicao(true)
        setRenderizarEdicao(!renderizarEdicao)
        setSpinAtivo(false)
    }

    function handleCancelar(){
        setRevelarMensagemAtencao(false)
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        setRevelarCadastro(false)
        setRevelarEdicao(false)
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                ColetaClickstream(1313) // dropAcoesRevelarExcluirClienteExcluir
                handleDeletar()
            } else {
                ColetaClickstream(1024) // dropAcoesRevelarExcluirClienteCancelar
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { NovoCliente === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-cliente-container">
                        <CadastroCliente
                            tituloJanela={'Cadastrar'}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroCliente>
                    </div>
                </div>
            }
            { EditarCliente === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-cliente-container">
                        <CadastroCliente
                            tituloJanela={'Editar'}
                            cdCliente={cdCliente}
                            dsCliente={dsCliente}
                            dsCpf={dsCpf}
                            dsCnpj={dsCnpj}
                            cdPessoa={cdPessoa}
                            dsNomeFantasia={dsNomeFantasia}
                            dsEmail={dsEmail}
                            dsTelefone={dsTelefone}
                            dsTipoPessoa={dsTipoPessoa}
                            cdTipoPessoa={cdTipoPessoa}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            >
                        </CadastroCliente>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_cliente' 
                                data={itens} 
                                condensed={true}
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Cliente "+ dsCliente +" deletado."}
                    mensagemBotao={"Continuar"}
                />
            }
            { ExcluirCliente &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+dsCliente}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
}  
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));