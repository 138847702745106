//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Form, Button, Tooltip, Col, OverlayTrigger } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Step, StepLabel, Stepper} from "@mui/material";
//REDUX
import { useDispatch, useSelector } from 'react-redux';

import { revelarRealocacao } from "../../../redux/actions/index";
import {api} from '../../../conexoes/api';
import {formatarData} from '../../../utils/formatadorDataTabela'
//ICONES
import {elipseVerde, fecharJanela, lixeiraVermelho, alertaAtencao, setaDropdown } from '../../../utils/alternanciaIcones';

import PaginacaoTabela from '../../../utils/paginacaoTabela'

import {identificarValidacaoAcoes} from '../../../utils/identificarValidacaoAcoes';

//MODAIS
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import SelectFormaEnvio from '../../componentesModais/selects/selectFormaEnvio'
import SelectTipoEndereco from '../../componentesModais/selects/selectTipoEndereco'
import SelectUf from '../../componentesModais/selects/selectUf2'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

import decryptJWT from '../../../utils/decryptJWT'
import SelectMotivoReposicao from '../../componentesModais/selects/selectMotivoReposicao';
import { AvisoCampoObrigatorio, iconeAlerta, RenderInputRows } from '../../adicionarSimcards/RenderInputs';
import BotoesDadosEnvio from '../../dadosDeEnvio/BotoesDadosEnvio';
const moment = require('moment');

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let simcardSelecionados = [];
let itensVar = []
let somaQtd = []

const routeM1FiltrarSimcard = '/m1/filtrar/simcard';

export default function CancelamentoReposicao(props) {  
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    
    const { register, handleSubmit, errors } = useForm();
    const [dsMotivo, setDsMotivo] = useState("");
    const [caracteresMensagem, setCaracteresMensagem] = useState(1000);
    const [renderizar, setRenderizar] = useState(false);
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    const [itemRemover, setItemRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [simcards, setSimcards] = useState([]);
    const [itens, setItens] = useState([])
    const [linhas, setLinhas] = useState([]);
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensInvalidosBusca, setItensInvalidosBusca] = useState(false);
    const [itensOperadora, setItensOperadora] = useState([]);
    const [cdOperadoraSelecionada, setCdOperadoraSelecionada] = useState(0);
    const [dsOperadoraSelecionada, setDsOperadoraSelecionada] = useState('Selecione');
    const [dsTamanhoSelecionada, setDsTamanhoSelecionada] = useState('Selecione');
    const [operadoraTamanho, setOperadoraTamanho] = useState([]);
    const [operadoraTamanhoExibir, setOperadoraTamanhoExibir] = useState([]);
    const [operadoras, setOperadoras] = useState([])
    const [dsResponsavel, setDsResponsavel] = useState('')
    const [cdFormaEnvio, setCdFormaEnvio] = useState(-1)
    const [dsFormaEnvio, setDsFormaEnvio] = useState('Selecione')
    const [revelarSelectFormaEnvio, setRevelarSelectFormaEnvio] = useState(false)
    const [cdTipoEndereco, setCdTipoEndereco] = useState(-1)
    const [dsTipoEndereco, setDsTipoEndereco] = useState('Selecione')
    const [revelarSelectTipoEndereco, setRevelarSelectTipoEndereco] = useState(false)
    const [dsCep, setDsCep] = useState('')
    const [dsCepValidador, setDsCepValidador] = useState(false)
    const [dsCepErroInfo, setDsCepErroInfo] = useState('')
    const [dsEndereco, setDsEndereco] = useState('')
    const [dsNumero, setDsNumero] = useState('')
    const [dsBairro, setDsBairro] = useState('')
    const [dsCidade, setDsCidade] = useState('')
    const [cdEstado, setCdEstado] = useState(-1)
    const [dsNomeFantasia, setDsNomeFantasia] = useState('')
    const [dsOutroEnvio, setDsOutroEnvio] = useState('')
    const [dsEstado, setDsEstado] = useState('Selecione')
    const [revelarSelectUf, setRevelarSelectUf] = useState(false)
    const [infoAdicionais, setInfoAdicionais] = useState('');
    const [blEditarEndereco, setBlEditarEndereco] = useState(false)
    const [blAdicionarEndereco, setBlAdicionarEndereco] = useState(false)
    const [somatorio, setSomatorio] = useState(0);
    const [revelarMensagemSucessoEdicao, setRevelarMensagemSucessoEdicao] = useState(false)
    const [revelarMensagemErroEdicao, setRevelarMensagemErroEdicao] = useState(false)
    const [revelarMensagemErroAdicao, setRevelarMensagemErroAdicao] = useState(false)
    const [revelarMensagemSucessoAdicao, setRevelarMensagemSucessoAdicao] =useState(false)


    //HOOKS SELECTS
    const [itensArray, setItensArray] = useState([{'cd_pedido': 1,'OPERADORA': '', "cd_dsp_operadora": '', 'PLANO': '', "cd_dsp_plano": '', 'TAMANHO': '','NUMERO_SIMCARDS': ''}])

    const [dsNovoPlano, setDsNovoPlano] = useState('Selecione');
    const [cdNovoPlano, setCdNovoPlano] = useState();
    const [revelarSelectNovoPlano, setRevelarSelectNovoPlano] = useState(false)
    const [beforeRevelarSelectNovoPlano, setBeforeRevelarSelectNovoPlano] = useState(false)

    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencaoValor, setRevelarMensagemAtencaoValor] = useState(false)
    const [revelarMensagemErroQuantidade, setRevelarMensagemErroQuantidade] = useState(false)
    const [revelarMensagemErroQuantidadeMenor, setRevelarMensagemErroQuantidadeMenor] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemLinhasIncapacitadas, setRevelarMensagemLinhasIncapacitadas] = useState(true)
    const [revelarSelectMotivo, setRevelarSelectMotivo] = useState(false)
    const [inputRevelado, setInputRevelado] = useState('Selecione');
    const [dsQuantidade, setDsQuantidade] = useState('Digite a quantidade')
    const [ADE_CODCAT, setADE_CODCAT] = useState()
    const [ADE_CODCAU, setADE_CODCAU] = useState()

    const mostrarBotaoAdicionarEndereco = blEditarEndereco === false && blAdicionarEndereco === false;
    const mostrarBotacaoCancelarAdicionarEndereco = blEditarEndereco === false && blAdicionarEndereco === true;

    //OUTROS HOOKS
    const despacho = useDispatch();
    const modal = useRef();
    const valor_queima = 5

    //STEPPER
    const localTema = localStorage.getItem('tema')
    const [stepActive, setStepActive] = useState(0)
    const steps = ['Linhas Selecionadas', 'Adicionar SIM Cards', 'Dados do envio'];
    const stepsStyle = {
        "& .Mui-active": {
            "& .MuiStepIcon-root": {
                color: '#33CC99',
                fontSize: "1.5rem !important"
            },
        },
        "& .Mui-completed": {
            "& .MuiStepIcon-root": {
                color: '#33CC99',
                fontSize: "1.5rem !important"
            },
        },
        "& .Mui-disabled": {
            "& .MuiStepIcon-root": {
                fontSize: "1.5rem !important"
            }
        },
    }
    const stepLabelStyleEscuro = {
        "& .MuiStepLabel-label":{
            color: "#FFF !important",
            "& .Mui-active": {
                color: "#FFF !important"
            }
        }
    }
   
    // const codigoCliente = RetornarParametro('codigoClienteErpAntigo')
    // const codigosClientes = RetornarParametro('codigoCliente')
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let itensTemp = []
    let itensInvalidosTemp = []
    let quantidadeTemp = []


    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const colunasItensInvalidos = [{
        dataField: 'CALLERID',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },]
    const colunasOperadoraTamanho = [{
        dataField: 'ds_dsp_operadora',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_tamanho_simcard',
        text: 'Tamanho',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'ds_quantidade',
        text: 'Quantidade',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleRemoverLinha(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]

    function validateQueima(row) {
        let dataAtual = moment()
        let dataCompra = moment(row.DATA_COMPRA)
        let diff = dataCompra.diff(dataAtual, 'months');
        return diff > 6;
    }

    const colunas = [{
        dataField: 'CD_SIMCARD',
        text: 'Código',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'OPERADORA',
        text: 'Operadora',
        headerClasses: 'nao-selecionavel',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'CALLERID',
        headerClasses: 'nao-selecionavel',
        text: 'Linha',
        sort: true, 
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ICCID',
        headerClasses: 'nao-selecionavel',
        text: 'ICCID',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Plano',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell} MB</p>;
        }
    },
    {
        dataField: 'VALOR_PLANO',
        headerClasses: 'nao-selecionavel',
        text: 'Valor',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">R$ {(parseFloat(cell).toFixed(2)).replace(".",",")}</p>;
        }
    },
    {
        dataField: 'DATA_COMPRA',
        headerClasses: 'nao-selecionavel',
        text: 'Data da compra',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                   cell = cell[0]
                }
              }
            return  formatarData(cell,true);
        }
    },
    {
        dataField: 'DATA_PRAZO_CONTRATO',
        headerClasses: 'nao-selecionavel',
        text: 'Prazo contrato',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                   cell = cell[0]
                }
              }
            return  formatarData(cell,true);   
        }
    },
    {
        dataField: 'FL_PRIMEIRA_REALOCACAO',
        headerClasses: 'nao-selecionavel',
        text: 'Valor queima',
        sort: true,
        formatter: (cell, row) => {
            return validateQueima(row) ? <p className="fonte-cor-3 label-tabela-2">R$ {(parseFloat(valor_queima).toFixed(2)).replace(".", ",")}</p> : <p className="fonte-cor-2 label-tabela-2">R$ 0,00</p>;
        }
    },
    {
        dataField: 'acoes',
        text: '',
        sort: false,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            return (
                <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                    <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                </Button>
            )
        }
    }]


    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setLinhaRemover(row.CD_SIMCARD);
        }
    }
    const eventosLinhasOperadora = {
        onClick: (e, row, rowIndex) => {
            setItemRemover(row.ds_dsp_operadora);
        }
    }

    async function handlerVerificarLinhas(){
        let auxOperadoras = []
        await Promise.all(
            itens.map((item,i)=>{
                if(item.CD_OPERADORA){
                    if(item.CD_OPERADORA != operadoras[--i] ){
                        auxOperadoras.push(item.CD_OPERADORA)
                    }
                }
            })
        )
        auxOperadoras = auxOperadoras.filter(function (item) {
            return !this[JSON.stringify(item)] && (this[JSON.stringify(item)] = true);
        }, Object.create(null))
        setOperadoras(auxOperadoras)
        setRevelarSelectNovoPlano(true)
    }

    useEffect(()=>{
        if(beforeRevelarSelectNovoPlano){
            handlerVerificarLinhas()
        }
        else{
            setRevelarSelectNovoPlano(false)
        }
    },[beforeRevelarSelectNovoPlano])

    function handleMensagemAtencaoValor(confirmacao) {
        if (revelarMensagemAtencaoValor === false) {
            setRevelarMensagemAtencaoValor(true)
        } else {
            setRevelarMensagemAtencaoValor(false)
            if (confirmacao === true) {
                    handleSalvar()
            }
        }
    }

    function handleMensagemErroQuantidade(confirmacao) {
        if(revelarMensagemErroQuantidade === false){
            setRevelarMensagemErroQuantidade(true)
        }else{ 
            setRevelarMensagemErroQuantidade(false)
        }
        if(confirmacao === true) {
           // ALGUMA FUNÇÃO
        }
    }

    function handleMensagemErroQuantidadeMenor(confirmacao) {
        if(revelarMensagemErroQuantidadeMenor === false) {
            setRevelarMensagemErroQuantidadeMenor(true)
        } else {
            setRevelarMensagemErroQuantidadeMenor(false)
        }

        if (confirmacao === true) {
            // ALGUMA FUNÇÃO
        }
    }

    function handleMotivo(motivo){
        setDsMotivo(motivo)
        setCaracteresMensagem(1000 - motivo.length)
    }

    function handleLinhasSelecionadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensTemp.length === 0){
            handleCancelar()
        }
    }

    //verifica se o usuario selecionou linhas extras que nao sao permitidas na acao e aciona um alerta
    function handleLinhasSelecionadasIncapacitadas(){
        setQuantidadeLinhasSelecionados(itensTemp.length)
        if(itensInvalidosTemp.length > 0){
            setRevelarMensagemAtencaoInvalido(true)
        }
    }

    async function handleRemoverLinha(CD_SIMCARD){
        ColetaClickstream(1082) // 'realocacaoRemoverLinha'
        setRevelarMensagemAtencao(false)
        if(modoSelecaoSimcard){
            let simcardNaoSelecionados
            if(itensInvalidosBusca){
                simcardNaoSelecionados = itensInvalidosBusca;
            }
            let simcardSelecionados = simcards
            if(simcardNaoSelecionados === undefined || simcardNaoSelecionados === null || simcardNaoSelecionados === false){
                simcardNaoSelecionados = CD_SIMCARD + ","
            }else{
                simcardNaoSelecionados += CD_SIMCARD + ","
            }
            setItensInvalidosBusca(simcardNaoSelecionados)

            for (let i = contador; i < simcardSelecionados.length; i++) {
                if(simcardSelecionados[i] == CD_SIMCARD){
                    simcardSelecionados.splice(i, 1)
                }
            }

            await Promise.all(
                itensVar.map((item_itensVar,i)=>{
                    if(CD_SIMCARD == item_itensVar.CD_SIMCARD){
                        itensVar.splice(i, 1);
                    }
                })
            )
            handlerValidarRealocacao(itensVar)
            setItens(itensVar)
            setSimcards(simcardSelecionados)
            await contarItens();
        }
        else{
            itensTemp = itens
            itens.forEach(function(e, index){
                if(CD_SIMCARD == e.CD_SIMCARD){
                    itens.splice(index, 1);
                }
            })
            setItens(itensTemp)
            handleIdSimcards()
            handleLinhasSelecionadas()

            handlerValidarRealocacao(itens)
        }
    }
    
    async function handlerValidarRealocacao(simcards){
        if(Array.isArray(simcards)){
            //coloca apenas o cd do simcard dentro de uma variavel para 
            let cd_dsp_simcards = [];
            await Promise.all(simcards.map((item_simcards,i)=>{
                if(item_simcards.CD_SIMCARD){
                    cd_dsp_simcards.push(item_simcards.CD_SIMCARD) 
                }
            }))
            //group by de operadora pra montar o tamanho por operadora
            const operadoras = await api.get('/m1/consultar/operadora',{
                simcards: cd_dsp_simcards,
                filtros:{vazio:true}
            })
            if( operadoras.data.status == 200 && operadoras.data.dados.length > 0 ){
                let tempOperadoras = []
                operadoras.data.dados.map((item)=>{
                    tempOperadoras.push({descricao1: item.ds_apelido,codigo: item.cd_dsp_operadora})
                })
                setItensOperadora(tempOperadoras)
            }
            /*
                veye-back\back\src\controles\dispositivos\crmSolicitacao.js : ln 372
                Criada nova query para realizar o cálculo de multa.
                Ela retorna a data de compra ao invés da boolean de primeira realocação como era anteriormente.
                A query antiga ainda existe no arquivo para manter o registro, mas não está sendo utilizada.
                
                Query antiga: veye-back\back\src\controles\dispositivos\crmSolicitacao.js : ln 394
            */
            const {data} = await api.post('/m1/consultar/solicitacao/solicitacao_validacao_reposicao',{
                simcards: cd_dsp_simcards,
                filtros:{vazio:true}
            })
            if( data.status == 200 && data.dados.length > 0 ){
                let somatorioDiff = 0;
                // Laço de repetição para percorrer todos os simcards
                    for (let i = 0; i < data.dados.length; i++) {
                        // Pega a diferença de meses entre a data atual e a data da compra do Simcard
                        let dataAtual = moment();
                        let dataCompra = moment(data.dados[i].dt_compra);
                        let diff = dataCompra.diff(dataAtual, 'months');
                        // Verifica se a diferença é menor do que 6 meses e acrescenta R$5,00 no valor
                        if (diff > -6) {
                            somatorioDiff = somatorioDiff + 5;
                        }
                    } 
                // const somatorioTemp = valor_queima * data.dados[0].primeira_realocacao 
                setSomatorio(somatorioDiff)
            }
        }
        else{
             //group by de operadora pra montar o tamanho por operadora
            const operadoras = await api.post('/m1/consultar/solicitacao/solicitacao_avaliacao_operadora',{
                filtros:props.filtros
            })
            if( operadoras.data.status == 200 && operadoras.data.dados.length > 0 ){
                setItensOperadora(operadoras.data.dados)
            }
            const {data} = await api.post('/m1/consultar/solicitacao/solicitacao_validacao_reposicao',{
                simcards: cd_dsp_simcards,
                filtros:{vazio:true}
            })
            if( data.status == 200 && data.dados.length > 0 ){
                let somatorioDiff = 0;
                // Laço de repetição para percorrer todos os simcards
                    for (let i = 0; i < data.dados.length; i++) {
                        // Pega a diferença de meses entre a data atual e a data da compra do Simcard
                        let dataAtual = moment();
                        let dataCompra = moment(data.dados[i].dt_compra);
                        let diff = dataCompra.diff(dataAtual, 'months');
                        // Verifica se a diferença é menor do que 6 meses e acrescenta R$5,00 no valor 
                        if (diff > -6) {
                            somatorioDiff = somatorioDiff + 5;
                        }
                    } 
                // const somatorioTemp = valor_queima * data.dados[0].primeira_realocacao 
                setSomatorio(somatorioDiff)
            }
        }
    }

    async function parseSimcardObject(simcards) {
        let tempData = [];
            simcards.map((item, i) => {
            tempData.push('Operadora: ' + item.OPERADORA + ' Tamanho:' + item.TAMANHO + ' Quantidade:'  + item.NUMERO_SIMCARDS + ' Novo Plano:' + item.PLANO)
        })
        return tempData;
    }

    async function handleSalvar(){
        let simcardArray = await parseSimcardObject(itensArray)
        ColetaClickstream(1084) // 'realocacaoConfrimar'
        let tempSimcards = simcards;
        let tempLinhas = linhas;
        setSpinAtivo(true)
        if(modoSelecaoSimcard === true){
            tempSimcards = [];
            tempLinhas = [];
            let consultaSimcards;
            if(props.solicitacaoCliente){
                //Deleta solicitacoes
                await api.post('/m1/deletar/solicitacao',{
                    cd_crm_solicitacao:props.filtros.cd_crm_solicitacoes
                })

                consultaSimcards = await api.post('/m1/consultar/simcard_geral_post',{
                    "filtros":props.filtros,
                    "itensInvalidosBusca":itensInvalidosBusca,
                })
                .then(function(resposta){
                    return resposta.data;
                })
                .catch(function(erro){
                    throw erro.stack;
                })
            }
            else{
                const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&itensInvalidosBusca=${itensInvalidosBusca}`
                consultaSimcards =  await api.get(urlGet)
                .then(function(resposta){
                    return resposta.data;
                })
                .catch(function(erro){
                    throw erro;
                })      
            }

           if(consultaSimcards.dados.length > 0 && consultaSimcards.status === 200){
                await Promise.all( 
                    consultaSimcards.dados.map((item, i) => {
                        tempSimcards.push(item.CD_SIMCARD)
                        tempLinhas.push(item.FULLCALLERID)
                    })
                )
           }else{
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
           }
        }

        let temporario = new Date();
        let dataHora = temporario.toISOString().substring(0, 10) +' '+ temporario.getHours()+':'+ temporario.getMinutes()+':'+ temporario.getSeconds();
        if(props.solicitacaoCliente){
            api.post('/m1/cadastrar/solicitacao', {
                'codigo_cliente' : props.CLIENTE,
                'fl_operadora': 0,
                'ds_email' : props.USUARIO_EMAIL,
                'simcards' : tempSimcards,
                'FULLCALLERID': tempLinhas,
                'cd_crm_solicitacao_tipo' : 18,
                'cd_crm_solicitacao_status' :  1,
                'ds_crm_solicitacao' :  dsMotivo,
                'dt_inicio' :  dataHora,
                'dt_fim' :  undefined,
                'dt_previsao' :  undefined,
                'cd_pav_usuario_created' : props.USUARIO,
                "ds_responsavel" : dsResponsavel,
                "ds_formaEnvio" :  dsFormaEnvio == 'Outro'?dsOutroEnvio:dsFormaEnvio,
                "ds_endereco" : dsEndereco +' '+ dsNumero +' '+ dsBairro +' '+ dsCidade +' '+ dsEstado +' '+ dsCep,
                "n0_valor_realocacao" : somatorio,
                "ds_protocolo_protheus_deletar":props.PROTOCOLO_PROTHEUS,
                "ds_operadora_tamanho":simcardArray,
                "valor_queima":valor_queima,
                "quantidade_simcards": dsQuantidade,
                "ADE_CODCAT" : ADE_CODCAT,
                "ADE_CODCAU" : ADE_CODCAU,
            })
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        }
        else{
            await api.post('/m1/cadastrar/solicitacao', {
                'fl_operadora': 0,
                'simcards' : tempSimcards,
                'FULLCALLERID': tempLinhas,
                'cd_crm_solicitacao_tipo' : 18,
                'cd_crm_solicitacao_status' :  1,
                'ds_crm_solicitacao' :  dsMotivo,
                'dt_inicio' :  dataHora,
                'dt_fim' :  undefined,
                'dt_previsao' :  undefined,
                "ds_responsavel" : dsResponsavel,
                "ds_formaEnvio" :  dsFormaEnvio == 'Outro'?dsOutroEnvio:dsFormaEnvio,
                "ds_endereco" : dsEndereco +' '+ dsNumero +' '+ dsBairro +' '+ dsCidade +' '+ dsEstado +' '+ dsCep,
                "n0_valor_realocacao" : somatorio,
                "ds_operadora_tamanho":simcardArray,
                "valor_queima":valor_queima,
                "ADE_CODCAT" : ADE_CODCAT,
                "ADE_CODCAU" : ADE_CODCAU,
                ds_rota_permissao:"veye/dispositivos/acoes/realocacao",
            })
            .then(function (response) {
                setSpinAtivo(false)
                handleMensagemSucesso(false)
            })
            .catch(function (error) {
                setSpinAtivo(false)
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            });
        }
    }

    async function handleCancelar() {
        ColetaClickstream(1083) // 'realocacaoCancelar'
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensInvalidosTemp = []
        simcardSelecionados = []
        setSpinAtivo(false)
        setRevelarMensagemLinhasIncapacitadas(true)
        despacho(revelarRealocacao(false))
    }

    function inserirData(data){
        // setItens('')
        if(itensVar.length == totalItens){
            for(let i=0;i<totalItens;i++){
                if (contador === i){
                    let k = i
                    for(let j=0;j<data.length;j++){
                        itensVar[k] = data[j]
                        k++
                    }
                }
                if(i == (totalItens-1)){
                    itensTemp = JSON.parse(JSON.stringify(itensVar))
                    setItens(JSON.parse(JSON.stringify(itensVar)))
                    handleIdSimcards()
                    
                }
            }
            handleLinhasSelecionadas()
        }
    }
    
    function handleIdSimcards(){
        let tempSimcards = []
        let tempLinhas = []
        for (let i = 0; i < itensTemp.length; i++) {
            tempSimcards.push(itensTemp[i].CD_SIMCARD)
            tempLinhas.push(itensTemp[i].FULLCALLERID)
        }
        simcardSelecionados = tempSimcards 
        setLinhas(tempLinhas)
        setSimcards(tempSimcards)
    }

    async function handlerConsultarSimcadsBusca(){
        if(totalPaginas == 0){
            contador = 0;
            itensVar = [];
            setItens([])
            await contarItens();
        }
        else{
            contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
            setSpinAtivo(true)
            let consultaSimcards;
            if(props.solicitacaoCliente){
                consultaSimcards = await api.post('/m1/consultar/simcard_geral_post',{
                    "carregarLimit":valorLimite,
                    "paguinaAtual":contador,
                    "filtros":props.filtros,
                    "itensInvalidosBusca":itensInvalidosBusca,
                })
                .then(function(resposta){
                    return resposta.data.dados;
                })
                .catch(function(erro){
                    throw erro.stack;
                })
            }
            else{

                const urlGet = `${routeM1FiltrarSimcard}?paguinaAtual=${contador}&carregarLimit=${valorLimite}&acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=false&itensInvalidosBusca=${itensInvalidosBusca}`
                consultaSimcards =  await api.get(urlGet)
                .then(function(resposta){
                    return resposta.data.dados;
                })
                .catch(function(erro){
                    throw erro;
                })
            }
            setSpinAtivo(false)
            inserirData(consultaSimcards)
        }
    }
    useEffect(()=>{
        if(modoSelecaoSimcard && itensInvalidosBusca){
            handlerConsultarSimcadsBusca()
        }
    },[itensInvalidosBusca,paginaAtual])

    useEffect(() => {
        setSpinAtivo(true)
        let validacao = []

        async function preencherTabela (){
            if(props.solicitacaoCliente){
                handlerConsultarSimcadsBusca()
                setItensInvalidosBusca([])
                handlerValidarRealocacao(false)
            }
            else{
                validacao = await identificarValidacaoAcoes(props.itensSelecionados,18,{filtros,buscaArquivo},modoSelecaoSimcard,conteudoBusca,separadorLinha)

                handlerValidarRealocacao(validacao.itensValidos)
                if(modoSelecaoSimcard){
                    itensTemp = validacao.itensValidos
                    itensInvalidosTemp = validacao.itensInvalidos
                    setItensInvalidos(validacao.itensInvalidos)
                    if(itensInvalidosTemp.length > 0){
                        await Promise.all(
                            validacao.itensInvalidos.map((item_validacao,i)=>{
                                simcardSelecionados += item_validacao.CD_SIMCARD + ','
                            })
                        )
                        setItensInvalidosBusca(simcardSelecionados)
                        setSimcards(validacao.itensValidos)
                        handleIdSimcards();
                        setSpinAtivo(false)
                    }else{
                        handlerConsultarSimcadsBusca()
                    }
                }
                else{
                    setItens(validacao.itensValidos)
                    setItensInvalidos(validacao.itensInvalidos)
                    setQuantidadeLinhasSelecionados(validacao.itensValidos.length)
                    itensTemp = validacao.itensValidos
                    itensInvalidosTemp = validacao.itensInvalidos
                    handleIdSimcards();
                    setItensInvalidosBusca(true)
                    setSpinAtivo(false)
                }
            }
            if(revelarMensagemLinhasIncapacitadas == true && validacao.itensInvalidos.length > 0){
                handleLinhasSelecionadasIncapacitadas();
                setRevelarMensagemLinhasIncapacitadas(false)
                
            }
         }
         preencherTabela()
        
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [renderizar]);

    async function contarItens(){
        let arrayItens = []
        let apiData = [];
        if (controladorPagina <= paginaAtual ) { 
            if(props.solicitacaoCliente){
                apiData = await api.post('/m1/consultar/simcard_geral_post',{
                    "contar":true,
                    "filtros":props.filtros,
                    "itensInvalidosBusca":itensInvalidosBusca,
                })
            }
            else{
                const urlGet = `${routeM1FiltrarSimcard}?acao=true&buscar=${conteudoBusca}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${JSON.stringify(buscaArquivo)}&separador=${separadorLinha}&contar=true&itensInvalidosBusca=${itensInvalidosBusca}`
                apiData =  await api.get(urlGet)
                .then(function(resposta){
                    return resposta;
                })
                .catch(function(erro){
                    throw erro;
                })
            }
        }
        
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao === true){
            handleRemoverLinha(linhaRemover)
        }
    }

    async function handleAlterarEndereco(){

        if (dsEndereco === '' || dsNumero === '' || dsCep === '' || dsBairro === '' || dsCidade === '' || dsEstado === 'Selecione') {
            setMensagemErro('Preencha todos os campos obrigatórios')
            setRevelarMensagemErro(true)
            return
        }



        setSpinAtivo(true)
        if(props.solicitacaoCliente){
            await api.post('/m1/alterar/cliente_endereco_entrega', {
                "dados":{
                    "ds_nome":dsTipoEndereco,
                    "cd_pav_cliente":props.cd_pav_cliente,
                    "ds_numero":dsNumero,
                    "ds_endereco":dsEndereco,
                    "ds_cep":dsCep,
                    "cd_pav_uf":cdEstado,
                    "ds_complemento":infoAdicionais,
                    "ds_bairro":dsBairro,
                    "ds_cidade":dsCidade,
                    "fl_considerar_busca":1
                },
                "condicao":{
                    "cd_dsp_cliente_endereco_entrega":cdTipoEndereco
                }
            })
            .then(function (response) {
                setRevelarMensagemSucessoEdicao(true)
                setSpinAtivo(false)
            })
            .catch(function (error) {
                setMensagemErro(error.response.data.info)
                setRevelarMensagemErroEdicao(true)
                setSpinAtivo(false)
            })
        }
        else{
            await api.post('/m1/alterar/cliente_endereco_entrega', {
                "dados":{
                    "ds_nome":dsTipoEndereco,
                    "cd_pav_cliente":decryptJWT('codigoCliente'),
                    "ds_numero":dsNumero,
                    "ds_endereco":dsEndereco,
                    "ds_cep":dsCep,
                    "cd_pav_uf":cdEstado,
                    "ds_complemento":infoAdicionais,
                    "ds_bairro":dsBairro,
                    "ds_cidade":dsCidade,
                    "fl_considerar_busca":1
                },
                "condicao":{
                    "cd_dsp_cliente_endereco_entrega":cdTipoEndereco
                }
            })
            .then(function (response) {
                setRevelarMensagemSucessoEdicao(true)
                setSpinAtivo(false)
            })
            .catch(function (error) {
                setMensagemErro(error.response.data.info)
                setRevelarMensagemErroEdicao(true)
                setSpinAtivo(false)
            })
        }
        setBlEditarEndereco(false)
    }

    async function handleAdicionarEndereco(){
        let resposta = false;

        if (dsEndereco === '' || dsNumero === '' || dsCep === '' || dsBairro === '' || dsCidade === '' || dsEstado === 'Selecione') {
            setMensagemErro('Preencha todos os campos obrigatórios')
            setRevelarMensagemErro(true)
            return false;
        }

        setSpinAtivo(true)
        if(props.solicitacaoCliente){
            await api.post('/m1/cadastrar/cliente_endereco_entrega', {
                "ds_nome":dsTipoEndereco,
                "cd_pav_cliente":props.cd_pav_cliente,
                "ds_numero":dsNumero,
                "ds_endereco":dsEndereco,
                "ds_cep":dsCep,
                "cd_pav_uf":cdEstado,
                "ds_complemento":infoAdicionais,
                "ds_bairro":dsBairro,
                "ds_cidade":dsCidade,
                "fl_considerar_busca":1
            })
            .then(function (response) {
                // setRevelarMensagemSucessoAdicao(true)
                setSpinAtivo(false)
                if(response.data.status == 200){
                    setCdTipoEndereco(response.data.dados.idInserido)
                    resposta = true;
                }
            })
            .catch(function (error) {
                setMensagemErro(error.response.data.info)
                setRevelarMensagemErroAdicao(true)
                setSpinAtivo(false)
            })
        }
        else{
            await api.post('/m1/cadastrar/cliente_endereco_entrega', {
                "ds_nome":dsTipoEndereco,
                "cd_pav_cliente":decryptJWT('codigoCliente'),
                "ds_numero":dsNumero,
                "ds_endereco":dsEndereco,
                "ds_cep":dsCep,
                "cd_pav_uf":cdEstado,
                "ds_complemento":infoAdicionais,
                "ds_bairro":dsBairro,
                "ds_cidade":dsCidade,
                "fl_considerar_busca":1
            })
            .then(function (response) {
                // setRevelarMensagemSucessoAdicao(true)
                setSpinAtivo(false)
                if(response.data.status == 200){
                    setCdTipoEndereco(response.data.dados.idInserido)
                    resposta = true;
                }
            })
            .catch(function (error) {
                setMensagemErro(error.response.data.info)
                setRevelarMensagemErroAdicao(true)
                setSpinAtivo(false)
            })
        }
        setBlAdicionarEndereco(false)
        return resposta;
    }

    function handleBlAdicionarEndereco(bl){
        setDsTipoEndereco('');
        setDsCep('')
        setDsEndereco('')
        setDsNumero('')
        setDsBairro('')
        setDsCidade('')
        setDsEstado('Selecione')
        setInfoAdicionais('')
        setBlAdicionarEndereco(bl)

        if(!bl) {
            setDsTipoEndereco('Selecione')
            setCdTipoEndereco(-1)
            setDsNomeFantasia('');
        }
    }

    async function handlerConsultarCep(cep){
        const consulta = await api.get('/m1/consultar/cep?cep='+cep)
            .then(function (response) {
                if(response.data.status == 200){
                    if(response.data.dados == 'Cep Invalido'){
                        setDsCepValidador(true)
                        setDsCepErroInfo(response.data.dados)
                        setDsBairro('')
                        setDsCidade('')
                        setDsEstado('Selecione')
                        setCdEstado(-1)
                        setDsEndereco('')
                    }
                    else{
                        setDsCepValidador(false)
                        setDsCep(response.data.dados.cep)
                        setDsBairro(response.data.dados.bairro)
                        setDsCidade(response.data.dados.localidade)
                        setDsEstado(response.data.dados.uf)
                        setCdEstado(response.data.dados.cd_pav_uf)
                        setDsEndereco(response.data.dados.logradouro)
                    }
                }
                return response
            })
            .catch(function (error) {
                if(error == 'Error: Request failed with status code 400'){
                    setDsCepValidador(true)
                    setDsCepErroInfo("Cep Invalido")
                }
                else{
                    setDsCepValidador(true)
                    setDsCepErroInfo("Erro interno")
                }
                return error
            })
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
            props.setRenderizar(!props.renderizar)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensInvalidos(){
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    function handleAdicionarItem(){
        if(dsTamanhoSelecionada != 'Selecione' && dsOperadoraSelecionada != 'Selecione' && dsQuantidade != 'Digite a quantidade' && dsNovoPlano !== 'Selecione') {
            // Tamanho Sim Card
            operadoraTamanho.push('Operadora: '+ dsOperadoraSelecionada + ' Tamanho:' + dsTamanhoSelecionada + ' Quantidade:' + dsQuantidade + ' Novo Plano:' + dsNovoPlano)
            operadoraTamanhoExibir.push({
            ds_dsp_operadora:dsOperadoraSelecionada,
            ds_tamanho_simcard:dsTamanhoSelecionada,
            ds_quantidade:dsQuantidade,
            ds_novo_plano: dsNovoPlano,
            })

            itensOperadora.map((item_itensOperadora,i)=>{
                if(item_itensOperadora.codigo == cdOperadoraSelecionada){
                    // Comentado o itensOperadora.splice para evitar erros ao excluir algum item da lista
                    //  itensOperadora.splice(i, 1);
                    // setDsQuantidade('Digite a quantidade');
                    setCdOperadoraSelecionada(0);
                    setDsOperadoraSelecionada('Selecione');
                    setDsTamanhoSelecionada('Selecione');
                }
            })
        }
        if (operadoraTamanhoExibir.length) {
            for (let i = 0; i < operadoraTamanhoExibir.length; i++) {
                if (quantidadeTemp == null || quantidadeTemp == undefined || quantidadeTemp == 0) {
                    quantidadeTemp = operadoraTamanhoExibir[i].ds_quantidade + ",";
                } else {
                    quantidadeTemp = quantidadeTemp + operadoraTamanhoExibir[i].ds_quantidade + ",";
                }

                let qtdTotal = quantidadeTemp.split(',').reduce(function(sum, cur) {
                    let n = cur.match(/(\d+)/);
                    return sum + (n && parseInt(n[1],10) || 0);
                  }, 0);
                  somaQtd = qtdTotal;
            }
        }
    }

    function parsePedidosAdicionados() {
        let loteFinalizados = itensArray.filter(item => item.OPERADORA && item.PLANO && item.TAMANHO && item.NUMERO_SIMCARDS > 0);
        return loteFinalizados
    }

    function parseTotalLinhas() {
        let loteFinalizados = parsePedidosAdicionados();
        let totalLinhas = loteFinalizados.reduce((acc, item) => acc + parseInt(item.NUMERO_SIMCARDS), 0);
        return totalLinhas;
    }

    async function handleNextStep() {

        if(stepActive === 0){
            if(dsMotivo === 'Selecione' || dsMotivo === ''){
                setMensagemErro('O campo motivo é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
        }

        if(stepActive === 1) {
            let loteFinalizados = parsePedidosAdicionados();
            if(loteFinalizados.length === 0) {
                setMensagemErro('Um ou mais campos do pedido estão vazios, preencha-os para prosseguir');
                setRevelarMensagemErro(true);
                return;
            } else if (loteFinalizados.length != itensArray.length){
                setMensagemErro('Preencha todos os campos do pedido para prosseguir');
                setRevelarMensagemErro(true);
                return;
            } else if (parseTotalLinhas() < quantidadeLinhasSelecionados) {
                setMensagemErro(`Você precisa adicionar ${quantidadeLinhasSelecionados - parseTotalLinhas()} simcards para prosseguir`);
                setRevelarMensagemErro(true);
                return;

            }
        }
        if(stepActive === 2) {
            if(dsResponsavel === ''){
                setMensagemErro('O campo responsável é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsFormaEnvio === ''){
                setMensagemErro('O campo forma de envio é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsFormaEnvio === 'Outro' && dsOutroEnvio === ''){
                setMensagemErro('O campo forma de envio é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsEndereco === ''){
                setMensagemErro('O campo endereço é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsNumero === ''){
                setMensagemErro('O campo número é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsCep === ''){
                setMensagemErro('O campo CEP é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsBairro === ''){
                setMensagemErro('O campo bairro é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsCidade === ''){
                setMensagemErro('O campo cidade é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            if(dsEstado === 'Selecione'){
                setMensagemErro('O campo estado é obrigatório');
                setRevelarMensagemErro(true);
                return;
            }
            else {
                if(mostrarBotacaoCancelarAdicionarEndereco) {
                    const cadastrouEndereco = await handleAdicionarEndereco();
                    if(!cadastrouEndereco) return;
                }

                handleMensagemAtencaoValor(false)
                return;
            }
        }
        setStepActive(stepActive + 1);

    }

    function handlePreviousStep() {
        if(stepActive === 0){
            handleCancelar()
        } else {
            setStepActive(stepActive - 1);
        }
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleMensagemAtencaoValor(false))}>

            <div className="modal-acao-cancelamento-resposicao-dimensoes modal-acao-cancelamento-resposicao-novo fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <div className={'modal-novo-pedido-header'}>
                    <Form.Row>
                        <Col>
                            <div className={'header-novo-pedido-title'}>
                                <div className={'header-title-left-block'}>
                                    <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-janela">Reposição</div>
                                    </Form.Label>
                                </div>
                                <div>
                                    <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                                        <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                                    </Button>
                                </div>
                            </div>


                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Stepper activeStep={stepActive} sx={stepsStyle}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel sx={localTema === 'escuro' ? stepLabelStyleEscuro : {}}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Col>
                    </Form.Row>
                    <div className={'label-wrapper-novo-pedido'}>
                        <div className={'fonte-cor-1 label-listagem-SIMcard'}>
                            <div>{steps[stepActive]}</div>
                        </div>
                    </div>
                </div>

                <div className={'modal-novo-pedido-content'}>

                    {
                        stepActive === 0 &&
                        <>
                            {/*TABELA DE SIMCARDS SELECIONADOS PARA REPOSIÇÃO*/}
                            <Form.Row>
                                <Col>
                                    <div className="container-tabela-modal-cancelamento-reposicao-texto container-tabela-modal-cancelamento-reposicao">
                                        <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                                            {
                                                ({
                                                     paginationProps,
                                                     paginationTableProps
                                                 }) => (
                                                    <>
                                                        <BootstrapTable
                                                            classes="tabela-transparente-modais"
                                                            condensed={true}
                                                            keyField='id'
                                                            data={itens}
                                                            columns={colunas}
                                                            rowEvents={eventosLinhas}
                                                            noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                                            bootstrap4={true}
                                                            { ...paginationTableProps }
                                                        />

                                                        <div className="paguinacao-tabela-modal">
                                                            <PaginacaoTabela
                                                                extrato={true}
                                                                styleClass={'paginacao-tabela-reposicao-grupo-btns'}
                                                                setPaginaAtual={setPaginaAtual}
                                                                totalPaginas={Math.ceil(itens.length / valorLimite)}
                                                                totalItens={totalItens}
                                                                valorLimite={valorLimite}
                                                                paginaAtual={paginaAtual}
                                                                page={paginationProps.page}
                                                                onPageChange={paginationProps.onPageChange}
                                                            />
                                                        </div>

                                                    </>
                                                )
                                            }
                                        </PaginationProvider>
                                    </div>
                                </Col>

                            </Form.Row>
                            <Col>
                                <span className="contador-linhas-acoes-dispositivos-margin fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{quantidadeLinhasSelecionados} linhas selecionadas</span>
                            </Col>


                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo label-campo-margin">
                                                Motivo
                                            </div>
                                        </Form.Label>
                                        {errors.motivo_cancelamento_reposicao &&
                                            <p className="fonte-cor-3 label-alerta">
                                                {iconeAlerta} Informe o motivo.
                                            </p>
                                        }
                                        {revelarSelectMotivo === false &&
                                            <Button className="campo-texto-cor-3 campo-select-novo-motivo"
                                                    onClick={() => setRevelarSelectMotivo(!revelarSelectMotivo)}
                                                    ref={register({required: true})} onChange={e => handleMotivo(e.target.value)}>

                                                <div className={'label-interno-wrapper'}>
                                                    <div className="fonte-cor-1 label-campo-novo">
                                                        {inputRevelado}
                                                    </div>

                                                    <img className={revelarSelectMotivo?
                                                        "campo-select-icone-ativado nao-selecionavel":
                                                        "campo-select-icone-desativado nao-selecionavel"}
                                                         src={setaDropdown()} alt="drop" />
                                                </div>



                                            </Button>
                                        }
                                        {revelarSelectMotivo &&
                                            <SelectMotivoReposicao
                                                setRevelarSelectMotivo={setRevelarSelectMotivo} //Hook para fechar a modal
                                                styleClass={'campo-select-novo-motivo'} // Estilo do select
                                                setInputRevelado={setInputRevelado} //Nome do FormaEnvio
                                                setADE_CODCAT={setADE_CODCAT} //Código do FormaEnvio
                                                setADE_CODCAU={setADE_CODCAU} //Código do FormaEnvio
                                                handleMotivo={handleMotivo}
                                                inputRevelado={inputRevelado}
                                            >
                                            </SelectMotivoReposicao>
                                        }
                                        {inputRevelado == "Outros" &&
                                            <>
                                                <div class="campo-select-triangulo-margin
                                campo-select-triangulo-cor-1 campo-select-triangulo">
                                                </div>
                                                <Form.Control className="campo-texto-cor-3 campo-texto-area" as="textarea"
                                                              rows="3" name="motivo_cancelamento_reposicao" ref={register({required: true})}
                                                              maxlength="1000" placeholder="Descreva aqui o motivo"
                                                              onChange={e => handleMotivo(e.target.value)}>
                                                </Form.Control>
                                                <Form.Label className="contador-carateres-restantes">
                                                    <div className="fonte-cor-1 label-normal">
                                                        {caracteresMensagem} caracteres restantes
                                                    </div>
                                                </Form.Label>
                                            </>
                                        }
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </>
                    }

                    {
                        stepActive === 1 &&
                        <>
                            <div className={'lote-row-novo-pedido'}>

                                <div className={'label-row-novo-pedido'}>
                                    <div className={'fonte-cor-1'} style={{flex:0.1}}>#</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.5}}>Operadora</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.5}}>Plano</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.3}}>Tamanho SIM Card</div>
                                    <div className={'fonte-cor-1 label-title-novo-pedido'} style={{flex: 1.2}}>Quantidade</div>
                                </div>

                                {itensArray.length > 0 &&
                                    <RenderInputRows
                                        {...{
                                            itens: itensArray,
                                            itensArray,
                                            setItensArray,
                                            setMensagemErro,
                                            setRevelarMensagemErro,
                                            mostrarBotaoNovoLote: stepActive == 1,
                                        }}
                                    />
                                }

                                <div>

                                </div>

                            </div>
                        </>
                    }

                    {
                        stepActive === 2 &&
                        <>
                            <Form.Row>
                                <Col>
                                    <div className="grupo-painel-pedidos-novo-simcard-1 margin-top-divider">
                                        <div className="coluna-painel-pedidos-novo-simcard-6">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Responsável</div>
                                                </Form.Label>
                                                    <Form.Control name="ds_responsavel" value={dsResponsavel} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o responsavel" onChange={e => setDsResponsavel(e.target.value)}
                                                                  ref={register({ required: true, min: 3, max: 100 })} maxlength="100"/>
                                                {dsResponsavel === '' && <AvisoCampoObrigatorio />}
                                            </Form.Group>
                                        </div>
                                        <div className="coluna-painel-pedidos-novo-simcard-7">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Forma de envio</div>
                                                </Form.Label>
                                                { revelarSelectFormaEnvio === false &&
                                                    <Button name="cdFormaEnvio" className="campo-texto-cor-3 campo-select-novo" onClick={() => setRevelarSelectFormaEnvio(!revelarSelectFormaEnvio)}>
                                                        <div className={"fonte-cor-1 label-campo"}>{dsFormaEnvio}</div>
                                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                    </Button>
                                                }
                                                { revelarSelectFormaEnvio === true &&
                                                    <SelectFormaEnvio
                                                        setRevelarSelectFormaEnvio={setRevelarSelectFormaEnvio} //Hook para fechar a modal
                                                        styleClass={'campo-select-novo'} // Estilo do select
                                                        setCdFormaEnvio={setCdFormaEnvio} //Código do FormaEnvio
                                                        setDsFormaEnvio={setDsFormaEnvio} //Nome do FormaEnvio
                                                        dsFormaEnvio={dsFormaEnvio}
                                                        phFormaEnvio={dsFormaEnvio} //Placeholder para mostrar o valor original em caso de edição
                                                    >
                                                    </SelectFormaEnvio>
                                                }
                                                {dsFormaEnvio === 'Selecione' && <AvisoCampoObrigatorio />}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control hidden={dsFormaEnvio != 'Outro'} name="ds_outro" value={dsOutroEnvio} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite outra forma de envio" onChange={e => setDsOutroEnvio(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="coluna-painel-pedidos-novo-simcard-8">
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo label-novo-pedido-endereco">Tipo de endereço</div>
                                                </Form.Label>
                                                { revelarSelectTipoEndereco === false  && blEditarEndereco === false && blAdicionarEndereco === false &&
                                                    <Button name="cdTipoEndereco" className="campo-texto-cor-3 campo-select-novo" onClick={() => setRevelarSelectTipoEndereco(!revelarSelectTipoEndereco)}>
                                                        <div className="fonte-cor-1 label-campo label-campo-select-overflow">{dsTipoEndereco + dsNomeFantasia}</div>
                                                        <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                    </Button>
                                                }
                                                { revelarSelectTipoEndereco === false && (blEditarEndereco === true || blAdicionarEndereco === true) &&
                                                    <>
                                                            <Form.Control name="ds_endereco" value={dsTipoEndereco} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o nome do Endereço" onChange={e => setDsTipoEndereco(e.target.value)}
                                                                          ref={register({ required: true, minLength: 3, maxLength: 100 })}/>
                                                        {errors.ds_endereco && <AvisoCampoObrigatorio />}
                                                    </>
                                                }
                                                { revelarSelectTipoEndereco === true &&
                                                    <SelectTipoEndereco
                                                        setRevelarSelectTipoEndereco={setRevelarSelectTipoEndereco} //Hook para fechar a modal
                                                        setCdTipoEndereco={setCdTipoEndereco} //Código do TipoEndereco
                                                        styleClass={'campo-select-novo'} // Estilo do select
                                                        setDsTipoEndereco={setDsTipoEndereco} //Nome do TipoEndereco
                                                        dsTipoEndereco={dsTipoEndereco}
                                                        phTipoEndereco={dsTipoEndereco} //Placeholder para mostrar o valor original em caso de edição
                                                        setDsCep={setDsCep}
                                                        setDsEndereco={setDsEndereco}
                                                        setDsNumero={setDsNumero}
                                                        setDsBairro={setDsBairro}
                                                        setDsCidade={setDsCidade}
                                                        setDsEstado={setDsEstado}
                                                        setInfoAdicionais={setInfoAdicionais}
                                                        setCdEstado={setCdEstado}
                                                        setDsNomeFantasia={setDsNomeFantasia}
                                                        dsNomeFantasia={dsNomeFantasia}
                                                        cd_pav_cliente={props.cd_pav_cliente}
                                                    >
                                                    </SelectTipoEndereco>
                                                }
                                            </Form.Group>
                                        </div>

                                    </div>
                                </Col>
                            </Form.Row>
                            { (cdTipoEndereco !== -1 || blEditarEndereco === true || blAdicionarEndereco === true) &&
                                <>
                                    <Form.Row>
                                        <Col>
                                            <div className="grupo-painel-pedidos-novo-simcard-1">
                                                <div className="coluna-painel-pedidos-novo-simcard-10">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 label-campo">CEP</div>
                                                        </Form.Label>
                                                            <Form.Control name="ds_cep" value={dsCep} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o cep" onChange={e => setDsCep(e.target.value)}
                                                                          ref={register({ required: true, min: 3, max: 9 })} onBlur={(e) => handlerConsultarCep(e.target.value)} maxlength="9" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                        {dsCepValidador && <p className="fonte-cor-3 label-alerta">{iconeAlerta} {dsCepValidador?dsCepErroInfo:"O campo é obrigatorio."}</p>}
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-painel-pedidos-novo-simcard-11">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 label-campo">Endereço</div>
                                                        </Form.Label>
                                                            <Form.Control name="ds_endereco" value={dsEndereco} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o nome da rua" onChange={e => setDsEndereco(e.target.value)}
                                                                          ref={register({ required: true, min: 3, max: 100 })} maxlength="100" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                        {dsEndereco === '' && <AvisoCampoObrigatorio />}
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-painel-pedidos-novo-simcard-12">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 label-campo">Número</div>
                                                        </Form.Label>
                                                            <Form.Control name="ds_numero" value={dsNumero} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o nº" onChange={e => setDsNumero(e.target.value)}
                                                                          ref={register({ required: true, min: 3, max: 100 })} maxlength="4" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                        {dsNumero === '' && <AvisoCampoObrigatorio />}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <div className="grupo-painel-pedidos-novo-simcard-1">
                                                <div className="coluna-painel-pedidos-novo-simcard-10">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 label-campo">Bairro</div>
                                                        </Form.Label>
                                                            <Form.Control name="ds_bairro" value={dsBairro} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite o bairro" onChange={e => setDsBairro(e.target.value)}
                                                                          ref={register({ required: true, min: 3, max: 100 })} maxlength="100" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                        {dsBairro === '' && <AvisoCampoObrigatorio />}
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-painel-pedidos-novo-simcard-11">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 label-campo">Cidade</div>
                                                        </Form.Label>
                                                            <Form.Control /*disabled={errors.ds_cep || dsCepValidador || dsCep.length<=8?false:true}*/ name="ds_cidade" value={dsCidade} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Digite a cidade" onChange={e => setDsCidade(e.target.value)}
                                                                                                                                                       ref={register({ required: true, min: 3, max: 100 })} maxlength="100" disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                                        {dsCidade === '' && <AvisoCampoObrigatorio />}
                                                    </Form.Group>
                                                </div>
                                                <div className="coluna-painel-pedidos-novo-simcard-12">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <div className="fonte-cor-1 label-campo">Estado</div>
                                                        </Form.Label>
                                                        { revelarSelectUf === false &&
                                                            <Button disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true } name="cdUf" className="campo-texto-cor-3 campo-select-novo" onClick={() => setRevelarSelectUf(!revelarSelectUf)}>
                                                                <div className="fonte-cor-1 label-campo">{dsEstado}</div>
                                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                                            </Button>
                                                        }
                                                        { revelarSelectUf === true &&
                                                            <SelectUf
                                                                setRevelarSelectUf={setRevelarSelectUf} //Hook para fechar a modal
                                                                styleClass={'campo-select-novo'} // Estilo do select
                                                                setCdUf={setCdEstado} //Código do Uf
                                                                setDsUf={setDsEstado} //Nome do Uf
                                                                dsUf={dsEstado}
                                                                phUf={dsEstado} //Placeholder para mostrar o valor original em caso de edição
                                                            >
                                                            </SelectUf>
                                                        }
                                                        {cdEstado == -1 && <AvisoCampoObrigatorio />}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>
                                                    <div className="fonte-cor-1 label-campo">Informações adicionais (opcional)</div>
                                                </Form.Label>
                                                    <Form.Control name="infoAdicionais" value={infoAdicionais} className="campo-texto-cor-3 campo-texto-novo" type="text" placeholder="Sala, apartamento, bloco, ponto de referência"
                                                                  onChange={e => setInfoAdicionais(e.target.value)} disabled={(blEditarEndereco == true || blAdicionarEndereco == true) ? false : true }/>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </>
                            }
                            <BotoesDadosEnvio {...{
                                mostrarBotaoAdicionarEndereco,
                                mostrarBotacaoCancelarAdicionarEndereco,
                                handleBlAdicionarEndereco,
                            }} />
                        </>

                    }
                </div>

                {/*SELECT MOTIVO*/}

                {stepActive == 0 &&
                <>
                    <Form.Row className="label-termos-cancelamento-reposicao-2 label-normal-caso">
                        <Col>
                        <span>
                            <span className="fonte-cor-1 label-normal"> *Este processo consiste em cancelar a linha atual e enviar uma nova. Haverá o custo de taxa de R$5,00 por linha somente se ela estiver sido adquirida a menos de 6 meses, caso contrário, não pagará taxas. O prazo para conclusão desta substituição é de 1 dia útil a partir da solicitação.</span>
                        </span>
                        </Col>
                    </Form.Row>
                    <Form.Row className="label-termos-cancelamento-reposicao-2 label-normal-caso">
                        <Col>
                        <span>
                            <span className="fonte-cor-1 label-normal"> *A reposição irá com o prazo de acordo com o contratado.</span>
                        </span>
                        </Col>
                    </Form.Row>
                </>
                }


                {/*FOOTER*/}
                <div className={'modal-footer-novo-pedido'}>

                    <Form.Row>
                        <Col>
                            <div className={'resumo-pedido-linha-disclaimer'}>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{parsePedidosAdicionados().length} pedido(s) adicionado(s)</span>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">{parseTotalLinhas()} linha(s) adicionada(s) aos pedidos</span>
                                <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">O valor total de queima é de: R${somatorio},00</span>
                                {
                                    quantidadeLinhasSelecionados > parseTotalLinhas() &&
                                    <>
                                        <span className="fonte-cor-1 label-normal contador-linhas-acoes-dispositivos">Você precisa adicionar {(quantidadeLinhasSelecionados - parseTotalLinhas())} linha(s) ao pedido de reposição</span>
                                    </>
                                }
                            </div>

                        </Col>
                    </Form.Row>
                    {((dsMotivo != 'Selecione' || dsMotivo != '') && stepActive === 0) || (stepActive > 0 && parsePedidosAdicionados().length > 0)  ?
                        <Form.Row>
                            <Col>
                                <Button onClick={(e) => handleNextStep()} className="botao-confirmar" variant="success" size="sm">
                                    <div className="fonte-cor-4">{stepActive == 2 ? "Confirmar" :"Próximo"}</div>
                                </Button>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={(e) => handlePreviousStep()}>
                                    <div className="fonte-cor-2">{stepActive == 0 ? "Cancelar" : "Voltar"}</div>
                                </Button>
                            </Col>
                        </Form.Row>
                        :
                        <Form.Row>
                            <Col>
                                <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Finalize o detalhamento do pedido para avançar.</Tooltip>}>
                                    <Button className="botao-confirmar-desabilitado" variant="success" size="sm">
                                        <div className="fonte-cor-4">{stepActive == 2 ? "Confirmar" :"Próximo"}</div>
                                    </Button>
                                </OverlayTrigger>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handlePreviousStep()}>
                                    <div className="fonte-cor-2">{stepActive == 0 ? "Cancelar" : "Voltar"}</div>
                                </Button>
                            </Col>
                        </Form.Row>
                    }

                </div>
            </div>                        

            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação está em andamento,"}
                            mensagemParagrafo2={"a confirmação de conclusão será enviada por e-mail em breve."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoValor &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencaoValor}
                    mensagemTitulo={"Atenção:"}
                    mensagemParagrafo1={"O custo total da taxa será de R$" + somatorio+",00"}
                    mensagemBotao={"Confirmar"}
                />
            }
            { revelarMensagemErroQuantidade &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                        handleMensagemErro={handleMensagemErroQuantidade}
                        mensagemTitulo={"Atenção:"}
                        mensagemParagrafo1={"A quantidade de linhas informada é maior que a quantidade selecionada."}
                        mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }

            { revelarMensagemErroQuantidadeMenor &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                        handleMensagemErro={handleMensagemErroQuantidadeMenor}
                        mensagemTitulo={"Atenção:"}
                        mensagemParagrafo1={"A quantidade de linhas informada é menor que a quantidade selecionada."}
                        mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}