//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { api, api_svdispositivos } from '../../../conexoes/api';/*eslint-disable*/
import { Image, ProgressBar, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import 'moment-timezone';
import moment from 'moment';

import ReactDragListView from "react-drag-listview";
// import XLSX from 'xlsx';
//IMPORTAÇÕES ICONES
import { setaDropdown, elipseVerde, elipseVermelho, elipseAzul } from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useSelector, useDispatch, connect } from 'react-redux';
import {
    modoSelecaoSimcard, exportarLinhas, revelarBarraFiltragem, limpadorFiltros, mudarPermissaoElementoAcao, mudarFiltros, realizouFiltro,
    mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, desabilitarAcoesCliente, mudarOrdenador, separadorLinhaBusca,
    esconderMenuAcoesDispositivosBloqueio, RevelarBuscarEFiltro, exportarOperadora, revelarMostrarEmDispositivosSolicitacaoReset, mudarBuscaArquivo, revelarDispositivosAlterarServicos, revelarDispositivosAlterarCicloDeVida, revelarDispositivosAlterarPlano
} from "../../../redux/actions/index";

//IMPORTAÇÕES TELAS DE AÇÕES
import AdicionarPacoteExtra from '../dspAdicionarPacoteExtra/adicionarPacoteExtra'
import Reset from '../dspReset/reset'
import TesteConexao from '../dspTesteConexao/testeConexaoVivo.jsx'
import FalhaNaOperadora from '../dspFalhaOperadora/falhaOperadora'
import SuporteTecnico from '../dspSuporteTecnico/suporteTecnico'
import Cancelamento from '../dspCancelamentoReposicao/cancelamento'
import Realocacao from '../dspCancelamentoReposicao/realocacao'
import AtivarLinha from '../dspSuspenderReativar/ativarLinha'
import ReativarLinha from '../dspSuspenderReativar/reativarLinhas'
import DesbloquearUso from '../dspBloqueioDesbloqueio/desbloquearUso'
import RemoverBloqueioTemporario from '../dspBloqueioTemporario/removerBloqueioTemporario'
import SuspenderLinha from '../dspSuspenderReativar/suspenderLinhas'
import BloquearUso from '../dspBloqueioDesbloqueio/bloquearUso'
import BloquearTemporariamento from '../dspBloqueioTemporario/bloquearTemporariamente'
import AlterarPlanoValor from '../dspAlterarPlanoValor/alterarPlanoValor'
import TrocaDeTitularidade from '../dspTrocaDeTitularidade/trocaDeTitularidade'
import AtivarDemonstracao from '../dspDemonstracao/ativarDemonstracao'
import DesativarDemonstracao from '../dspDemonstracao/desativarDemonstracao'
import CancelamentoConta from '../dspCancelamentoConta/cancelamentoConta'
import IotTelecom from '../iotTelecom/iotTelecom';
import LiberarSms from '../dspSms/smsLiberar'
import BloquearSms from '../dspSms/smsBloquear'
import EnviarSms from '../dspSms/smsEnviar'
import Mapa from '../dspMapa/mapa'
import AlterarGrupo from '../dspGrupo/alterarGrupo'
import ConfigurarGrupo from '../dspGrupo/configurarGrupo'
import NovoPedidoSimcard from '../../painelPedidos/pedidosSimcard/novoPedido/novoPedido'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import CancelamentoReposicao from '../dspCancelamentoReposicao/cancelamentoReposicao'
// import TransferirLinha from "../dspTransferirLinha/transferirLinha"
import RenegociarContrato from '../dspRenegociarContrato/renegociarContrato.js';
// IMPORTAÇÕES AÇÕES FATURAS
import AlterarCicloDeVida from '../../painelFaturas/fatDispositivos/acoes/alterarCicloDeVida'
// import AlterarConta from '../../painelFaturas/fatDispositivos/acoes/alterarConta'
// import ModalAssociarClientes from '../../painelFaturas/fatFebraban/associarFatura/associarFatura'
import AtribuirLinhas from '../../painelFaturas/fatDispositivos/acoes/atribuirLinhas'
import AlterarPlano from '../../painelFaturas/fatDispositivos/acoes/alterarPlano'
import AlterarServico from '../../painelFaturas/fatDispositivos/acoes/alterarServico'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import PersonalizarColunas from '../../personalizarColunas/personalizarColunas'
import ImportarDescricao from '../dspImportarDescricao/ImportarDescricao'
import RelatorioConsumo from '../dspRelatorioConsumo/RelatorioConsumo'
import RelatorioDisponibilidade from '../dspRelatorioDisponibilidade/RelatorioDisponibilidade';
//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import DetalhamentoLinha from '../dspDetalhamentoLinha/detalhamentoLinha'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import { validadorPermissaoElemento } from '../../../utils/verificadorPermissaoElemento'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import decryptJWT from '../../../utils/decryptJWT';
// import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import '../../../css/painelDispositivos/dspSimcard/dspAcoes.css'
import BarraTabelaLegenda from '../../tabelaSelecao/barraTabelaLegenda';
import { clickDesselecionarCheckboxVerde, desselecionarTodosCheckboxes } from '../../tabelaSelecao/checkboxSelecao';
// import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import { getListaIccidsNaoEncontrados, getListaLinhasNaoEncontradas, montarXlsLinhasNaoEncontradas } from '../../../utils/exportarArquivo';
import AtivacaoLinhasAWS from '../../componentesModais/importarLinhasAWS/ativacaoLinhasAWS';

import { formatarData } from '../../../utils/formatadorDataTabela'
import { formatarMilhar } from '../../../utils/formatarMilhar'
import verificarNomeDsp from './verificarNome';
import verificarColunaDsp from './verificarColuna';
import { isPortal } from '../../../utils/nivelCliente';
import { getColunasClienteNivelN3 } from '../../../utils/colunas';
import { RenderMensagemSucesso } from '../../componentesModais/mensagens/mensagemAcao.js';
import { AtencaoBaixarArquivoLinhasNaoEncontradas, BotaoInvisivelExportarLinhasNaoEncontradas, clickBotaoInvisivelExportarLinhasNaoEncontradas } from '../../buscaArquivo';


let contador = 0
let controladorPagina = 1
let paginaAntiga = 0;
let totalPaginas = 0
let totalItens = 50
let valorLimite = 50
let totalExistente = 0
let simcardsSelecionados = []; 
let itensVar = []
let operadorasSelecionadas = [];
let ordem = {}
// let ciclo = undefined
let alternadorSelecao = false

// const max_length_url_get = 1000
let chaveArquivoLinhasNaoEncontradas = false;

function TabelaSimcard(props) {
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    // const [valorLimite, setValorLimite] = useState(50);
    // const [cicloAtual, setCicloAtual] = useState(0);
    const [permissaoDescricao, setPermissaoDescricao] = useState();
    const [dadosParaDownloadLinhasNaoEncontradas, setDadosParaDownloadLinhasNaoEncontradas] = useState([['Linha', 'ICCID']])
    const [renderDadosParaDownloadLinhasNaoEncontradas, setRenderDadosParaDownloadLinhasNaoEncontradas] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [revelarContador, setRevelarContador] = useState(false)
    const [showCaption, setShowCaption] = useState(false);
    const [limpandoSelecao, setLimpandoSelecao] = useState(false);
    
    const [mensagemSucesso, setMensagemSucesso] = useState(false)

    //REDUX HOOKS
    let reduxModoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let revelarAdicionarPacoteExtra = useSelector(state => state.manejadorState.revelarAdicionarPacoteExtra);
    let revelarReset = useSelector(state => state.manejadorState.revelarReset);
    let revelarTesteConexao = useSelector(state => state.manejadorState.revelarTesteConexao);
    let revelarFalhaNaOperadora = useSelector(state => state.manejadorState.revelarFalhaNaOperadora);
    let revelarSuporteTecnico = useSelector(state => state.manejadorState.revelarSuporteTecnico);
    let revelarCancelamento = useSelector(state => state.manejadorState.revelarCancelamento);
    let revelarRealocacao = useSelector(state => state.manejadorState.revelarRealocacao);
    let revelarAtivarLinha = useSelector(state => state.manejadorState.revelarAtivarLinha);
    let revelarReativarLinha = useSelector(state => state.manejadorState.revelarReativarLinha);
    let revelarDesbloquearUso = useSelector(state => state.manejadorState.revelarDesbloquearUso);
    let revelarRemoverBloqueioTemporario = useSelector(state => state.manejadorState.revelarRemoverBloqueioTemporario);
    let revelarSuspenderLinha = useSelector(state => state.manejadorState.revelarSuspenderLinha);
    let revelarBloquearUso = useSelector(state => state.manejadorState.revelarBloquearUso);
    let revelarBloquearTemporariamento = useSelector(state => state.manejadorState.revelarBloquearTemporariamento);
    let revelarAlterarPlanoValor = useSelector(state => state.manejadorState.revelarAlterarPlanoValor);
    let revelarAtribuirLinhas = useSelector(state => state.manejadorState.revelarAtribuirLinhas);
    let revelarDesatribuirLinhas = useSelector(state => state.manejadorState.revelarDesatribuirLinhas);
    let revelarTrocaDeTitularidade = useSelector(state => state.manejadorState.revelarTrocaDeTitularidade);
    let revelarAtivarDemonstracao = useSelector(state => state.manejadorState.revelarAtivarDemonstracao);
    let revelarDesativarDemonstracao = useSelector(state => state.manejadorState.revelarDesativarDemonstracao);
    let revelarCancelamentoDeConta = useSelector(state => state.manejadorState.revelarCancelamentoDeConta);
    let revelarIotTelecom = useSelector(state => state.manejadorState.revelarIotTelecom);
    let revelarAtivarLinhasAWS = useSelector(state => state.manejadorState.revelarAtivarLinhasAWS);
    let revelarLiberarSms = useSelector(state => state.manejadorState.revelarLiberarSms);
    let revelarRenegociarContrato = useSelector(state => state.manejadorState.revelarRenegociarContrato);
    let revelarBloquearSms = useSelector(state => state.manejadorState.revelarBloquearSms);
    let revelarEnviarSms = useSelector(state => state.manejadorState.revelarEnviarSms);
    let revelarAlterarGrupo = useSelector(state => state.manejadorState.revelarAlterarGrupo);
    let revelarConfigurarGrupo = useSelector(state => state.manejadorState.revelarConfigurarGrupo);
    let revelarExibirColunas = useSelector(state => state.manejadorState.revelarExibirColunas);
    let revelarVisualizarDados = useSelector(state => state.virtueyesState.visualizarDados);
    let revelarImportarDescricao = useSelector(state => state.manejadorState.revelarImportarDescricao);
    let revelarRelatorioConsumo = useSelector(state => state.manejadorState.revelarRelatorioConsumo);
    let revelarCancelamentoReposicao = useSelector(state => state.manejadorState.revelarCancelamentoReposicao);
    // let revelarTransferirLinhaClienteFinal = useSelector(state => state.manejadorState.revelarTransferirLinhaClienteFinal);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let revelarexportarLinhas = useSelector(state => state.manejadorState.exportarLinhas);
    let revelarexportarOperadora = useSelector(state => state.manejadorState.exportarOperadora);
    let revelarNovoPedido = useSelector(state => state.pedidosState.revelarNovoPedidoSimcard);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let revelarMapa = useSelector(state => state.manejadorState.revelarMapa);
    let revelarRelatorioDisponibilidade = useSelector(state => state.manejadorState.revelarRelatorioDisponibilidade)
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let limparFiltros = useSelector(state => state.filtrosState.limparFiltros);
    const buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    // AÇOES FATURAS
    let revelarDispositivosAlterarServicosVar = useSelector(state => state.faturasState.revelarDispositivosAlterarServicos);
    let revelarDispositivosAlterarPlanoVar = useSelector(state => state.faturasState.revelarDispositivosAlterarPlano);
    let revelarDispositivosAlterarCicloDeVidaVar = useSelector(state => state.faturasState.revelarDispositivosAlterarCicloDeVida);
    let revelarDispositivosAlterarContaVar = useSelector(state => state.faturasState.revelarDispositivosAlterarConta);
    // let associarFatura = useSelector(state => state.faturasState.revelarAssociarFatura);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    // const [colunaSimcardArray, setcolunaSimcardArray] = useState([]);
    // let colunaSimcard = [];

    const despacho = useDispatch();
    let temFiltros = false;

    // -----------------------------------------------------------------
    useEffect(() => {
        if((!filtros || filtros?.vazio) && !conteudoBusca && (!buscaArquivo || !Object.keys(buscaArquivo).length)) {
            totalExistente = totalItens;
        }
    }, [totalItens, filtros, conteudoBusca, buscaArquivo])

    function GetIsSelecaoParcial() {
        const qtdSelecionados = simcardsSelecionados.length;

        if((qtdSelecionados && qtdSelecionados < totalExistente) ||
            (reduxModoSelecaoSimcard && (totalItens < totalExistente))
        ) {
            return true;
        }
        return false;
    }
    // -----------------------------------------------------------------

    const getFiltrosToBody = (filtros) => {

        if(filtros && !filtros?.vazio) { // gambiarra para resolver ticket 55/2024 (estava enviando filtros a mais com valor 'on')
            const keysFiltros = Object.keys(filtros);
            
            keysFiltros.map((key) => {
                if(filtros[key] === 'on') {
                    delete filtros[key];
                }
            })
        }

        return (filtros?.vazio === 'vazio') ? '' : `${JSON.stringify(filtros)}`
    };


    function onClickSelecaoParqueTodo(novoValor) {
        if(novoValor === false) { // limpar seleção
            desselecionarTodosCheckboxes('limparSelecao')

            setLimpandoSelecao(true);
            setShowCaption(false);
        }
        handleAlternarSelecao(novoValor);
    }

    useEffect(() => {
        if (coletaUsabilidade) {
            ColetaClickstream(168) // 'dspSimcardTabelaSimcard'
            setColetaUsabilidade(false)
        }
    }, [])

    async function handleMontarXlsLinhasNaoEncontradas(dados) {
        const dadosMontar = await montarXlsLinhasNaoEncontradas(dados);

        if(dadosMontar) {
            dadosParaDownloadLinhasNaoEncontradas.push(...dadosMontar);
            setRenderDadosParaDownloadLinhasNaoEncontradas(true) // provoca o useEffect abaixo (com renderDadosParaDownloadLinhasNaoEncontradas)
        }
    }

    function handleMensagemAtencao() {
        if (renderDadosParaDownloadLinhasNaoEncontradas) {
            clickBotaoInvisivelExportarLinhasNaoEncontradas();
            setRenderDadosParaDownloadLinhasNaoEncontradas(false)
            setDadosParaDownloadLinhasNaoEncontradas([['Linha', 'ICCID']])
        }
    }

    useEffect(() => {
        function checarFiltros() {
            if (props.filtros.vazio == 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
                despacho(revelarMostrarEmDispositivosSolicitacaoReset(false))
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(() => {
        despacho(modoSelecaoSimcard(false))
        alternadorSelecao = reduxModoSelecaoSimcard
        async function handlerVerificarPermissaoAcao() {
            despacho(mudarPermissaoElementoAcao(!await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/acoes")))
            setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento"))
        }
        handlerVerificarPermissaoAcao()
    }, [])

    useEffect(() => {
        // despacho(modoSelecaoSimcard(false))
        if (limparFiltros) {
            despacho(revelarBarraFiltragem(false))
            if (!props.filtros.vazio) {
                despacho(mudarFiltros({ vazio: 'vazio' }))
                despacho(revelarBarraFiltragem(false))
                despacho(limpadorFiltros(false))
            }
        }
    }, [])

    useEffect(() => {
        restaurarConfigsIniciaisPagina()
    }, [props.ordenador])

    useEffect(() => {
        async function pegarSeparador() {
            const separador = await api.get('/m1/consultar/preferencias?cd_pav_usuario=' + decryptJWT('codigo')).then(
                function (resposta) {
                    return resposta.data.dados[0];
                }
            ).catch(
                function (erro) {
                    throw erro.stack;
                }
            )
            despacho(separadorLinhaBusca(separador.busca_separador))
        }

        pegarSeparador();

    }, [])

    useEffect(() => {
        // let getNameStatusContrato
        const controller = new AbortController();
        const controller2 = new AbortController();

        if (simcardsSelecionados.length === 0) {
            despacho(desabilitarAcoes(true));
        }

        /*
        let data = new Date();
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1;
        let dia = data.getDate();
        let anoFormatado = String(ano);
        let mesFormatado = mes < 10 ? '0' + String(mes) : String(mes);
        let diaFormatado = dia < 10 ? '0' + String(dia) : String(dia);
        let dataFormatada = anoFormatado + '-' + mesFormatado + '-' + diaFormatado;
        */
        const limparBuscaPorArquivo = () => despacho(mudarBuscaArquivo({}));

        const getParams = (body, separadorLinha) => { 
            // ex: recebe { key1: 'teste', key2: true } => 'key1=teste&key2=true'
            if(body.busca){
                if(separadorLinha === `enter`){
                    body.busca = body.busca.replaceAll(`\\n`, ';')
                    body.busca = body.busca.replaceAll(' ', ';')
                }
                if(separadorLinha === `espaco`){
                    body.busca = body.busca.replaceAll(' ', ';')
                }
                if(separadorLinha === `,`){
                    body.busca = body.busca.replaceAll(',', ';')
                }
                if(separadorLinha === `|`){
                    body.busca = body.busca.replaceAll('|', ';')
                }
            }
            const keys = Object.keys(body);
            let strParams = '';
            for(let idx=0; idx<keys.length; idx++) {
                const key = keys[idx];
                if(idx > 0) strParams += '&';
                strParams += `${key}=${body[key]}`;
            }
            return strParams;
        }

        async function carregarTabela() {
            let desselecionarCheckboxAposRecarregarTabela = false;

            async function getDadosDispositivos({body, atualizaDados=true}) {
                setRevelarContador(false)

                const simcards_SV = await fazRequisicaoDispositivos({ body, signal: controller.signal })
                if(!mudouPagina) await funcContador(simcards_SV)
                if(atualizaDados) inserirData(simcards_SV.data.devices)

                setRevelarContador(true)
                return simcards_SV;
            }

            async function fazRequisicaoDispositivos({ body, signal }) {
                // const OldUrlFiltrarSimcard = '/m1/filtrar/simcard';
                const urlFiltrarSimcard = '/api/v2/devices/simcard';
                let buscaArquivoStr = JSON.stringify(buscaArquivo);

                if(filtros?.ICCID && buscaArquivo?.chave) {
                    buscaArquivoStr = '{}';
                    limparBuscaPorArquivo();
                }

                body.buscaArquivo = buscaArquivoStr;

                const fazRequisicaoPOST = async () => api_svdispositivos.post(urlFiltrarSimcard, body, { signal });

                const fazRequisicaoGET = async () => {
                    let urlGet = urlFiltrarSimcard + '?' + getParams(body, separadorLinha);
                    return api_svdispositivos.get(urlGet, { signal })
                }

                /* OBS: Requisição via GET é mais simples e mais RÁPIDA. 
                    Entretanto, se a URL é muito grande dá erro 414 (Request URI too large) OU 431 (Request Header Fields Too Large) em PRODUÇÃO (ex: url com tamanho muito maior do que 5000 caracteres).
                    Isso acontecia quando selecionava um pedido simcard -> selecionava um peddo com +250 linhas -> ações -> mostrar em dispositivos
                    Por isso foi desenvolvida a solução abaixo
                */

                const limiteGetEvitarErro414 = 250;
                if((filtros?.ICCID?.length > limiteGetEvitarErro414) || (filtros?.LINHA?.length > limiteGetEvitarErro414)) {
                    return fazRequisicaoPOST();
                } else {
                    return  fazRequisicaoGET();
                }
            }

            setSpinAtivo(true)
            // ciclo = await api.get('/m1/consultar/ciclo?dt_data=' + dataFormatada)
            // setCicloAtual(ciclo)

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if (limpouBuscaGeral) {
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if (realizouBuscaGeral) {
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if (realizouFiltragem) {
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            //necessario para pegar os dados do filtro, pois por algum motivo ele se perde e nao aciona os props.filtros
            if (temFiltros) {
                temFiltros = false;
                restaurarConfigsIniciaisPagina()
            }

            let dadosSimcard = []
            valorLimite = 50

            setItens([])
            if (totalPaginas != 0) {
                contador = (paginaAtual - 1) * valorLimite;
            }
            else if (!props.conteudoBusca) {
                
                if (filtros.PACOTE_EXTRA && filtros.PACOTE_EXTRA == "on") { // TODO: entender porque existe essa verificação
                    delete filtros.PACOTE_EXTRA
                } if (filtros.PLANO_TIPO && filtros.PLANO_TIPO == "on") {
                    delete filtros.PLANO_TIPO
                } if (filtros.SMS_ATIVADO && filtros.SMS_ATIVADO == "on") {
                    delete filtros.SMS_ATIVADO
                } if (filtros.TECNOLOGIA && filtros.TECNOLOGIA == "on") {
                    delete filtros.TECNOLOGIA
                } if (filtros.TIPO_SIMCARD && filtros.TIPO_SIMCARD == "on") {
                    delete filtros.TIPO_SIMCARD
                } if (filtros.TRAFEGO && filtros.TRAFEGO == "on") {
                    delete filtros.TRAFEGO
                } if (filtros.ULTIMA_CONEXAO && filtros.ULTIMA_CONEXAO == "on") {
                    delete filtros.ULTIMA_CONEXAO
                }
                despacho(RevelarBuscarEFiltro(false))
            /* Parece que esse código (comentado abaixo) faz carregar 2x desnecessariamente. 
                Testei várias vezes e comparei com ambiente de PROD 
                e se confirmou que não precisaria desse código comentado abaixo.
                Mas acredito ser necessário mais tempo com mais usuários testando pra ver se surge algum problema.
            */
            //     dadosSimcard = await fazRequisicaoDispositivos({ signal: controller2.signal })

            //     const data = dadosSimcard.data;

            //     if(data?.status && [400, 500].includes(data.status) || !data.dados) {
            //         setSpinAtivo(false)
            //         return;
            //     }

            //     if(data?.dados) {
            //         if (data?.dados?.length < 50) {
            //             valorLimite = data.dados.length
            //         }
    
            //         inserirData(data.dados)
            //     }
            //     setSpinAtivo(false)
            }

            if (filtros.STATUS_CONTRATO === 'ATIVO') { // corrige quando está em Dashboard e clica em 'mostrar em dispositivos' -> Ativo.
                filtros.STATUS_CONTRATO = 'Ativo*1'
                // delete filtros.STATUS_CONTRATO
            } else if(filtros.STATUS_CONTRATO === 'DEMONSTRACAO'){
                filtros.STATUS_CONTRATO = 'Demonstração*4'
            }


            async function funcContador(apiData) { // CONTADOR
                totalItens = apiData.data.item_count;

                let arrayItens = []
                totalPaginas = Math.ceil(totalItens / valorLimite)

                for (var i = 0; i < totalItens; i++) {
                    arrayItens.push(i)
                }

                itensVar = arrayItens;
                despacho(RevelarBuscarEFiltro(true))

                // ---------------------------------------
                desselecionarCheckboxAposRecarregarTabela = true;
            }

            const apenasBusca = props.conteudoBusca && (props.filtros.vazio && !props.buscaArquivo?.chave);

            let trazerCount = true;

            const mudouPagina = paginaAntiga !== 0 && (paginaAntiga !== paginaAtual);
            
            if(mudouPagina) {
                trazerCount = false;
            }

            if (apenasBusca) {
                if (controladorPagina <= paginaAtual) {
                    const body = {
                        limit: valorLimite,
                        offset: contador,
                        busca: conteudoBusca,
                        ordenador: ordenador,
                        contar: trazerCount
                    }
                    await getDadosDispositivos({body});
                }
            }
            else if (props.buscaArquivo?.chave) {
                if (totalItens >= 1) {
                    if (controladorPagina <= paginaAtual) {
                        const body = {
                            limit: valorLimite,
                            offset: contador,
                            busca: conteudoBusca,
                            ordenador: ordenador,
                            contar: trazerCount,
                            filtro: getFiltrosToBody(filtros),
                        }
                        const simcards = await getDadosDispositivos({body, atualizaDados: false});

                        const aindaNaoGerouArquivoLinhasNaoEncontradas = (chave) => (chaveArquivoLinhasNaoEncontradas !== chave)

                        if(simcards?.data?.devices?.length > 0 && simcards?.status == 200 && aindaNaoGerouArquivoLinhasNaoEncontradas(buscaArquivo.chave)) {
                            chaveArquivoLinhasNaoEncontradas = buscaArquivo.chave;

                            // armazena a chave e o código do usuario, para consultar no BD
                            // as linhas ou iccids enviados no filtro arquivo 
                            const dados = {
                                chave: buscaArquivo.chave,
                                usuario: decryptJWT('codigo')
                            }

                            async function getSimcardsFiltrados() {
                                const body2 = {
                                    campos: 'CALLERID,ICCID',
                                    busca: conteudoBusca,
                                    contar: false, 
                                    filtro: getFiltrosToBody(filtros),
                                }

                                // COLOQUEI ROTA ANTIGA TEMPORARIAMENTE, PORQUE ALGO ACONTECE SOMENTE NO AMBIENTE REMOTO, QUE NÃO ENTRA NO IF NO sveye-dispositivos
                                const params = getParams({ ...body2, buscaArquivo: buscaArquivo ? JSON.stringify(buscaArquivo) : buscaArquivo }, separadorLinha);
                                
                                const res = await api.get(`/m1/filtrar/simcard?${params}`, { signal: controller.signal });

                                if(res?.data?.dados) {
                                    res.data.devices = res.data.dados;
                                    delete res.data.dados;
                                }
                                return res;
                               // -----------------------------------------

                                // return fazRequisicaoDispositivos({ body: body2, signal: controller.signal }); 
                            }

                            const getDadosArquivo = async() => {
                                // Faz a consulta ao BD e retorna TODOS OS ITENS ENVIADOS NO ARQUIVO DE FILTRO POR ARQUIVO
                                return api.post("/m1/filtrar/dsp_iccid_ou_linhas_nao_encontrados", dados);
                            }

                            const [simcards2Data, dataLinhasNaoEncontradas] = await Promise.allSettled([
                                getSimcardsFiltrados(),
                                getDadosArquivo(),
                            ]).then(responses => {
                                return responses.map(promiseRes => {
                                    const { status, data } = promiseRes?.value;
                                    if(promiseRes?.status === 'fulfilled' && status === 200) {
                                        return data
                                    }
                                })
                            })
                            const simcardsArquivo = simcards2Data.devices;
                            // Monta um array com os dados retornados do BD, 
                            // caso encontrado algum registro de ICCID/LINHA relevante a busca
                            if(simcardsArquivo !== undefined){
                                const simsEncontrados = { dados:simcardsArquivo, status: 200 };

                                const listaIccids = await getListaIccidsNaoEncontrados({dataLinhasNaoEncontradas, simcards: simsEncontrados, dspSimcard: true });
                                handleMontarXlsLinhasNaoEncontradas(listaIccids)

                                const listaLinhas = await getListaLinhasNaoEncontradas({dataLinhasNaoEncontradas, simcards: simsEncontrados, dspSimcard: true });
                                handleMontarXlsLinhasNaoEncontradas(listaLinhas)
                                // Para que ele não faça download do mesmo arquivo
                                // limpamos os filtros
                                despacho(limpadorFiltros(true))
                            }

                            // handleMontarXlsLinhasNaoEncontradas(simcardsArquivo)
                        }
                        inserirData(simcards.data.devices)
                    }
                }
            }
            else if (totalItens >= 1 && (controladorPagina <= paginaAtual)){
                const body = {
                    limit: valorLimite,
                    offset: contador,
                    busca: conteudoBusca,
                    ordenador: ordenador,
                    filtro: getFiltrosToBody(filtros),
                    contar: trazerCount
                }
                await getDadosDispositivos({body});
            }

            setSpinAtivo(false)

            // quando altera dados da tabela e precisa desselecionar ----------
            if(desselecionarCheckboxAposRecarregarTabela && !simcardsSelecionados?.length) {
                if(alternadorSelecao) {
                    onClickSelecaoParqueTodo(false);
                } else {
                    desselecionarTodosCheckboxes('alterouDadosTabela');
                }
            }
        }
        carregarTabela();

        return () => {
            controller.abort();
        }
    }, [paginaAtual, props.conteudoBusca, renderizar, props.filtros, props.buscaArquivo, ordem, props.ordenador
        // , reduxModoSelecaoSimcard
    ])

    function inserirData(data) {

        for (let i = 0; i < totalItens; i++) {
            if (contador === i) {
                let k = i
                for (let j = 0; j < data.length; j++) {
                    itensVar[k] = data[j]
                    k++
                }
            }
            if (i == (totalItens - 1)) {
                setItens(JSON.parse(JSON.stringify(itensVar)))

                // setItens(parseFloat(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)

        if(limpandoSelecao) {
            clickDesselecionarCheckboxVerde(false);
        }
    }

    //Enconta dentro dos itens da tabela o item ao qual vai ser ter a linha expandida
    function mapearDadoDetalhamentoLinha(dados) {
        let detalhamento = ''
        itens.map((item_simcard, i) => {
            if (item_simcard.CD_SIMCARD === dados.CD_SIMCARD) {
                detalhamento = item_simcard;
            }
        })
        return [detalhamento]
    }

    useEffect(() => {
        if (revelarexportarLinhas) {
            async function handleExportarLinhas() {
                setSpinAtivo(true)
                let exportacaoLinhasAux = []
                if (reduxModoSelecaoSimcard && revelarexportarOperadora == false) {
                    const dados = {
                        descricao: 'Relatório de SIM cards buscando pelo parque inteiro',
                        tipo_arquivo: revelarexportarLinhas,
                        tipo_relatorio: 6, // EXPORTAR LINHAS
                        filtros: filtros,
                        buscaArquivo,
                        buscar: conteudoBusca,
                        separador: separadorLinha,
                        contar: false
                    }
                    api.post('/m1/cadastrar/relatorios', dados)
                    handleMensagemInformacao(true)
                    despacho(exportarLinhas(false))
                    despacho(exportarOperadora(false))
                } else if (reduxModoSelecaoSimcard && revelarexportarOperadora == true) {
                    const dados = {
                        descricao: 'Relatório de SIM cards buscando pelo parque inteiro',
                        tipo_arquivo: revelarexportarLinhas,
                        tipo_relatorio: 23,
                        filtros: filtros,
                        buscaArquivo,
                        buscar: conteudoBusca,
                        separador: separadorLinha,
                        contar: false
                    }
                    api.post('/m1/cadastrar/relatorios', dados)
                    handleMensagemInformacao(true)
                    despacho(exportarLinhas(false))
                    despacho(exportarOperadora(false))
                } else {
                    let auxArrayCdSimcard = []
                    simcardsSelecionados.map((simcard) => {
                        if (simcard.CD_SIMCARD !== undefined && simcard.CD_SIMCARD !== null && simcard.CD_SIMCARD !== '') {
                            auxArrayCdSimcard.push(simcard.CD_SIMCARD)
                        } else {
                            auxArrayCdSimcard.push(simcard.FAT_CD_SIMCARD)
                        }
                    })
                    const dados = {
                        descricao: 'Relatório de SIM cards buscando por linhas específicas',
                        tipo_arquivo: revelarexportarLinhas,
                        tipo_relatorio: 6,
                        tipo_relatorioOperadora: 23,
                        arrayCdSimcard: auxArrayCdSimcard,
                        filtros: filtros,
                        buscaArquivo,
                        buscar: conteudoBusca,
                        separador: separadorLinha,
                        contar: false,
                        operadoraAtual: revelarexportarOperadora
                    }
                    await api.post('/m1/cadastrar/relatorios', dados)
                    handleMensagemInformacao(true)
                    despacho(exportarLinhas(false))
                    despacho(exportarOperadora(false))
                    // despacho(revelarexportarOperadora(undefined))


                }
                setSpinAtivo(false)
            }
            handleExportarLinhas()
        }
    }, [revelarexportarLinhas])


    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu === true ? "expand-row-tabela-dispositivos-1" : "expand-row-tabela-dispositivos-2"}>
                <DetalhamentoLinha renderConsultaColunas={props.render} setRenderConsultaColunas={props.setRender} row={mapearDadoDetalhamentoLinha(row)} cdSimcard={row.CD_SIMCARD} gsm={row.FULLCALLERID} previsaoConsumo={row.PREVISAO} plano={row.PLANO + row.EXCEDENTE} renderizar={renderizar} setRenderizar={setRenderizar}></DetalhamentoLinha>
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="cabecalho-linha-expandida">
            </div>
        ),
        expandColumnRenderer: (rowKey) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown(rowKey.rowKey)}>
                    <Image id={rowKey.rowKey} className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()} />
                </Button>
            );
        },
        showExpandColumn: permissaoDescricao,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    const selecaoLinhas = {
        mode: 'checkbox',
        hideSelectColumn: reduxModoSelecaoSimcard,
        hideSelectAll: reduxModoSelecaoSimcard,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(limpandoSelecao) setLimpandoSelecao(false);

            if (isSelect) {
                handleSelecionarSimcard(row.CD_SIMCARD)
            } else {
                handleDesselecionarSimcard(row.CD_SIMCARD)
                if(showCaption) setShowCaption(false);
            }
        },
        onSelectAll: (isSelect, rows, e) => {  // checkbox VERDE
            if(limpandoSelecao) setLimpandoSelecao(false);

            if (isSelect) {
                handleSelecionarTodosSimcards(rows)
                setShowCaption(true);
            } else {
                handleDesselecionarTodosSimcards(rows)
                if(showCaption) setShowCaption(false);
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} className="input-checkbox-simcard" {...rest} />
                    <label className="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            if(rest.checked && !showCaption) {
                setShowCaption(true);
            }

            return ( // checkbox verde
                <>
                    <input type={mode} className="input-checkbox-header-simcard" {...rest} />
                    <label className="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: { backgroundColor: 'gray' },
        color: 'red'
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    function handleMensagemInformacao(confirmacao) {
        if (revelarMensagemInformacao === false) {
            setRevelarMensagemInformacao(true)
        } else {
            setRevelarMensagemInformacao(false)
            if (confirmacao === true) {
                setRenderizar(!renderizar);
            }
        }
    }

    function handleSelecionarTodosSimcards(rows) { // quando SELECIONA o "checkbox verde"
        ColetaClickstream(179)// 'dspSimcardTabelaSimcardHandleSelecionarTodosSimcards'
        // if(alternadorSelecao){
        //     simcardsSelecionados = JSON.parse(JSON.stringify(itens))
        // }else{
        rows.map((item, i) => {
            simcardsSelecionados.push(rows[i]);
        })
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
        // }
    }

    function handleDesselecionarTodosSimcards(rows) {
        ColetaClickstream(180) // 'dspSimcardTabelaSimcardHandleDesselecionarTodosSimcards'
        // if(alternadorSelecao){
        //     simcardsSelecionados = []
        // }else{
        rows.map((item, i) => {
            simcardsSelecionados.map((simcards, x) => {
                if (rows[i].CD_SIMCARD === simcardsSelecionados[x].CD_SIMCARD) {
                    simcardsSelecionados.splice(x, 1);
                }
            })
        })
        // }
        if (simcardsSelecionados.length === 0) {
            simcardsSelecionados = []
            despacho(desabilitarAcoes(true));
        }
    }

    function handleSelecionarSimcard(simcard) {
        ColetaClickstream(181)// 'dspSimcardTabelaSimcardHandleSelecionarSimcard'
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].CD_SIMCARD === simcard) {
                simcardsSelecionados.push(itens[i]);
                operadorasSelecionadas.push(itens[i].DS_PEDIDO_OPERADORA);
                break;
            }
        }
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    function handleDesselecionarSimcard(simcard) {
        ColetaClickstream(182)// 'dspSimcardTabelaSimcardHandleDesselecionarSimcard'
        for (let i = 0; i < simcardsSelecionados.length; i++) {
            if (simcardsSelecionados[i].CD_SIMCARD === simcard) {
                simcardsSelecionados.splice(i, 1);
                operadorasSelecionadas.splice(i, 1);
                break;
            }
        }
        if (simcardsSelecionados.length == 0) {
            despacho(desabilitarAcoes(true));
        }
    }

    function trocarPagina(novaPagina) {
        paginaAntiga = paginaAtual;
        setPaginaAtual(novaPagina)
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange: (newPage) => {
            trocarPagina(newPage)
        }
    }

    function restaurarConfigsIniciaisPagina() {
        totalPaginas = 0
        contador = 0
        paginaAntiga = 0
        controladorPagina = 1
        totalItens = 50
        valorLimite = 50
        simcardsSelecionados = [];
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        setPaginaAtual(1)
    }

    // function handleAlternarSelecao(valor){
    //     alternadorSelecao = valor
    //     despacho(modoSelecaoSimcard(valor))
    // }

    function handleAlternarSelecao(valor) { // Serve para selecionar parque todo (antigo checkbox vermelho). valor=true => selecionado, valor=false => NÃO selecionado.
        alternadorSelecao = valor
        despacho(modoSelecaoSimcard(valor))
        if (valor) {
            despacho(desabilitarAcoes(false));
            despacho(desabilitarAcoesCliente(true));
        } else {
            despacho(desabilitarAcoes(true));
            despacho(desabilitarAcoesCliente(false));
        }
        simcardsSelecionados = []
    }

    const getLimiteNaPagina = () => contador + valorLimite <= totalItens ? contador + valorLimite : totalItens;
    const getTotalItensNaPagina = () => getLimiteNaPagina() - contador;

    function RenderModalAcao({ Acao, ...props }) {
        let newProps = {renderizar, setRenderizar, simcardsSelecionados,  ...props};

        if(props.itensSelecionados) delete newProps.simcardsSelecionados;

        return (
            <div className="componente-modal-overlay">
                <div className="componente-modal-exibir-colunas">
                    <Acao
                        {...newProps}
                    />
                </div>
            </div>
        )
    }

    function handleRespostaSolicitacao({response, error}) { 
        let arrMsgSucesso = {
            arrTexto: ["Sucesso", "Solicitação enviada"],
            btnTexto: "Continuar"
        };

        if(response) { // sucesso
            fecharModalAcao();

            const { linhasInvalidas } = response?.data
            const qtdInvalidas = linhasInvalidas?.length
            if(qtdInvalidas) {
                arrMsgSucesso = {
                    arrTexto: [
                        "Solicitação enviada",
                        `Foram encontradas ${qtdInvalidas} linhas inválidas na sua solicitação. 
                            Todas as demais linhas foram incluídas.`,
                        "Clique em baixar para verificar as linhas inválidas.",
                    ],
                    btnTexto: "Fechar",
                    linhasInvalidas,
                }
            } 

            handleMensagemSucesso(false, arrMsgSucesso);
        }
        else if(error) {
            const resp = error?.response;
            const respDados = resp?.data
            const mensagemErro = respDados?.info;
            
            if(mensagemErro && [400, 401].includes(resp?.status)) {
                return { atencao: mensagemErro }
            }
            else {
                console.error(error)
                return { erro:true }
            }
        }
    }

    function fecharModalAcao() {
        if(revelarDispositivosAlterarCicloDeVidaVar) {
            despacho(revelarDispositivosAlterarCicloDeVida(false))
        }
        else if(revelarDispositivosAlterarPlanoVar) {
            despacho(revelarDispositivosAlterarPlano(false))
        }
        else if(revelarDispositivosAlterarServicosVar) {
            despacho(revelarDispositivosAlterarServicos(false))
        }
    }

    function handleMensagemSucesso(confirmacao, arrMsgSucesso){
        if(mensagemSucesso === false){
            setMensagemSucesso(arrMsgSucesso)
        }else{
            setMensagemSucesso(false)
        }
        if(confirmacao){
            setRenderizar(!renderizar)
        }
    }

    return (
        <>
            {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
            <div className='container-tela'>
                <BotaoInvisivelExportarLinhasNaoEncontradas {...{dadosParaDownloadLinhasNaoEncontradas}} />
                { mensagemSucesso && 
                    <RenderMensagemSucesso {...{mensagemSucesso, handleMensagemSucesso}} />
                }
                {renderDadosParaDownloadLinhasNaoEncontradas && 
                    <AtencaoBaixarArquivoLinhasNaoEncontradas
                        {...{handleMensagemAtencao, dadosParaDownloadLinhasNaoEncontradas}}
                    />
                }

                {revelarAdicionarPacoteExtra === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-adicionar-pacote-extra-container"
                        Acao={AdicionarPacoteExtra}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarReset === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-reset-container"
                        Acao={Reset}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarTesteConexao &&
                    <RenderModalAcao
                        className="componente-modal-acao-reset-container"
                        Acao={TesteConexao}
                        totalItensPreSelecao={totalItens}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarFalhaNaOperadora === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-informar-falha-em-operadora-container"
                        Acao={FalhaNaOperadora}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarSuporteTecnico === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-suporte-tecnico-container"
                        Acao={SuporteTecnico}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarCancelamento === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-cancelamento-container"
                        Acao={Cancelamento}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarRealocacao === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-cancelamento-reposicao-container"
                        Acao={Realocacao}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarNovoPedido === true &&
                    <RenderModalAcao
                        className="componente-modal-tabela-novo-pedido-container"
                        Acao={NovoPedidoSimcard}
                        reposicao={1}
                    />
                }

                {revelarAtivarLinha === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-ativar-linha-container"
                        Acao={AtivarLinha}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarReativarLinha === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-reativar-linha-container"
                        Acao={ReativarLinha}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarDesbloquearUso === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-desbloquear-uso-container"
                        Acao={DesbloquearUso}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarRemoverBloqueioTemporario === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-remover-bloqueio-temporario-container"
                        Acao={RemoverBloqueioTemporario}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarSuspenderLinha === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-suspender-linha-container"
                        Acao={SuspenderLinha}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarBloquearUso === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-bloquear-uso-container"
                        Acao={BloquearUso}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarBloquearTemporariamento === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-bloquear-temporariamente-container"
                        Acao={BloquearTemporariamento}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarAlterarPlanoValor === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-alterar-plano-valor-container"
                        Acao={AlterarPlanoValor}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarAtribuirLinhas === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-alterar-plano-valor-container"
                        Acao={AtribuirLinhas}
                        itensSelecionados={simcardsSelecionados}
                        acaoModal={'Atribuir'}
                        setRenderizar={setRenderizar}
                    />}

                {revelarDesatribuirLinhas === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-alterar-plano-valor-container"
                        Acao={AtribuirLinhas}
                        itensSelecionados={simcardsSelecionados}
                        acaoModal={'Desatribuir'}
                        setRenderizar={setRenderizar}
                    />}
                {revelarTrocaDeTitularidade === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-troca-de-titularidade-container"
                        Acao={TrocaDeTitularidade}
                        itensSelecionados={simcardsSelecionados}
                        isSelecaoParcial={GetIsSelecaoParcial()}
                    />
                }
                {revelarCancelamentoDeConta === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-cancelamento-de-conta-container"
                        Acao={CancelamentoConta}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarIotTelecom === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-cancelamento-de-conta-container"
                        Acao={IotTelecom}
                        // itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarAtivarLinhasAWS === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-cancelamento-de-conta-container"
                        Acao={AtivacaoLinhasAWS}
                        origem='tabelaSimcard'
                        // itensSelecionados={simcardsSelecionados}
                    />
                }

                {revelarAtivarDemonstracao === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-ativar-demonstracao-container"
                        Acao={AtivarDemonstracao}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarDesativarDemonstracao === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-desativar-demonstracao-container"
                        Acao={DesativarDemonstracao}
                        itensSelecionados={simcardsSelecionados}
                    />
                }


                {revelarLiberarSms === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-liberar-sms-container"
                        Acao={LiberarSms}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarBloquearSms === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-bloquear-sms-container"
                        Acao={BloquearSms}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarCancelamentoReposicao === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-cancelamento-reposicao-container"
                        Acao={CancelamentoReposicao}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarEnviarSms === true &&
                    <RenderModalAcao
                        className="componente-modal-acao-enviar-sms-container"
                        Acao={EnviarSms}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarMapa === true &&
                    <RenderModalAcao
                        className="componente-modal-mapa"
                        Acao={Mapa}
                    />
                }
                {revelarAlterarGrupo === true &&
                    <RenderModalAcao
                        className="componente-modal-alterar-grupo"
                        Acao={AlterarGrupo}
                        itensSelecionados={simcardsSelecionados}
                    />
                }
                {revelarConfigurarGrupo === true &&
                    <RenderModalAcao
                        className="componente-modal-configurar-grupo"
                        Acao={ConfigurarGrupo}
                    />
                }

                {revelarImportarDescricao === true &&
                    <RenderModalAcao
                        className="componente-modal-configurar-grupo"
                        Acao={ImportarDescricao}
                    />
                }

                {revelarRelatorioConsumo === true &&
                    <RenderModalAcao
                        className="componente-modal-configurar-grupo"
                        Acao={RelatorioConsumo}
                        {...{filtros,buscaArquivo, conteudoBusca,separadorLinha}}
                    />
                }
                {revelarRelatorioDisponibilidade === true &&
                    <RenderModalAcao
                        className="componente-modal-configurar-grupo"
                        Acao={RelatorioDisponibilidade}
                        {...{filtros,buscaArquivo, conteudoBusca,separadorLinha}}
                    />
                }
                {revelarExibirColunas === true &&
                    <RenderModalAcao
                        className="componente-modal-exibir-colunas"
                        Acao={PersonalizarColunas}
                        render={props.render}
                        setRender={props.setRender}
                    />
                }
                {revelarDispositivosAlterarServicosVar &&
                    <RenderModalAcao
                        className="componente-modal-exibir-colunas"
                        Acao={AlterarServico}
                        {...{operadorasSelecionadas, handleRespostaSolicitacao}}
                    />
                }
                {revelarDispositivosAlterarPlanoVar &&
                    <RenderModalAcao
                        className="componente-modal-exibir-colunas"
                        Acao={AlterarPlano}
                        {...{operadorasSelecionadas, handleRespostaSolicitacao}}
                    />
                }
                {revelarRenegociarContrato === true &&
                        <div className="componente-modal-overlay">
                             <div className="componente-modal-acao-alterar-plano-valor-container">
                                 <RenegociarContrato
                                     renderizar={renderizar}
                                     setRenderizar={setRenderizar}
                                     itensSelecionados={simcardsSelecionados}
                                 >
                                 </RenegociarContrato>
                             </div>
                        </div>
                }
                 {revelarDispositivosAlterarContaVar &&
                    <RenderModalAcao
                        className="componente-modal-exibir-colunas"
                        Acao={AlterarCicloDeVida}
                        {...{operadorasSelecionadas, handleRespostaSolicitacao}}
                    />
                }
                 {revelarDispositivosAlterarCicloDeVidaVar &&
                    <RenderModalAcao
                        className="componente-modal-exibir-colunas"
                        Acao={AlterarCicloDeVida}
                        {...{operadorasSelecionadas, handleRespostaSolicitacao}}
                    />
                }
                {revelarVisualizarDados === true &&
                    <BarraDadosCliente />
                }
                {revelarBarraFiltro === true &&
                    <BarraFiltragem />
                }


                <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <>
                                <ReactDragListView.DragColumn
                                    onDragEnd={props.onDragEnd}
                                    nodeSelector="th"
                                    ignoreSelector="th.selection-cell-header, th.expand-cell-header"
                                >
                                    {showCaption && !spinAtivo && (
                                        <BarraTabelaLegenda
                                            setShowCaption={setShowCaption}
                                            onClick={onClickSelecaoParqueTodo}
                                            parqueTodoSelecionado={alternadorSelecao}
                                            filtros={revelarBarraFiltro}
                                            totalItens={totalItens}
                                            getTotalItensNaPagina={getTotalItensNaPagina}
                                        />
                                    )}

                                    <BootstrapTable // TABELA
                                        classes={"tabela"}
                                        condensed={true}
                                        keyField='CD_SIMCARD'
                                        data={itens}
                                        columns={props.colunas}
                                        rowEvents={eventosLinhas}
                                        selectRow={selecaoLinhas}
                                        expandRow={itemExpandido}
                                        bootstrap4={true}
                                        bordered={false}
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        {...paginationTableProps}
                                    />
                                </ReactDragListView.DragColumn>

                                {revelarContador &&
                                    <PaginacaoTabela
                                        setPaginaAtual={trocarPagina}
                                        totalPaginas={totalPaginas}
                                        totalItens={totalItens}
                                        valorLimite={valorLimite}
                                        paginaAtual={paginaAtual}
                                        page={paginationProps.page}
                                        onPageChange={paginationProps.onPageChange}
                                    />
                                }

                            </>
                        )
                    }
                </PaginationProvider>

                {revelarContador ? // rodapé tabela
                    <div className="itens-exibidos-tabela fonte-cor-1">
                        Mostrando
                        <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                        -
                        <span className="label-tabela-itens-exibidos">{getLimiteNaPagina()}</span>
                        de
                        <span className="label-tabela-itens-exibidos">{totalItens}</span>
                    </div>
                    :
                    <div className="itens-exibidos-tabela fonte-cor-1">
                        Carregando...
                    </div>
                }
            </div>
        </>
    )
}

let verificadorBloqueioFinanceiro = false

function CarregarColunas(props) {
    let colunaSimcard;
    const [colunas, setColunas] = useState([]);
    const [render, setRender] = useState(false);
    const [colunasMongo, setColunasMongo] = useState();
    const [colunasMongoFaturas, setColunasMongoFaturas] = useState()
    const [permissaoFaturas, setPermissaoFaturas] = useState();
    let dataFormatada = moment().format('YYYY-MM-DD')

    const despacho = useDispatch();
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    useEffect(() => {
        if (permissaoElemento.length > 0) {
            async function handlePermissao() {
                setPermissaoFaturas(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/colunasFaturas"))
            }
            handlePermissao()
        }
    }, [permissaoElemento])

    async function handlerOrdenador(nomeOrdenador, nomeDiferenciado) {
        ColetaClickstream(187) // 'dspSimcardTabelaSimcardHandlerOrdenador'
        if (decryptJWT("clientePrimario") != 2455) {
            let isItDescrescente = false
            const imagem = document.getElementById(nomeOrdenador + nomeDiferenciado)
            //verifica esta mudando a forma de ordenacao no mesmo elemento
            if (nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]) {
                await ordenador.split(' ').map((item_ordenador, i) => {
                    if (nomeOrdenador == "CONSUMO_PERCENTUAL" || nomeOrdenador == "CONSUMO_MB"){
                        if (item_ordenador === 'ASC') {
                            ordenador = ''
                            isItDescrescente = false
                            handleRemoverImagens(imagem)
                        }
                        if (item_ordenador === 'DESC') {
                            ordenador = nomeOrdenador + ' ASC'
                            isItDescrescente = true
                            handlerDesativarImagens(imagem, 'DESC')
                        }
                    } else {   
                    //verifica se existe tal item e muda para decrescente
                    if (item_ordenador === 'ASC') {
                        //Sera tal ordenador para decrescente
                        ordenador = nomeOrdenador + ' DESC'
                        isItDescrescente = true
                        //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                        handlerDesativarImagens(imagem, 'ASC')
                    }
                    if (item_ordenador === 'DESC') {
                        //Sera tal ordenador para crescente
                        ordenador = ''
                        isItDescrescente = false
                        //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                        handleRemoverImagens(imagem)
                    }
                }
                })
            } else if (!isItDescrescente) {
                if (nomeOrdenador == "CONSUMO_PERCENTUAL" || nomeOrdenador == "CONSUMO_MB") {
                    ordenador = nomeOrdenador + ' DESC'
                    handlerDesativarImagens(imagem, 'ASC')
                } else {
                    ordenador = nomeOrdenador + ' ASC'
                    handlerDesativarImagens(imagem, 'DESC')
                }
            }

            despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));
        }
    }



    const onDragEnd = async (fromIndex, toIndex) => {
        //nao deixa colocar nas primeiras duas colunas que sao o select e o descricao de linha
        if (toIndex >= 0) {
            let columnsCopy = [];
            let clunasHidden = [];
            //retirado dois pois os dois primeiras colunas sao da linha expandida e do select
            //src  https://codesandbox.io/s/qz20y44449?file=/index.js:1290-1525
            //ORDEM NO ARRAY
            await Promise.all(
                colunas.map((item_colunas, i) => {
                    if (!item_colunas.hidden && item_colunas.hidden != true) {
                        columnsCopy.push(item_colunas)
                    }
                    else {
                        clunasHidden.push(item_colunas)
                    }
                })
            )
            //DADOS NO MONGO DA ORDEM
            let itemColunaFrom = JSON.parse(JSON.stringify(columnsCopy[fromIndex]))
            let itemColunaTo = JSON.parse(JSON.stringify(columnsCopy[toIndex]))

            //ORDEM NO ARRAY
            const item = columnsCopy.splice(fromIndex, 1)[0];
            columnsCopy.splice(toIndex, 0, item);

            //ORDEM NO ARRAY
            await Promise.all(
                clunasHidden.map((item_clunasHidden, i) => {
                    columnsCopy.push(item_clunasHidden)
                })
            )

            //se for movido da direita pra esquesda
            //adiciona 1 nos elementos que estiverem entre o elemento movido e onde ele foi movido 
            if (itemColunaFrom.ordem > itemColunaTo.ordem) {
                await Promise.all(
                    colunasMongo.map((item_colunasMongo, i) => {
                        if (itemColunaFrom.ordem > item_colunasMongo.ordem && itemColunaTo.ordem <= item_colunasMongo.ordem) {
                            item_colunasMongo.ordem += 1
                        }
                        if (itemColunaFrom.dataField === item_colunasMongo.id) {
                            item_colunasMongo.ordem = itemColunaTo.ordem
                        }
                    })
                )
            }

            //se for movido da esquerda pra direita
            //adiciona 1 nos elementos que estiverem entre o elemento movido e onde ele foi movido so que ao contrario
            if (itemColunaFrom.ordem < itemColunaTo.ordem) {
                await Promise.all(
                    colunasMongo.map((item_colunasMongo, i) => {
                        if (itemColunaTo.ordem >= item_colunasMongo.ordem && itemColunaFrom.ordem < item_colunasMongo.ordem) {
                            item_colunasMongo.ordem -= 1
                        }
                        if (itemColunaFrom.dataField === item_colunasMongo.id) {
                            item_colunasMongo.ordem = itemColunaTo.ordem
                        }
                    })
                )
            }

            //ATUALIZA A ORDEM DAS COLUNAS
            await Promise.all(
                columnsCopy.map((item_columnsCopy, i) => {
                    colunasMongo.map((item_colunasMongo, i) => {
                        if (item_columnsCopy.dataField === item_colunasMongo.id) {
                            item_columnsCopy.ordem = item_colunasMongo.ordem
                        }
                    })
                })
            )
            //salva as alteracoes
            await api.post('/m1/servicos/dados/alterar_colunas_simcard', {
                email: decryptJWT('email'),
                colunas: colunasMongo,
                colunasFaturas: colunasMongoFaturas
            })

            setColunas(columnsCopy)
        }
    };


    useEffect(() => {

        encodeURIComponent(decryptJWT('email'))
        async function checarFiltros() {
            despacho(mudarManejador("dspDispositivos"));
            const bloqueioFinanceiro = await api.get('/m1/consultar/cliente_bloqueio?cd_pav_cliente=' + decryptJWT('codigoCliente') + "&fl_acao=1");
            if (bloqueioFinanceiro.data.dados.length > 0) {
                verificadorBloqueioFinanceiro = true
                despacho(esconderMenuAcoesDispositivosBloqueio(true))
            }
            else {
                verificadorBloqueioFinanceiro = false
                despacho(esconderMenuAcoesDispositivosBloqueio(false))
            }

            const cicloAtual = await api.get('/m1/consultar/ciclo?dt_data=' + dataFormatada)
                .then(async function (resposta) {
                    return resposta.data.dados[0]
                })
            await api.get("/m1/servicos/token/buscar_colunas_simcard?email=" + encodeURIComponent(decryptJWT('email')))
                .then(async function (resposta) {
                    let colunasFaturas = resposta.data.dados.colunasFaturas
                    setColunasMongoFaturas(colunasFaturas)
                    colunaSimcard = resposta.data.dados.colunas
                    //atualiza os nomes das colunas personalizados
                    const colunasPersonalizada = await api.get('/m1/consultar/informacoes_adicionais_customizadas_cliente?cd_pav_cliente=' + decryptJWT('codigoCliente'));

                    const dadosColunasPersonalizadas = colunasPersonalizada?.data?.dados;

                    if (colunaSimcard && colunasPersonalizada && colunasPersonalizada.data.status == 200 && colunaSimcard.length > 0 && dadosColunasPersonalizadas.length > 0) { // && colunaSimcard.length >=43

                        colunaSimcard = colunaSimcard?.map(col => {
                            if(['IAC_DESCRICAO_1', 'IAC_DESCRICAO_2', 'IAC_DESCRICAO_3'].includes(col?.id)) {
                                const idx = col.id.substring(14) - 1;
                                return { ...col, nome: dadosColunasPersonalizadas[idx].TITULO }
                            }
                            else {
                                return col;
                            }
                        })
                        
                        await api.post('/m1/servicos/dados/alterar_colunas_simcard', {
                            email: decryptJWT('email'),
                            colunas: colunaSimcard,
                            colunasFaturas: colunasFaturas
                        })
                    }
                    setColunasMongo(colunaSimcard)

                    const colunasTemporario = getColunasTemporario({colunaSimcard, verificadorBloqueioFinanceiro, cicloAtual})
                    let ordenacaoColunas = []

                    await Promise.all(
                        colunasTemporario.map((item_colunasTemporario, i) => {
                            colunaSimcard.map((item_colunaSimcard, ij) => {
                                if (item_colunaSimcard.id == item_colunasTemporario.dataField) {
                                    item_colunasTemporario['ordem'] = item_colunaSimcard.ordem
                                    ordenacaoColunas.push(item_colunasTemporario)
                                }
                            })
                        })
                    )
                    //ordena os elementos
                    ordenacaoColunas.sort(function (a, b) { return a.ordem - b.ordem });
                    //seta as colunas
                    setColunas(ordenacaoColunas)
                    return resposta.data.dados;
                })
        }

        function getColunasTemporario({colunaSimcard, verificadorBloqueioFinanceiro, cicloAtual}) {
            const verificarColuna = (id) => verificarColunaDsp(colunaSimcard, id);
        
            const verificarNome = (id) => verificarNomeDsp(colunaSimcard, id);
        
            const isClienteBloqueadoOuLinhaInativa = (row) => verificadorBloqueioFinanceiro || row?.TRAFEGO != "Ativo"

            function ContainerFormatter({valor, row}) {
                return <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"} key={row?.CD_SIMCARD}>
                    {valor}
                </p>
            }
        
            const MoneyFormatter = (cell, row) => { // formatter: ...
                const valor = parseFloat(cell);
                return <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 label-tabela-1 container-valor-simcard" : "fonte-cor-1 label-tabela-1 container-valor-simcard"}>
                    {(!valor || isNaN(valor)) ? 
                        'R$ 0.00' : 
                        <>
                            <p className="valor-rs-simcard">R$</p> 
                            <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 valor-simcard" : "fonte-cor-1 valor-simcard"}> 
                                {formatarMilhar(valor)}
                            </p>
                        </>
                    }
                </p>;
            }

            // const colunasTodosNiveisClientes = [
            const colunasInicial = [
                {  dataField: 'CALLERID', nome: 'Linha',
                    formatter: (cell, row) => {
                        if (cell === null || cell === undefined || cell === '') {
                            return <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"} key={row.FAT_CD_SIMCARD}>{row.FULLCALLERID}</p>;
                        } else {
                            return <ContainerFormatter valor={cell} row={row} />;
                        }
                    },
                },
                { dataField: 'ICCID', nome: 'ICCID' },
                { dataField: 'OPERADORA', nome: 'Operadora' },
                {
                    dataField: 'ON_OFF', nome: 'Status',
                    formatter: (cell, row) => { // OBS: ISSO SE REPETE 3X no front
                        const valorStatus = cell && cell.toLowerCase();
                        if (valorStatus === 'online') {
                            return (
                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Inicio - {moment(row.INICIO).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>}>
                                    <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVerde()} /> Online</p>
                                </OverlayTrigger>
                            )
                        }
                        else if (valorStatus === 'offline') {
                            return (
                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Fim - {moment(row.FIM).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>}>
                                    <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVermelho()} /> Offline</p>
                                </OverlayTrigger>
                            )
                        }
                        else if (valorStatus === 'estoque') {
                            return (
                                <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Estoque</Tooltip>}>
                                    <p className={isClienteBloqueadoOuLinhaInativa(row) ? "fonte-cor-7 label-tabela-1 label-tabela-tooltip" : "fonte-cor-1 label-tabela-1 label-tabela-tooltip"} key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseAzul()} /> Estoque</p>
                                </OverlayTrigger>
                            )
                        }
                    },
                },
                {
                    dataField: 'ULTIMO_EVENTO', nome: 'Último Evento',
                    formatter: (cell, row) => {
                        return formatarData(cell, false, isClienteBloqueadoOuLinhaInativa(row));
                    },
                },
                {
                    dataField: 'CONECTOU', nome: 'Início da última conexão',
                    formatter: (cell, row) => {
                        return formatarData(row.INICIO, false, isClienteBloqueadoOuLinhaInativa(row));
                    },
                },                        
                {
                    dataField: 'DESCONECTOU', nome: 'Fim da última conexão',
                    formatter: (cell, row) => {
                        return formatarData(row.FIM, false, isClienteBloqueadoOuLinhaInativa(row));
                    },
                },
                {
                    dataField: 'TEMPO_DURACAO', nome: 'Tempo da conexão', 
                    formatter: (cell, row) => {

                        const calculaTempoDuracao = (dtFim_formatado) => {
                            let diferencaDatas = dtFim_formatado.diff(moment(row.INICIO,"YYYY-MM-DD HH:mm:ss"));
                            let diferencaDatasDuration = moment.duration(diferencaDatas);
                            let asHours = Math.floor(diferencaDatasDuration.asHours());

                            if(asHours < 0) {
                                TEMPO_DURACAO = '-';
                            } else {
                                if (asHours < 10) {
                                    asHours = '0' + asHours;
                                }
                                return asHours + ":" + moment.utc(diferencaDatas).format("mm") +":" + moment.utc(diferencaDatas).format("ss") +"";
                            }
                        }
                    
                        let TEMPO_DURACAO = "-";
                        if (row.INICIO && row.INICIO != 'null') {
                            if (row.FIM && row.FIM != 'null') {
                                TEMPO_DURACAO = calculaTempoDuracao(moment(row.FIM,"YYYY-MM-DD HH:mm:ss"));

                            } else {
                                if(row.ON_OFF === 'Offline') { // if FIM === 'null'
                                    TEMPO_DURACAO = '-';
                                } else {
                                    TEMPO_DURACAO = calculaTempoDuracao(moment(moment().format("YYYY-MM-DD HH:mm:ss")));
                                }
                            }
                        }
                        return <p className={verificadorBloqueioFinanceiro ? "fonte-cor-7 label-tabela-1":"fonte-cor-1 label-tabela-1"}>{TEMPO_DURACAO}</p>;
                    },
                },
                { dataField: 'TECNOLOGIA', nome: 'Tecnologia' },
                { dataField: 'TRAFEGO', nome: 'Tráfego' },
                {
                    dataField: 'PLANO', nome: 'Plano',
                    formatter: (cell, row) => {
                        const excedente = row?.EXCEDENTE > 0 ? '+ ' + row.EXCEDENTE : ''
                        return <ContainerFormatter valor={`${cell ? (cell.toFixed(2) + ' ' + excedente) : cell} MB`}  row={row} />
                    },
                },
                {
                    dataField: 'CONSUMO_PERCENTUAL', nome: 'Consumo (%)',
                    formatter: (cell, row) => {
                        let styleProgressBar = '';
                        if (!cell || cell == '')
                            cell = 0;

                        if (cell > cicloAtual.perc_dias_ciclo) {
                            styleProgressBar = '#CD2D37'

                        } else {
                            styleProgressBar = '#36D896'
                        }

                        return (
                            <ProgressBar className='barra-progresso-container'>
                                <ProgressBar style={{ 'backgroundColor': styleProgressBar }} now={cell} />
                                <span className={isClienteBloqueadoOuLinhaInativa(row) ? "texto-barra-progresso fonte-cor-1" : "texto-barra-progresso fonte-cor-1"}>{cell === undefined ? '0.00%' : cell.toFixed(0) + '%'}</span>
                            </ProgressBar>
                        )
                    },
                },
                {
                    dataField: 'SALDO_MB', nome: 'Saldo',
                    formatter: (cell, row) => {
                        const excedente = row?.EXCEDENTE > 0 ? parseFloat(row.EXCEDENTE) : 0;
                        return <ContainerFormatter valor={`${cell === null || cell === undefined || isNaN(cell) ? '0.00' : (parseFloat(cell.toFixed(2)) + excedente).toFixed(2)} MB`}  row={row} />
                    },
                },
                {
                    dataField: 'CONSUMO_MB', nome: 'Consumo',
                    formatter: (cell, row) => {
                        return <ContainerFormatter valor={cell != undefined/*  && isNaN(cell) */ ? cell.toFixed(2) + ' MB' : "-"}  row={row} />
                        //COMENTADO && isNaN(cell) para exibir a quantidade de MB consumido
                    },
                },
                {
                    dataField: 'SMS_ATIVADO', nome: 'Envio de SMS',
                    formatter: (cell, row) => {
                        if (cell == 0) {
                            return <ContainerFormatter valor={'Bloqueado'} row={row} />;
                        }
                        else {
                            return <p className={verificadorBloqueioFinanceiro ? "fonte-cor-7 label-tabela-1" : "fonte-cor-1 label-tabela-1"}>Ativo</p>;
                        }
                    },
                },
                { dataField: 'IMEI_VERIFICADOR', nome: 'IMEI' },
                { dataField: 'IA_MODELO_DISPOSITIVO', nome: 'Modelo' },
                { dataField: 'IA_MARCA_DISPOSITIVO', nome: 'Marca' },
                { dataField: 'NOME_GRUPO', nome: 'Grupo' },
                { dataField: 'IA_NOME', nome: 'Nome' },
            // ]

            // const colunasDspN1N2 = [
                { dataField: 'DIAS_USADOS', nome: 'Dias utilizados' },
                { dataField: 'IA_MODELO_IMEI', nome: 'Modelo modem' },
                { dataField: 'IA_MARCA_IMEI', nome: 'Marca modem' },
                { dataField: 'IA_ENDERECO', nome: 'Endereço' },
                { dataField: 'IA_CIDADE', nome: 'Cidade' },
                { dataField: 'IA_UF', nome: 'Estado' },
                { dataField: 'IA_CEP', nome: 'CEP' },

                { dataField: 'CLIENTE_CODIGO', nome: 'Cliente' },
                { dataField: 'LAT_LONG_OPERADORA', nome: 'Operadora atual' },
                { dataField: 'CLIENTE_NOME', nome: 'Nome do cliente' },
                { dataField: 'IP', nome: 'IP' },
                { dataField: 'IMSI', nome: 'IMSI' },

                { dataField: 'NUMPACOTES', nome: 'Pacotes no ciclo', usaFormatarMilhar: true },
                { dataField: 'CONEXOES_DIA', nome: 'Conexão no dia', usaFormatarMilhar: true },
                { dataField: 'PACOTES_DIA', nome: 'Pacotes no dia', usaFormatarMilhar: true },

                // COLUNAS FATURAS 
                { dataField: 'FAT_CONTA', nome: 'Conta' },
                { dataField: 'FAT_CICLOVIDA', nome: 'Ciclo de vida' },
                { dataField: 'FAT_PLANO', nome: 'Nome do plano' },
                { dataField: 'FAT_NOTA_FISCAL', nome: 'Nota Fiscal' },

                { dataField: 'IAC_DESCRICAO_1' },
                { dataField: 'IAC_DESCRICAO_2' },
                { dataField: 'IAC_DESCRICAO_3' },
                { dataField: 'FAT_PEDIDO', nome: 'Pedido' },

                { dataField: 'PLANO_TIPO', nome: 'Plano tipo' },
                { dataField: 'SERVICO_ADICIONAL', nome: 'Tipo de produto' },
                { dataField: 'TIPO_SIMCARD', nome: 'Tipo de SIM card' },
                { 
                    dataField: 'SITUACAO', 
                    nome: 'Contrato'
                }, 
                {
                    dataField: 'CONSUMO_DIA_MB', nome: 'Consumo no dia',
                    formatter: (cell, row) => {
                        return <ContainerFormatter valor={cell ? cell.toFixed(2) + ' MB' : '-'}  row={row} />
                    },
                },

                {
                    dataField: 'DATA_ATIVACAO', nome: 'Ativação',
                    formatter: (cell, row) => {
                        return formatarData(cell, true, isClienteBloqueadoOuLinhaInativa(row));
                    },
                },
                {
                    dataField: 'DATA_PRAZO_CONTRATO', nome: 'Prazo contrato',
                    formatter: (cell, row) => {
                        return formatarData(cell, true, isClienteBloqueadoOuLinhaInativa(row));
                    },
                },
                {
                    dataField: 'DATA_COMPRA', nome: 'Aquisição',
                    formatter: (cell, row) => {
                        return formatarData(cell, true, isClienteBloqueadoOuLinhaInativa(row));
                    },
                },

                {
                    dataField: 'SITUACAO_GRUPO', nome: 'Situação',
                    formatter: (cell, row) => {
                        return <ContainerFormatter valor={cell ? cell.split(';')[0] : '-'} row={row} />;
                    },
                },

                { dataField: 'IA_OBSERVACOES', nome: 'Informações adicionais' },
                { dataField: 'NUMCONEXOES', nome: 'Conexão no ciclo' },

                {
                    dataField: 'FAT_N2_VALOR_PLANO', nome: 'Valor fatura',
                    formatter: MoneyFormatter
                },
                {
                    dataField: 'VALOR_PLANO', nome: 'Valor',
                    formatter: MoneyFormatter 
                }
            ]

            let colunasDsp =  getColunasClienteNivelN3(colunasInicial, 'dataField')

            if(!isPortal()) {
                colunasDsp = [...colunasInicial]
            }

            // const cdCliente = decryptJWT("codigoCliente");
            // if(cdCliente !== '1') {
            //     colunasDsp = colunasDsp.filter(item => item.dataField !== "SERVICO_ADICIONAL");
            // }

            return colunasDsp.map(({dataField, nome, formatter, usaFormatarMilhar}) => {
                let headerClasses = 'nao-selecionavel';
                let nomeColuna = nome;
        
                if(['IAC_DESCRICAO_1', 'IAC_DESCRICAO_2', 'IAC_DESCRICAO_3'].includes(dataField)) {
                    headerClasses = undefined;
                    nomeColuna = verificarNome(dataField)
                }
        
                let buttonOnClick = () => {
                    if(dataField === 'TEMPO_DURACAO') return;
        
                    let ordenarPor = dataField;
                    switch(dataField) {
                        case 'SITUACAO':
                            ordenarPor = 'Contrato'
                            break;
                        case 'ON_OFF':
                            ordenarPor = 'STATUS'
                            break;
                        case 'SITUACAO_GRUPO':
                            ordenarPor = 'SITUACAO'
                            break;
                        case 'IMEI_VERIFICADOR':
                            ordenarPor = 'IMEI'
                            break;
                    }
                    return handlerOrdenador(ordenarPor, ordenarPor)
                }
        
                let hidden = !verificarColuna(dataField);
        
                if(dataField === 'LAT_LONG_OPERADORA' || (colunasPermissaoFaturas.includes(dataField) && !permissaoFaturas)) {
                        hidden = true;
                }

                if(!formatter) {
                    formatter = (cell, row) => { 
                        let valor = (!cell || cell === 'null') ? '-' : cell;
                        if(cell && usaFormatarMilhar) {
                            valor = formatarMilhar(cell);
                        }
            
                        return <ContainerFormatter 
                            row={row}
                            valor={valor}
                        />;
                    };
                }
        
                return {
                    dataField,
                    headerClasses,
                    text: <button className='btn-header-nome fonte-cor-1' onClick={buttonOnClick}>
                        {nomeColuna}
                        <Image id={dataField+dataField} className='d-none' src={setaDropdown()} />
                    </button>,
                    hidden,
                    formatter,
                }
            })
        }

        checarFiltros();
    }, [render, permissaoFaturas]);

    return (
        <>
            {colunas.length !== 0 && <TabelaSimcard onDragEnd={onDragEnd} colunas={colunas} render={render} setRender={setRender} conteudoBusca={props.conteudoBusca} 
            filtros={props.filtros} 
            buscaArquivo={props.buscaArquivo} 
            ordenador={props.ordenador} />}
        </>
    )

}

const colunasPermissaoFaturas = [        
    'FAT_CONTA',
    'FAT_CICLOVIDA',
    'FAT_PLANO',
    'FAT_PEDIDO',
    'FAT_NOTA_FISCAL',
    'FAT_N2_VALOR_PLANO',
]

function handlerDesativarImagens(imagem, terminacao) {
    const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
    const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
    elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
    elementosAtivosASC.forEach(el => el.classList.add("d-none"))
    elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
    elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

    imagem.classList.remove("d-none")
    imagem.classList.add("icone-ordenacao-" + terminacao)
}

function handleRemoverImagens(imagem) {
    const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
    const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
    elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
    elementosAtivosASC.forEach(el => el.classList.add("d-none"))
    elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
    elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
    elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
    elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
    imagem.classList.remove("icone-ordenacao-ASC")
    imagem.classList.remove("icone-ordenacao-DESC")
}

function mapStateToProps(state) {
    return {
        conteudoBusca: state.manejadorState.conteudoBusca,
        filtros: state.filtrosState.filtros,
        buscaArquivo: state.buscaArquivoState.buscaArquivo,
        ordenador: state.manejadorState.ordenador
    };
}

//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(CarregarColunas))
