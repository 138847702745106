//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {formatarData} from '../../../utils/formatadorDataTabela'/*eslint-disable*/

import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro } from "../../../redux/actions/index"
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import CadastroMensagem from './cadastro'
import EnviosMensagem from './envios'
//COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//ESTILIZACAO
import '../../../css/painelVirtueyes/centralMensagens/centralMensagens.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Grid(props){
    //HOOKS TABELA
    const [itens, setItens] = useState([]);
    const [renderizar, setRenderizar] = useState(false);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [valorLimite, setValorLimite] = useState(50);
    const [mensagemSelecionada, setMensagemSelecionada] = useState([])
    const [revelarModalReenvio, setRevelarModalReenvio] = useState(false)
    const [envios, setEnvios] = useState()
    //HOOKS MENSAGENS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemOperacaoNegada, setRevelarMensagemOperacaoNegada] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS MODAL
    const [renderizarCadastro, setRenderizarCadastro] = useState(false)
    const [renderizarEdicao, setRenderizarEdicao] = useState(false)
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    const [revelarEdicao, setRevelarEdicao] = useState(false)
    //REDUX VARIÁVEIS
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let temFiltros = false;
    let itensVar = []
    let revelarNovaMensagem =  useSelector(state => state.mensagensState.revelarNovaMensagem);

    const [spinAtivo, setSpinAtivo] = useState(true);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(385) // 'centralMensagensTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("centralMensagens"));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }   


            if(conteudoBusca  && !temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fila_mensagem?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    contar: false
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fila_mensagem", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fila_mensagem?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }

    }
  

    async function contarItens(){
        let arrayItens = []
        let apiData;
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/fila_mensagem?contar=true&buscar='+conteudoBusca);
            } else{
                const dados = {
                   
                    buscar: conteudoBusca,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/fila_mensagem", dados);
            }
            itensVar = []
            if(apiData.data.dados[0].CONTADOR){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
        setItens([])
    }



    const colunas = [{
        dataField: "createdAt",
        headerClasses: 'nao-selecionavel',
        text: 'Data',
        sort: true,
        formatter: (cell, row) => {
            let dataBr = '-'
            if(cell && cell != "0000-00-00 00:00:00"){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'nivel',
        headerClasses: 'nao-selecionavel',
        text: 'Nível',
        sort: true,
        formatter: (cell, row) => {
            if(cell != '' && cell != null && cell != undefined){
                if(cell == 3)
                    return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-verde"/><p className="fonte-cor-1 label-tabela-1">Normal</p></div>)
                if(cell == 2)
                    return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-amarela"/><p className="fonte-cor-1 label-tabela-1">Atenção</p></div>)
                if(cell == 1)
                    return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-vermelha"/><p className="fonte-cor-1 label-tabela-1">Urgente</p></div>)
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'assunto',
        headerClasses: 'nao-selecionavel',
        text: 'Assunto',
        sort: true,
        formatter: (cell, row) => {
            if(cell != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'email_remetente',
        headerClasses: 'nao-selecionavel',
        text: 'Autor',
        sort: true,
        formatter: (cell, row) => {
            if(cell != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'envios',
        headerClasses: 'nao-selecionavel',
        text: 'Destinatário',
        sort: true,
        formatter: (cell, row) => {
            if(cell != '' && cell != null && cell != undefined){
                cell= cell.split(',')
                if((cell.length) > 1){
                    return <p onClick={() => handleRenderizarModalEnvios(row.cd_msg_fila_mensagem)} className="fonte-cor-2 label-tabela-2">Diversos</p>;
                }else{
                    return <p onClick={() => handleRenderizarModalEnvios(row.cd_msg_fila_mensagem)} className="fonte-cor-2 label-tabela-2">{cell[0]}</p>;
                }
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'tipo',
        headerClasses: 'nao-selecionavel',
        text: 'Forma de envio',
        sort: true,
        formatter: (cell, row) => {
            let auxTipo
            if(cell === 'Push')auxTipo ='Notificação'
            if( cell === 'Email')auxTipo ='E-mail'
            if( cell === 'Plataforma')auxTipo ='Plataforma'

            if(cell != '' && cell != null && cell != undefined){
                return <p className="fonte-cor-1 label-tabela-1">{auxTipo}</p>;
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
        }
    },
    {
        dataField: 'status',
        headerClasses: 'nao-selecionavel',
        text: 'Status',
        sort: true,
        formatter: (cell, row) => {
            if(cell != '' && cell != null && cell != undefined){
                if(cell == 3)
                  return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-verde"/><p className="fonte-cor-1 label-tabela-1">Enviado</p></div>)
                if(cell == 2)
                  return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-amarela"/><p className="fonte-cor-1 label-tabela-1">Enviado parcialmente</p></div>)
                if(cell == 1)
                  return (<div className="tabela-central-mensagens-container-esfera"><div className="tabela-central-mensagens-esfera-vermelha"/><p className="fonte-cor-1 label-tabela-1">Não enviado</p></div>)
            }else{
                return <p className="fonte-cor-1 label-tabela-1">-</p>;
            }
                  
        }
    }]

    async function handleRenderizarModalEnvios(cd_msg_fila_mensagem){
        const {data} = await api.get('/m1/consultar/fila_mensagem_envio?cd_msg_fila_mensagem='+cd_msg_fila_mensagem)
        setEnvios(data.dados)
        setRevelarModalReenvio(true)
    }

    function handleMensagemAtencao(confirmacao){

        setRenderizarEdicao(!renderizarEdicao)
        setRevelarMensagemOperacaoNegada(true)

        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleDeletar()
            }
        }
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        //await api.get('/m1/deletar/fila_mensagem?_id='+mensagemSelecionada.cd_msg_fila_mensagem)
        .then(function (response) {
            handleMensagemSucesso(false)
            restaurarConfigsIniciaisPagina()
            setRenderizar(!renderizar)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    function handleMensagemAtencao(confirmacao){
        setSpinAtivo(true)
        setRevelarMensagemOperacaoNegada(true)
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleDeletar()
            }
        }
        setSpinAtivo(false)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemOperacaoNegada(confirmacao){
        if(revelarMensagemOperacaoNegada === false){
            setRevelarMensagemOperacaoNegada(true)
        }else{
            setRevelarMensagemOperacaoNegada(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }



    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
          
            setMensagemSelecionada(row)          
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

return(
        <>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarNovaMensagem === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-mensagem-container">
                        <CadastroMensagem
                            tituloJanela={'Cadastrar'}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroMensagem>
                    </div>
                </div>
            }
            { revelarEdicao === true && mensagemSelecionada.status == 'Não enviado' && 
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-mensagem-container">
                        <CadastroMensagem
                            tituloJanela={'Editar'}
                            mensagem={mensagemSelecionada}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            >
                        </CadastroMensagem>
                    </div>
                </div>
            }
            { revelarModalReenvio === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-mensagem-container">
                        <EnviosMensagem
                            envios={envios}
                            setRevelarModalReenvio={setRevelarModalReenvio}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            >
                        </EnviosMensagem>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='ID' 
                                data={itens} 
                                condensed={true}
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Mensagem deletada!"}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencao && mensagemSelecionada.status == 'Não enviado' && 
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar mensagem ?"}
                />
            }
             {revelarMensagemOperacaoNegada && mensagemSelecionada.status != 'Não enviado' &&
                <MensagemErro
                handleMensagemErro={handleMensagemOperacaoNegada}
                mensagemTitulo={'Operação negada'}
                mensagemParagrafo1={"Apenas mensagens com  status 'Não enviado' podem ser alteradas ou excluídas!"}
                mensagemBotao={"Retornar"}
            />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Algo deu errado!"}
                    mensagemParagrafo2={"Por favor, tente mais tarde."}
                    mensagemParagrafo3={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));