//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
import {api} from '../../../conexoes/api';
import decryptJWT from '../../../utils/decryptJWT'
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectTipoEndereco from '../../componentesModais/selects/selectTipoEndereco'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectTipoEndereco, setRevelarSelectTipoEndereco] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">TipoEndereco {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectTipoEndereco === false &&
                <Button name="cdTipoEndereco" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoEndereco(!revelarSelectTipoEndereco)}>
                    <div className="fonte-cor-1 label-campo">{dsTipoEndereco}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectTipoEndereco === true &&
                <SelectTipoEndereco
                    setRevelarSelectTipoEndereco={setRevelarSelectTipoEndereco} //Hook para fechar a modal
                    setCdTipoEndereco={setCdTipoEndereco} //Código do TipoEndereco
                    setDsTipoEndereco={setDsTipoEndereco} //Nome do TipoEndereco
                    dsTipoEndereco={dsTipoEndereco} 
                    phTipoEndereco={phTipoEndereco} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectTipoEndereco>
            }
        </Form.Group>*/

export default function SelectTipoEndereco(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const [selectClass, setSelectClass] = useState(props.styleClass || "campo-select" )
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS MODAL
    const [lsTipoEndereco, setLsTipoEndereco] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleTipoEndereco(TipoEndereco, CodigoEndereco, Cep, Endereco, Numero, Bairro, Cidade, Estado, CodigoEstado,nome_fantasia,infoAdicionais){
        props.setDsTipoEndereco(TipoEndereco)
        props.setCdTipoEndereco(CodigoEndereco)
        props.setDsCep(Cep)
        props.setDsEndereco(Endereco)
        props.setDsNumero(Numero)
        props.setDsBairro(Bairro)
        props.setDsCidade(Cidade)
        props.setDsEstado(Estado)
        props.setCdEstado(CodigoEstado)
        props.setDsNomeFantasia(' / '+nome_fantasia)
        props.setInfoAdicionais(infoAdicionais)
        handleCancelar()
    }

    function handleCancelar(){
        props.setRevelarSelectTipoEndereco(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderPlano(){
            let consulta;
            if(props.cd_pav_cliente){
                consulta =  await api.get('/m1/consultar/cliente_endereco_entrega?cd_pav_cliente='+props.cd_pav_cliente);
            }
            else{
                consulta = await api.get('/m1/consultar/cliente_endereco_entrega?cd_pav_cliente='+decryptJWT("codigoCliente"));
            }
            
            if(consulta.data.dados.length > 0){
                let objetoTipoEndereco = consulta.data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_dsp_cliente_endereco_entrega}>
                            <div className="campo-select-opcao campo-select-opcao-1" onClick={() => handleTipoEndereco(item.ds_nome, 
                                                                                                                    item.cd_dsp_cliente_endereco_entrega, 
                                                                                                                    item.ds_cep,
                                                                                                                    item.ds_endereco,
                                                                                                                    item.ds_numero,
                                                                                                                    item.ds_bairro,
                                                                                                                    item.ds_cidade,
                                                                                                                    item.ch_sigla,
                                                                                                                    item.cd_pav_uf,
                                                                                                                    item.ds_nome_fantasia,
                                                                                                                    item.ds_complemento) }>
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">{item.ds_nome} / {item.ds_nome_fantasia}</span>
                            </div>
                            {consulta.data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsTipoEndereco(objetoTipoEndereco)
            }else{
                let objetoTipoEndereco = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsTipoEndereco(objetoTipoEndereco)
            }
            setSpinAtivo(false)
        }
        renderPlano()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <Button name="cdTipoPessoa" className={`campo-texto-cor-3 ${selectClass}`} onClick={() => props.setRevelarSelectTipoEndereco(false)}>
                <div className="fonte-cor-1 label-campo label-campo-select-overflow">{props.dsTipoEndereco}{props.dsNomeFantasia}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
            <div className="campo-texto-cor-3 campo-select-corpo">
                <ul className="campo-select-lista">
                    {lsTipoEndereco}
                </ul>
            </div>
        </Form>
    )
}