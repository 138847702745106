//IMPORTAÇÕES
import React, {useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import {Button} from 'react-bootstrap';/*eslint-disable*/
//IMPORTAÇÕES ICONES
import {fecharJanela} from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { visualizarDados} from "../../../redux/actions/index";
import {OverlayTrigger, Tooltip } from "react-bootstrap";
//ESTILIZACAO
import '../../../css/componentesModais/barraDadosCliente/barraDadosCliente.css'
// SPINNER
import decryptJWT from '../../../utils/decryptJWT'

function BarraDadosCliente(props){

    const [codigoCliente, setCodigoCliente] = useState('-')
    const [razaoSocial, setRazaoSocial] = useState('-')
    const [nomeFantasia, setNomeFantasia] = useState('-')
    const [cpfCnpj, setCpfCnpj] = useState('-')
    const [fone, setFone] = useState('-')
    const [email, setEmail] = useState('-')
    const [spinAtivo, setSpinAtivo] = useState(true);
    const despacho = useDispatch();

    let visualizarDadosRefresh = useSelector(state => state.virtueyesState.visualizarDadosRefresh);

    useEffect(() => {
        setSpinAtivo(true)
        async function carregarDados(){
            const {data} = await api.get("/m1/consultar/cliente?cd_pav_cliente="+decryptJWT("codigoCliente"))
            setCodigoCliente(data.dados[0].n0_codigo_cliente)
            setRazaoSocial(data.dados[0].ds_nome)
            setNomeFantasia(data.dados[0].ds_nome_fantasia)
            {data.dados[0].ds_cnpj == "" &&
                setCpfCnpj(data.dados[0].ds_cpf)
            }
            {data.dados[0].ds_cpf == "" &&
                setCpfCnpj(data.dados[0].ds_cnpj)
            }
            setFone(data.dados[0].ds_telefone)
            setEmail(data.dados[0].ds_email)
        }
        carregarDados()
        setSpinAtivo(false)
    }, [visualizarDadosRefresh])

    return(
        <div className="barra-dados-cliente fundo-cor-5">
            <div className="barra-dados-caixa-titulo">
                <span>Cliente</span>
            </div>
            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{codigoCliente}</Tooltip>}>
                <div className="barra-dados-caixa">
                    <span className="fonte-cor-1">Código: {codigoCliente}</span>
                </div>
            </OverlayTrigger>
            <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{razaoSocial}</Tooltip>}>
                <div className="barra-dados-caixa">
                        <span className="fonte-cor-1">Razão social: {razaoSocial}</span>
                </div>
            </OverlayTrigger>
            {/* <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{nomeFantasia}</Tooltip>}>
                <div className="barra-dados-caixa">
                    <span className="fonte-cor-1">Nome fantasia: {nomeFantasia}</span>
                </div>
            </OverlayTrigger>  */}
            <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{cpfCnpj}</Tooltip>}>
                <div className="barra-dados-caixa">
                    <span className="fonte-cor-1">CPF/CNPJ: {cpfCnpj}</span>
                </div>
            </OverlayTrigger>
            {/* <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{fone}</Tooltip>}>
                <div className="barra-dados-caixa">
                    <span className="fonte-cor-1">Fone: {fone}</span>
                </div>
            </OverlayTrigger>
            <div className="divisora-vertical-barra-dados campo-select-divisor-cor-2"/>
            <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>{email}</Tooltip>}>
                <div className="barra-dados-caixa">
                    <span className="fonte-cor-1">Email: {email}</span>
                </div>
            </OverlayTrigger> */}
            <div className="barra-dados-caixa-fechar">
                {/* <Button className="botao-fechar-janela" variant="danger" onClick={() => despacho(visualizarDados(false))}>
                    <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                </Button> */}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        visualizarDados: state.virtueyesState.visualizarDados,
        visualizarDadosRefresh: state.virtueyesState.visualizarDadosRefresh
     };
} 

export default connect(mapStateToProps)(BarraDadosCliente);