import axios from "axios";
import { getToken } from "../servicos/auth";
const config = require('../configuracoes.json')

const isAmbienteProd = window.location.host.includes("localhost") ||
window.location.host.includes("127.0.0.1") ||
window.location.host.includes("integra") ? false : true;

const apiRotinasTelecall = config["servico-rotinas/TELECALL"]; // iotTelecom / ativacaoSimcard

const ip_super_veye = config.super_veye.ip;

const api_svdispositivos = axios.create({
  baseURL: isAmbienteProd ?
      'https://' + ip_super_veye :
      (config.super_veye.protocolo ? config.super_veye.protocolo : 'http://') + ip_super_veye + ":" + config.super_veye.porta,
});

api_svdispositivos.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// --------------------------------------------------------------------------
const ip_super_veye_sol = config.super_veye_sol.ip;
const porta_super_veye_sol = config.super_veye_sol.porta;

const api_svsolicitacoes = axios.create({
  baseURL: isAmbienteProd ?
      'https://' + ip_super_veye_sol :
      (config.super_veye_sol.protocolo ? config.super_veye_sol.protocolo : 'http://') + ip_super_veye_sol + ':' + porta_super_veye_sol,
});

api_svsolicitacoes.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
// --------------------------------------------------------------------------

const ip = config.m1.ip;
const porta = config.m1.porta;
const api = axios.create({
  baseURL: isAmbienteProd ?
      'https://' + ip :
      (config.m1.protocolo ? config.m1.protocolo : 'http://') + ip + ":" + porta,
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const ip_s3 = config.s3.ip;

const api_s3 = axios.create({
  baseURL: isAmbienteProd ?
      'https://' + ip_s3 :
      (config.s3.protocolo ? config.s3.protocolo : 'http://') + ip_s3 + ":" + config.s3.porta,
});

api_s3.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const ip_papeis = config.papeis.ip;

const api_papeis = axios.create({
  baseURL: isAmbienteProd ?
      `https://${ip_papeis}` :
      (config.papeis.protocolo ? config.papeis.protocolo : 'http://') + ip_papeis,
});
api_papeis.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const ip_clientes = config.clientes.ip;
const api_clientes = axios.create({ 
  baseURL: isAmbienteProd ?
      `https://${ip_clientes}` :
      (config.clientes.protocolo ? config.clientes.protocolo : 'http://') + ip_clientes,
});
api_clientes.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const api_opencellid = axios.create({
  baseURL: 'https://us1.unwiredlabs.com'
})

api_opencellid.interceptors.request.use(async config => {
  config.data.token = "9382a0dafaf86f";
  return config;
});

const rotinas_Telecall = axios.create({
  baseURL: isAmbienteProd ?
      'https://' + apiRotinasTelecall?.ip :
      (apiRotinasTelecall?.protocolo ? apiRotinasTelecall?.protocolo : 'http://') + apiRotinasTelecall?.ip + ":" + apiRotinasTelecall?.porta,
})

rotinas_Telecall.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api, api_svdispositivos, api_svsolicitacoes, api_s3, rotinas_Telecall, api_opencellid, api_papeis, api_clientes };