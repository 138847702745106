//BIBLIOTECAS
import React,{useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {formatarData} from '../../../../utils/formatadorDataTabela'
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment'
//ICONES
import { elipse, elipseVerde, fecharJanela } from "../../../../utils/alternanciaIcones";
//OUTROS
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js';
import { revelarLogReset } from "../../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
import '../../../../css/painelVirtueyes/solicitacaoOperadora/tabela.css'

export default function VisaoGeral(props) {
    //HOOKS
    const [itens, setItens] = useState('')
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const modal = useRef();
    const despacho = useDispatch();

    useEffect(() => {
        async function handleCarregarLogs(){
            setSpinAtivo(true) 
            const logs = await api.post('m1/consultar/pav_log_solicitacao_api', {"arraySolicitacoes": props.itensSelecionados});
            if(logs.data.dados != undefined){
                setItens(logs.data.dados)
            }
            setSpinAtivo(false) 
        }
        handleCarregarLogs();
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };
    async function handleCancelar() {
        ColetaClickstream(1397) // 'solicitacaoOperadoraTabelaDropAcoesLogResetCancelar
        setSpinAtivo(false)
        despacho(revelarLogReset(false))
    }

    const colunas = [
        {
            dataField: 'LINHA',
            text: 'SIM card',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
        }
        },
        {
            dataField: 'CD_SOLICITACAO',
            text: 'ID solicitação',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DS_RESPONSE',
            text: 'Response',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DS_URL',
            text: 'URL',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DS_OPERADORA',
            text: 'Operadora',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DT_CRIACAO',
            text: 'Envio',
            headerClasses: 'tabela-transparente-cabecalho nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' ? moment(cell).format('DD-MM-YYYY HH:mm:ss') : "-"}</p>;
            }
        }
    ]
     
    return(
        <Form ref={modal}>
            <div className="modal-adicionar-log-reset fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse" />
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Log de reset</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela" />
                        </Button>
                    </Col>
                </Form.Row>
                <div className="campo-select-triangulo-cor-2 campo-select-triangulo"></div>
                <div id="tela-descricao-visao-geral-soliciatacao-cliente">
                    <div className="bg-background-2 container-caixas-descricao-solicitacao-cliente">
                        <div className="container-caixas-tela-descricao-solicitacao-log-reset fundo-cor-3">
                            <div className="caixa-descricao-solicitacao-cliente-dados">
                                <BootstrapTable
                                    hover
                                    classes='tabela-transparente-header-fixo'
                                    keyField='operadora'
                                    headerClasses={ "class-centralizar"}
                                    data={ itens }
                                    columns={ colunas}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}