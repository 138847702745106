import React, { useEffect } from "react";/*eslint-disable*/
import { logout } from "../../servicos/auth";/*eslint-disable*/
import {api} from "../../conexoes/api";
import { Redirect } from "react-router-dom";
import decryptJWT from '../../utils/decryptJWT'
import Keycloak from 'keycloak-js';
// REDUX
import { useDispatch } from 'react-redux';
import { imagemPerfil } from "../../redux/actions/index";
// COLETA CLICKSTREAM
// import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
import utilsLocalStorage from "../../utils/localStorage";

export default function Logout(props) {
    const cdClienteErp = localStorage.getItem("clientePrimario")
    const despacho = useDispatch();

        
    useEffect(() => {
        async function Logout(){
            ClearKeycloac()
             await api.post('/m1/servicos/dados/logout', {
                id: decryptJWT("id"),
            })
            .then(function (response) {
                logout();
                if(cdClienteErp == 2455){
                    window.location.reload()
                }
                props.history.push('/login')
            })
            .catch(function (error) {
               
                logout();
                props.history.push('/login')
            }); 
        }
        async function ClearKeycloac(){
            const keycloak = Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`);
            keycloak.init().then(authenticated => {
            keycloak.logout()
            })
        }
        despacho(imagemPerfil(''))
        ClearKeycloac()
        Logout(); 
        utilsLocalStorage.removeLocalStorageSms();
    }, [])

    return (
        <Redirect
            to={{ pathname: "/login" }}
        />
    )
}