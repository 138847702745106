import React,{useEffect, useState} from 'react';/*eslint-disable*/
import {Card, Row, Col, Form, Tab,Tabs, Nav, Button,Image} from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import SelectTipoPermissao from '../../componentesModais/selects/selectTipoPermissao'
import SelectPerfil from '../../componentesModais/selects/selectPerfil'
import SelectCliente from '../../componentesModais/selects/selectCliente'
import SelectUsuario from '../../componentesModais/selects/selectUsuario'
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import BootstrapTable from 'react-bootstrap-table-next';
import { IconeFaturasVerde, IconeFaturas, elipseVerde, fecharJanela, setaDropdown, confirmar, cancelar, editarVerde, adicionarMaisVerde, 
    editarCinza, menuFinanceiro , menuFinanceiroVerdeAtivo, menuSMS, menuSMSVerdeAtivo, menuPedidos, menuPedidosVerdeAtivo, 
    dispositivo, dispositivoVerdeAtivo, olho , olhoVerdeAtivo, admin, adminVerdeAtivo, naoPermitido, teste, sms, pedidos, clientes, dashboard, 
    dashboardVerdeAtivo, usuarios, automacao , automacaoVerdeAtivo, relatorios, exclamacao, usuariosVerdeAtivo, outros, outrosVerde, IconeCadastros,
    IconeCadastroVerde, IconeSolicitacoes, IconeSolicitacoesVerde,relatoriosAtivo, manutencao, manutencaoVerdeAtivo } from '../../../utils/alternanciaIcones';
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//REDUX
import { visualizarDados, mudarManejador } from "../../../redux/actions/index";
import { useDispatch, useSelector } from 'react-redux';
//ESTLIZACAO
import "../../../css/painelAdministrativo/pavPermissao/pavPermissao.css"
import decryptJWT from '../../../utils/decryptJWT'

import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function CadastroPermissao() {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS STATES
    const [tipoPermissao, setTipoPermissao] = useState(true)
    const [dsTipoPermissao, setDsTipoPermissao] = useState('Selecione');
    const [cdTipoPermissao, setCdTipoPermissao] = useState();
    const [phTipoPermissao, setPhTipoPermissao] = useState('Selecione');
    const [cliente, setCliente] = useState(false);
    const [dsCliente, setDsCliente] = useState('Selecione');
    const [cdCliente, setCdCliente] = useState();
    const [perfil, setPerfis] = useState(false);
    const [dsPerfil, setDsPerfil] = useState('Selecione');
    const [cdPerfil, setCdPerfil] = useState();
    const [usuario, setUsuario] = useState(false);
    const [dsUsuario, setDsUsuario] = useState('Selecione');
    const [cdUsuario, setCdUsuario] = useState();
    // HOOKS TABELAS
    const [permissao, setPermissao] = useState([[],[],[],[]]);
    // HOOKS
    const [manejadorId, setManejadorId] = useState('');
    const [permissaoSelecionada, setPermissaoSelecionada] = useState(false);
    const [checkPermissao, setCheckPermissao] = useState({});
    const [renderizar, setRenderizar] = useState(true);
    // HOOKS MENSAGENS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    // HOOKS SELECTS
    const [revelarSelectTipoPermissao, setRevelarSelectTipoPermissao] = useState(false)
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false)
    const [revelarSelectPerfil, setRevelarSelectPerfil] = useState(false)
    const [revelarSelectUsuario, setRevelarSelectUsuario] = useState(false)
    const [cd_pav_cliente_origem, setCd_pav_cliente_origem] = useState(1)


    const [menuModulo, setMenuModulo] = useState([]);
    const [verificadorPrimeiroModulo, setVerificadorPrimeiroModulo] = useState('')
    const [idElementoCor, setIdElementoCor] = useState(0)
    const [verificadorPrimeiraTab, setVerificadorPrimeiraTab] = useState(1)
    const [cdMenuModuloAtivo, setCdMenuModuloAtivo] = useState('');
    const [customizado] = useState(1);
    const [moduloTela, setModuloTela] = useState('')
    const [menuTela, setMenuTela] = useState([]);
    const [tabAtivado, setTabAtivado] = useState('')
    const [elementoAtivado, setElementoAtivado] = useState('')
    const [menuElemento, setMenuElemento] = useState([]);
    const [elementoTela, setElementoTela] = useState('')
    const [cdPermissao, setCdPermissao] = useState(-1)
    const [primeiroModulo, setPrimeiroModulo] = useState(1)
    const [dummy, setDummy] = useState()

    let indentificadorModuloElemento = false;
    // OUTROS
    const [respostaCadastro,setRespostaCadastro] = useState()
    const despacho = useDispatch();
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(158) // 'pavPermissaoCadastro'
            setColetaUsabilidade(false)
        }
    },[])

    async function preencherTipoPermissao(cd){
        if(cd == 'cliente'){
            setCliente(!cliente);
            setUsuario();
            setPerfis();
        }else if(cd == 'perfil'){
            setPerfis(!perfil);
            setCliente();
            setUsuario();
        }else if(cd == 'usuario'){
            setUsuario(!usuario);
            setCliente();
            setPerfis();
        }
        setPermissaoSelecionada(cd)
    }

    useEffect(() => {
        despacho(mudarManejador("Permissao"))
        if(cdTipoPermissao!==undefined){
            preencherTipoPermissao(cdTipoPermissao)
        }
    }, [cdTipoPermissao]);

    useEffect(() => {
        if(cdCliente!==undefined){
            setCdPermissao(cdCliente)
            preencherPermissao(cdCliente)
        }
    }, [cdCliente]);

    useEffect(() => {
        if(cdPerfil!==undefined){
            setCdPermissao(cdPerfil)
            preencherPermissao(cdPerfil)
        }
    }, [cdPerfil]);

    useEffect(() => {
        if(cdUsuario!==undefined){
            setCdPermissao(cdUsuario)
            preencherPermissao(cdUsuario)
        }
    }, [cdUsuario]);

    async function preencherPermissao(cd){
        setSpinAtivo(true)
        const response = await api.get('/m1/consultar/permissao?cd_pav_'+permissaoSelecionada+"="+cd+"&cd_pav_cliente_origem="+cd_pav_cliente_origem+"&painel_administrativo=true")
        .then(function(resposta){
            setPermissao(resposta.data.dados)
            setMenuModulo(resposta.data.dados[1])
            setMenuTela(resposta.data.dados[2])
            setMenuElemento(resposta.data.dados[3])
            setSpinAtivo(false)
            return resposta.data.dados; 
        }) 
        .catch(function(erro){
            setSpinAtivo(false)
            return erro.stack;
        })
        setManejadorId(cd);
        setPermissao(response)
    }

    async function cadastrarPermissao(e){
        ColetaClickstream(1059) // 'pavPermissaoCadastroCadastrarPermissao'
        setSpinAtivo(true)
        const resposta = await api.post('/m1/cadastrar/permissao', {
            'permissoes': permissao,
            'cd':permissaoSelecionada,
            'id' :manejadorId,
            "cd_pav_cliente_origem":cd_pav_cliente_origem
        })
        .then(async function(resposta){
            if(resposta.data.status === 200){
            setRevelarMensagemSucesso(!revelarMensagemSucesso)
            const atualizarLogin = await api.get('/m1/servicos/token/atualizarLogin?cd_pav_'+permissaoSelecionada+"="+manejadorId)
            }
            setSpinAtivo(false)
            return resposta.data
        }) 
        .catch(function(error){
            setRevelarMensagemErro(!revelarMensagemErro)
            setSpinAtivo(false)
            return error.response
        })
        setRespostaCadastro(resposta)
    }

    async function mudarDescricaoPermissao(e){
        let perm = permissao;
        let tipo = e.id.split('_')[1]
        let id = e.id.split('_')[2]
        perm[tipo].map((item, i)=>{
        if(perm[tipo][i].id == id){
            perm[tipo][i].descricao = e.value
        }
        })
        setRenderizar(!renderizar)
    }

    async function mudarCheckBoxPermissao(e){
        let perm = permissao;
        let tipo = e.id.split('_')[1]
        let id = e.id.split('_')[2]
        perm[tipo].map((item, i)=>{
        if(perm[tipo][i].id == id){
            perm[tipo][i].indentiPermissao = !perm[tipo][i].indentiPermissao
            if(perm[tipo][i].indentiPermissao === false)
            perm[tipo][i].ativo = 0
        }
        })
        setRenderizar(!renderizar)
    }

    async function mudarCheckPermissao(e){
        let perm = permissao;
        let tipo = e.id.split('_')[1]
        let id = e.id.split('_')[2]
        perm[tipo].map((item, i)=>{
        if(perm[tipo][i].id == id){
            if(perm[tipo][i].ativo === 1)
            perm[tipo][i].ativo = 0
            else
            perm[tipo][i].ativo = 1
        }
        })
        setRenderizar(!renderizar)
    }

    function handleCancelar(){
        ColetaClickstream(1063) // 'pavPermissaoCadastroHandleCancelar'
        setPermissao([[],[],[],[]])
        setCheckPermissao({})
        setPerfis(false)
        setDsPerfil('Selecione')
        setCdPerfil()
        setCliente(false)
        setDsCliente('Selecione')
        setCdCliente()
        setUsuario(false)
        setDsUsuario('Selecione')
        setDsTipoPermissao('Selecione')
        setCdUsuario()
        setCdTipoPermissao()
        setManejadorId('')
        setPermissaoSelecionada(false)
        setRenderizar(true)
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        setRevelarSelectPerfil(false)
        setRevelarSelectCliente(false)
        setRevelarSelectUsuario(false)
    }

    function renderIcones(icone) {
        if (icone === 'outros'){
            return outros();
        }
        if (icone === 'dispositivos'){
            return dispositivo();
        }
        if (icone === "admin"){
            return admin();
        }
        if (icone === "manutencao"){
            return manutencao();
        }
        if (icone === "teste"){
            return teste();
        }
        if (icone === "virtueyes"){
            return olho();
        }
        if (icone === "financeiro"){
            return menuFinanceiro();
        }
        if (icone === "pedidos"){
            return menuPedidos();
        }
        if (icone === "sms"){
            return menuSMS();
        }
        if (icone === "sms"){
            return sms();
        }
        if (icone === "pedidos"){
            return pedidos();
        }
        if (icone === "clientes"){
            return clientes();
        }
        if (icone === "dashboard"){
            return dashboard();
        }
        if (icone === "usuarios"){
            return usuarios();
        }
        if (icone === "automacao"){
            return automacao();
        }
        if (icone === "relatorios"){
            return relatorios();
        }
        if (icone === "faturas"){
            return IconeFaturas();
        }
        if (icone === "cadastros"){
            return IconeCadastros();
        }
        if (icone === "solicitacoes"){
            return IconeSolicitacoes();
        }
    }

    function renderIconesVerdes(icone) {
        if (icone === 'outros'){
            return outrosVerde();
        }
        if (icone === 'dispositivos'){
            return dispositivoVerdeAtivo();
        }
        if (icone === "admin"){
            return adminVerdeAtivo();
        }
        if (icone === "manutencao"){
            return manutencaoVerdeAtivo();
        }
        if (icone === "virtueyes"){
            return olhoVerdeAtivo();
        }
        if (icone === "financeiro"){
            return menuFinanceiroVerdeAtivo();
        }
        if (icone === "pedidos"){
            return menuPedidosVerdeAtivo();
        }
        if (icone === "sms"){
            return menuSMSVerdeAtivo();
        }
        if (icone === "dashboard"){
            return dashboardVerdeAtivo();
        }
        if (icone === "automacao"){
            return automacaoVerdeAtivo();
        }
        if (icone === "usuarios"){
            return usuariosVerdeAtivo();
        }
        if (icone === "faturas"){
            return IconeFaturasVerde();
        }
        if (icone === "cadastros"){
            return IconeCadastroVerde();
        }
        if (icone === "solicitacoes"){
            return IconeSolicitacoesVerde();
        }

        if (icone === "relatorios"){
            return relatoriosAtivo();
        }
    }

    function escondeDropdown(el){
        el.classList.remove("modulo-permissao-cadastro-usuario-cliente-ativo");
        el.classList.remove("fundo-cor-3");
        el.classList.add("modulo-permissao-cadastro-usuario-cliente-desativo");
        el.classList.add("fundo-cor-5");
    }

    function ajuntadorPermissoes(consultaPrimeira, consultaSegunda){
        consultaPrimeira.map((usuario, i)=>{
            let contador = 0;
            consultaSegunda.map((perfil, j)=>{
                if(perfil.ds_tipo === 'SISTEMA' && usuario.ds_tipo === 'SISTEMA'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um SISTEMA
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                if(perfil.ds_tipo === 'MODULO' && usuario.ds_tipo === 'MODULO'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um MODULO
    
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                if(perfil.ds_tipo === 'TELA' && usuario.ds_tipo === 'TELA'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um TELA
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                if(perfil.ds_tipo === 'ELEMENTO' && usuario.ds_tipo === 'ELEMENTO'){
                    if(usuario.cd_tipo === perfil.cd_tipo){
                        //verifica se a permissao de esta sobrepondo a de perfil e esta desativando um ELEMENTO
                        if( usuario.bl_ativo === 0 ){
                            consultaSegunda.splice(contador,1)
                            contador--
                        }
                    }
                }
                contador++
            })
        })
    }

    function handlerResetarValores(consultaPermissao){
        consultaPermissao.map((item,i)=>{
            consultaPermissao[i].map((item_consultaPermissao,ij)=>{
                item_consultaPermissao.ativo = 0;
                item_consultaPermissao.indentiPermissao = false;
            })
        })
    }

    function deletarElementos(consultaPermissao,consulta){
        let conter = 0;
        consultaPermissao.map((item, i)=>{
            consultaPermissao[i].map((item, j)=>{
                if(item.indentiPermissao === false){
                    consulta[i].splice(conter,1)
                    conter--
                }
                conter++;
            })
            conter = 0;
        })
    }

    function handleDropTelas(elementoId,id) {
        //reseta o valor do primeiro elemento selecionado
        setVerificadorPrimeiraTab(1)

        setCdMenuModuloAtivo(id)
        
        let elemento = document.getElementById(elementoId);
        let elementosAtivos = document.querySelectorAll(".modulo-permissao-cadastro-usuario-cliente-ativo");
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdown)
        }
        elemento.classList.remove("modulo-permissao-cadastro-usuario-cliente-desativo");
        elemento.classList.remove("fundo-cor-5");
        elemento.classList.add("modulo-permissao-cadastro-usuario-cliente-ativo");
        elemento.classList.add("fundo-cor-3");
        
        
        setIdElementoCor(elementoId + "-div")
    }

    useEffect(()=>{
        if((primeiroModulo == 1 || primeiroModulo == -1) && verificadorPrimeiroModulo !== ''){
            setPrimeiroModulo(2)
            handleDropTelas(verificadorPrimeiroModulo.split('??')[0], verificadorPrimeiroModulo.split('??')[1])
        }
    },[verificadorPrimeiroModulo, cdPerfil, cdUsuario, cdCliente])
    
    useEffect(()=>{
        function setatSelecione(){
            setDsUsuario('Selecione')
            setDsCliente('Selecione')
            setDsPerfil('Selecione')
            setCdPermissao(-1)

        }
        setatSelecione()
    }, [dsTipoPermissao]); 
    
    useEffect(()=>{
        function handlerCarregarModulos(){
            let modulo = menuModulo.map((item_menuModulo,i)=>{
                if(i == 0) {
                    setVerificadorPrimeiroModulo("moduloUsuario-" + item_menuModulo.cdMenu +"??"+ item_menuModulo.cdMenu)
                }
                return(
                    <li className="modulo-permissao-cadastro-usuario-cliente-desativo fundo-cor-5" key={"moduloUsuario-" + item_menuModulo.cdMenu} id={"moduloUsuario-" + item_menuModulo.cdMenu} onClick={(e)=> handleDropTelas("moduloUsuario-" + item_menuModulo.cdMenu, item_menuModulo.cdMenu)}>
                        {customizado === 1 &&
                            <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-modulo fonte-cor-1">
                                <label id={'_1_'+item_menuModulo.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                    <input  id={'_1_'+item_menuModulo.id+'_checkbox'} defaultChecked={item_menuModulo.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                    <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                </label>
                            </label>
                        }
                        <Image className={idElementoCor === ("moduloUsuario-" + item_menuModulo.cdMenu + '-div')?"float-left icone-menu":"float-left icone-menu-invisivel"} src={renderIconesVerdes(item_menuModulo.icone)}/>
                        <Image className={idElementoCor === ("moduloUsuario-" + item_menuModulo.cdMenu + '-div')?"float-left icone-menu-invisivel":"float-left icone-menu"} src={renderIcones(item_menuModulo.icone)}/>
                        <span onBeforeInput to={"/" + item_menuModulo.rota}  className="col menu-texto float-left label-modulo-permissao-cadastro-usuario-cliente">{item_menuModulo.label}</span>
                        
                        {customizado === 1 &&
                            <div className="conteudo-tab-elementos-permissoes-cadastro-usuario-cliente-menu">
                                <div class="flipswitch">
                                    <input type="checkbox" name="flipswitch" defaultChecked={item_menuModulo.ativo} class="flipswitch-cb" id={'_1_'+item_menuModulo.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                    <label class="flipswitch-label" for={'_1_'+item_menuModulo.id+'_switch'}>
                                        <div class="flipswitch-inner flipswitch-cor-3"></div>
                                        <div class="flipswitch-switch"></div>
                                    </label>
                                </div>
                            </div>
                        }
                    </li>
                )
            })
            setModuloTela(modulo)
        }
        handlerCarregarModulos()
    },[cdMenuModuloAtivo,menuModulo,cdPermissao, revelarSelectCliente, revelarSelectPerfil, revelarSelectUsuario])

    useEffect(()=>{
        function handlerCarregarElementos(){
            let verificadorFirstPassada = 1; 
            let elementoTelatemporario =
                    menuTela.map((item_menuTela,i)=>{
                        if(parseInt(cdMenuModuloAtivo) === parseInt(item_menuTela.menuPai)){
                            if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1){
                                setTabAtivado(item_menuTela.label)
                                setElementoAtivado(item_menuTela.label)
                                setVerificadorPrimeiraTab(2);
                                verificadorFirstPassada++
                            }
                            return( 
                                <Tab onClick={() => setTabAtivado(item_menuTela.label)} tabClassName={elementoAtivado == item_menuTela.label? "tab-elementos-permissoes-cadastro-permissao-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-permissao-desativado fonte-cor-1"} eventKey={item_menuTela.label} title={item_menuTela.label}>
                                    <div className="campo-select-divisor-cor-1 campo-divisor"></div>
                                    {customizado === 1 &&
                                        <div className="switch-tab-elementos-permissoes-cadastro-usuario-cliente">
                                            <div class="flipswitch">
                                                <input type="checkbox" name="flipswitch" defaultChecked={item_menuTela.ativo} class="flipswitch-cb" id={'_2_'+item_menuTela.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                <label class="flipswitch-label" for={'_2_'+item_menuTela.id+'_switch'}>
                                                    <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                    <div class="flipswitch-switch"></div>
                                                </label>
                                            </div>
                                            <span className="col menu-texto">Habilitar</span>
                                        </div>
                                    }
                                    {customizado === 1 &&
                                        <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-tela fonte-cor-1">
                                            <label id={'_2_'+item_menuTela.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                                <input  id={'_2_'+item_menuTela.id+'_checkbox'} defaultChecked={item_menuTela.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                                <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                            </label>
                                        </label>
                                    }
                                    <div className="container-tab-elementos-permissoes-cadastro">
                                        {menuElemento.map((item_menuElemento,i)=>{
                                            if(item_menuElemento.menuPai === item_menuTela.cdMenu){
                                                if(item_menuElemento.filho == 0){
                                                    indentificadorModuloElemento = true;
                                                    return(
                                                        <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>
                                                            {customizado === 1 ?
                                                                <div class="flipswitch">
                                                                    <input type="checkbox" defaultChecked={item_menuElemento.ativo} disabled={!item_menuTela.ativo} name="flipswitch" class={item_menuTela.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menuElemento.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                    <label class="flipswitch-label" for={'_3_'+item_menuElemento.id+'_switch'}>
                                                                        <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                        <div class="flipswitch-switch"></div>
                                                                    </label>
                                                                </div>
                                                            :
                                                                <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menuElemento.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                            }
                                                            {customizado === 1 &&
                                                                <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-tela fonte-cor-1">
                                                                    <label id={'_3_'+item_menuElemento.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                                                        <input  id={'_3_'+item_menuElemento.id+'_checkbox'} defaultChecked={item_menuElemento.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                                                        <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                                                    </label>
                                                                </label>
                                                            }
                                                            <span className="col menu-texto">{item_menuElemento.label}</span>
                                                            <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                        </div>
                                                    )
                                                }
                                            }
                                            
                                        })}
                                    </div>
                                </Tab>
                            )
                        }
                    })

            if(indentificadorModuloElemento){
                setElementoTela(elementoTelatemporario)
            }
            else{
                let elementoTelatemporario =
                    menuModulo.map((item_modulo,i)=>{
                        let mapFilho = false
                        if(parseInt(cdMenuModuloAtivo) === parseInt(item_modulo.cdMenu)){
                            menuTela.forEach(item_tela => {
                                if(item_tela.menuPai === item_modulo.cdMenu){
                                    mapFilho = true
                                }
                            });
                            if(mapFilho === true){
                                return( menuTela.map((item_menu_tela,it)=>{
                                    if(item_menu_tela.menuPai === item_modulo.cdMenu){
                                        if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1) {
                                            setTabAtivado(item_menu_tela.label)
                                            setElementoAtivado(item_menu_tela.label)
                                            setVerificadorPrimeiraTab(2);
                                            verificadorFirstPassada++
                                        }
                                        return(
                                            <Tab id={item_menu_tela.label} onClick={() => setTabAtivado(item_menu_tela.label)} tabClassName={elementoAtivado == item_menu_tela.label? "tab-elementos-permissoes-cadastro-permissao-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-permissao-desativado fonte-cor-1"} eventKey={item_menu_tela.label} title={item_menu_tela.label}>
                                                <div className="campo-select-divisor-cor-1 campo-divisor"></div>
                                                <div className="switch-tab-elementos-permissoes-cadastro-usuario-cliente">
                                                    <div class="flipswitch">
                                                        <input type="checkbox" defaultChecked={item_menu_tela.ativo} name="flipswitch" class="flipswitch-cb" id={'_3_'+item_menu_tela.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                        <label class="flipswitch-label" for={'_3_'+item_menu_tela.id+'_switch'}>
                                                            <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                            <div class="flipswitch-switch"></div>
                                                        </label>
                                                    </div>
                                                    <span className="col menu-texto">Habilitar</span>
                                                </div>
                                                {customizado === 1 &&
                                                    <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-tela fonte-cor-1">
                                                        <label id={'_2_'+item_menu_tela.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                                            <input  id={'_2_'+item_menu_tela.id+'_checkbox'} defaultChecked={item_menu_tela.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                                            <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                                        </label>
                                                    </label>
                                                }
                                                <div className="container-tab-elementos-permissoes-cadastro">
                                                    {menuElemento.map((item_menu_tela_filho,i)=>{
                                                        if(item_menu_tela_filho.menuPai === item_menu_tela.cdMenu){
    
                                                            indentificadorModuloElemento = true;
                                                            return(
                                                                <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>
                                                                    {customizado === 1 ?
                                                                        <div class="flipswitch">
                                                                            <input type="checkbox" defaultChecked={item_menu_tela_filho.ativo} disabled={!item_menu_tela.ativo} name="flipswitch" class={item_menu_tela.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menu_tela_filho.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                            <label class="flipswitch-label" for={'_3_'+item_menu_tela_filho.id+'_switch'}>
                                                                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                                <div class="flipswitch-switch"></div>
                                                                            </label>
                                                                        </div>
                                                                        :
                                                                        <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menu_tela_filho.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                                    }
                                                                    {customizado === 1 &&
                                                                        <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-tela fonte-cor-1">
                                                                            <label id={'_3_'+item_menu_tela_filho.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                                                                <input  id={'_3_'+item_menu_tela_filho.id+'_checkbox'} defaultChecked={item_menu_tela_filho.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                                                                <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                                                            </label>
                                                                        </label>
                                                                    }
                                                                    <span className="col menu-texto">{item_menu_tela_filho.label}</span>
                                                                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </Tab>
                                        )
                                    }
                                }))
                            }else{
                                return( menuElemento.map((item_menuElemento,iml)=>{
                                    if(item_menuElemento.menuPai === item_modulo.cdMenu){
                                        if(verificadorPrimeiraTab == 1 && verificadorFirstPassada == 1) {
                                            setTabAtivado(item_menuElemento.label)
                                            setElementoAtivado(item_menuElemento.label)
                                            setVerificadorPrimeiraTab(2);
                                            verificadorFirstPassada++
                                        }
                                        return(
                                            <Tab id={item_menuElemento.label} onClick={() => setTabAtivado(item_menuElemento.label)} tabClassName={elementoAtivado == item_menuElemento.label? "tab-elementos-permissoes-cadastro-permissao-ativado fonte-cor-1":"tab-elementos-permissoes-cadastro-permissao-desativado fonte-cor-1"} eventKey={item_menuElemento.label} title={item_menuElemento.label}>
                                                <div className="campo-select-divisor-cor-1 campo-divisor"></div>
                                                <div className="switch-tab-elementos-permissoes-cadastro-usuario-cliente">
                                                    <div class="flipswitch">
                                                        <input type="checkbox" defaultChecked={item_menuElemento.ativo} name="flipswitch" class="flipswitch-cb" id={'_3_'+item_menuElemento.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                        <label class="flipswitch-label" for={'_3_'+item_menuElemento.id+'_switch'}>
                                                            <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                            <div class="flipswitch-switch"></div>
                                                        </label>
                                                    </div>
                                                    <span className="col menu-texto">Habilitar</span>
                                                </div>
                                                {customizado === 1 &&
                                                    <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-tela fonte-cor-1">
                                                        <label id={'_3_'+item_menuElemento.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                                            <input  id={'_3_'+item_menuElemento.id+'_checkbox'} defaultChecked={item_menuElemento.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                                            <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                                        </label>
                                                    </label>
                                                }
                                                <div className="container-tab-elementos-permissoes-cadastro">
                                                    {menuElemento.map((item_menuElemento_filho,i)=>{
                                                        if(item_menuElemento.cdMenu === item_menuElemento_filho.menuPai){

                                                            indentificadorModuloElemento = true;
                                                            return(
                                                                <div className='conteudo-tab-elementos-permissoes-cadastro-usuario-cliente'>
                                                                    {customizado === 1 ?
                                                                        <div class="flipswitch">
                                                                            <input type="checkbox" defaultChecked={item_menuElemento_filho.ativo} disabled={!item_menuElemento.ativo} name="flipswitch" class={item_menuElemento.ativo?"flipswitch-cb":"flipswitch-cb-desativado"} id={'_3_'+item_menuElemento_filho.id+'_switch'} onChange={(e) => mudarCheckPermissao(e.target)} />
                                                                            <label class="flipswitch-label" for={'_3_'+item_menuElemento_filho.id+'_switch'}>
                                                                                <div class="flipswitch-inner flipswitch-cor-3"></div>
                                                                                <div class="flipswitch-switch"></div>
                                                                            </label>
                                                                        </div>
                                                                        :
                                                                        <Image className="imagem-conteudo-tab-elementos-permissoes-cadastro-usuario-cliente" src={item_menuElemento_filho.ativo == 1 ?confirmar(): naoPermitido()}/>
                                                                    }
                                                                    {customizado === 1 &&
                                                                        <label className="container-radio-button-cadastro-permissao container-radio-button-cadastro-permissao-tela fonte-cor-1">
                                                                            <label id={'_3_'+item_menuElemento_filho.id+'_checkbox'} className="container-radio-button-cadastro-permissao fonte-cor-1">
                                                                                <input  id={'_3_'+item_menuElemento_filho.id+'_checkbox'} defaultChecked={item_menuElemento_filho.indentiPermissao} type="checkbox" onClick={(e) => mudarCheckBoxPermissao(e.target)}/>
                                                                                <span className="radio-button-cadastro-permissao radio-button-cor-1"></span>
                                                                            </label>
                                                                        </label>
                                                                    }
                                                                    <span className="col menu-texto">{item_menuElemento_filho.label}</span>
                                                                    <div className="campo-select-divisor-cor-1 divisoria-cadastro-usuario-cliente"></div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </Tab>
                                        )
                                    }
                                }))
                            }
                        }
                    })
                setElementoTela(elementoTelatemporario)
            }
        }
        handlerCarregarElementos()
    },[cdMenuModuloAtivo,renderizar,elementoAtivado,tabAtivado, revelarSelectPerfil])

    useEffect(()=>{
        function renderizarBusca(){
            setPermissao([[],[],[],[]])
            setMenuModulo([])
            setMenuTela([])
            setMenuElemento([])
            setCdMenuModuloAtivo(-1)
            setRenderizar(!renderizar)
        }
        renderizarBusca()
    },[dsTipoPermissao, dsCliente, dsUsuario, dsPerfil])
    function handerAlterarTab(k){
        setElementoAtivado(k)
        setTabAtivado(k)
    }

    return(
        <>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <div className="container-topo-cadastro-permissao fundo-cor-5">
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-permissao-1">
                            <div className="barra-verde-cadastro-permissao"></div>
                            <div className="divisora-vertical-cadastro-permissao campo-select-divisor-cor-2"/>
                            <div className="coluna-cadastro-permissao-2">
                                { tipoPermissao &&
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Tipo de permissão</div>
                                        </Form.Label>
                                        { revelarSelectTipoPermissao === false &&
                                            <Button name="cdTipoPermissao" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectTipoPermissao(!revelarSelectTipoPermissao)}>
                                                <div className="fonte-cor-1 label-campo">{dsTipoPermissao}</div>
                                                <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectTipoPermissao === true &&
                                            <SelectTipoPermissao
                                                setRevelarSelectTipoPermissao={setRevelarSelectTipoPermissao} //Hook para fechar a modal
                                                setCdTipoPermissao={setCdTipoPermissao} //Código do TipoPermissao
                                                setDsTipoPermissao={setDsTipoPermissao} //Nome do TipoPermissao
                                                dsTipoPermissao={dsTipoPermissao}
                                                phTipoPermissao={phTipoPermissao}
                                            >
                                            </SelectTipoPermissao>
                                        }
                                    </Form.Group>
                                }
                            </div>
                            <div className="coluna-cadastro-permissao-3">
                                {dsTipoPermissao === 'Selecione' &&
                                    <Form.Group>
                                        <Form.Label className="label">
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        { revelarSelectCliente === false &&
                                            <Button disabled={true} className="campo-texto-cor-3 campo-select">
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                    </Form.Group>
                                } 
                                {cliente &&
                                    <Form.Group>
                                        <Form.Label className="label">
                                            <div className="fonte-cor-1 label-campo">Cliente</div>
                                        </Form.Label>
                                        { revelarSelectCliente === false &&
                                            <Button disabled={dsTipoPermissao === '' || dsTipoPermissao === 'Selecione' || dsTipoPermissao === null || dsTipoPermissao === undefined ? true : false} className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsCliente}</div>
                                                <img className={revelarSelectCliente?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectCliente === true &&
                                            <SelectGenerico
                                                paramRota={"todos_clientes"}
                                                paramCampo1={null}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdCliente}
                                                setDescricao1={setDsCliente}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectCliente}
                                                placeholder={dsCliente}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                }
                                { usuario &&
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Usuário</div>
                                        </Form.Label>
                                        { revelarSelectUsuario === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUsuario(!revelarSelectUsuario)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsUsuario}</div>
                                                <img className={revelarSelectUsuario?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectUsuario === true &&
                                            <SelectGenerico
                                                paramRota={"todos_usuarios"}
                                                paramCampo1={null}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdUsuario}
                                                setDescricao1={setDsUsuario}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectUsuario}
                                                placeholder={dsUsuario}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                }
                                { perfil &&
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Perfil</div>
                                        </Form.Label>
                                        { revelarSelectPerfil === false &&
                                            <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectPerfil(!revelarSelectPerfil)}>
                                                <div className="fonte-cor-1 label-campo campo-texto-select-tamanho">{dsPerfil}</div>
                                                <img className={revelarSelectPerfil?"campo-select-icone-ativado nao-selecionavel":"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                            </Button>
                                        }
                                        { revelarSelectPerfil === true &&
                                            <SelectGenerico
                                                paramRota={"todos_perfis"}
                                                paramCampo1={null}
                                                paramCampo2={null}
                                                paramCampo3={null}
                                                paramCampo4={null}
                                                setCodigo={setCdPerfil}
                                                setDescricao1={setDsPerfil}
                                                setDescricao2={setDummy}
                                                setDescricao3={setDummy}
                                                setDescricao4={setDummy}
                                                setRevelarSelect={setRevelarSelectPerfil}
                                                placeholder={dsPerfil}
                                                sufixo={""}
                                            >
                                            </SelectGenerico>
                                        }
                                    </Form.Group>
                                }
                            </div>
                            <div className="coluna-cadastro-permissao-2">
                                { tipoPermissao &&
                                    <Form.Group>
                                        <Form.Label>
                                            <div className="fonte-cor-1 label-campo">Cliente primario</div>
                                        </Form.Label>
                                        <Form.Control className="campo-texto-cor-3 campo-texto"
                                            name="clientePrimario"
                                            id="clientePrimario"
                                            onChange={e => setCd_pav_cliente_origem(e.target.value)}
                                            value={cd_pav_cliente_origem}
                                        />
                                    </Form.Group>
                                }
                            </div>
                            <div className="divisora-vertical-cadastro-permissao campo-select-divisor-cor-2"/>
                        </div>
                    </Col>
                </Form.Row>
            </div>
            {dsTipoPermissao != 'Selecione' && cdPermissao != -1?
                <div className="container-principal-permissoes">
                    <Form.Row className="form-permissoes-cadastro">
                        <Col className="modulos-cadastro-usuario-cliente">
                            {
                                moduloTela
                            }
                        </Col>
                        <Col className="permissoes-cadastro-col fundo-cor-6">
                            <Tabs activeKey={tabAtivado} onSelect={(k) => handerAlterarTab(k)} class="elementos-permissoes-cadastro-usuario-permissoes" id="uncontrolled-tab-example">
                                {
                                    elementoTela
                                }
                            </Tabs>
                            {tipoPermissao != 'Selecione' && (dsCliente != 'Selecione' || dsPerfil != 'Selecione' || dsUsuario != 'Selecione') &&
                                <div className="coluna-cadastro-permissao-4">
                                    <div className="container-botoes-cadastro-permissao">
                                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm" onClick={() => cadastrarPermissao()}>
                                            <div className="fonte-cor-4">Cadastrar</div>
                                        </Button>
                                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                            <div className="fonte-cor-2">Cancelar</div>
                                        </Button>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Form.Row>
                </div>
                :
                <div className="container-principal-permissoes">
                     <Form.Row className="form-permissoes-cadastro">
                        <div className="modulos-cadastro-usuario-cliente-default fundo-cor-5">
                        </div>
                        <div className="permissoes-cadastro-col-default fundo-cor-6">
                        </div>
                    </Form.Row>
                </div>
            }
            { revelarMensagemSucesso === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"O cadastro de permissao ocorreu com exito!"}
                            mensagemBotao={"Prosseguir"}
                        />
                    </div>
                </div>
            }
        </>
    )
}