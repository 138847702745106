//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api, api_svsolicitacoes} from '../../../../conexoes/api';
import { useForm } from "react-hook-form";
import {Button, Form, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import decryptJWT from '../../../../utils/decryptJWT'
import moment from 'moment'
import XLSX from 'xlsx';
//ICONES
import { exclamacao, alertaAtencao, setaDropdown} from '../../../../utils/alternanciaIcones';
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { revelarAlterarICCIDFaturas } from "../../../../redux/actions/index";
//MODAIS
import { RenderMensagemAtencao } from '../../../componentesModais/mensagens/mensagemAtencao'
import Calendario from '../../../calendario/calendario'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../clickstreamAnalise/clickstreamColeta.js'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
// import ImportarArquivoVeye from '../../../importarArquivo';
import ImportarArquivoXls from '../../../importarArquivoXls';
import '../../../../css/painelFaturas/fatDispositivos/fatAcoes.css'
import { FatModalHeader } from '../utils/componentesModal';
import { MensagemErro } from '../../../componentesModais/mensagens/mensagemAcao';
import { getClientePermitidoCMP, getDadosFatSimcard, verificaOperadorasCMP } from '../utils/funcoes';
import { exportarXlsx } from '../../../../utils/exportarArquivo.js';

const valorLimite = 50
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []
let itensTemp = []

const dadosJSONXLS = [["linha","iccid"],[" ", "'"]]
const dadosJSONCSV = [{"linha": "'","iccid": "'",}]

export default function AlterarIccid(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [dsProtocolo, setDsProtocolo] = useState("")
    const [dsDescricao, setDsDescricao] = useState();
    // const [dsIccid, setDsIccid] = useState("")
    const [quantidadeLinhasSelecionados, setQuantidadeLinhasSelecionados] = useState(-1);
    const [linhaRemover, setLinhaRemover] = useState('');
    // const [controladorValidadorAcao, setControladorValidadorAcao] = useState(false)
    const [itens, setItens] = useState([])
    // const [cdSimcardsArray, setCdSimcardsArray] = useState([])
    const [arquivo, setArquivo] = useState([])
    const [nomeArquivo, setNomeArquivo] = useState('')
    const [extensaoArquivo, setExtensaoArquivo] = useState('')
    const importarArquivoRef = useRef(null)

    // const [todosSimcards, setTodosSimcards] = useState([]);
    // const [todasLinhas, setTodasLinhas] = useState([]);
    const [verificouCMP, setVerificouCMP] = useState(false);
    const [temCMP, setTemCMP] = useState(false);
    const [mensagemCMP, setMensagemCMP] = useState(false);
    const [revelarMensagemCMP, setRevelarMensagemCMP] = useState(false);

    //OUTROS HOOKS
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [dataMin, setDataMin] = useState()
    const [dataMax, setDataMax] = useState()
    const [dataAbertura, setDataAbertura] = useState(new Date)
    const [dataAtual, setDataAtual] = useState(new Date)
    const [dataConclusao, setDataConclusao] = useState('')

    const [customMensagemAtencao, setCustomMensagemAtencao] = useState('')
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [mensagemErroQtdLinhas, setMensagemErroQtdLinhas] = useState("A solicitação deve conter menos de 10 mil simcards.");
    const [revelarMensagemErroSemDados, setRevelarMensagemErroSemDados] = useState(false)
    const [itensInvalidos, setItensInvalidos] = useState([])
    const [itensCancelados, setItensCancelados] = useState([])
    const [linhasInvalidasAux, setLinhasInvalidasAux] = useState([])
    const [ignorarSimcardsArray, setIgnorarSimcardsArray] = useState([])
    const [revelarMensagemAtencaoInvalido, setRevelarMensagemAtencaoInvalido] = useState(false)
    const [revelarMensagemAtencaoLinhaNaoEncontradas, setRevelarMensagemAtencaoLinhaNaoEncontradas] = useState(false)
    const [revelarMensagemAtencaoCancelados, setRevelarMensagemAtencaoCancelados] = useState(false)
    const [bodyMensagemAtencao, setBodyMensagemAtencao] = useState(" ")
    const [erroQtdLinhas, setErroQtdLinhas] = useState(false)
    const [tituloMensagemAtencao, setTituloMensagemAtencao] = useState("Atenção:")
    const [renderDadosParaDownloadLinhasNaoEncontradas, setRenderDadosParaDownloadLinhasNaoEncontradas] = useState(false)
    const [dadosParaDownloadLinhasNaoEncontradas, setDadosParaDownloadLinhasNaoEncontradas] = useState([['Linha','ICCID']])
    const [revelarSelecaoCmp, setRevelarSelecaoCmp] = useState(false)
    const despacho = useDispatch();
    const modal = useRef();

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const cdCliente = decryptJWT('codigoCliente')
    const cdUsuario = decryptJWT('codigo')

    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let modoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);

    const routeM1FiltrarFatSimcard = '/m1/filtrar/fatSimcard'
    const cd_sol_solicita_tipo = 4;

    async function getOperadorasCMP() {
        const simcards = itens;
        if(verificouCMP || !simcards.length) return;

        const { tempSimcards, tempLinhas, erro } = await getDadosFatSimcard({
            modoSelecaoSimcard, simcards, // linhas,
            routeM1FiltrarFatSimcard,
            filtros, conteudoBusca, separadorLinha, buscaArquivo, itensInvalidosBusca:false
        })

        if(erro) {
            setSpinAtivo(false)
            setMensagemErro(erro)
            handleMensagemErro(false)
        }

        // setTodosSimcards(tempSimcards)
        // setTodasLinhas(tempLinhas)

        setVerificouCMP(true);

        const {temOperadoraCmp,mensagem} = await verificaOperadorasCMP({ cd_sol_solicita_tipo, tempSimcards });
        
        if(mensagem) {
            setMensagemCMP(mensagem);
            if(!temOperadoraCmp) setRevelarMensagemCMP(true);
        }

        if(temOperadoraCmp) {
            setTemCMP(true);
        }
        else if(temCMP) {
            setTemCMP(false);
        }
    }

    useEffect(() => {
        getOperadorasCMP();
    }, [itens]);

    useEffect(() => {
        if(revelarSelecaoCmp && mensagemCMP) setRevelarMensagemCMP(true);
    }, [revelarSelecaoCmp, mensagemCMP])

    function handleMensagemCMP(){
        if(revelarMensagemCMP === false){
            setRevelarMensagemCMP(true)
        }else{
            setRevelarMensagemCMP(false)
        }
    }

    const clientePermitido = getClientePermitidoCMP(cdCliente);
    const podeHabilitarSwitch = clientePermitido && temCMP; // qual a limitação? pensa que não seleciona. É POR ARQUIVO


    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(()=>{
        if(itens.length > 10000){
            setErroQtdLinhas(true)
        }else{
            setErroQtdLinhas(false)
        }
    }, [itens])

    useEffect(()=>{
        document.addEventListener('mousedown', handleClickFora);
        return () => {
            limparModal();
            document.removeEventListener('mousedown', handleClickFora);
        }
    },[])

    async function consultarCdSimcard(dados){
        setSpinAtivo(true)
        let auxLinhas = []
        let auxSimcards = []

        dados.map((item)=>{
            auxLinhas.push(item)
        })

        const bodyY = {
            'buscarCdSimcard':auxLinhas,
            "cd_pav_usuario":decryptJWT('codigo'),
            "contar":false,
        }

        const dadosSimcard = await api.post('/m1/consultar/fatSimcard', bodyY)

        if(dadosSimcard.data.dados.length > 0){
            await Promise.all(dadosSimcard.data.dados.map((item)=>{
                auxSimcards.push(item.CALLERID)
            }))

            await Promise.all(dados.map((itemLinhas)=>{
                if(auxSimcards.includes(itemLinhas) === false){
                    linhasInvalidasAux.push(itemLinhas.linha)
                    let arrayAux = [ 
                        itemLinhas.linha+'',
                        itemLinhas.iccid+''
                    ]
                    dadosParaDownloadLinhasNaoEncontradas.push(arrayAux)
                }
            }))
            
            setItens(dadosSimcard.data.dados)
        }else{
            setSpinAtivo(false)
            setMensagemErro("Erro na solicitação")
            handleMensagemErro(false)
        }

        if(linhasInvalidasAux.length > 0){
            setRenderDadosParaDownloadLinhasNaoEncontradas(true)
        }
        // if(dadosParaDownloadLinhasNaoEncontradas.length > 1){
        //     //setBodyMensagemAtencao("Algumas linhas não foram encontradas no sistema. O download do arquivo .xls com a identificação destas linhas foi iniciado.")
        //     handleFecharMensagemAtencaoLinhaNaoEncontradas(false)
        // }
        
        setItens(dadosSimcard.data.dados)
    }

    function limparModal() {
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        itensTemp = []
    }

    async function handleCancelar() {     
        limparModal();
        importarArquivoRef.current.Cancelar()
        despacho(revelarAlterarICCIDFaturas(false))
        setSpinAtivo(false)
    }

    const colunasItensInvalidos = [{
        dataField: 'callerid',
        text: 'Linha',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    },
    {
        dataField: 'motivo',
        text: 'Motivo',
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-nao-permitidos">{cell}</p>;
        }
    }]
    
    function handleFecharMensagemAtencaoLinhaNaoEncontradas(){
        setRevelarMensagemAtencaoLinhaNaoEncontradas(!revelarMensagemAtencaoLinhaNaoEncontradas)
        if(revelarMensagemAtencaoLinhaNaoEncontradas === false){
            setRevelarMensagemAtencaoLinhaNaoEncontradas(true)
        }else{
            setRevelarMensagemAtencaoLinhaNaoEncontradas(false)
        }
    }

    function handleFecharMensagemItensInvalidos(){
        setRevelarMensagemAtencaoInvalido(!revelarMensagemAtencaoInvalido)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }
    
    function handleFecharMensagemItensCancelados(){
        setRevelarMensagemAtencaoCancelados(!revelarMensagemAtencaoCancelados)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }

    const removeNonNumeric = (str) => str.replace(/\D/g, '');

    async function importacaoDeIccids(dados, nomeArquivo, extensaoArquivo){
        let newDados = {}

        await Promise.all(dados.map(async (item, chave) => {
            newDados = {}
            Object.keys(item).map(async (key) => {
                newDados[key.toLowerCase()] = item[key]
            })
            dados[chave] = newDados
        }));
        if(dados.length > 0){
            let dados2 = await dados.map( (item) => { return parseInt(item.linha); })

            dados = await dados.map( ({ linha, iccid }) => ({
                linha, 
                iccid: (typeof iccid === 'string') ? removeNonNumeric(iccid) : iccid
            }))

            setSpinAtivo(true)
            setArquivo(dados)
            setNomeArquivo(nomeArquivo)
            setExtensaoArquivo(extensaoArquivo)
            await consultarCdSimcard(dados2)
        }
        else{
            setRevelarMensagemErroSemDados(true)
        }
        setSpinAtivo(false)
    }

    async function handleSalvar(){
        ColetaClickstream(1445) // FaturasDispositivosDropAcoesAlterarICCID
        setSpinAtivo(true)
        // await api.post('/m1/cadastrar/fat_solicitacao/solicitacao_sem_operadora', {
        await api_svsolicitacoes.post('/api/v2/solicitacao', {
            "cd_pav_cliente": cdCliente,
            "modoSelecao":modoSelecaoSimcard,
            "ds_solicitacao":"Alteração de Iccid",
            "cd_fat_solicitacao_tipo":19,
            cd_sol_solicita_tipo,
            "cd_fat_solicitacao_status":1,
            "dt_inicio": revelarSelecaoCmp ? moment(dataAtual).format('YYYY-MM-DD HH:mm:ss') : moment(dataAbertura).format('YYYY-MM-DD HH:mm:ss'),  
            "dt_prazo_conclusao": dataConclusao ? moment(dataConclusao).format('YYYY-MM-DD HH:mm:ss') : '0000-00-00 00:00:00',
            "ds_motivo":dsDescricao,
            "ds_protocolo_operadora":dsProtocolo,
            "novosIccids":arquivo,
            "simcards":itens.map((item) => item.CD_SIMCARD),
            "cd_pav_usuario_created": decryptJWT('codigo'),
            "filtros":filtros,
            "buscaArquivo":buscaArquivo,
            "buscar":conteudoBusca,
            "separador":separadorLinha,
            "cd_pav_usuario_created": decryptJWT('codigo'),
            "ignorarSimcardsArray":ignorarSimcardsArray,
            'fl_cmp': revelarSelecaoCmp,
        })
        .then(function (response) {
            
            setSpinAtivo(false)
            importarArquivoRef?.current?.Cancelar()

            props.handleRespostaSolicitacao({response});
            // handleMensagemSucesso(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            importarArquivoRef?.current?.Cancelar()

            const { atencao, erro } = props.handleRespostaSolicitacao({error});
            if(atencao) {
                setCustomMensagemAtencao(atencao)
                setRevelarMensagemAtencao(true)
            }
            else if(erro) {
                setMensagemErro("Erro na solicitação")
                handleMensagemErro(false)
            }
        });
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            setCustomMensagemAtencao('');
        }
        if(confirmacao ===true){
        }
    }

    function handleMensagemErroSemDados(confirmacao){
        if(revelarMensagemErroSemDados === false){
            setRevelarMensagemErroSemDados(true)
        }else{
            setRevelarMensagemErroSemDados(false)
        }
        if(confirmacao === true ){
        }
    }

    function handleMensagemQtdLinhas(confirmacao){
        if(erroQtdLinhas === false){
            setErroQtdLinhas(true)
        }else{
            setErroQtdLinhas(false)
        }
        if(confirmacao){
            handleCancelar()
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

    function handleFecharMensagemItensCancelados(){
        setRevelarMensagemAtencaoCancelados(!revelarMensagemAtencaoCancelados)
        if(modoSelecaoSimcard){
            if(itens <= 0){
                handleCancelar()
            }
        }
        else{
            if(quantidadeLinhasSelecionados <= 0){
                handleCancelar()
            }
        }
    }
    
    function exportarXlsLinhasNaoEncontradas(){
        exportarXlsx(dadosParaDownloadLinhasNaoEncontradas, "Linhas_nao_encontradas.xls")
    } 

    useEffect(()=>{
        if(renderDadosParaDownloadLinhasNaoEncontradas){
            const exportar = document.getElementById('exportar-linhas-nao-encontradas-iccid')
            exportar.click();
            setRenderDadosParaDownloadLinhasNaoEncontradas(false)
            setDadosParaDownloadLinhasNaoEncontradas([['Linha','ICCID']])
        }
    },[renderDadosParaDownloadLinhasNaoEncontradas])

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
            <div className="modal-acao-alterar-ciclo-vida fundo-cor-6">
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <button id='exportar-linhas-nao-encontradas-iccid' className='d-none' onClick={() => exportarXlsLinhasNaoEncontradas()}>Export</button>
                
                <FatModalHeader
                    tituloModal='Alterar ICCID' // Realocação
                    clientePermitido={clientePermitido}
                    podeHabilitarSwitch={podeHabilitarSwitch}
                    revelarSelecaoCmp={revelarSelecaoCmp}
                    setRevelarSelecaoCmp={setRevelarSelecaoCmp}
                    handleCancelar={handleCancelar}
                />
                <Form.Row>
                    <Col>
                        <div className="grupo-alterar-ciclo-vida-1">
                            {/* <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Protocolo</div>
                                    </Form.Label>
                                    <Form.Control 
                                        className="campo-texto-cor-3 campo-texto"
                                        type="text"
                                        maxLength="255"
                                        name="protocolo" 
                                        value={dsProtocolo}
                                        onChange={e => setDsProtocolo(e.target.value)}
                                        placeholder="Insira o nº do protocolo" 
                                    />
                                </Form.Group>                                
                            </div> */}
                            <div className="coluna-alterar-ciclo-vida-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Abertura da solicitação</div>
                                    </Form.Label>
                                    { revelarSelecaoCmp ?
                                        <Calendario formato="DD/MM/YYYY" imputCustomisado='De:' data={dataAtual} readOnly={true} setData={setDataAtual}></Calendario>
                                        :
                                        <Calendario formato="DD/MM/YYYY" imputCustomisado='De:' data={dataAbertura} readOnly={false} setData={setDataAbertura}></Calendario>
                                    }
                                    {errors.abertura && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div>
                            {/* <div className="coluna-alterar-ciclo-vida-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Previsão de conclusão</div>
                                    </Form.Label>
                                    <Calendario ref={register({ required: true})} formato="DD/MM/YYYY" imputCustomisado='Até:' data={dataConclusao} setData={setDataConclusao} dataMax={dataMax} dataMin={dataMin}></Calendario>
                                    {errors.conclusao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                                </Form.Group>                                
                            </div> */}
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                <div className="fonte-cor-1 label-campo">Descrição</div>
                            </Form.Label>
                            <Form.Control name="descricao" className="campo-texto-cor-3 campo-texto-area" as="textarea" rows="3" maxLength="255" placeholder="Descreva aqui o motivo" ref={register({ required: true, min: 3, max: 255 })} onChange={e => setDsDescricao(e.target.value)}></Form.Control>
                            {errors.descricao && <p className="fonte-cor-3 label-alerta">{iconeAlerta} Campo obrigatório.</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="div-importar-pedido-faturas">
                            <ImportarArquivoXls
                                ref={importarArquivoRef}
                                templateXLS = {dadosJSONXLS}
                                onChange = {importacaoDeIccids}
                            />
                        </div>
                        <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta">{iconeAlerta} Os tipos de arquivos suportados são XLSX e XLS.</p>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {dataAbertura != '' && dsDescricao !=  undefined && arquivo.length > 0?
                            <Button type="submit" className="botao-confirmar" variant="success" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>
                        :
                            <Button className="botao-confirmar-desabilitado" size="sm">
                                <div className="fonte-cor-4">Confirmar</div>
                            </Button>
                        }
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>

            { revelarMensagemCMP &&
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemCMP}
                    mensagemTitulo={'Atenção'}
                    mensagemParagrafo1={mensagemCMP}
                    mensagemBotao={"FECHAR"}
                />
            }

            { revelarMensagemAtencao && customMensagemAtencao && 
                <RenderMensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={customMensagemAtencao}
                    mensagemBotao={"Retornar"}
                />
            }

            { revelarMensagemAtencao && !customMensagemAtencao && 
                <RenderMensagemAtencao
                handleMensagemAtencao={handleMensagemAtencao}
                mensagemTitulo={tituloMensagemAtencao}
                mensagemParagrafo1={bodyMensagemAtencao}
                mensagemBotao={"OK"}
                />
            }

            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagem={handleMensagemErro}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            
            { erroQtdLinhas &&
                <MensagemErro
                    handleMensagem={handleMensagemQtdLinhas}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={mensagemErroQtdLinhas}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemErroSemDados &&
                <MensagemErro
                    handleMensagem={handleMensagemErroSemDados}
                    mensagemTitulo={"Erro"}
                    mensagemParagrafo1={"O arquivo não possui dados"}
                    mensagemBotao={"Confirmar"}
                />
            }
            { revelarMensagemAtencaoLinhaNaoEncontradas &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-linha-nao-encontrada">
                        <div className="modal-mensagem-atencao-linha-nao-encontrada fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 mensagem-modal">Algumas linhas não foram encontradas no sistema. O download do arquivo .xls com a identificação destas linhas foi iniciado.</div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                    <div className="fonte-cor-2">OK</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { revelarMensagemAtencaoInvalido &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">{itensInvalidos.length} linhas foram retiradas</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensInvalidos}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensInvalidos()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { revelarMensagemAtencaoCancelados &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <div className="modal-mensagem-atencao-acoes-simcards fundo-cor-6">
                            <div className="mensagem-container-icone">
                                <img className="mensagem-icone" src={alertaAtencao()} alt="sucesso"/>
                            </div>
                            <div className="fonte-cor-1 label-janela">Você estará executando uma solicitação para {itensCancelados.length} linhas que constam como canceladas ou bloqueadas.</div>
                            <div className="container-tabela-modal-itens-nao-permitidos">
                                <BootstrapTable
                                    classes="tabela-transparente-modais"
                                    keyField='ds_linha'
                                    condensed={true}
                                    data={itensCancelados}
                                    columns={colunasItensInvalidos}
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    bootstrap4 = {true}
                                />
                            </div>
                            <div className="mensagem-container-botao">
                                <Button className="mensagem-botao-cancelar" variant="success" size="sm" onClick={() => handleFecharMensagemItensCancelados()}>
                                    <div className="fonte-cor-2">Continuar</div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Form>
    )
}