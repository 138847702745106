//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { Label, Cell,Bar, Pie, PieChart, XAxis, YAxis, CartesianGrid,BarChart, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import 'moment/locale/pt-br'
import {formatarMilhar, formatarMilharSemDecimais} from '../../../utils/formatarMilhar'
import decryptJWT from '../../../utils/decryptJWT'
import {exportarImagemDashFaturas} from './exportarImagem'

//ICONES
import { reticenciasVerde, setaDropdown} from '../../../utils/alternanciaIcones'
//SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//BARRA DE FILTRAGEM
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, revelarBarraFiltragem, limpadorFiltros, realizouFiltro, mudarFiltros, filtrosArrayBarra, recarregarBarraFiltragem } from "../../../redux/actions/index";
//ESTLIZAÇÃO
import '../../../css/painelFaturas/fatDashboard/dashboard.css'
import { DashArrayCoresDefault, corDash as cor, handleGetColorByIdx } from '../../../utils/dashboard.js';

const mockGrafico1 = [
    {mes: 'Janeiro', cobrado: 4000, apurado: 2400},
    {mes: 'Fevereiro', cobrado: 3000, apurado: 1398},
    {mes: 'Março', cobrado: 2000, apurado: 8000},
    {mes: 'Abril', cobrado: 2780, apurado: 3908},
    {mes: 'Maio', cobrado: 1800, apurado: 4800},
    {mes: 'Junho', cobrado: 2390, apurado: 3800},
    {mes: 'Julho', cobrado: 3490, apurado: 4300},
];

moment.locale('pt-br')
let momentMeses = 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_');
let momentSemanas = 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_');
moment.updateLocale('es', { months : momentMeses, weekdays : momentSemanas });

let valorCobradoTotal = 10000
let valorApuradoTotal = 17000
let statusPaga = 20
let statusVencida = 1
let statusContestada = 7
let statusAberta = 2
let totalFaturasStatus = statusAberta+statusPaga+statusContestada+statusVencida

let operadoraVivo = 12
let operadoraClaro = 9
let operadoraTim = 7
let operadoraOi = 5
let totalFaturasAbertaOperadora = operadoraOi+operadoraVivo+operadoraTim+operadoraClaro

let valorOperadoraVivo = 5000
let valorOperadoraClaro = 275
let valorOperadoraTim = 199
let valorOperadoraOi = 77
let totalValorFaturasOperadora = valorOperadoraVivo+valorOperadoraClaro+valorOperadoraTim+valorOperadoraOi


const mockGrafico2 = [
    {mes: 'Janeiro', contestado: 3000},
    {mes: 'Fevereiro', contestado: 9000},
    {mes: 'Março', contestado: 2400},
    {mes: 'Abril', contestado: 2780},
    {mes: 'Maio', contestado: 1930},
    {mes: 'Junho', contestado: 7830},
    {mes: 'Julho', contestado: 3490},
];

let faturamento30dias = 10
let faturamento60dias = 20
let faturamento90dias = 30
let faturamento120dias = 40
let totalFaturamento = faturamento30dias+faturamento60dias+faturamento90dias+faturamento120dias

let quantidadeContestacaoVivo = 5300
let quantidadeContestacaoClaro = 2750
let quantidadeContestacaoTim = 1936
let quantidadeContestacaoOi = 107
let totalContestacaoOperadoras = quantidadeContestacaoVivo+quantidadeContestacaoClaro+quantidadeContestacaoTim+quantidadeContestacaoOi

let valorContestacaoVivo = 1000
let ValorContestacaoClaro = 1500
let ValorContestacaoTim = 789
let valorContestacaoOi = 386
let ValorContestadoTotal = valorContestacaoVivo+ValorContestacaoClaro+ValorContestacaoTim+valorContestacaoOi


const mockGrafico3 = [
    {mes: 'Janeiro', simcards: 700},
    {mes: 'Fevereiro', simcards: 1000},
    {mes: 'Março', simcards: 2300},
    {mes: 'Abril', simcards: 2780},
    {mes: 'Maio', simcards: 3030},
    {mes: 'Junho', simcards: 7830},
    {mes: 'Julho', simcards: 8500},
];

let totalSimcardsMock = 26170

let quantidadeSimcardVivo = 5300
let quantidadeSimcardClaro = 2750
let quantidadeSimcardTim = 1996
let quantidadeSimcardOi = 7717
let totalQuantidadeSimcardOperadoras = quantidadeSimcardVivo+quantidadeSimcardClaro+quantidadeSimcardTim+quantidadeSimcardOi

let quantidadeSimcardCompartilhado = 7000
let quantidadeSimcardIlimitado = 520
let quantidadeSimcardIndividual = 789
let totalQuantidadeSimcardPlano = quantidadeSimcardCompartilhado+quantidadeSimcardIlimitado+quantidadeSimcardIndividual

let quantidadeSimcardAtivo = 100
let quantidadeSimcardCancelado = 200
let quantidadeSimcardSuspenso = 300
let quantidadeSimcardTradein = 400
let quantidadeSimcardPreativo = 500
let quantidadeSimcardBloqueado = 600
let totalQuantidadeSimcardCicloVida = quantidadeSimcardAtivo+quantidadeSimcardCancelado+quantidadeSimcardSuspenso+quantidadeSimcardTradein+quantidadeSimcardPreativo+quantidadeSimcardBloqueado

let arrayCores = []
if(decryptJWT('codigoCliente') == 500000 || decryptJWT('codigoCliente') == 500001){
    arrayCores =  [
        cor.verde, ...DashArrayCoresDefault,
    ]
}else{
    arrayCores =  [
        cor.verdeClaro, ...DashArrayCoresDefault,
    ]
}

const getColorByIdx = (idx) => handleGetColorByIdx(arrayCores, idx);

function Dasboard(props){
    //OUTROS HOOKS
    const despacho = useDispatch();
    // let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    // let revelarMostrarEmDispositivo =  useSelector(state => state.pedidosState.revelarMostrarEmDispositivos);
    // let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let numeroPaginaDashboardFaturas = useSelector(state => state.faturasState.paginaDashboardFaturas)

    // const [revelarMostrarEmDispositivoOnline, setRevelarMostrarEmDispositivoOnline] = useState(false)
    // let filtros = useSelector(state => state.filtrosState.filtros);
    const [spinAtivo, setSpinAtivo] = useState(true);
    const localTema = localStorage.getItem('tema');
    //HOOKS DE CONTROLE
    const [totalPaginasDashboard, setTotalPaginasDashboard] = useState(2)
    const [numeroDashboard, setNumeroDashboard] = useState(2)
    const [totalPaginasStatusContrato, setTotalPaginasStatusContrato] = useState(0)
    const [numeroStatusContrato, setNumeroStatusContrato] = useState(0)
    const [totalPaginasTotalSimcards, setTotalPaginasTotalSimcards] = useState(0)
    const [numeroTotalSimcards, setNumeroTotalSimcards] = useState(0)
    // HOOKS DOS GRAFICOS DE AREA
    const [dataCiclo, setDataCiclo] = useState()
    // HOOKS DOS GRAFICOS DE CONSUMO
    const [totalSimcardsConsumo, setTotalSimcardsConsumo] = useState(0)

    const [revelarOpcoesConexoes, setRevelarOpcoesConexoes] = useState (false)
    // HOOKS DOS GRAFICOS DE PACOTES
    const [revelarOpcoesPacotes, setRevelarOpcoesPacotes] = useState (false)

    // HOOKS DOS GRAFICOS 
    const [arrayValorCobrado, setArrayValorCobrado] = useState([])
    const [tooltipValorCobrado, setTooltipValorCobrado] = useState(0)
    const [arrayValorApurado, setArrayValorApurado] = useState([])
    const [tooltipValorApurado, setTooltipValorApurado] = useState(0)
    const [arrayValorContestado, setArrayValorContestado] = useState([])
    const [tooltipValorContestado, setTooltipValorContestado] = useState(0)

    const [arrayQuantidadeSimcard, setArrayQuantidadeSimcard] = useState([])
    const [tooltipQuantidadeSimcard, setTooltipQuantidadeSimcard] = useState(0)

    const [arrayFaturasPorStatus, setArrayFaturasPorStatus] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipStatusPaga, setTooltipStatusPaga] = useState(0)
    const [tooltipStatusVencida, setTooltipStatusVencida] = useState(0)
    const [tooltipStatusContestada, setTooltipStatusContestada] = useState(0)
    const [tooltipStatusAberta, setTooltipStatusAberta] = useState(0)
    const [tooltipTotalFaturasStatus, setTooltipTotalFaturasStatus] = useState(0)

    const [arrayFaturasAbertaOperadora, setArrayFaturasAbertaOperadora] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipVivo, setTooltipVivo] = useState(0)
    const [tooltipClaro, setTooltipClaro] = useState(0)
    const [tooltipTim, setTooltipTim] = useState(0)
    const [tooltipOi, setTooltipOi] = useState(0)
    const [tooltipTotalFaturaAbertaOperadora, setTooltipTotalFaturaAbertaOperadora] = useState(0)

    const [arrayValorFaturasOperadora, setArrayValorFaturasOperadora] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipValorVivo, setTooltipValorVivo] = useState(0)
    const [tooltipValorClaro, setTooltipValorClaro] = useState(0)
    const [tooltipValorTim, setTooltipValorTim] = useState(0)
    const [tooltipValorOi, setTooltipValorOi] = useState(0)
    const [tooltipTotalValorFaturaOperadora, setTooltipTotalValorFaturaOperadora] = useState(0)
    
    const [arraySimcardsFaturamento, setArraySimcardsFaturamento] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipFaturamento30dias, setTooltipFaturamento30dias] = useState(0)
    const [tooltipFaturamento60dias, setTooltipFaturamento60dias] = useState(0)
    const [tooltipFaturamento90dias, setTooltipFaturamento90dias] = useState(0)
    const [tooltipFaturamento120dias, setTooltipFaturamento120dias] = useState(0)
    const [tooltipTotalSimcardsFaturamento, setTooltipTotalSimcardsFaturamento] = useState(0)

    const [arraySimcardsOperadora, setArraySimcardsOperadora] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipSimcardsVivo, setTooltipSimcardsVivo] = useState(0)
    const [tooltipSimcardsOi, setTooltipSimcardsOi] = useState(0)
    const [tooltipSimcardsTim, setTooltipSimcardsTim] = useState(0)
    const [tooltipSimcardsClaro, setTooltipSimcardsClaro] = useState(0)
    const [tooltipTotalSimcardsOperadora, setTooltipTotalSimcardsOperadora] = useState(0)

    const [arraySimcardsPlano, setArraySimcardsPlano] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipSimcardsCompartilhado, setTooltipSimcardsCompartilhado] = useState(0)
    const [tooltipSimcardsIlimitado, setTooltipSimcardsIlimitado] = useState(0)
    const [tooltipSimcardsIndividual, setTooltipSimcardsIndividual] = useState(0)
    const [tooltipTotalSimcardsPlano, setTooltipTotalSimcardsPlano] = useState(0)

    const [arraySimcardsCicloVida, setArraySimcardsCicloVida] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipSimcardsAtivo, setTooltipSimcardsAtivo] = useState(0)
    const [tooltipSimcardsCancelado, setTooltipSimcardsCancelado] = useState(0)
    const [tooltipSimcardsSuspenso, setTooltipSimcardsSuspenso] = useState(0)
    const [tooltipSimcardsTradein, setTooltipSimcardsTradein] = useState(0)
    const [tooltipSimcardsPreativo, setTooltipSimcardsPreativo] = useState(0)
    const [tooltipSimcardsBloqueado, setTooltipSimcardsBloqueado] = useState(0)
    const [tooltipTotalSimcardsCicloVida, setTooltipTotalSimcardsCicloVida] = useState(0)

    const [arrayFaturasContestadasOperadora, setArrayFaturasContestadasOperadora] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipContestadasVivo, setTooltipContestadasVivo] = useState(0)
    const [tooltipContestadasClaro, setTooltipContestadasClaro] = useState(0)
    const [tooltipContestadasTim, setTooltipContestadasTim] = useState(0)
    const [tooltipContestadasOi, setTooltipContestadasOi] = useState(0)
    const [tooltipTotalFaturasContestadasOperadora, setTooltipTotalFaturasContestadasOperadora] = useState(0)
    
    const [arrayValorFaturasContestadasOperadora, setArrayValorFaturasContestadasOperadora] = useState([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
    const [tooltipValorContestadoVivo, setTooltipValorContestadoVivo] = useState(0)
    const [tooltipValorContestadoClaro, setTooltipValorContestadoClaro] = useState(0)
    const [tooltipValorContestadoTim, setTooltipValorContestadoTim] = useState(0)
    const [tooltipValorContestadoOi, setTooltipValorContestadoOi] = useState(0)
    const [tooltipTotalValorContestadoFaturaOperadora, setTooltipTotalValorContestadoFaturaOperadora] = useState(0)






    //****VARIAVEIS CRIADAS NA INTEGRACAO****/
    const [graficoTimelineCobradoApurado, setGraficoTimelineCobradoApurado] = useState([])
    const [graficoTimelineContestado, setGraficoTimelineContestado] = useState([])
    const [graficoTimelineQuantidadeSimcards, setGraficoTimelineQuantidadeSimcards] = useState([])
    const [totalSimcards, setTotalSimcards] = useState(0)
    const [totalSimcardsAtual, setTotalSimcardsAtual] = useState(0)
    const [faturaQtdMesStatus, setFaturaQtdMesStatus] = useState(false)
    const [faturaUtlimoCicloStatus, setFaturaUtlimoCicloStatus] = useState(false)
    const [faturaQtdMesStatusContestacaoOperadora, setFaturaQtdMesStatusContestacaoOperadora] = useState(false)
    const [faturaUtlimoCicloStatusContestacaoOperadora, setFaturaUtlimoCicloStatusContestacaoOperadora] = useState(false)
    const [faturaQtdMesStatusContestacaoOperadoraValor, setFaturaQtdMesStatusContestacaoOperadoraValor] = useState(false)
    const [faturaUtlimoCicloStatusContestacaoOperadoraValor, setFaturaUtlimoCicloStatusContestacaoOperadoraValor] = useState(false)
    const [graficoTimelinePeriodo, setGraficoTimelinePeriodo] = useState(5)


    // HOOKS FILTROS DO GRAFICO PIZZA
    const [menuOpcoesFaturasStatus, setMenuOpcoesFaturasStatus] = useState()
    const [revelarOpcoesFaturasStatus, setRevelarOpcoesFaturasStatus] = useState(false)

    const [menuOpcoesFaturasAbertas, setMenuOpcoesFaturasAbertas] = useState()
    const [revelarOpcoesFaturasAbertas, setRevelarOpcoesFaturasAbertas] = useState (false)

    const [menuOpcoesContestacaoOperadora, setMenuOpcoesContestacaoOperadora] = useState()
    const [revelarOpcoesContestacaoOperadora, setRevelarOpcoesContestacaoOperadora] = useState (false)
    
    const [menuOpcoesSimcardsNaoFaturando, setMenuOpcoesSimcardsNaoFaturando] = useState()
    const [revelarOpcoesSimcardsNaoFaturando, setRevelarOpcoesSimcardsNaoFaturando] = useState (false)

    const [menuOpcoesFaturasAbertasOperadora, setMenuOpcoesFaturasAbertasOperadora] = useState()
    const [revelarOpcoesFaturasAbertasOperadora, setRevelarOpcoesFaturasAbertasOperadora] = useState (false)

    const [menuOpcoesValorContestacaoOperadora, setMenuOpcoesValorContestacaoOperadora] = useState()
    const [revelarOpcoesValorContestacaoOperadora, setRevelarOpcoesValorContestacaoOperadora] = useState (false)

    const [menuOpcoesSimcardsOperadora, setMenuOpcoesSimcardsOperadora] = useState()
    const [revelarOpcoesSimcardsOperadora, setRevelarOpcoesSimcardsOperadora] = useState (false)

    const [menuOpcoesSimcardsPlano, setMenuOpcoesSimcardsPlano] = useState()
    const [revelarOpcoesSimcardsPlano, setRevelarOpcoesSimcardsPlano] = useState (false)

    const [menuOpcoesSimcardsCicloVida, setMenuOpcoesSimcardsCicloVida] = useState()
    const [revelarOpcoesSimcardsCicloVida, setRevelarOpcoesSimcardsCicloVida] = useState (false)

    // const [menuOpcoesConsumo, setMenuOpcoesConsumo] = useState()
    const [revelarOpcoesConsumo, setRevelarOpcoesConsumo] = useState (false)

    // HOOKS DOS GRAFICOS DE TOTAL SIMCARDS OPERADORAS
    const [revelarOpcoesTotalSimcardsOperadora, setRevelarOpcoesTotalSimcardsOperadora] = useState (false)
    // HOOKS DOS GRAFICOS DE STATUS DE CONTRATO
  
    const [revelarOpcoesStatusContrato, setRevelarOpcoesStatusContrato] = useState (false)
    // HOOKS DOS GRAFICOS DE PLANOS CONTRATADOS
  
    const [revelarOpcoesPlanosContratados, setRevelarOpcoesPlanosContratados] = useState (false)
    // HOOKS DOS GRAFICOS SIMCARDS COM PACOTE EXTRA
    const [revelarOpcoesSimcardsComPacotesExtra, setRevelarOpcoesSimcardsComPacotesExtra] = useState (false)
    const [revelarOpcoesCicloAtual, setRevelarOpcoesCicloAtual] = useState (false)
    // HOOKS DOS GRAFICOS DE STATUS DE TRAFEGO
    const [revelarOpcoesStatusTrafego, setRevelarOpcoesStatusTrafego] = useState (false)
    //HOOK DO PERÍODO
    const [periodo, setPeriodo] = useState(0)

    useEffect(()=>{
        setNumeroDashboard(numeroPaginaDashboardFaturas)
    })

    /* CONTROLA O CLICK FORA DOS MENUS DE OPCOES */
    function handleAbrirMenuOpcoes(identificador){
        if(identificador === "consumo"){
            setRevelarOpcoesConsumo(!revelarOpcoesConsumo)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "faturasAbertas"){
            setRevelarOpcoesFaturasAbertas(!revelarOpcoesFaturasAbertas)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "faturasStatus"){
            setRevelarOpcoesFaturasStatus(!revelarOpcoesFaturasStatus)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "contestacaoOperadora"){
            setRevelarOpcoesContestacaoOperadora(!revelarOpcoesContestacaoOperadora)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "faturasAbertasOperadora"){
            setRevelarOpcoesFaturasAbertasOperadora(!revelarOpcoesFaturasAbertasOperadora)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "simcardsNaoFaturando"){
            setRevelarOpcoesSimcardsNaoFaturando(!revelarOpcoesSimcardsNaoFaturando)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "valorContestacaoOperado"){
            setRevelarOpcoesValorContestacaoOperadora(!revelarOpcoesValorContestacaoOperadora)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "simcardsCicloVida"){
            setRevelarOpcoesSimcardsCicloVida(!revelarOpcoesSimcardsCicloVida)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsOperadora(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "simcardsOperadora"){
            setRevelarOpcoesSimcardsOperadora(!revelarOpcoesSimcardsOperadora)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsPlano(false)
        }

        if(identificador === "simcardsPlano"){
            setRevelarOpcoesSimcardsPlano(!revelarOpcoesSimcardsPlano)
            setRevelarOpcoesSimcardsCicloVida(false)
            setRevelarOpcoesValorContestacaoOperadora(false)
            setRevelarOpcoesSimcardsNaoFaturando(false)
            setRevelarOpcoesFaturasStatus(false)
            setRevelarOpcoesFaturasAbertas(false)
            setRevelarOpcoesContestacaoOperadora(false)
            setRevelarOpcoesFaturasAbertasOperadora(false)
            setRevelarOpcoesConsumo(false)
            setRevelarOpcoesSimcardsOperadora(false)
        }


    };
    
    /* ALTERNA A TELA DE DASHBOARD (TELA INTEIRA) */
    function handlAlternarPaginasDashboard(passo){
        if(passo === 'proximo'){
            if(numeroDashboard === totalPaginasDashboard){
                setNumeroDashboard(0)
            }else{
                let numero = (numeroDashboard + 1)
                setNumeroDashboard(numero)
            }
        }else if(passo === 'anterior'){
            if(numeroDashboard === 0){
                setNumeroDashboard(totalPaginasDashboard)
            }else{
                let numero = (numeroDashboard - 1)
                setNumeroDashboard(numero)
            }
        }
        setRevelarOpcoesConsumo(false)
        setRevelarOpcoesConexoes(false)
        setRevelarOpcoesPacotes(false)
        setRevelarOpcoesFaturasStatus(false)
        setRevelarOpcoesFaturasAbertas(false)
        setRevelarOpcoesFaturasAbertasOperadora(false)
        setRevelarOpcoesTotalSimcardsOperadora(false)
        setRevelarOpcoesStatusContrato(false)
        setRevelarOpcoesPlanosContratados(false)
        setRevelarOpcoesSimcardsComPacotesExtra(false)
        setRevelarOpcoesCicloAtual(false)
        setRevelarOpcoesStatusTrafego(false)
    }

    /* ALTERNA A PAGINA SOMENTE DO STATUS DE CONTRATO */
    function handlAlternarPaginasStatusContrato(passo){
        if(passo === 'proximo'){
            if(numeroStatusContrato === totalPaginasStatusContrato){
                setNumeroStatusContrato(0)
            }else{
                let numero = (numeroStatusContrato + 1)
                setNumeroStatusContrato(numero)
            }
        }else if(passo === 'anterior'){
            if(numeroStatusContrato === 0){
                setNumeroStatusContrato(totalPaginasStatusContrato)
            }else{
                let numero = (numeroStatusContrato - 1)
                setNumeroStatusContrato(numero)
            }
        }
        setRevelarOpcoesConsumo(false)
        setRevelarOpcoesConexoes(false)
        setRevelarOpcoesPacotes(false)
        setRevelarOpcoesFaturasStatus(false)
        setRevelarOpcoesFaturasAbertas(false)
        setRevelarOpcoesFaturasAbertasOperadora(false)
        setRevelarOpcoesTotalSimcardsOperadora(false)
        setRevelarOpcoesStatusContrato(false)
        setRevelarOpcoesPlanosContratados(false)
        setRevelarOpcoesSimcardsComPacotesExtra(false)
        setRevelarOpcoesCicloAtual(false)
        setRevelarOpcoesStatusTrafego(false)
    }
    
    /* ALTERNA A PAGINA DO TOTAL DE SIMCARDS (OPERADORAS) */
    function handlAlternarPaginasTotalSimcards(passo){
        if(passo === 'proximo'){
            if(numeroTotalSimcards === totalPaginasTotalSimcards){
                setNumeroTotalSimcards(0)
            }else{
                let numero = (numeroTotalSimcards + 1)
                setNumeroTotalSimcards(numero)
            }
        }else if(passo === 'anterior'){
            if(numeroTotalSimcards === 0){
                setNumeroTotalSimcards(totalPaginasTotalSimcards)
            }else{
                let numero = (numeroTotalSimcards - 1)
                setNumeroTotalSimcards(numero)
            }
        }
        setRevelarOpcoesConsumo(false)
        setRevelarOpcoesConexoes(false)
        setRevelarOpcoesPacotes(false)
        setRevelarOpcoesFaturasStatus(false)
        setRevelarOpcoesFaturasAbertas(false)
        setRevelarOpcoesFaturasAbertasOperadora(false)
        setRevelarOpcoesTotalSimcardsOperadora(false)
        setRevelarOpcoesStatusContrato(false)
        setRevelarOpcoesPlanosContratados(false)
        setRevelarOpcoesSimcardsComPacotesExtra(false)
        setRevelarOpcoesCicloAtual(false)
        setRevelarOpcoesStatusTrafego(false)
    }
    
    /* GERA O TOOLTIP DO GRAFICO DE AREA */
    function TooltipGraficoArea({ payload, label, active }) {
        if (active && payload!==null && payload.length > 0) {
            if (payload[0].payload.consumo!==null && payload[0].payload.consumo!==undefined){
                return (
                    <div className="container-tooltip-grafico-area-dashboard">
                        <p className="label-tooltip-grafico-area-dashboard">{`${formatarMilhar(payload[0].payload.consumo)}`}</p>
                        <div className="triangulo-tooltip-grafico-area-dashboard"></div>
                        <div className={props.revelarBarraFiltro===false?"barra-tooltip-grafico-area-dashboard":"barra-tooltip-grafico-area-dashboard-2"}></div>
                        <div className="elipse-tooltip-grafico-area-dashboard"></div>
                    </div>
                );
            }else if (payload[0].payload.conexoes!==null && payload[0].payload.conexoes!==undefined){
                return (
                    <div className="container-tooltip-grafico-area-dashboard">
                        <p className="label-tooltip-grafico-area-dashboard">{`${formatarMilhar(payload[0].payload.conexoes)}`}</p>
                        <div className="triangulo-tooltip-grafico-area-dashboard"></div>
                        <div className="barra-tooltip-grafico-area-dashboard"></div>
                        <div className="elipse-tooltip-grafico-area-dashboard"></div>
                    </div>
                );
            }else if (payload[0].payload.pacotes!==null && payload[0].payload.pacotes!==undefined){
                return (
                    <div className="container-tooltip-grafico-area-dashboard">
                        <p className="label-tooltip-grafico-area-dashboard">{`${formatarMilhar(payload[0].payload.pacotes)}`}</p>
                        <div className="triangulo-tooltip-grafico-area-dashboard"></div>
                        <div className="barra-tooltip-grafico-area-dashboard"></div>
                        <div className="elipse-tooltip-grafico-area-dashboard"></div>
                    </div>
                );
            }else{
                return (
                    <div className="container-tooltip-grafico-area-dashboard">
                        <p className="label-tooltip-grafico-area-dashboard">{`${formatarMilhar(payload[0].payload.previsao)}`}</p>
                        <div className="triangulo-tooltip-grafico-area-dashboard"></div>
                        <div className="barra-tooltip-grafico-area-dashboard"></div>
                        <div className="elipse-tooltip-grafico-area-dashboard"></div>
                    </div>
                );
            }
        }
        return null;
    }


    /* GERA A LABEL DE TOTAL DOS GRAFICOS COMPOSTOS */
    function LabelGraficosPizzaCompostoValor({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        let auxTooltipTotal
        if(parseFloat(valorPercentual) > 2000000){
            auxTooltipTotal = parseFloat(valorPercentual/1000000).toFixed(1) + " mi"
        }else if(parseFloat(valorPercentual) > 1000000){
            auxTooltipTotal = parseFloat(valorPercentual/1000000).toFixed(1) + " mi"
        }else if(parseFloat(valorPercentual) > 1000){
            auxTooltipTotal = parseFloat(valorPercentual/1000).toFixed(1) + " mil"
        }else{
            auxTooltipTotal = formatarMilhar(valorPercentual)
        }
        return (
            <>
                <text className="texto-titulo-legenda-grafico-pizza-grande" x={cx} y="40%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                    Total
                </text>
                <text className="label-percentual-graficos-pizza-composto" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                    R$ {auxTooltipTotal}
                </text>
            </>
        )
    }

    
    /* GERA A LABEL DE TOTAL DOS GRAFICOS COMPOSTOS */
    function LabelGraficosPizzaComposto({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        let auxTooltipTotal
        if(parseFloat(valorPercentual) > 2000000){
            auxTooltipTotal = parseFloat(valorPercentual/1000000).toFixed(1) + " mi"
        }else if(parseFloat(valorPercentual) > 1000000){
            auxTooltipTotal = parseFloat(valorPercentual/1000000).toFixed(1) + " mi"
        }else if(parseFloat(valorPercentual) > 1000){
            auxTooltipTotal = parseFloat(valorPercentual/1000).toFixed(1) + " mil"
        }else{
            auxTooltipTotal = formatarMilharSemDecimais(valorPercentual)
        }
        return (
            <>
                <text className="texto-titulo-legenda-grafico-pizza-grande" x={cx} y="40%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                    Total
                </text>
                <text className="label-percentual-graficos-pizza-composto" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                    {auxTooltipTotal}
                </text>
            </>
        )
    }

    /* GERA A LABEL DE % DOS GRAFICOS GRANDES */
    function LabelGraficosPizzaGrande({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        return (
            <text className="label-percentual-graficos-pizza-grande" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                {valorPercentual + "%"}
            </text>
        )
    }

    /* GERA A LABEL DE % DOS GRAFICOS EM GRUPOS DE 5 */
    function LabelGraficosPizzaGrupoCinco({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        return (
            <text className="label-percentual-graficos-pizza-grupo-cinco" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                {valorPercentual + "%"}
            </text>
        )
    }

    /* GERA A LABEL DE % DOS GRAFICOS EM GRUPOS DE 4 */
    function LaleGraficosPizzaGrupoQuatro({viewBox, valorPercentual}){
        const {cx, cy} = viewBox;
        return (
            <text className="label-percentual-graficos-pizza-grupo-quatro" x={cx} y="50%" fill={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} textAnchor="middle" dominantBaseline="central">
                {valorPercentual + "%"}
            </text>
        )
    }

    /* PREENCHE OS ARRAYS USADOS PARA CARREGAR OS GRAFICOS */
    function handlePreencherGraficoPizza(primeiroValor, segundoValor, cor){
        return [{"value": parseFloat(primeiroValor.toFixed(2)), "cor": cor},
                {"value": parseFloat((segundoValor - primeiroValor).toFixed(2)), "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro}]
    }

    /* PREENCHE OS ARRAYS USADOS PARA CARREGAR OS GRAFICOS */
    function handlePreencherGraficoPizzaDias(primeiroValor, segundoValor, cor){
        return [{"value": primeiroValor, "cor": cor},
                {"value": (segundoValor - primeiroValor), "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro}]
    }

    /* CALCULA O % EXIBIDO NO CENTRO DOS GRAFICOS */
    // function handlePreencherPercentualGraficoPizza(primeiroValor, segundoValor){
    //     return(
    //         ((primeiroValor / segundoValor) * 100).toFixed(0)
    //     )
    // }

    /* CALCULA A LEGENDA DOS GRAFICOS DO TOPO */
    // function handleCalcularLegendaGraficoPizza(valor){
    //     let tamanhoValor = parseFloat(valor).toFixed(0).length
    //     let primeiroDigito
    //     if(tamanhoValor > 1) {
    //         primeiroDigito = parseInt((valor.toString()).split(0, 1))
    //     }else{
    //         primeiroDigito = parseInt(valor.toString())
    //     }
    //     if(tamanhoValor < 4){
    //         return formatarMilhar(parseFloat(parseFloat(valor).toFixed(2)))
    //     }else if (tamanhoValor >= 4 && tamanhoValor <= 6){
    //         return formatarMilhar(parseFloat(parseFloat((valor / 1000).toFixed(2)))) + " mil"
    //     }else if (tamanhoValor >= 7 && primeiroDigito === 1){
    //         return formatarMilhar(parseFloat(parseFloat((valor / 1000000).toFixed(2)))) + " milhão"
    //     }else if (tamanhoValor >= 7 && primeiroDigito != 1){
    //         return formatarMilhar(parseFloat(parseFloat((valor / 1000000).toFixed(2)))) + " milhões"
    //     }
    // }
    
    /* CALCULA A TOOLTIP DE HOVER DOS GRAFICOS DE CONSUMO */
    const TooltipGraficoPizzaFaturaStatus = ({ active, payload }) => {
        if(tooltipTotalFaturasStatus == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / tooltipTotalFaturasStatus ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }
    const TooltipGraficoPizzaFaturaAbertoOperadora = ({ active, payload }) => {
        if(tooltipTotalFaturaAbertaOperadora == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / tooltipTotalFaturaAbertaOperadora ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }
    const TooltipGraficoPizzaFaturaAbertoOperadoraValor = ({ active, payload }) => {
        if(tooltipTotalValorFaturaOperadora == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / tooltipTotalValorFaturaOperadora ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }
    const TooltipGraficoPizzaSimcardNaoFaturaIramIniciarFaturar = ({ active, payload }) => {
        if(tooltipTotalSimcardsFaturamento == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / tooltipTotalSimcardsFaturamento ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }
    const TooltipGraficoPizzaContestacaoOperadora = ({ active, payload }) => {
        if(tooltipTotalFaturasContestadasOperadora == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / tooltipTotalFaturasContestadasOperadora ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }
    const TooltipGraficoPizzaContestacaoOperadoraValor = ({ active, payload }) => {
        if(tooltipTotalValorContestadoFaturaOperadora == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / tooltipTotalValorContestadoFaturaOperadora ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }
    const TooltipGraficoPizzaSimcard = ({ active, payload }) => {
        if(totalSimcardsAtual == 0){
            return (
                <div className="container-tooltip-grafico-pizza">
                    <p className="label-tooltip-grafico-pizza">100%</p>
                </div>
            );
        }else{
            if (active && payload!==null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-pizza">
                        <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / totalSimcardsAtual ) * 100).toFixed(0)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }

    /* CALCULA A TOOLTIP DE HOVER DOS GRAFICOS CONEXOES*/
    // const TooltipGraficoPizzaConexoes = ({ active, payload }) => {
    //     if(totalSimcardsConexoes === 0){
    //         return (
    //             <div className="container-tooltip-grafico-pizza">
    //                 <p className="label-tooltip-grafico-pizza">100%</p>
    //             </div>
    //         );
    //     }else{
    //         if (active && payload!==null && payload.length > 0) {
    //             return (
    //                 <div className="container-tooltip-grafico-pizza">
    //                     <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / totalSimcardsConexoes) * 100).toFixed(2)}`}%</p>
    //                 </div>
    //             );
    //         }
    //     }
    //     return null;
    // }

    /* CALCULA A TOOLTIP DE HOVER DOS GRAFICOS CONEXOES*/
    // const TooltipGraficoPizzaPacotes = ({ active, payload }) => {
    //     if(totalSimcardsPacotes === 0){
    //         return (
    //             <div className="container-tooltip-grafico-pizza">
    //                 <p className="label-tooltip-grafico-pizza">100%</p>
    //             </div>
    //         );
    //     }else{
    //         if (active && payload!==null && payload.length > 0) {
    //             return (
    //                 <div className="container-tooltip-grafico-pizza">
    //                     <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / totalSimcardsPacotes) * 100).toFixed(2)}`}%</p>
    //                 </div>
    //             );
    //         }
    //     }
    //     return null;
    // }
    
    /* CALCULA A TOOLTIP DE HOVER DOS GRAFICOS CONEXOES*/
    // const TooltipGraficoPizzaCiclo = ({ active, payload }) => {
    //     if(diasCicloMax === 0){
    //         return (
    //             <div className="container-tooltip-grafico-pizza">
    //                 <p className="label-tooltip-grafico-pizza">100%</p>
    //             </div>
    //         );
    //     }else{
    //         if (active && payload!==null && payload.length > 0) {
    //             return (
    //                 <div className="container-tooltip-grafico-pizza">
    //                     <p className="label-tooltip-grafico-pizza">{`${parseFloat((payload[0].payload.value / diasCicloMax) * 100).toFixed(2)}`}%</p>
    //                 </div>
    //             );
    //         }
    //     }
    //     return null;
    // }

    /* CONTROLA A EXPANSAO E CONTRACAO DOS DROPDOWNS DOS MENUS DE OPCOES */
    function dropMenuOpcoes(event, elementoId,imagemId){
        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(imagemId);

        if(imagem.classList.contains("menu-opcoes-icone-desativado")){
            imagem.classList.remove("menu-opcoes-icone-desativado")
            imagem.classList.add("menu-opcoes-icone-ativado")
        }else if(imagem.classList.contains("menu-opcoes-icone-ativado")){
            imagem.classList.remove("menu-opcoes-icone-ativado")
            imagem.classList.add("menu-opcoes-icone-desativado")
        }

        if(elemento.classList.contains("dropdown-desativado")){
            elemento.classList.remove("dropdown-desativado")
            elemento.classList.add("dropdown-ativado")
        }else if(elemento.classList.contains("dropdown-ativado")){
            elemento.classList.remove("dropdown-ativado")
            elemento.classList.add("dropdown-desativado")
        }
    }
    function mudarPeriodo(periodo){
        // setPeriodo(periodo)
        setRevelarOpcoesConsumo(false)
        setRevelarOpcoesPacotes(false)
        setRevelarOpcoesConexoes(false)
        setRevelarOpcoesFaturasStatus(false)
    }

    function mudarDataLabel(array,periodo){
        if(periodo === 0){
            let dataAtual = Date()
            setDataCiclo(moment(dataAtual).format('MM/YYYY'))
        }else if( periodo === 1){
            let dataAtual = array.timeline[0].data;
            setDataCiclo(moment(dataAtual).format('MM/YYYY'))
        }else if(periodo === 2){
            let dataInicio = array.timeline[0].data_mes;
            let dataAtual = array.timeline[2].data_mes;
            setDataCiclo(`De ${dataInicio} até ${dataAtual}`)
        }
        else if(periodo === 3){
            let dataInicio = array.timeline[0].data_mes;
            let dataAtual = array.timeline[5].data_mes;
            setDataCiclo(`De ${dataInicio} até ${dataAtual}`)
        }
    }
    useEffect(() => {
        //clicou no botão para filtro
        if(realizouFiltragem){
            despacho(realizouFiltro(false));
        }

        despacho(mudarManejador("dashboardFaturas"));
        setSpinAtivo(true)

        if (numeroDashboard === 0){
            async function handleCarregarGraficosDashboardPagina1(){
                
                let dadosConsulta = ''

                if(!props.filtros.vazio){
                    const dados = {
                        pagina:1,
                        fatura_qtd_mes_timeline:graficoTimelinePeriodo,
                        fatura_qtd_mes_status:faturaQtdMesStatus,
                        fatura_utlimo_ciclo_status:faturaUtlimoCicloStatus,
                        filtros: props.filtros
                    }
                    const {data} = await api.post('/m1/filtrar/fat_dashboard', dados)
                    if(data.status == 200){
                        dadosConsulta = JSON.parse(JSON.stringify(data.dados))
                    }
                }else{
                    let consulta = '/m1/consultar/fat_dashboard?pagina='+1+
                        "&fatura_qtd_mes_timeline="+graficoTimelinePeriodo+
                        "&fatura_qtd_mes_status="+faturaQtdMesStatus+
                        "&fatura_utlimo_ciclo_status="+faturaUtlimoCicloStatus
                    const {data} = await api.get(consulta)
                    if(data.status == 200){
                        dadosConsulta = JSON.parse(JSON.stringify(data.dados))
                    }
                }

                if(Array.isArray(dadosConsulta)){
                    let timelineCobradoApurado = JSON.parse(JSON.stringify(dadosConsulta[0]))
                    let timelineCobradoApuradoTotal = JSON.parse(JSON.stringify(dadosConsulta[1]))
                    let timelineValoresStatus = JSON.parse(JSON.stringify(dadosConsulta[2]))
                    let timelineValoresStatusTotal = JSON.parse(JSON.stringify(dadosConsulta[3]))
                    let timelineFaturaAbertoOperadora = JSON.parse(JSON.stringify(dadosConsulta[4]))
                    let timelineFaturaAbertoOperadoraTotal = JSON.parse(JSON.stringify(dadosConsulta[5]))
                    let timelineValorFaturaAbertoOperadora = JSON.parse(JSON.stringify(dadosConsulta[6]))
                    let timelineValorFaturaAbertoOperadoraTotal = JSON.parse(JSON.stringify(dadosConsulta[7]))
                    
                    //TIMELINE
                    await Promise.all(timelineCobradoApurado.map((item_timelineCobradoApurado,i)=>{
                        item_timelineCobradoApurado.mes = moment(item_timelineCobradoApurado.mes).format("MMMM YYYY")
                    }))
                    
                    setGraficoTimelineCobradoApurado(timelineCobradoApurado)
                    
                    setTooltipValorCobrado("R$ " + formatarMilhar(timelineCobradoApuradoTotal[0].quantidadeCobrado))
                    setTooltipValorApurado("R$ " + formatarMilhar(timelineCobradoApuradoTotal[0].quantidadeApurado))

                    //FATURA POR STATUS
                    let tempTimelineValoresStatus = []
                    if(timelineValoresStatus.length>0){
                        await Promise.all(timelineValoresStatus.map((item_timelineValoresStatus,i)=>{
                            tempTimelineValoresStatus.push({
                                "value": item_timelineValoresStatus.contador, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_status,
                            })
                        }))
    
                        setArrayFaturasPorStatus(tempTimelineValoresStatus)
                    }
                    else{
                        setArrayFaturasPorStatus([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //FATURA POR STATUS TOTAL
                    setTooltipTotalFaturasStatus(timelineValoresStatusTotal[0].quantidade)
                    
                    //FATURA EM ABERTO POR OPERADORA
                    let tempTimelineFaturaAbertoOperadora = []
                    if(timelineFaturaAbertoOperadora.length>0){
                        await Promise.all(timelineFaturaAbertoOperadora.map((item_timelineValoresStatus,i)=>{
                            tempTimelineFaturaAbertoOperadora.push({
                                "value": item_timelineValoresStatus.contador, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_operadora,
                            })
                        }))
    
                        setArrayFaturasAbertaOperadora(tempTimelineFaturaAbertoOperadora)
                    }
                    else{
                        setArrayFaturasAbertaOperadora([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //FATURA EM ABERTO POR OPERADORA TOTAL
                    setTooltipTotalFaturaAbertaOperadora(timelineFaturaAbertoOperadoraTotal[0].quantidade)


                    //VALOR FATURA EM ABERTO POR OPERADORA
                    let tempTimelineValorFaturaAbertoOperadora = []
                    if(timelineValorFaturaAbertoOperadora.length>0){
                        await Promise.all(timelineValorFaturaAbertoOperadora.map((item_timelineValoresStatus,i)=>{
                            tempTimelineValorFaturaAbertoOperadora.push({
                                "value": item_timelineValoresStatus.valor, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_operadora,
                            })
                        }))
    
                        setArrayValorFaturasOperadora(tempTimelineValorFaturaAbertoOperadora)
                    }
                    else{
                        setArrayValorFaturasOperadora([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //VALOR FATURA EM ABERTO POR OPERADORA TOTAL
                    setTooltipTotalValorFaturaOperadora(timelineValorFaturaAbertoOperadoraTotal[0].quantidade)
                    


                }
               



                /* GRAFICOS DE CONSUMO */
                setArrayValorCobrado([{"value": 100, "cor": cor.verdeClaro}])
                setArrayValorApurado([{"value": 100, "cor": cor.vermelho}])

                /* SIMCARDS SEM COMUNICAR */
                // if(statusPaga === 0 && statusVencida === 0 && statusContestada === 0 && statusAberta === 0){
                //     setArrayFaturasPorStatus([{"value": 100, "cor": localTema ==='claro'?cor.cinza:cor.cinzaEscuro}])
                // }else{
                //     setArrayFaturasPorStatus([{"value": statusPaga, "cor": cor.verdeClaro},
                //                             {"value": statusVencida, "cor": cor.amarelo},
                //                             {"value": statusContestada, "cor": cor.vermelho},
                //                             {"value": statusAberta, "cor": cor.laranja}])
                // }
                setTooltipStatusPaga(statusPaga)
                setTooltipStatusVencida(statusVencida)
                setTooltipStatusContestada(statusContestada)
                setTooltipStatusAberta(statusAberta)
                setTotalSimcardsConsumo(totalFaturasStatus)

                // if(operadoraVivo === 0 && operadoraOi === 0 && operadoraTim === 0 && operadoraClaro === 0){
                //     setArrayFaturasAbertaOperadora([{"value": 100, "cor": localTema ==='claro'?cor.cinza:cor.cinzaEscuro}])
                // }else{
                //     setArrayFaturasAbertaOperadora([{"value": operadoraVivo, "cor": cor.verdeClaro},
                //                             {"value": operadoraOi, "cor": cor.amarelo},
                //                             {"value": operadoraTim, "cor": cor.vermelho},
                //                             {"value": operadoraClaro, "cor": cor.laranja}])
                // }
                setTooltipVivo(operadoraVivo)
                setTooltipOi(operadoraOi)
                setTooltipTim(operadoraTim)
                setTooltipClaro(operadoraClaro)
                setTotalSimcardsConsumo(totalFaturasAbertaOperadora)

                setTooltipValorVivo(valorOperadoraVivo)
                setTooltipValorOi(valorOperadoraOi)
                setTooltipValorTim(valorOperadoraTim)
                setTooltipValorClaro(valorOperadoraClaro)
                setTotalSimcardsConsumo(totalValorFaturasOperadora)
                setSpinAtivo(false)
            }
            handleCarregarGraficosDashboardPagina1()
        }else if (numeroDashboard === 1){
            async function handleCarregarGraficosDashboardPagina2(){
               

                let dadosConsulta = ''
                if(!props.filtros.vazio){
                    const dados = {
                        pagina:2,
                        contestacao_qtd_mes_timeline:graficoTimelinePeriodo,
                        contestacao_qtd_mes_contestacao_operadora: faturaQtdMesStatusContestacaoOperadora,
                        contestacao_utlimo_ciclo_contestacao_operadora: faturaUtlimoCicloStatusContestacaoOperadora,
                        contestacao_qtd_mes_valor_contestacao_operadora: faturaQtdMesStatusContestacaoOperadoraValor,
                        contestacao_utlimo_ciclo_valor_contestacao_operadora: faturaUtlimoCicloStatusContestacaoOperadoraValor,
                        filtros: props.filtros
                    }
                    const {data} = await api.post('/m1/filtrar/fat_dashboard', dados)
                    if(data.status == 200){
                        dadosConsulta = JSON.parse(JSON.stringify(data.dados))
                    }   
                }else{
                    let consulta = '/m1/consultar/fat_dashboard?pagina='+2+
                        "&contestacao_qtd_mes_timeline="+graficoTimelinePeriodo+
                        "&contestacao_qtd_mes_contestacao_operadora="+faturaQtdMesStatusContestacaoOperadora+
                        "&contestacao_utlimo_ciclo_contestacao_operadora="+faturaUtlimoCicloStatusContestacaoOperadora+
                        "&contestacao_qtd_mes_valor_contestacao_operadora="+faturaQtdMesStatusContestacaoOperadoraValor+
                        "&contestacao_utlimo_ciclo_valor_contestacao_operadora="+faturaUtlimoCicloStatusContestacaoOperadoraValor
                    const {data} = await api.get(consulta)
                    if(data.status == 200){
                        dadosConsulta = JSON.parse(JSON.stringify(data.dados))
                    }
                }

                if(Array.isArray(dadosConsulta)){
                    let timelineValoresContestados = JSON.parse(JSON.stringify(dadosConsulta[0]))
                    let timelineValoresContestadosTotal = JSON.parse(JSON.stringify(dadosConsulta[1]))
                    let timelineSimcardsNaoFaturandoIraoFaturar = JSON.parse(JSON.stringify(dadosConsulta[2]))
                    let timelineSimcardsNaoFaturandoIraoFaturarTotal = JSON.parse(JSON.stringify(dadosConsulta[3]))
                    let timelineContestacaoOperadora = JSON.parse(JSON.stringify(dadosConsulta[4]))
                    let timelineContestacaoOperadoraTotal = JSON.parse(JSON.stringify(dadosConsulta[5]))
                    let timelineValorContestacaoOperadora = JSON.parse(JSON.stringify(dadosConsulta[6]))
                    let timelineValorContestacaoOperadoraTotal = JSON.parse(JSON.stringify(dadosConsulta[7]))
                    
                    //TIMELINE
                    await Promise.all(timelineValoresContestados.map((item_timelineCobradoApurado,i)=>{
                        item_timelineCobradoApurado.mes = moment(item_timelineCobradoApurado.mes).format("MMMM YYYY")
                    }))

                    setGraficoTimelineContestado(timelineValoresContestados)
                    setTooltipValorContestado("R$ " + formatarMilhar(timelineValoresContestadosTotal[0].quantidade))


                    //SIMCARDS QUE NAO ESTAO FATURANDO E QUE INICIARAO O FATURAMENTO
                    let temTimelineSimcardsNaoFaturandoIraoFaturar = []
                    if(timelineSimcardsNaoFaturandoIraoFaturar.length>0){
                        await Promise.all(timelineSimcardsNaoFaturandoIraoFaturar.map((item_timelineValoresStatus,i)=>{
                            temTimelineSimcardsNaoFaturandoIraoFaturar.push({
                                "value": item_timelineValoresStatus.quantidade, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.dias,
                            })
                        }))
    
                        setArraySimcardsFaturamento(temTimelineSimcardsNaoFaturandoIraoFaturar)
                    }
                    else{
                        setArraySimcardsFaturamento([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //SIMCARDS QUE NAO ESTAO FATURANDO E QUE INICIARAO O FATURAMENTO
                    setTooltipTotalSimcardsFaturamento(timelineSimcardsNaoFaturandoIraoFaturarTotal[0].quantidade)
                    if(timelineSimcardsNaoFaturandoIraoFaturarTotal[0].quantidade == 0){
                        setArraySimcardsFaturamento([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //CONTESTACOES POR OPERADORA
                    let tempTimelineContestacaoOperadora = []
                    if(timelineContestacaoOperadora.length>0){
                        await Promise.all(timelineContestacaoOperadora.map((item_timelineValoresStatus,i)=>{
                            tempTimelineContestacaoOperadora.push({
                                "value": item_timelineValoresStatus.quantidade, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_operadora,
                            })
                        }))
    
                        setArrayFaturasContestadasOperadora(tempTimelineContestacaoOperadora)
                    }
                    else{
                        setArrayFaturasContestadasOperadora([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //CONTESTACOES POR OPERADORA TOTAL
                    setTooltipTotalFaturasContestadasOperadora(timelineContestacaoOperadoraTotal[0].quantidade)

                    
                    //VALOR DE CONTESTACOES POR OPERADORA
                    let tempTimelineValorContestacaoOperadora = []
                    if(timelineValorContestacaoOperadora.length>0){
                        await Promise.all(timelineValorContestacaoOperadora.map((item_timelineValoresStatus,i)=>{
                            tempTimelineValorContestacaoOperadora.push({
                                "value": item_timelineValoresStatus.valor, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_operadora,
                            })
                        }))
                        setArrayValorFaturasContestadasOperadora(tempTimelineValorContestacaoOperadora)
                    }
                    else{
                        setArrayValorFaturasContestadasOperadora([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //VALOR DE CONTESTACOES POR OPERADORA TOTAL
                    setTooltipTotalValorContestadoFaturaOperadora(timelineValorContestacaoOperadoraTotal[0].quantidade)
                }








                setArrayValorContestado([{"value": 100, "cor": cor.verdeClaro}])
                
                // setArrayValorApurado([{"value": 100, "cor": cor.vermelho}])
                // setTooltipValorApurado("R$" + formatarMilhar(valorApuradoTotal))
                

                /* SIMCARDS SEM COMUNICAR */
                // if(faturamento30dias === 0 && faturamento60dias === 0 && faturamento90dias === 0 && faturamento120dias === 0){
                //     setSimcardsFaturamento([{"value": 100, "cor": localTema ==='claro'?cor.cinza:cor.cinzaEscuro}])
                // }else{
                //     setSimcardsFaturamento([{"value": faturamento30dias, "cor": cor.verdeClaro},
                //                             {"value": faturamento60dias, "cor": cor.amarelo},
                //                             {"value": faturamento90dias, "cor": cor.vermelho},
                //                             {"value": faturamento120dias, "cor": cor.laranja}])
                // }
                setTooltipFaturamento30dias(faturamento30dias)
                setTooltipFaturamento60dias(faturamento60dias)
                setTooltipFaturamento90dias(faturamento90dias)
                setTooltipFaturamento120dias(faturamento120dias)

                setTotalSimcardsConsumo(totalFaturamento)

                /*-------------------------------------------------------------------------*/

                setTooltipContestadasVivo(quantidadeContestacaoVivo)
                setTooltipContestadasOi(quantidadeContestacaoOi)
                setTooltipContestadasTim(quantidadeContestacaoTim)
                setTooltipContestadasClaro(quantidadeContestacaoClaro)
                setTotalSimcardsConsumo(totalContestacaoOperadoras)

                 /*-------------------------------------------------------------------------*/

                setTooltipValorContestadoVivo(valorContestacaoVivo)
                setTooltipValorContestadoOi(valorContestacaoOi)
                setTooltipValorContestadoTim(ValorContestacaoTim)
                setTooltipValorContestadoClaro(ValorContestacaoClaro)
                setTotalSimcardsConsumo(ValorContestadoTotal)
                setSpinAtivo(false)
            }
            handleCarregarGraficosDashboardPagina2()
        }else if (numeroDashboard === 2){
            async function handleCarregarGraficosDashboardPagina3(){
                let dadosConsumo
                // if(!props.filtros.vazio){
                //     const dados = {
                //         codigo_cliente: decryptJWT("codigoClienteErpAntigo"),
                //         periodo:periodo,
                //         filtros: props.filtros
                //     }
                //     const {data} = await api.post('/m1/filtrar/dashboard_consumos', dados)
                //     dadosConsumo = data.dados
                // }else{
                //     const {data} = await api.get('/m1/consultar/dashboard_consumos?codigo_cliente='+decryptJWT("codigoClienteErpAntigo")+'&periodo='+periodo)
                //     dadosConsumo = data.dados
                //     mudarDataLabel(dadosConsumo,periodo)
                // }
                // /* GRAFICO DE AREA */
                // if(dadosConsumo.timeline === 0 || dadosConsumo.timeline === undefined){
                //     setValorGraficoAreaConsumo([{"consumo": 0, "previsao": 0, "dia": 1}])
                // }else{
                //     setValorGraficoAreaConsumo(dadosConsumo.timeline)
                // }
                /* GRAFICOS DE CONSUMO */


                let dadosConsulta = ''
                let dadosQuantidadeSimcardsOperadora = ''
                let dadosQuantidadeSimcardsPlano = ''
                let dadosQuantidadeSimcardsCicloVida = ''
                let dadosQuantidadeSimcardsTotal = 0
                if(!props.filtros.vazio){
                    const dados = {
                        pagina:3,
                        simcards_qtd_mes_timeline:graficoTimelinePeriodo,
                        filtros: props.filtros
                    }
                    const {data} = await api.post('/m1/filtrar/fat_dashboard', dados)
                    if(data.status == 200){
                        dadosConsulta = JSON.parse(JSON.stringify(data.dados))
                    }
                }else{

                    // pegar dados Cliclo de vida
                    let consulta_cd_fat_ciclo_vidas = '/m1/consultar/fat_dashboard?d_cd_fat_ciclo_vidas='+true+''
                        // "&simcards_qtd_mes_timeline="+graficoTimelinePeriodo   
                    const cd_fat_ciclo_vidas = await api.get(consulta_cd_fat_ciclo_vidas)

                    // pegar dados Cliclo de vida canceladas
                    let consulta_cd_fat_ciclo_vidas_canceladas = '/m1/consultar/fat_dashboard?d_cd_fat_ciclo_vidas_canceladas='+true+''
                        // "&simcards_qtd_mes_timeline="+graficoTimelinePeriodo   
                    const cd_fat_ciclo_vidas_canceladas = await api.get(consulta_cd_fat_ciclo_vidas_canceladas)

                    //dados Operadora
                    let operadora = '/m1/consultar/fat_dashboard?get_qtd_sim_operadora='+true+
                    "&ciclo_vidas="+cd_fat_ciclo_vidas.data.dados[0].d_cd_fat_ciclo_vidas
                    const getOperadora = await api.get(operadora)

                    if(getOperadora.status == 200){
                        dadosQuantidadeSimcardsOperadora = getOperadora.data.dados
                    }

                    // quantidade total de SimCards
                    let soma = 0
                    let somaOperadora = getOperadora.data.dados.map(item => item.qtdSimcards	
                    )
                    for(var i = 0; i < somaOperadora.length; i++) {
                        soma += somaOperadora[i];
                    }
                    dadosQuantidadeSimcardsTotal = soma

                    //dados Plano
                    let plano = '/m1/consultar/fat_dashboard?get_qtd_sim_plano='+true+
                    "&ciclo_vidas="+cd_fat_ciclo_vidas.data.dados[0].d_cd_fat_ciclo_vidas  
                    const getPlano = await api.get(plano)

                    if(getPlano.status == 200){
                        dadosQuantidadeSimcardsPlano = getPlano.data.dados
                    }

                    //dados Ciclo de vida
                    let ciclo_vida = '/m1/consultar/fat_dashboard?get_qtd_ciclo_vida='+true+
                    "&ciclo_vidas_canceladas="+cd_fat_ciclo_vidas_canceladas.data.dados[0].d_cd_fat_ciclo_vidas_canceladas  
                    const getcicloVida = await api.get(ciclo_vida)

                    if(getcicloVida.status == 200){
                        dadosQuantidadeSimcardsCicloVida = getcicloVida.data.dados
                      
                    }
                }


                if(dadosQuantidadeSimcardsTotal){
                  if(dadosQuantidadeSimcardsOperadora){
                    // let timelineQuantidadeSimcards = JSON.parse(JSON.stringify(dadosConsulta[0]))
                    // let timelineQuantidadeSimcardsTotal = JSON.parse(JSON.stringify(dadosConsulta[1]))
                    let timelineQuantidadeSimcardsOperadora = dadosQuantidadeSimcardsOperadora
                    let timelineQuantidadeSimcardsPlano = dadosQuantidadeSimcardsPlano
                    let timelineQuantidadeSimcardsCicloVida = dadosQuantidadeSimcardsCicloVida
                    let timelineQuantidadeSimcardsTotalAtual = dadosQuantidadeSimcardsTotal
                    
                    //TIMELINE
                    // await Promise.all(timelineQuantidadeSimcards.map((item_timelineCobradoApurado,i)=>{
                    //     item_timelineCobradoApurado.mes = moment(item_timelineCobradoApurado.mes).format("MMMM YYYY")
                    // }))
                    
                    setGraficoTimelineQuantidadeSimcards(0)
                    setTotalSimcards(0)
                    setTotalSimcardsAtual(timelineQuantidadeSimcardsTotalAtual)
                    

                    //SIMCARDS POR OPERADORA
                    let tempTimelineQuantidadeSimcardsOperadora = []
                    if(timelineQuantidadeSimcardsOperadora.length>0){
                        await Promise.all(timelineQuantidadeSimcardsOperadora.map((item_timelineValoresStatus,i)=>{
                            tempTimelineQuantidadeSimcardsOperadora.push({
                                "value": item_timelineValoresStatus.qtdSimcards, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_apelido,
                            })
                        }))
    
                        setArraySimcardsOperadora(tempTimelineQuantidadeSimcardsOperadora)
                    }
                    else{
                        setArraySimcardsOperadora([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //SIMCARDS POR PLANO
                    let tempTimelineQuantidadeSimcardsPlano = []
                    if(timelineQuantidadeSimcardsPlano.length>0){
                        await Promise.all(timelineQuantidadeSimcardsPlano.map((item_timelineValoresStatus,i)=>{
                            tempTimelineQuantidadeSimcardsPlano.push({
                                "value": item_timelineValoresStatus.qtdSimcards, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_plano,
                            })
                        }))
    
                        setArraySimcardsPlano(tempTimelineQuantidadeSimcardsPlano)
                    }
                    else{
                        setArraySimcardsPlano([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }

                    //SIMCARDS Clico de vida
                    let tempTimelineQuantidadeSimcardsCicloVida = []
                    if(timelineQuantidadeSimcardsCicloVida.length>0){
                        await Promise.all(timelineQuantidadeSimcardsCicloVida.map((item_timelineValoresStatus,i)=>{
                            tempTimelineQuantidadeSimcardsCicloVida.push({
                                "value": item_timelineValoresStatus.qtdSimcards, 
                                "cor": getColorByIdx(i), 
                                "label": item_timelineValoresStatus.ds_status,
                            })
                        }))
    
                        setArraySimcardsCicloVida(tempTimelineQuantidadeSimcardsCicloVida)
                    }
                    else{
                        setArraySimcardsCicloVida([{"value": 100, "cor": localTema ==='claro' || localTema ==='algarClaro'?cor.cinza:cor.cinzaEscuro, "label":false}])
                    }
                  }

                }

                setArrayQuantidadeSimcard([{"value": 100, "cor": cor.verdeClaro}])
                setTooltipQuantidadeSimcard("R$ " + formatarMilhar(totalContestacaoOperadoras))
                
                // setArrayValorApurado([{"value": 100, "cor": cor.vermelho}])
                // setTooltipValorApurado("R$" + formatarMilhar(valorApuradoTotal))

                /* SIMCARDS SEM COMUNICAR */
                setTooltipSimcardsVivo(quantidadeSimcardVivo)
                setTooltipSimcardsOi(quantidadeSimcardOi)
                setTooltipSimcardsTim(quantidadeSimcardTim)
                setTooltipSimcardsClaro(quantidadeSimcardClaro)
                setTooltipTotalSimcardsOperadora(totalQuantidadeSimcardOperadoras)
                setTotalSimcardsConsumo(totalQuantidadeSimcardOperadoras)

                /*-------------------------------------------------------------------------*/

                setTooltipSimcardsCompartilhado(quantidadeSimcardCompartilhado)
                setTooltipSimcardsIndividual(quantidadeSimcardIndividual)
                setTooltipSimcardsIlimitado(quantidadeSimcardIlimitado)
                setTooltipTotalSimcardsPlano(totalQuantidadeSimcardPlano)
                setTotalSimcardsConsumo(totalQuantidadeSimcardPlano)

                 /*-------------------------------------------------------------------------*/

                setTooltipSimcardsAtivo(quantidadeSimcardAtivo)
                setTooltipSimcardsCancelado(quantidadeSimcardCancelado)
                setTooltipSimcardsSuspenso(quantidadeSimcardSuspenso)
                setTooltipSimcardsTradein(quantidadeSimcardTradein)
                setTooltipSimcardsPreativo(quantidadeSimcardPreativo)
                setTooltipSimcardsBloqueado(quantidadeSimcardBloqueado)
                setTooltipTotalSimcardsCicloVida(totalQuantidadeSimcardCicloVida)
                setTotalSimcardsConsumo(totalQuantidadeSimcardCicloVida)
                setSpinAtivo(false)
            }
            handleCarregarGraficosDashboardPagina3()
        }
    }, [numeroDashboard, periodo,props.filtros,faturaUtlimoCicloStatusContestacaoOperadoraValor,
        faturaQtdMesStatusContestacaoOperadoraValor,faturaUtlimoCicloStatusContestacaoOperadora,
        faturaQtdMesStatusContestacaoOperadora,faturaUtlimoCicloStatus,faturaQtdMesStatus,
        graficoTimelinePeriodo])

    
    useEffect(() => {
        if(props.revelarBarraFiltro === true){
            despacho(revelarBarraFiltragem(false))
            setSpinAtivo(true)
        }
        if(props.filtros.vazio != 'vazio'){
            despacho(mudarFiltros({vazio: 'vazio'}))
            despacho(limpadorFiltros(true))
            setSpinAtivo(true)
        }
    }, [])
    
    function CustomTooltipGrafico1({ payload, label, active }) {
        if (active && payload!==null && payload.length > 0) {
            return (
                <div className="container-tooltip-grafico-ciclo-ultimo">
                    <p className="label-tooltip-grafico-ciclo-ultimo"> Valor Cobrado: {"R$ " + formatarMilhar(payload[0].payload.valorCobrado)} </p>
                    <p className="label-tooltip-grafico-ciclo-ultimo"> Valor Apurado: {"R$ " + formatarMilhar(payload[0].payload.valorApurado)} </p>
                </div>
            );
        }
        return null;
    }

    function CustomTooltipGrafico2({ payload, label, active }) {
        if (active && payload!==null && payload.length > 0) {
            return (
                <div className="container-tooltip-grafico-ciclo-ultimo">
                    <p className="label-tooltip-grafico-ciclo-ultimo"> Valor contestado: {"R$ " + formatarMilhar(payload[0].payload.valorContestado)} </p>
                    {/* <p className="label-tooltip-grafico-ciclo-ultimo"> Valor Apurado: {"R$ " + formatarMilhar(payload[0].payload.apurado)} </p> */}
                </div>
            );
        }
        return null;
    }

    function CustomTooltipGrafico3({ payload, label, active }) {
        if (active && payload!==null && payload.length > 0) {
            return (
                <div className="container-tooltip-grafico-ciclo-ultimo">
                    <p className="label-tooltip-grafico-ciclo-ultimo"> Quantidade de simcard: {payload[0].payload.qtdSimcards} </p>
                    {/* <p className="label-tooltip-grafico-ciclo-ultimo"> Valor Apurado: {"R$ " + formatarMilhar(payload[0].payload.apurado)} </p> */}
                </div>
            );
        }
        return null;
    }

    return( 
        <div className="container-dashboard-tela">
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { props.revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            {/* { numeroDashboard === 0 &&
                <div className={props.revelarBarraFiltro===false?"container-dashboard-topo fundo-cor-5":"container-dashboard-topo-2 fundo-cor-5"}>
                    <Form.Row>
                        <Col>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-dashboard">Valor cobrado e apurado</div>
                            </Form.Label>
                            <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("consumo")}>
                                <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                            </Button>
                            <div className="data-ciclo fonte-cor-1">{dataCiclo}</div>
                        </Col>
                    </Form.Row>
                    <div className="container-graficos-topo">
                        {revelarOpcoesConsumo === true &&
                            <div className="menu-opcoes-dashboard-topo">
                                {<RenderMenuOpcoesTopo setGraficoTimelinePeriodo={setGraficoTimelinePeriodo} setRevelarOpcoesConsumo={setRevelarOpcoesConsumo} revelarOpcoesConsumo={revelarOpcoesConsumo}/>}
                            </div>
                        }
                        <div className="coluna-graficos-topo-1">
                            <div className="linha-grafico-topo">
                                <div className="container-grafico-pizza-topo">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={arrayValorCobrado}
                                                outerRadius="40%"
                                                fill={cor.cinzaEscuro}
                                                paddingAngle={0}
                                                stroke={0}
                                                isAnimationActive={false}
                                            >
                                            { arrayValorCobrado .map((entry, index) => <Cell fill={entry.cor}/>) }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-legenda-grafico-pizza-topo">
                                    <div className="container-titulo-legenda-grafico-pizza-topo">
                                        <span className="texto-titulo-legenda-grafico-pizza-topo fonte-cor-1">Valor cobrado</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-topo">
                                        <span className="texto-corpo-legenda-grafico-pizza-topo fonte-cor-1">{tooltipValorCobrado}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="linha-grafico-topo">
                                <div className="container-grafico-pizza-topo">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={arrayValorApurado} 
                                                outerRadius="40%"
                                                fill={cor.cinzaEscuro}
                                                paddingAngle={0}
                                                stroke={0}
                                                isAnimationActive={false}
                                            >
                                            <Label content={<LabelGraficosPizzaTopo valorPercentual={percentualConsumoMedia}/>}/>
                                            {arrayValorApurado.map((entry, index) => <Cell fill={entry.cor}/>)}
                                            </Pie>
                                            <Tooltip content={<TooltipGraficoPizza/>}/>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-legenda-grafico-pizza-topo">
                                    <div className="container-titulo-legenda-grafico-pizza-topo">
                                        <span className="texto-titulo-legenda-grafico-pizza-topo fonte-cor-1">Valor apurado</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-topo">
                                        <span className="texto-corpo-legenda-grafico-pizza-topo fonte-cor-1">{tooltipValorApurado}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="coluna-graficos-topo-2">
                            <ResponsiveContainer>
                                <BarChart data={graficoTimelineCobradoApurado}>
                                    <CartesianGrid stroke={'transparent'} strokeDasharray="0" />
                                    <XAxis stroke={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} dataKey={"mes"} />
                                    <YAxis stroke={localTema ==='claro' || localTema ==='algarClaro'?cor.preto:cor.branco} />
                                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltipGrafico1 />}/>
                                    <Bar barSize={36} dataKey="valorCobrado" fill={cor.verdeClaro} background={{ fill: localTema ==='claro' || localTema ==='algarClaro'?cor.cinza3:cor.preto2}}/>
                                    <Bar barSize={36} dataKey="valorApurado" fill={cor.vermelho} background={{ fill: localTema ==='claro' || localTema ==='algarClaro'?cor.cinza3:cor.preto2}}/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            } */}

            { numeroDashboard === 1 &&
                <div className={props.revelarBarraFiltro===false?"container-dashboard-topo ":"container-dashboard-topo-2 fundo-cor-5"}>
                    <Form.Row>
                        <Col>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-dashboard">Valores contestados</div>
                            </Form.Label>
                            <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("consumo")}>
                                <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                            </Button>
                            <div className="data-ciclo fonte-cor-1">{dataCiclo}</div>
                        </Col>
                    </Form.Row>
                    <div className="container-graficos-topo">
                        {revelarOpcoesConsumo === true &&
                            <div className="menu-opcoes-dashboard-topo">
                                {<RenderMenuOpcoesTopo setGraficoTimelinePeriodo={setGraficoTimelinePeriodo} setRevelarOpcoesConsumo={setRevelarOpcoesConsumo} revelarOpcoesConsumo={revelarOpcoesConsumo}/>}
                            </div>
                        }
                        <div className="coluna-graficos-topo-1">
                            <div className="linha-grafico-topo">
                                <div className="container-grafico-pizza-topo">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={arrayValorContestado}
                                                // innerRadius="60%"
                                                outerRadius="40%"
                                                fill={cor.cinzaEscuro}
                                                paddingAngle={0}
                                                stroke={0}
                                                isAnimationActive={false}
                                            >
                                            { arrayValorContestado .map((entry, index) => <Cell fill={entry.cor}/>) }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-legenda-grafico-pizza-topo">
                                    <div className="container-titulo-legenda-grafico-pizza-topo">
                                        <span className="texto-titulo-legenda-grafico-pizza-topo fonte-cor-1">Valor contestado</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-topo">
                                        <span className="texto-corpo-legenda-grafico-pizza-topo fonte-cor-1">{tooltipValorContestado}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="coluna-graficos-topo-2">
                            <ResponsiveContainer>
                                <BarChart data={graficoTimelineContestado} /*data={mudarGraficoDias === "15dias" ? arrayGrafico15dias : arrayGrafico7dias}>*/>
                                    <CartesianGrid stroke={'transparent'} strokeDasharray="0" />
                                    <XAxis stroke={localTema ==='claro' || localTema==='algarClaro'?cor.preto:cor.branco} dataKey={"mes"} />
                                    <YAxis stroke={localTema ==='claro' || localTema==='algarClaro'?cor.preto:cor.branco} />
                                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltipGrafico2 />}/>
                                    <Bar barSize={36} dataKey="valorContestado" fill={cor.verdeClaro} background={{ fill: localTema ==='claro' || localTema==='algarClaro'?cor.cinza3:cor.preto2}}/>
                                    {/* <Bar barSize={36} dataKey="apurado" fill={cor.vermelho} background={{ fill: localTema ==='claro' || localTema==='algarClaro'?cor.cinza3:cor.preto2}}/> */}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            }
            {/* { numeroDashboard === 2 &&
                <div className={props.revelarBarraFiltro===false?"container-dashboard-topo fundo-cor-5":"container-dashboard-topo-2 fundo-cor-5"}>
                    <Form.Row>
                        <Col>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela-dashboard">Quantidade de SIM cards</div>
                            </Form.Label>
                            <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("consumo")}>
                                <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                            </Button>
                            <div className="data-ciclo fonte-cor-1">{dataCiclo}</div>
                        </Col>
                    </Form.Row>
                    <div className="container-graficos-topo">
                        {revelarOpcoesConsumo === true &&
                            <div className="menu-opcoes-dashboard-topo">
                                {<RenderMenuOpcoesTopo setGraficoTimelinePeriodo={setGraficoTimelinePeriodo} setRevelarOpcoesConsumo={setRevelarOpcoesConsumo} revelarOpcoesConsumo={revelarOpcoesConsumo}/>}
                            </div>
                        }
                        <div className="coluna-graficos-topo-1">
                            <div className="linha-grafico-topo">
                                <div className="container-grafico-pizza-topo">
                                    <ResponsiveContainer height="100%" width="100%">
                                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                            <Pie
                                                data={arrayQuantidadeSimcard}
                                                // innerRadius="60%"
                                                outerRadius="40%"
                                                fill={cor.cinzaEscuro}
                                                paddingAngle={0}
                                                stroke={0}
                                                isAnimationActive={false}
                                            >
                                            { arrayQuantidadeSimcard .map((entry, index) => <Cell fill={entry.cor}/>) }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="container-legenda-grafico-pizza-topo">
                                    <div className="container-titulo-legenda-grafico-pizza-topo">
                                        <span className="texto-titulo-legenda-grafico-pizza-topo fonte-cor-1">Total simcards</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-topo">
                                        <span className="texto-corpo-legenda-grafico-pizza-topo fonte-cor-1">{totalSimcards}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="coluna-graficos-topo-2">
                            <ResponsiveContainer>
                                <BarChart data={graficoTimelineQuantidadeSimcards}>
                                    <CartesianGrid stroke={'transparent'} strokeDasharray="0" />
                                    <XAxis stroke={localTema ==='claro' || localTema==='algarClaro'?cor.preto:cor.branco} dataKey={"mes"} />
                                    <YAxis stroke={localTema ==='claro' || localTema==='algarClaro'?cor.preto:cor.branco} />
                                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltipGrafico3 />}/>
                                    <Bar barSize={36} dataKey="qtdSimcards" fill={cor.verdeClaro} background={{ fill: localTema ==='claro' || localTema==='algarClaro'?cor.cinza3:cor.preto2}}/>
                                    <Bar barSize={36} dataKey="apurado" fill={cor.vermelho} background={{ fill: localTema ==='claro' || localTema==='algarClaro'?cor.cinza3:cor.preto2}}/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            } */}





            
            <div className={props.revelarBarraFiltro===false?"container-dashboard-faturas-fundo-faturas":"container-dashboard-faturas-fundo-4"}>
                { numeroDashboard === 0 &&
                    <>
                        <div className="container-dashboard-faturas-fundo-1  fundo-cor-5" id="grafico-faturas-por-status">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> Faturas por status</div>
                                    </Form.Label>
                                    <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("faturasStatus")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesFaturasStatus === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                        <RenderMenuOpcoesMostrarFaturasStatus 
                                            tipo={'grafico-faturas-por-status'} 
                                            exportar="grafico-faturas-por-status" 
                                            setRevelarOpcoesFaturasStatus={setRevelarOpcoesFaturasStatus} 
                                            revelarOpcoesFaturasStatus={revelarOpcoesFaturasStatus}
                                            setFaturaUtlimoCicloStatus={setFaturaUtlimoCicloStatus}
                                            setFaturaQtdMesStatus={setFaturaQtdMesStatus}
                                        />
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arrayFaturasPorStatus} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={tooltipTotalFaturasStatus}/>}/>
                                                        {
                                                        arrayFaturasPorStatus.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaFaturaStatus/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arrayFaturasPorStatus[0].label != false &&
                                            arrayFaturasPorStatus.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilharSemDecimais(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className="linha-legenda-sem-comunicar">
                                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:cor.azul}}/></div>
                                            <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Em estoque</span></div>
                                            <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilhar(tooltipSimcardsEmEstoque)}</span></div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(tooltipTotalFaturasStatus)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-dashboard-faturas-fundo-3 fundo-cor-5" id="grafico-faturas-em-aberto-por-operadora">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> Valor de faturas em aberto por operadora </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("faturasAbertasOperadora")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesFaturasAbertasOperadora === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                         {
                                            <RenderMenuOpcoesMostrarDispositivosExportar 
                                                tipo="faturas-em-aberto-por-operadora" 
                                                nomeMostrar="Mostrar em faturas"
                                                rota="/veye/faturas/faturas"
                                                exportar="grafico-faturas-em-aberto-por-operadora" 
                                                setRevelarOpcoesFaturasAbertasOperadora={setRevelarOpcoesFaturasAbertasOperadora} 
                                                revelarOpcoesFaturasAbertasOperadora={revelarOpcoesFaturasAbertasOperadora} 
                                            />
                                        }
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arrayValorFaturasOperadora} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaCompostoValor valorPercentual={tooltipTotalValorFaturaOperadora}/>}/>
                                                        {
                                                            arrayValorFaturasOperadora.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaFaturaAbertoOperadoraValor/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arrayValorFaturasOperadora[0].label != false &&
                                            arrayValorFaturasOperadora.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2-faturas"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">R$ {formatarMilhar(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">R$ {formatarMilhar(tooltipTotalValorFaturaOperadora)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                { numeroDashboard === 1 &&
                    <>
                        <div className="container-dashboard-faturas-fundo-1  fundo-cor-5" id="grafico-simcards-que-nao-estao-faturanto-irao-faturar">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> SIM cards que não estão faturando e que iniciarão o faturamento</div>
                                    </Form.Label>
                                    <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("simcardsNaoFaturando")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesSimcardsNaoFaturando === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                          {
                                            <RenderMenuOpcoesMostrarDispositivosExportar 
                                                tipo="simcards-que-nao-estao-faturanto-irao-faturar" 
                                                nomeMostrar="Mostrar em dispositivos"
                                                rota="/veye/faturas/dispositivos" 
                                                exportar="grafico-simcards-que-nao-estao-faturanto-irao-faturar" 
                                                setRevelarOpcoesSimcardsNaoFaturando={setRevelarOpcoesSimcardsNaoFaturando} 
                                                revelarOpcoesSimcardsNaoFaturando={revelarOpcoesSimcardsNaoFaturando} 
                                            />
                                        }
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arraySimcardsFaturamento} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={tooltipTotalSimcardsFaturamento}/>}/>
                                                        {
                                                            arraySimcardsFaturamento.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaSimcardNaoFaturaIramIniciarFaturar/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arraySimcardsFaturamento[0].label != false &&
                                            arraySimcardsFaturamento.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label} Dias</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{item_arrayFaturasPorStatus.value}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{tooltipTotalSimcardsFaturamento}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-dashboard-faturas-fundo-3 fundo-cor-5" id="grafico-valor-contestacoes-por-operadora">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2">  Valor de contestações por operadora </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("valorContestacaoOperado")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesValorContestacaoOperadora === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                        {
                                            <RenderMenuOpcoesMostrarContestacaoOperadoraValor
                                                tipo=""
                                                exportar="grafico-valor-contestacoes-por-operadora"
                                                setRevelarOpcoesValorContestacaoOperadora={setRevelarOpcoesValorContestacaoOperadora}
                                                revelarOpcoesValorContestacaoOperadora={revelarOpcoesValorContestacaoOperadora}
                                                setFaturaQtdMesStatusContestacaoOperadoraValor={setFaturaQtdMesStatusContestacaoOperadoraValor}
                                                setFaturaUtlimoCicloStatusContestacaoOperadoraValor={setFaturaUtlimoCicloStatusContestacaoOperadoraValor}
                                            />
                                         }
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arrayValorFaturasContestadasOperadora} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={tooltipTotalValorContestadoFaturaOperadora}/>}/>
                                                        {
                                                            arrayValorFaturasContestadasOperadora.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaContestacaoOperadoraValor/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arrayValorFaturasContestadasOperadora[0].label != false &&
                                            arrayValorFaturasContestadasOperadora.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilhar(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilhar(tooltipTotalValorContestadoFaturaOperadora)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                { numeroDashboard === 2 &&
                    <>
                        <div className="container-dashboard-faturas-fundo-1  fundo-cor-5" id="grafico-simcards-por-operadora">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> SIM cards por operadora </div>
                                    </Form.Label>
                                    {/* <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("simcardsOperadora")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button> */}
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesSimcardsOperadora === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                          {
                                            <RenderMenuOpcoesMostrarDispositivosExportar 
                                                tipo="simcards-por-operadora" 
                                                nomeMostrar="Mostrar em dispositivos"
                                                rota="/veye/faturas/dispositivos" 
                                                exportar="grafico-simcards-por-operadora" 
                                                setRevelarOpcoesSimcardsOperadora={setRevelarOpcoesSimcardsOperadora} 
                                                revelarOpcoesSimcardsOperadora={revelarOpcoesSimcardsOperadora} 
                                            />
                                        }
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arraySimcardsOperadora} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={totalSimcardsAtual}/>}/>
                                                        {
                                                            arraySimcardsOperadora.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaSimcard/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arraySimcardsOperadora[0].label != false &&
                                            arraySimcardsOperadora.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilharSemDecimais(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(totalSimcardsAtual)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-dashboard-faturas-fundo-3 fundo-cor-5" id="grafico-simcards-por-status-ciclo-vida">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> SIM cards por status de ciclo de vida </div>
                                    </Form.Label>
                                    {/* <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("simcardsCicloVida")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button> */}
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesSimcardsCicloVida === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                         <RenderMenuOpcoesMostrarDispositivosExportar 
                                            tipo="simcards-por-status-ciclo-vida" 
                                            nomeMostrar="Mostrar em dispositivos"
                                            rota="/veye/faturas/dispositivos" 
                                            exportar="grafico-simcards-por-status-ciclo-vida" 
                                            setRevelarOpcoesSimcardsCicloVida={setRevelarOpcoesSimcardsCicloVida} 
                                            revelarOpcoesSimcardsCicloVida={revelarOpcoesSimcardsCicloVida} 
                                        />
                                    </div>
                                }
                                <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arraySimcardsCicloVida} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={totalSimcardsAtual}/>}/>
                                                        {
                                                            arraySimcardsCicloVida.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaSimcard/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arraySimcardsCicloVida[0].label != false &&
                                            arraySimcardsCicloVida.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilharSemDecimais(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(totalSimcardsAtual)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={props.revelarBarraFiltro===false?"container-dashboard-faturas-fundo-faturas":"container-dashboard-faturas-fundo-4"}>
            { numeroDashboard === 0 &&
                    <>
                        <div className="container-dashboard-faturas-fundo-2 fundo-cor-5" id="grafico-faturas-aberto-por-operadora">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2">Faturas em aberto por operadora </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("faturasAbertas")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesFaturasAbertas === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                        {
                                            <RenderMenuOpcoesMostrarDispositivosExportar 
                                                tipo="faturas-aberto-por-operadora" 
                                                nomeMostrar="Mostrar em faturas"
                                                exportar="grafico-faturas-aberto-por-operadora" 
                                                rota="/veye/faturas/faturas"
                                                setRevelarOpcoesFaturasAbertas={setRevelarOpcoesFaturasAbertas} 
                                                revelarOpcoesFaturasAbertas={revelarOpcoesFaturasAbertas} 
                                            />
                                        }
                                    </div>
                                }
                               <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arrayFaturasAbertaOperadora} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={tooltipTotalFaturaAbertaOperadora}/>}/>
                                                        {
                                                            arrayFaturasAbertaOperadora.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaFaturaAbertoOperadora/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arrayFaturasAbertaOperadora[0].label != false &&
                                            arrayFaturasAbertaOperadora.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilharSemDecimais(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(tooltipTotalFaturaAbertaOperadora)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                { numeroDashboard === 1 &&
                    <>
                        <div className="container-dashboard-faturas-fundo-2 fundo-cor-5" id="grafico-contestacoes-por-operadora">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> Contestações por operadora </div>
                                    </Form.Label>
                                    <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("contestacaoOperadora")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button>
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesContestacaoOperadora === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                        {<RenderMenuOpcoesMostrarContestacaoOperadora
                                            tipo=""
                                            exportar="grafico-contestacoes-por-operadora"
                                            setRevelarOpcoesContestacaoOperadora={setRevelarOpcoesContestacaoOperadora}
                                            revelarOpcoesContestacaoOperadora={revelarOpcoesContestacaoOperadora}
                                            setFaturaQtdMesStatusContestacaoOperadora={setFaturaQtdMesStatusContestacaoOperadora}
                                            setFaturaUtlimoCicloStatusContestacaoOperadora={setFaturaUtlimoCicloStatusContestacaoOperadora}
                                         />}
                                    </div>
                                }
                               <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arrayFaturasContestadasOperadora} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={tooltipTotalFaturasContestadasOperadora}/>}/>
                                                        {
                                                            arrayFaturasContestadasOperadora.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaContestacaoOperadora/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arrayFaturasContestadasOperadora[0].label != false &&
                                            arrayFaturasContestadasOperadora.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label} Dias</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{item_arrayFaturasPorStatus.value}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{tooltipTotalFaturasContestadasOperadora}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                { numeroDashboard === 2 &&
                    <>
                        <div className="container-dashboard-faturas-fundo-2 fundo-cor-5" id="grafico-simcards-por-plano">
                            <Form.Row>
                                <Col>
                                    <Form.Label className="titulo-grafico-dashboard">
                                        <div className="fonte-cor-1 label-janela-dashboard-2"> Simcards por plano </div>
                                    </Form.Label>
                                    {/* <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes("simcardsPlano")}>
                                        <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções"/>
                                    </Button> */}
                                </Col>
                            </Form.Row>
                            <div className="grupo-grafico-pizza-grande">
                                {revelarOpcoesSimcardsPlano === true &&
                                    <div className="menu-opcoes-dashboard-fundo">
                                         <RenderMenuOpcoesMostrarDispositivosExportar 
                                            tipo="simcards-por-plano" 
                                            nomeMostrar="Mostrar em dispositivos"
                                            rota="/veye/faturas/dispositivos" 
                                            exportar="grafico-simcards-por-plano" 
                                            setRevelarOpcoesSimcardsPlano={setRevelarOpcoesSimcardsPlano} 
                                            revelarOpcoesSimcardsPlano={revelarOpcoesSimcardsPlano} 
                                            />
                                    </div>
                                }
                               <div className="container-simcards-sem-comunicar">
                                    <div className="container-grafico-pizza-sem-comunicar">
                                        <div className="caixa-grafico-pizza-sem-comunicar">
                                            <ResponsiveContainer height="100%" width="100%">
                                                <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <Pie
                                                        data={arraySimcardsPlano} 
                                                        innerRadius="60%"
                                                        outerRadius="80%"
                                                        fill={cor.cinzaEscuro}
                                                        paddingAngle={0}
                                                        stroke={0}
                                                    >
                                                    <Label content={<LabelGraficosPizzaComposto valorPercentual={totalSimcardsAtual}/>}/>
                                                        {
                                                            arraySimcardsPlano.map((entry, index) => <Cell fill={entry.cor}/>)
                                                        }
                                                    </Pie>
                                                    <Tooltip content={<TooltipGraficoPizzaSimcard/>}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className="container-legenda-sem-comunicar">
                                        { arraySimcardsPlano[0].label != false &&
                                            arraySimcardsPlano.map((item_arrayFaturasPorStatus,i)=>{
                                                return(
                                                    <div className="linha-legenda-sem-comunicar">
                                                        <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{backgroundColor:item_arrayFaturasPorStatus.cor}}/></div>
                                                        <div className="coluna-legenda-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">{item_arrayFaturasPorStatus.label}</span></div>
                                                        <div className="coluna-legenda-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-faturas">{formatarMilharSemDecimais(item_arrayFaturasPorStatus.value)}</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="container-legenda-grafico-pizza-grande">
                                    <div className="container-titulo-legenda-grafico-pizza-grande">
                                        <span className="texto-titulo-legenda-grafico-pizza-grande fonte-cor-1">Total</span>
                                    </div>
                                    <div className="container-corpo-legenda-grafico-pizza-grande">
                                        <span className="texto-corpo-legenda-grafico-pizza-grande fonte-cor-1">{formatarMilharSemDecimais(totalSimcardsAtual)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {/* <div className="wrapper-botoes-alternar-dashboard">
                <div className="container-botoes-alternar-dashboard">
                    <Button className="botao-alternar-dashboard-mais">
                        <img className="icone-botao-alternar-dashboard-mais" src={sinalMaiorQueVerde()} alt="anterior" onClick={() => handlAlternarPaginasDashboard("anterior")}/>
                    </Button>
                    <Button className="botao-alternar-dashboard-menos">
                        <img className="icone-botao-alternar-dashboard-menos" src={sinalMaiorQueVerde()} alt="próximo" onClick={() => handlAlternarPaginasDashboard("proximo")}/>
                    </Button>
                </div>
            </div> */}
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca,
         filtros: state.filtrosState.filtros, 
         revelarBarraFiltro: state.filtrosState.revelarBarraFiltragem ,
         numeroPaginaDashboardFaturas: state.faturasState.numeroPaginaDashboardFaturas};
} 

 /* CRIA MENU DE OPCOES DOS GRAFICOS DO TOPO (QUE NUNCA MUDAM) */
function RenderMenuOpcoesTopo(props){
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       props.setRevelarOpcoesConsumo(!props.revelarOpcoesConsumo)
    }
    

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return(
        <Form ref={modal} className="menu-opcoes-container menu-opcoes-container-timeline">
            <div className="campo-select-triangulo-cor-2 menu-opcoes-triangulo-topo"></div>
            <div className="menu-opcoes-corpo-topo fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label fonte-cor-1" onClick={()=> props.setGraficoTimelinePeriodo(0)}>Ciclo atual</span>
                        </div>
                        <div className="menu-opcoes-divisora-topo campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label fonte-cor-1" onClick={()=> props.setGraficoTimelinePeriodo(2)}>Trimestre</span>
                        </div>
                        <div className="menu-opcoes-divisora-topo campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label fonte-cor-1" onClick={()=> props.setGraficoTimelinePeriodo(5)}>Semestre</span>
                        </div>
                        <div className="menu-opcoes-divisora-topo campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span  className="drop-acoes-menu-label fonte-cor-1" onClick={()=> props.setGraficoTimelinePeriodo(11)}>Anual</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}

function RenderMenuOpcoesMostrarFaturasStatus(props){
    const modal = useRef();
    const [arrayDados, setArrayDados] = useState([])
    const despacho = useDispatch();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       props.setRevelarOpcoesFaturasStatus(!props.revelarOpcoesFaturasStatus)
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        handlerRendeizarItens();
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handlerAlterarFiltro(value){
        props.setFaturaQtdMesStatus(value)
        props.setFaturaUtlimoCicloStatus(false)
    }
    function handlerAlterarFiltroUltimo(value){
        props.setFaturaUtlimoCicloStatus(value)
        props.setFaturaQtdMesStatus(false)
    }

    function handlerModificarFiltro(item,ds){
        despacho(mudarFiltros({FATURA_STATUS:item}))
        despacho(filtrosArrayBarra(["Status: "+ds+"?FATURA_STATUS"]))
        despacho(limpadorFiltros(false));
        despacho(revelarBarraFiltragem(true));
        const elemento = document.getElementById("link-mostrar-faturas");
        elemento.click();
    }
    async function handlerRendeizarItens(){
        let tempHtml = []
        const {data} = await api.get('/m1/consultar/fat_fatura_status')
        if(data.status == 200){
            await Promise.all(data.dados.map((item_data,i)=>{
                tempHtml.push(
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.cd_fat_fatura_status,item_data.ds_fatura_status)}>
                            <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.ds_fatura_status}</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                )
            }))
        }
        setArrayDados(tempHtml)
    }

    return(
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-2 menu-opcoes-triangulo"></div>
            <div className="menu-opcoes-corpo fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(0)}>Ciclo atual</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltroUltimo(1)}> Último ciclo</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(2)}>Trimestre</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={e => dropMenuOpcoes(e, "menu-mostrar-em-dispositivos", "imagem-menu-mostrar-em-dispositivos")}>
                            <span className="drop-acoes-menu-label fonte-cor-1">Mostrar em faturas</span>
                            <img id={"imagem-menu-mostrar-em-dispositivos"} className="menu-opcoes-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        <ul id={"menu-mostrar-em-dispositivos"}  className="drop-acoes-lista-submenu dropdown-desativado">
                            {arrayDados && 
                                arrayDados
                            }
                            <NavLink id="link-mostrar-faturas" className="d_nome" to={'/veye/faturas/faturas'}/>
                        </ul>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => exportarImagemDashFaturas(props.exportar)}>Exportar imagem</span>
                        </div>
                    </li>
                </ul>
            </div>
        </Form>
    )
}

function RenderMenuOpcoesMostrarDispositivosExportar(props){
    const modal = useRef();
    const [arrayDados, setArrayDados] = useState([])
    const despacho = useDispatch();

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       if(props.revelarOpcoesSimcardsCicloVida){
           props.setRevelarOpcoesSimcardsCicloVida(!props.revelarOpcoesSimcardsCicloVida)
       }
       if(props.revelarOpcoesSimcardsPlano){
           props.setRevelarOpcoesSimcardsPlano(!props.revelarOpcoesSimcardsPlano)
       }
       if(props.revelarOpcoesFaturasAbertasOperadora){
           props.setRevelarOpcoesFaturasAbertasOperadora(!props.revelarOpcoesFaturasAbertasOperadora)
       }
       if(props.revelarOpcoesSimcardsNaoFaturando){
           props.setRevelarOpcoesSimcardsNaoFaturando(!props.revelarOpcoesSimcardsNaoFaturando)
       }
       if(props.revelarOpcoesFaturasAbertas){
           props.setRevelarOpcoesFaturasAbertas(!props.revelarOpcoesFaturasAbertas)
       }
       if(props.revelarOpcoesSimcardsOperadora){
           props.setRevelarOpcoesSimcardsOperadora(!props.revelarOpcoesSimcardsOperadora)
       }
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        handlerRendeizarItens()
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handlerModificarFiltro(item,ds){
        if(props.tipo === 'faturas-aberto-por-operadora' || props.tipo === "faturas-em-aberto-por-operadora"){
            despacho(mudarFiltros({FATURA_STATUS:4}))
            despacho(filtrosArrayBarra(["Status: Aberta?FATURA_STATUS"]))
        }
        if(props.tipo === "simcards-por-operadora"){
            despacho(mudarFiltros({CD_OPERADORA:item}))
            despacho(filtrosArrayBarra(["Operadora: "+ds+"?CD_OPERADORA"]))
        }
        if(props.tipo === 'simcards-por-plano'){
            despacho(mudarFiltros({CD_PLANO:item}))
            despacho(filtrosArrayBarra(["Plano: "+ds+"?CD_PLANO"]))
        }
        if( props.tipo === "simcards-por-status-ciclo-vida"){
            despacho(mudarFiltros({CD_CICLO_VIDA:item}))
            despacho(filtrosArrayBarra(["Ciclo vida: "+ds+"?CD_CICLO_VIDA"]))
        }
        if( props.tipo === "simcards-que-nao-estao-faturanto-irao-faturar"){
            despacho(mudarFiltros({SIMCARDS_IRAM_FATURAR:item}))
            despacho(filtrosArrayBarra(["Simcards que irão faturar em "+ds+" dias?SIMCARDS_IRAM_FATURAR"]))
        }
        despacho(limpadorFiltros(false));
        despacho(revelarBarraFiltragem(true));
        const elemento = document.getElementById("link-mostrar-faturas");
        elemento.click();
    }

    async function handlerRendeizarItens(){
        let tempHtml = []
        if(props.tipo === "simcards-por-operadora"){
            const {data} = await api.get('/m1/consultar/fat_operadora_geral?cd_pav_usuario='+decryptJWT('codigo'))
            if(data.status == 200){
                await Promise.all(data.dados.map((item_data,i)=>{
                    tempHtml.push(
                        <li>
                            <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.cd_dsp_operadora,item_data.ds_apelido)}>
                                <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.ds_apelido}</span>
                            </div>
                            <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        </li>
                    )
                }))
            }
        }
        if(props.tipo === "simcards-por-plano"){
            const {data} = await api.get('/m1/consultar/fat_plano?cd_pav_usuario='+decryptJWT('codigo'))
            if(data.status == 200){
                await Promise.all(data.dados.map((item_data,i)=>{
                    tempHtml.push(
                        <li>
                            <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.cd_fat_plano,item_data.ds_plano)}>
                                <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.ds_plano}</span>
                            </div>
                            <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        </li>
                    )
                }))
            }
        }
        if(props.tipo === "simcards-por-status-ciclo-vida"){
            const {data} = await api.get('/m1/consultar/fat_ciclo_vida?cd_pav_usuario='+decryptJWT('codigo'))
            if(data.status == 200){
                await Promise.all(data.dados.map((item_data,i)=>{
                    tempHtml.push(
                        <li>
                            <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.CD_CICLO_VIDA,item_data.DS_STATUS)}>
                                <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.DS_STATUS}</span>
                            </div>
                            <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        </li>
                    )
                }))
            }
        }
        if(props.tipo === "simcards-que-nao-estao-faturanto-irao-faturar"){
            let tempConsulta = [
                    {
                        nome:30
                    },
                    {
                        nome:60
                    },
                    {
                        nome:90
                    },
                    {
                        nome:120
                    }]
            await Promise.all(tempConsulta.map((item_data,i)=>{
                tempHtml.push(
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.nome,item_data.nome)}>
                            <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.nome} dias</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                )
            }))
        }
        setArrayDados(tempHtml)
    }
    

    return(
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-2 menu-opcoes-triangulo"></div>
            <div className="menu-opcoes-corpo fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        { props.tipo === "simcards-por-operadora" || props.tipo === 'simcards-por-plano' || props.tipo === "simcards-por-status-ciclo-vida"
                            || props.tipo === "simcards-que-nao-estao-faturanto-irao-faturar"?
                            <>
                                <div className="drop-acoes-menu item-drop-acoes-hover" onClick={e => dropMenuOpcoes(e, "menu-mostrar-em-dispositivos", "imagem-menu-mostrar-em-dispositivos")}>
                                    <span className="drop-acoes-menu-label fonte-cor-1">{props.nomeMostrar}</span>
                                    <img id={"imagem-menu-mostrar-em-dispositivos"} className="menu-opcoes-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                                <ul id={"menu-mostrar-em-dispositivos"}  className="drop-acoes-lista-submenu dropdown-desativado">
                                    {arrayDados &&  
                                        arrayDados
                                    }
                                </ul>
                            </>
                            :
                            <>
                                <div className="drop-acoes-menu item-drop-acoes-hover">
                                    {/* <div className="drop-acoes-menu-label fonte-cor-1">Mostrar em dispositivos</div> */}
                                    <div onClick={()=> handlerModificarFiltro()} className="drop-acoes-menu-label fonte-cor-1"> 
                                        {props.nomeMostrar}
                                    </div>
                                </div>
                                <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                            </>
                        }
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => exportarImagemDashFaturas(props.exportar)}>Exportar imagem</span>
                        </div>
                        {/* <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div> */}
                    </li>
                </ul>
                <NavLink id="link-mostrar-faturas" className="d_nome" to={props.rota}/>
            </div>
        </Form>
    )
}

function RenderMenuOpcoesMostrarContestacaoOperadora(props){
    const modal = useRef();
    const [arrayDados, setArrayDados] = useState([])
    const despacho = useDispatch();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       if(props.revelarOpcoesContestacaoOperadora){
           props.setRevelarOpcoesContestacaoOperadora(!props.revelarOpcoesContestacaoOperadora)
       }
       if(props.revelarOpcoesValorContestacaoOperadora){
           props.setRevelarOpcoesValorContestacaoOperadora(!props.revelarOpcoesValorContestacaoOperadora)
       }
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        handlerRendeizarItens()
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handlerModificarFiltro(item,ds){
        despacho(mudarFiltros({CD_OPERADORA:item}))
        despacho(filtrosArrayBarra(["Operadora: "+ds+"?CD_OPERADORA"]))
        despacho(limpadorFiltros(false));
        despacho(revelarBarraFiltragem(true));
        const elemento = document.getElementById("link-mostrar-faturas");
        elemento.click();
    }

    async function handlerRendeizarItens(){
        let tempHtml = []
        const {data} = await api.get('/m1/consultar/fat_operadora_geral?cd_pav_usuario='+decryptJWT('codigo'))
        if(data.status == 200){
            await Promise.all(data.dados.map((item_data,i)=>{
                tempHtml.push(
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.cd_dsp_operadora,item_data.ds_apelido)}>
                            <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.ds_apelido}</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                )
            }))
        }
        setArrayDados(tempHtml)
    }


    function handlerAlterarFiltro(value){
        props.setFaturaQtdMesStatusContestacaoOperadora(value)
        props.setFaturaUtlimoCicloStatusContestacaoOperadora(false)
    }
    function handlerAlterarFiltroUltimo(value){
        props.setFaturaUtlimoCicloStatusContestacaoOperadora(value)
        props.setFaturaQtdMesStatusContestacaoOperadora(false)
    }

    return(
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-2 menu-opcoes-triangulo"></div>
            <div className="menu-opcoes-corpo fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={e => dropMenuOpcoes(e, "menu-mostrar-em-dispositivos", "imagem-menu-mostrar-em-dispositivos")}>
                            <span className="drop-acoes-menu-label fonte-cor-1">Mostrar em contestação</span>
                            <img id={"imagem-menu-mostrar-em-dispositivos"} className="menu-opcoes-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        <ul id={"menu-mostrar-em-dispositivos"}  className="drop-acoes-lista-submenu dropdown-desativado">
                            {arrayDados &&  
                                arrayDados
                            }
                        </ul>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => exportarImagemDashFaturas(props.exportar)}>Exportar imagem</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(0)}>Ciclo atual</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltroUltimo(1)}> Último ciclo</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(2)}>Trimestre</span>
                        </div>  
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(5)}>Semestre</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(11)}>Anual</span>
                        </div>
                    </li>
                </ul>
                <NavLink id="link-mostrar-faturas" className="d_nome" to="/veye/faturas/contestacao"/>
            </div>
        </Form>
    )
}
function RenderMenuOpcoesMostrarContestacaoOperadoraValor(props){
    const modal = useRef();
    const [arrayDados, setArrayDados] = useState([])
    const despacho = useDispatch();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar(){
       if(props.revelarOpcoesContestacaoOperadora){
           props.setRevelarOpcoesContestacaoOperadora(!props.revelarOpcoesContestacaoOperadora)
       }
       if(props.revelarOpcoesValorContestacaoOperadora){
           props.setRevelarOpcoesValorContestacaoOperadora(!props.revelarOpcoesValorContestacaoOperadora)
       }
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])
    
    function handlerAlterarFiltro(value){
        props.setFaturaQtdMesStatusContestacaoOperadoraValor(value)
        props.setFaturaUtlimoCicloStatusContestacaoOperadoraValor(false)
    }
    function handlerAlterarFiltroUltimo(value){
        props.setFaturaUtlimoCicloStatusContestacaoOperadoraValor(value)
        props.setFaturaQtdMesStatusContestacaoOperadoraValor(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        handlerRendeizarItens()
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handlerModificarFiltro(item,ds){
        despacho(mudarFiltros({CD_OPERADORA:item}))
        despacho(filtrosArrayBarra(["Operadora: "+ds+"?CD_OPERADORA"]))
        despacho(limpadorFiltros(false));
        despacho(revelarBarraFiltragem(true));
        const elemento = document.getElementById("link-mostrar-faturas");
        elemento.click();
    }

    async function handlerRendeizarItens(){
        let tempHtml = []
        const {data} = await api.get('/m1/consultar/fat_operadora_geral?cd_pav_usuario='+decryptJWT('codigo'))
        if(data.status == 200){
            await Promise.all(data.dados.map((item_data,i)=>{
                tempHtml.push(
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={()=> handlerModificarFiltro(item_data.cd_dsp_operadora,item_data.ds_apelido)}>
                            <span  className="menu-opcoes-sub-label fonte-cor-1">{item_data.ds_apelido}</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                )
            }))
        }
        setArrayDados(tempHtml)
    }

    return(
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-2 menu-opcoes-triangulo"></div>
            <div className="menu-opcoes-corpo fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={e => dropMenuOpcoes(e, "menu-mostrar-em-dispositivos", "imagem-menu-mostrar-em-dispositivos")}>
                            <span className="drop-acoes-menu-label fonte-cor-1">Mostrar em contestação</span>
                            <img id={"imagem-menu-mostrar-em-dispositivos"} className="menu-opcoes-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                        <ul id={"menu-mostrar-em-dispositivos"}  className="drop-acoes-lista-submenu dropdown-desativado">
                            {arrayDados &&  
                                arrayDados
                            }
                        </ul>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => exportarImagemDashFaturas(props.exportar)}>Exportar imagem</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(0)}>Ciclo atual</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltroUltimo(1)}> Último ciclo</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(2)}>Trimestre</span>
                        </div>  
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(5)}>Semestre</span>
                        </div>
                        <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>
                    </li>
                    <li>
                        <div className="drop-acoes-menu item-drop-acoes-hover">
                            <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => handlerAlterarFiltro(11)}>Anual</span>
                        </div>
                    </li>
                </ul>
                <NavLink id="link-mostrar-faturas" className="d_nome" to="/veye/faturas/contestacao"/>
            </div>
        </Form>
    )
}
function dropMenuOpcoes(event, elementoId,imagemId){
    let elemento = document.getElementById(elementoId);
    let imagem = document.getElementById(imagemId);

    if(imagem.classList.contains("menu-opcoes-icone-desativado")){
        imagem.classList.remove("menu-opcoes-icone-desativado")
        imagem.classList.add("menu-opcoes-icone-ativado")
    }else if(imagem.classList.contains("menu-opcoes-icone-ativado")){
        imagem.classList.remove("menu-opcoes-icone-ativado")
        imagem.classList.add("menu-opcoes-icone-desativado")
    }

    if(elemento.classList.contains("dropdown-desativado")){
        elemento.classList.remove("dropdown-desativado")
        elemento.classList.add("dropdown-ativado")
    }else if(elemento.classList.contains("dropdown-ativado")){
        elemento.classList.remove("dropdown-ativado")
        elemento.classList.add("dropdown-desativado")
    }
}

export default connect(mapStateToProps)(Dasboard);

