//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectMultiFatPais from '../../componentesModais/selects/SelectMultiFatPais'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectMultiFatPais, setRevelarSelectMultiFatPais] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">Pais</div>
            </Form.Label>
            { revelarSelectMultiFatPais === false &&
                <Button name="cdFatPais" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectMultiFatPais(!revelarSelectMultiFatPais)}>
                    <div className="fonte-cor-1 label-campo">{dsFatPais}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectMultiFatPais === true &&
                <SelectMultiFatPais
                    setRevelarSelectMultiFatPais={setRevelarSelectMultiFatPais} //Hook para fechar a modal
                    setCdFatPais={setCdFatPais} //Código do FatPais
                    setDsFatPais={setDsFatPais} //Nome do FatPais
                    dsFatPais={dsFatPais} 
                    phFatPais={phFatPais} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectMultiFatPais>
            }
        </Form.Group>*/
  
export default function SelectMultiPais(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsPais, setLsPais] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();
    let todosUsuarios = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handlePais(codigo, checkbox){
        if(codigo == -1){
            handleSelecionarTodos(checkbox)
        }
        else{
            if(checkbox === true){
                props.arrayPais.push(codigo)
            }else{
                props.arrayPais.forEach(function(e, index){
                    if(codigo == e){
                        props.arrayPais.splice(index, 1);
                    }
                })
            }
        }
        
    }

    function handleSelecionarTodos(checked){
        const length = props.arrayPais.length
        let i =0
        while(i < length){
            props.arrayPais.pop()
            i++
        }
  
        if(checked === true){
            todosUsuarios.forEach(function(pais,i){
                props.arrayPais.push({'cd_pav_pais' : pais.cd_pav_pais})
        })
       }

    }

    function handleCancelar(){
        props.setRevelarSelectMultiPais(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderPais(){
            const {data} = await api.get('/m1/consultar/pais');
            data.dados.unshift({"cd_pav_pais":-1,"ds_pav_pais":"Todos"})
            todosUsuarios=data.dados
            
            if(data.dados.length > 0){
                let objetoPais = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_pav_pais}>
                            <div className="campo-select-opcao-checkbox campo-select-opcao-2 campo-select-opcao-automacao">
                                <label class="container-checkbox-item-select">
                                    <input id={item.cd_pav_pais} type="checkbox" defaultChecked={handleMarcarCheckboxes(item.cd_pav_pais) ? true : false} onChange={e => handlePais(item.cd_pav_pais, e.target.checked)}/>
                                    <span class="chekmark-checkbox-item-select"></span>
                                </label>
                                <span className="fonte-cor-1 nao-selecionavel">{item.ds_pav_pais}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsPais(objetoPais)
            }else{
                let objetoPais = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsPais(objetoPais)
            }
            setSpinAtivo(false)
        }
        renderPais()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handleMarcarCheckboxes(codigo){
        let verificacao = false
        props.arrayPais.forEach(function(e, index){

            if(codigo == e){

                verificacao = true
            }
        })
        return verificacao
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select campo-select-absoluto" onClick={() => handleCancelar()}>
                <div className="fonte-cor-1 label-campo">{props.dsPais}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo triangulo-select-absoluto"></div>
            <div className="campo-texto-cor-3 campo-select-usuarios-checkbox-corpo">
                <ul className="campo-select-lista">
                    {lsPais}
                </ul>
            </div>
        </Form>
    )
}