//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { api } from '../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import { Image, Button } from 'react-bootstrap';/*eslint-disable*/
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
//IMPORTAÇÕES ICONES
import { setaDropdown } from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarDoppelgangerCliente, visualizarDadosRefresh, mudarOrdenador, mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, visualizarDados, mudarFiltros, revelarBarraFiltragem, realizouFiltro, revelarExcluirEmpresa } from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
// import DetalhamentoCliente from '../../painelVirtueyes/detalhamentoCliente/detalhamentoCliente'
import DetalhamentoEmpresa from './detalhamento/detalhamentoEmpresa'
import Cadastro from './acoes/cadastro'

import PaginacaoTabela from '../../../utils/paginacaoTabela'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import decryptJWT from '../../../utils/decryptJWT'
//ESTILIZAÇÃO
import '../../../css/painelFaturas/fatEmpresas/tabela.css'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaClientesVirtueyes(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);  
    const [noCdCliente, setNoCdCliente] = useState(-1);
    const [cdEmpresa, setCdEmpresa] = useState(-1);
    const [emailEmpresa, setEmailEmpresa] = useState('')
    const [telefone, setTelefone] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [cepEmpresa, setCepEmpresa] = useState('')
    const [complemento, setComplemento] = useState('')
    const [enderecoEmpresa, setEnderecoEmpresa] = useState('')
    const [numero, setNumero] = useState('')
    const [bairroEmpresa, setBairroEmpresa] = useState('')
    const [cidadeEmpresa, setCidadeEmpresa] = useState('')
    const [ufEmpresa, setUfEmpresa] = useState('')
    const [informacoesAdicionais, setInformacoesAdicionais] = useState('')
    const [cdEndereco, setCdEndereco] = useState()
    const [cdLogradouro, setCdLogradouro] = useState()
    const [cdBairro, setCdBairro] = useState()
    const [cdCidade, setCdCidade] = useState()
    const [cdUf, setCdUf] = useState()
    const [cdCep, setCdCep] = useState()
    const [mensagemErro, setMensagemErro] = useState('')
    

    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    let itensVar = []

    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let estadoVisualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let novaEmpresa =  useSelector(state => state.faturasState.revelarNovaEmpresa);
    let revelarEditarEmpresa =  useSelector(state => state.faturasState.revelarEditarEmpresa);
    let excluirEmpresa = useSelector(state => state.faturasState.revelarExcluirEmpresa);
    let ordenador = useSelector(state => state.manejadorState.ordenador);

    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    const despacho = useDispatch();
    const cd_pav_usuario = decryptJWT('codigo')
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(649) // 'fatEmpresasTabela' 
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("fatEmpresa"));
        despacho(desabilitarAcoes(true));
        
        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario
                    const { data } = await api.get('/m1/consultar/fat_empresa?'+queryString);
                    await contarItens();
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    ordenador: ordenador,
                    filtros: filtros,
                    cd_pav_usuario: cd_pav_usuario
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fat_empresa", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario
                    const { data } = await api.get('/m1/consultar/fat_empresa?'+queryString);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
        contarItens()

    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get('/m1/consultar/fat_empresa?contar=true&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario);
        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                ordenador: ordenador,
                filtros: filtros,
                contar: true,
                cd_pav_usuario: cd_pav_usuario
            }
            apiData = await api.post("/m1/filtrar/fat_empresa", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu===true?"expand-row-tabela-clientes-virtueyes-1":"expand-row-tabela-clientes-virtueyes-2"}>
                <DetalhamentoEmpresa 
                    dsLogradouro={row.ds_pav_logradouro}
                    dsNumero={row.n0_numero}
                    dsBairro={row.ds_pav_bairro}
                    dsCidade={row.ds_pav_cidade}
                    dsUf={row.ch_sigla}
                    dsCep={row.ds_pav_cep}
                    dsEmail={row.ds_email}
                />
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    const colunas = [
        {
            dataField: 'ds_nome_fantasia',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_nome_fantasia",'ds_nome_fantasia')}>
            Nome fantasia
            <Image id="ds_nome_fantasiads_nome_fantasia" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'ds_razao_social',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_razao_social",'ds_razao_social')}>
            Razão social
            <Image id="ds_razao_socialds_razao_social" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_cnpj',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_cnpj",'ds_cnpj')}>
            CNPJ
            <Image id="ds_cnpjds_cnpj" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_telefone',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_telefone",'ds_telefone')}>
            Telefone
            <Image id="ds_telefoneds_telefone" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_email',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_email",'ds_email')}>
            E-mail
            <Image id="ds_emailds_email" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : row.ds_cnpj}</p>;
            }
        },
        {
            dataField: 'n0_simcards',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_simcards",'n0_simcards')}>
            Número de SIM cards
            <Image id="n0_simcardsn0_simcards" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarCliente(
                    row.cd_fat_empresa, 
                    row.ds_cnpj,
                    row.ds_telefone,
                    row.ds_nome_fantasia,
                    row.n0_numero,
                    row.ds_razao_social,
                    row.ds_pav_bairro,
                    row.ds_email,
                    row.ds_pav_logradouro,
                    row.ds_observacoes,
                    row.ds_pav_cidade,
                    row.ds_pav_cep,
                    row.ch_sigla,
                    row.ds_complemento,
                    row.cd_pav_endereco,
                    row.cd_pav_logradouro,
                    row.cd_pav_bairro,
                    row.cd_pav_cidade,
                    row.cd_pav_uf,
                    row.cd_pav_cep
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarCliente(codigo, cnpj, telefone, nomeFantasia, numero, razaoSocial, bairro,
    emailEmpresa, endereco, informacoesAdicionais, cidade, cep, uf, complemento, 
    cdEndereco, cdLogradouro, cdBairro, cdCidade, cdUf, cdCep){
        setCdEmpresa(codigo)
        setCnpjEmpresa(cnpj)
        setTelefone(telefone)
        setNomeFantasia(nomeFantasia)
        setNumero(numero)
        setRazaoSocial(razaoSocial)
        setCepEmpresa(cep)
        setEnderecoEmpresa(endereco)
        setBairroEmpresa(bairro)
        setCidadeEmpresa(cidade)
        setInformacoesAdicionais(informacoesAdicionais)
        setUfEmpresa(uf)
        setEmailEmpresa(emailEmpresa)
        setComplemento(complemento)
        setCdEndereco(cdEndereco)
        setCdLogradouro(cdLogradouro)
        setCdBairro(cdBairro)
        setCdCidade(cdCidade)
        setCdUf(cdUf)
        setCdCep(cdCep)
        despacho(desabilitarAcoes(false));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNoCdCliente(row.n0_codigo_cliente)
            setCdEmpresa(row.codigo);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1)
    }

    async function handleDeletar(){
        ColetaClickstream(1451) // CadastrosEmpresasDropAcoesExcluir
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_empresa?cd_fat_empresa='+cdEmpresa+'&cd_pav_usuario='+cd_pav_usuario)
        .then(function (response) {
            if(response.data.status === 600){
                setMensagemErro(response.data.info)
                despacho(revelarExcluirEmpresa(false))
                setRevelarMensagemAtencao(false)
                handleMensagemErro(false)
                setSpinAtivo(false)
            }else{
                despacho(revelarExcluirEmpresa(false))
                setRevelarMensagemAtencao(false)
                handleMensagemSucesso(false)
                setRenderizar(!renderizar)
                setSpinAtivo(false)
            }
        })
        .catch(function (error) {
            setMensagemErro("Erro ao excluir empresa!")
            despacho(revelarExcluirEmpresa(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(excluirEmpresa === true){
            setRevelarMensagemAtencao(true)
        }
    },[excluirEmpresa])

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDoppelgangerCliente(false))
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar))
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                setRevelarMensagemAtencao(false)
                handleDeletar()
            }
        }
    }

    return(
        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"A empresa "+razaoSocial+" foi deletada."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }

            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }

            { revelarMensagemAtencao === true &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+nomeFantasia}
                />
            }

            {novaEmpresa === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-historico-acesso-container">
                        <Cadastro
                            tituloJanela="Cadastrar"
                            codigo_cliente={noCdCliente}
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }

            {revelarEditarEmpresa === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-adicionar-login-container">
                        <Cadastro
                            tituloJanela={'Editar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdEmpresa={cdEmpresa}
                            dsNomeFantasia={nomeFantasia}
                            dsRazaoSocial={razaoSocial}
                            dsCnpj={cnpjEmpresa}
                            dsEmail={emailEmpresa}
                            dsTelefone={telefone}
                            dsCep={cepEmpresa}
                            cdCep={cdCep}
                            dsLogradouro={enderecoEmpresa}
                            cdLogradouro={cdLogradouro}
                            dsNumero={numero}
                            dsBairro={bairroEmpresa}
                            cdBairro={cdBairro}
                            dsCidade={cidadeEmpresa}
                            cdCidade={cdCidade}
                            dsUf={ufEmpresa}
                            cdUf={cdUf}
                            cdEndereco={cdEndereco}
                            dsInformacoesAdicionais={informacoesAdicionais}
                            dsComplemento={complemento}
                        />
                    </div>
                </div>
            }

            {estadoVisualizarDados === true &&
                <BarraDadosCliente/>
            }

            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }

            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_fat_empresa' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                expandRow={itemExpandido}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesVirtueyes));