//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';/*eslint-disable*/
import {Card, Button, Form, ButtonToolbar, Image, OverlayTrigger, Tooltip} from 'react-bootstrap';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faAsterisk} from '@fortawesome/free-solid-svg-icons';
import {lixeiraVermelho, editarVerde, passarPaguinaDireita, passarPaguinaEsquerda} from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador } from "../../../redux/actions/index";

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Grid(props){
  const [cdTipoPessoa, setCdTipoPessoa] = useState("");
  const [dsTipoPessoa, setDsTipoPessoa] = useState("");
  const [dsDescricao, setDsDescricao] = useState("");
  const [itens, setItens] = useState("");
  const [mostrarAviso, setMostrarAviso] = useState(false)// eslint-disable-next-line
  const [mostrarConfirmacaoCadastro, setMostrarConfirmacaoCadastro] = useState(false);
  const [mostrarConfirmacaoAlteracao, setMostrarConfirmacaoAlteracao] = useState(false);
  const [mostrarConfirmacaoDelecao, setMostrarConfirmacaoDelecao] = useState(false);
  const [edicao, setEdicao] = useState(true);// eslint-disable-next-line
  const [renderizar, setRenderizar] = useState(false);// eslint-disable-next-line
  const [paginacao, setPaginacao] = useState(1);// eslint-disable-next-line
  const [esconder, setEsconder] = useState(true)
  const {register, handleSubmit, errors} = useForm();// eslint-disable-next-line
  const [cdTela, setCdTela] = useState()
  const [lsTela, setLsTela] = useState()
  
  const [valorLimite, setValorLimite] = useState(50);

  const [carregando, setCarregando] = useState(true);  
  const [paginaAtual, setPaginaAtual] = useState(1);  
  // a funcao funcaoMostrarAviso/toast, seta o setMostrarConfirmacao e o (!) inverte ou nega a variavel, no caso se mostrarConfirmacao for true, ele com o ! (!mostrarConfirmacao) vira false;
  const funcaoMostrarAviso = () => setMostrarAviso(!mostrarAviso);
  const funcaoMostrarConfirmacaoCadastro = () => setMostrarConfirmacaoCadastro(!mostrarConfirmacaoCadastro);
  const funcaoMostrarConfirmacaoAlteracao = () => setMostrarConfirmacaoAlteracao(!mostrarConfirmacaoAlteracao);
  const funcaoMostrarConfirmacaoDelecao = () => setMostrarConfirmacaoDelecao(!mostrarConfirmacaoDelecao);
  const funcaoApagarEsconder = () => {deletar(); setMostrarAviso(!mostrarAviso); setMostrarConfirmacaoDelecao(!mostrarConfirmacaoDelecao)};
  // a funcaoo funcaoApagarEsconder, faz a consulta no banco atraves do deletar(), e depois inverte os valores das variaveis
  //HOOKS REDUX
  const despacho = useDispatch();
  let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
  let filtros = useSelector(state => state.filtrosState.filtros);
  let temFiltros = false;

  let itensVar = []
  

  useEffect(() => {
    despacho(mudarManejador("tipoPessoa"))
    async function carregarTabela(){
      if(!carregando) setCarregando(true);
      if(conteudoBusca  && !temFiltros){
          if(totalPaginas == 0){
            contador = 0;
            itensVar = []
            setItens([])
            await contarItens();
          }
          else{
            contador = (paginaAtual - 1) * valorLimite;
          }
          if (controladorPagina <= paginaAtual) {
            const { data } = await api.get('/m1/consultar/tipoPessoa?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
            inserirData(data.dados)
          }
      }
      else if(temFiltros){
        if(totalPaginas == 0){
          contador = 0;
          itensVar = [];
          setItens([])
          await contarItens();
        }
        else{
          contador = (paginaAtual - 1) * valorLimite;
        }
        const dados = {
          paguinaAtual: contador,
          carregarLimit: valorLimite,
          filtros: filtros
        }
        if (controladorPagina <= paginaAtual) {
          const { data } = await api.post("/m1/filtrar/tipoPessoa", dados);
          inserirData(data.dados)
        }
      }
      else{
        if(totalPaginas == 0){
          await contarItens();
        }
        else{
          contador = (paginaAtual - 1) * valorLimite;
        }
        if (controladorPagina <= paginaAtual) {
          const { data } = await api.get('/m1/consultar/tipoPessoa?paginaAtual='+contador+'&carregarLimit='+valorLimite);
          inserirData(data.dados)
        }
      }

      setCarregando(false);
    }
    
    carregarTabela();
  }, [paginaAtual, conteudoBusca, renderizar])

  
  function inserirData(data){
    for(let i=0;i<totalItens;i++){
      if (contador === i){
        let k = i
        for(let j=0;j<data.length;j++){
          itensVar[k] = data[j]
          k++
        }
      }
      if(i == (totalItens-1)){
        setItens(JSON.parse(JSON.stringify(itensVar)))
      }
    }
  }
  
  async function contarItens(){
    let arrayItens = []
    if (controladorPagina <= paginaAtual) { 
      const { data } = await api.get('/m1/contar/pav_tipo_pessoa');
        totalPaginas = Math.ceil(data.dados[0].CONTADOR / valorLimite)
        totalItens = data.dados[0].CONTADOR;
      for( var i = 0 ; i < data.dados[0].CONTADOR; i++){
        arrayItens.push(i)
      }
      itensVar = arrayItens
    }
  }

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-navegacao-grupo" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn-paginacao ${isSelect ? 'btn-paginacao-ativo' : 'btn-paginacao-inativo'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  async function resolve(){
    let {data} = await api.get('/m1/consultar/elemento');
    setItens(data.dados);
  }  

  async function lista(){
    let {data} = await api.get('/m1/consultar/tela');
    let telas = data.dados.map((item) => {
        return (
        <option value={item.cd_pav_tela} onClick={e => setCdTela(e.target.value)}>
            {item.ds_pav_tela}
        </option>
        );
    })
    setLsTela(telas)
  }


  async function resolve(){
    let {data} = await api.get('/m1/consultar/tipoPessoa');
    setItens(data.dados);
  }  

  function alterarCadastro(){
    setCdTipoPessoa("");
    setDsTipoPessoa("");
    setDsDescricao("");
    setEdicao(true)
    setEsconder(false)
  }

  function alterarEdicao(){
    setEdicao(false)
    setEsconder(false)
  }

  function cancelar(){
    setEdicao(true)
    setEsconder(true)
  }
  
  const colunas = [
    {
        dataField: 'cd_pav_tipo_pessoa',
        text: 'Código',
        sort: true
    },
    {
        dataField: 'ds_pav_tipo_pessoa',
        text: 'Tipo de Pessoa',
        sort: true
    },
    {
        dataField: 'ds_descricao',
        text: 'Descrição',
        sort: true
    },
    {
        dataField: '',
        text:colunaAcoes(),
        formatter: botaoEditarDeletar
    }
  ]
     
  const eventosLinhas = {
    onClick: (e, row, rowIndex) => {
      if (edicao) {
        setCdTipoPessoa(row.cd_pav_tipo_pessoa);
        setDsTipoPessoa(row.ds_pav_tipo_pessoa);
        setDsDescricao(row.ds_descricao);
      }
    }
  }
        
  const opcoesPaginacao = {
    page: paginaAtual,
    showTotal: true,
    sizePerPage: valorLimite,
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Última',
    nextPageTitle: 'Proxima',
    prePageTitle: 'Anterior',
    custom: true,
    onPageChange:(page) => {setPaginaAtual(page)}
  }

  function paginaSeguinte ({page, onPageChange}) {
    if(totalPaginas !== paginaAtual){
      onPageChange(page + 1);
    }
  }

  function paginaAnterior ({page, onPageChange}){
    if(paginaAtual !== 1)
      onPageChange(page - 1);
  }


  function colunaAcoes(){
    return(
      <OverlayTrigger  placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Adicionar Tipo de Pessoa</Tooltip>}>
        <p className="acoes">Ações <Button variant="outline-dark" className="bt-grid-criar" size="sm" onClick={alterarCadastro}> <FontAwesomeIcon icon={faPlus}/></Button>
        </p>
      </OverlayTrigger>
    );
  }

  function botaoEditarDeletar(cell, row) {
    return (
      <div id="BtGridAcoes">
        <OverlayTrigger  placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Alterar Tipo de Pessoa</Tooltip>}>
          <Button variant="outline-primary" id="bt-grid-editar" className="bt-grid-editar" size="sm" onClick={alterarEdicao}>
            <img className="icone-botao-acao-tabela" src={editarVerde()} alt="editar-linha"/>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger  placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Deletar Tipo de Pessoa</Tooltip>}>
          <Button variant="outline-danger" id="bt-grid-deletar" className="bt-grid-deletar" size="sm" onClick={funcaoMostrarAviso}>
            <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
          </Button>
        </OverlayTrigger>
      </div>
    );
  }
  
  async function submitDados(){
    if(edicao){
      await cadastrarAlteracao();
    }else{
      await cadastrarNovo();
    }
  }

  async function cadastrarNovo() {
    await api.post('/m1/cadastrar/tipoPessoa', {
        'ds_pav_tipo_pessoa': dsTipoPessoa.trim(),
        'ds_descricao': dsDescricao.trim()
    })
    .then(function (response) {
        funcaoMostrarConfirmacaoCadastro() 
        setRenderizar(!renderizar)     
    })
    .catch(function (error) {
    })
  }

  async function cadastrarAlteracao() {
    await api.post('/m1/alterar/tipoPessoa', {
        'cd_pav_tipo_pessoa': cdTipoPessoa,
        'ds_pav_tipo_pessoa': dsTipoPessoa.trim(),
        'ds_descricao': dsDescricao.trim()
    })
    .then(function (response) {
        funcaoMostrarConfirmacaoAlteracao()  
        setRenderizar(!renderizar)     
    })
    .catch(function (error) {
    })
  }

  async function deletar(){
    await api.get('/m1/deletar/tipoPessoa?cd_pav_tipo_pessoa='+cdTipoPessoa)
    .then(function (response) {
      funcaoMostrarConfirmacaoDelecao()
      setRenderizar(!renderizar)
    })
    .catch(function (error) {
    })
  }
  
  function confirmarCadastro(){
    funcaoMostrarConfirmacaoCadastro()
    document.getElementById("bt-cancelar-cadastro").click()
  }

  function confirmarAlteracao(){
    funcaoMostrarConfirmacaoAlteracao()
    document.getElementById("bt-cancelar-cadastro").click()
  }

  return(
        <div className='container-tela'>
          <Card id="card-tela-principal" className="card-tela-principal">
            { mostrarAviso &&
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Confirmar"
                confirmBtnBsStyle="danger"
                cancelBtnText="Cancelar"    
                cancelBtnBsStyle="default"
                title="Tem certeza que você deseja excluir este registro?"
                onConfirm={funcaoApagarEsconder}
                onCancel={funcaoMostrarAviso}
                focusCancelBtn
              > 
              </SweetAlert>
            }

            { mostrarConfirmacaoDelecao &&
              <SweetAlert
                success
                title="Registro apagado!"
                onConfirm={funcaoMostrarConfirmacaoDelecao}>
              </SweetAlert>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <>
                    <BootstrapTable
                      hover={true}
                      condensed={true}
                      keyField='ID' 
                      data={itens} 
                      columns={colunas} 
                      rowEvents={eventosLinhas}
                      bootstrap4={true}
                      bordered={false}
                      noDataIndication={carregando ? "carregando..." : "Nenhum item encontrado"}
                      { ...paginationTableProps }
                    />
                    <div className="btn-group" >
                      <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(1) }>
                        <h5>Primeira</h5>
                      </button>
                      <button className="botao-paginacao-tabela" onClick={ () => paginaAnterior(paginationProps)}>
                        <img  src={passarPaguinaEsquerda()} alt="proxima paguina"/>
                      </button>

                      
                        {botoesPaginacaoGrande(paginationProps)}


                      <button className="botao-paginacao-tabela" onClick={ () => paginaSeguinte(paginationProps)}>
                        <img  src={passarPaguinaDireita()} alt="proxima paguina"/>
                      </button>
                      <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(totalPaginas) }>
                        <h5>Ultima</h5>
                      </button>
                    </div>

                  </>
                )
              }
            </PaginationProvider>
            <div id="menu-cadastro" hidden={esconder}>
              <Form>
                <Card id="card-cadastro" className="card-cadastro">
                  { mostrarConfirmacaoCadastro &&
                    <SweetAlert
                      success
                      title="Tela cadastrado!"
                      onConfirm={confirmarCadastro}>
                    </SweetAlert>
                  }
                  { mostrarConfirmacaoAlteracao &&
                    <SweetAlert
                      success
                      title="Registro Alterado!"
                      onConfirm={confirmarAlteracao}>
                    </SweetAlert>
                  }
                  <Card.Body id="card-cadastro-corpo" className="card-cadastro-corpo">
                  <Card.Title hidden={!edicao} disabled={!edicao} id="titulo-cadastro-novo" className="titulo-cadastro">Novo Tipo de Pessoa</Card.Title>
                  <Card.Title hidden={edicao} disabled={edicao} id="titulo-cadastro-alterar" className="titulo-cadastro">Alterar Tipo de Pessoa</Card.Title>
                  <form onSubmit={handleSubmit(submitDados)}>
                    <Form.Group className="form-group-edicao">
                      <Form.Label id="label-cadastro-nome" className="label-cadastro"><br/>Nome <FontAwesomeIcon id="icone-cadastro-asterisco" className="icone-cadastro" icon={faAsterisk} /></Form.Label>
                      <Form.Control name="ds_nome" type="text" id="campo-texto-nome" className="campo-texto-cadastro" ref={register({ required: true, min: 3, max: 50 })} placeholder={dsTipoPessoa} value={dsTipoPessoa} onChange={e => setDsTipoPessoa(e.target.value)}/>
                        {errors.ds_nome && <div className="icone-exclamacao-erro"><Image src={"/icones/exclamacao.svg"}/> <p className="text-danger">Informe o tipo de pessoa</p></div>}
                      <Form.Label id="label-cadastro-descricao" className="label-cadastro"><br/>Descrição</Form.Label>
                      <Form.Control as="textarea" row='4' id="campo-texto-descricao-cadastro" className="campo-texto-cadastro" ref={register({ maxLength: 500 })} placeholder={dsDescricao} value={dsDescricao} onChange={e => setDsDescricao(e.target.value)}/>
                      <ButtonToolbar id="barra-botoes-cadastro" className="barra-botoes-cadastro">
                        <Button id="bt-cancelar-cadastro" className="bt-cancelar-cadastro" variant="danger" size="sm" onClick={cancelar}> Cancelar </Button>
                        <Button type="submit" id="bt-cadastro-cadastro-novo" className="bt-cadastrar-cadastro" variant="primary" size="sm"> Enviar </Button>
                      </ButtonToolbar>
                    </Form.Group>
                  </form>
                  </Card.Body>
                </Card>
              </Form>
            </div>
          </Card>
        </div>
  )
  function botoesPaginacaoGrande(){
    if(totalItens > (valorLimite * 2)){
        return (
            <>
                {paginaAtual === totalPaginas &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual - 2) }>
                        { paginaAtual - 2 }
                    </button> 
                }
                {paginaAtual >=2 &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual - 1) }>
                        { paginaAtual - 1 }
                    </button>
                }
                <button className="botao-paginacao-tabela" >
                    { paginaAtual }
                </button>
                {paginaAtual !== totalPaginas &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual + 1) }>
                        { paginaAtual + 1 }
                    </button> 
                }
                {paginaAtual <=1 && totalPaginas > 3 &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual + 2) }>
                        { paginaAtual + 2 }
                    </button>
                }
                {paginaAtual !== totalPaginas && paginaAtual !== totalPaginas-1 && paginaAtual !== totalPaginas-2 && paginaAtual !== totalPaginas-3 &&
                    <button className="botao-paginacao-tabela">
                        . . .
                    </button> 
                }
                { paginaAtual !== totalPaginas && paginaAtual !== totalPaginas -1 && paginaAtual !== totalPaginas -2 &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(totalPaginas - 1)}>
                        { totalPaginas - 1 }
                    </button> 
                }
                { paginaAtual !== totalPaginas && paginaAtual !== totalPaginas -1 &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(totalPaginas)}>
                        { totalPaginas }
                    </button>
                }
            </>
        )
    }
    else{
        return (
            <>
                {paginaAtual >=2 &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual - 1) }>
                        { paginaAtual - 1 }
                    </button>
                }
                <button className="botao-paginacao-tabela" >
                    { paginaAtual }
                </button>
                {paginaAtual < 2 && totalPaginas > 1 &&
                    <button className="botao-paginacao-tabela" onClick={ () => setPaginaAtual(paginaAtual + 1) }>
                        { paginaAtual + 1 }
                    </button>
                }
            </>
        )
    }
  }
}

function mapStateToProps(state) {
  return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 

export default connect(mapStateToProps)(Grid);