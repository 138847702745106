//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';
import { useForm } from "react-hook-form";/*eslint-disable*/
import { Button, Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import SelectGenerico from '../../componentesModais/selects/selectGenerico'
//ICONES
import {elipseVerde, fecharJanela, olhoDesligado, olhoLigado, exclamacao, setaDropdown} from '../../../utils/alternanciaIcones'
//MODAIS
// import SelectPerfil from '../../componentesModais/selects/selectPerfil'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavUsuario/pavUsuario.css'

import decryptJWT from '../../../utils/decryptJWT'

import { useDispatch } from 'react-redux';
import { revelarNovoUsuario, revelarEditarUsuario } from "../../../redux/actions/index";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let arrayClientes = []

export default function CadastroUsuario(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [cdCliente, setCdCliente] = useState(null);
    const [dsCliente, setDsCliente] = useState('Selecione');
    const [cdUsuario, setCdUsuario] = useState('');
    const [dsUsuario, setDsUsuario] = useState('');
    const [dsEmail, setDsEmail] = useState('');
    const [dsSenha, setDsSenha] = useState('');
    const [dsConfirmacao, setDsConfirmacao] = useState('');
    const [dsSobrenome, setDsSobrenome] = useState('');
    const [dsTelefone, setDsTelefone] = useState('')
    const [dsCpf, setDsCpf] = useState('')
    const [flAtivo, setFlAtivo] = useState(1)
    //HOOKS PLACEHOLDERS
    const [phCliente, setPhCliente] = useState('');
    const [phUsuario, setPhUsuario] = useState('');
    const [phEmail, setPhEmail] = useState('');
    const [phSenha, setPhSenha] = useState('*****');
    const [phConfirmacao, setPhConfirmacao] = useState('*****');
    const [phMensagemSucesso, setPhMensagemSucesso] = useState('cadastrado')
    const [phSobrenome, setPhSobrenome] = useState('');
    const [phTelefone, setPhTelefone] = useState('')
    const [phCpf, setPhCpf] = useState('')
    const [valorLimite, setValorLimite] = useState(1000)
    //HOOKS MODAIS
    const [revelarSelectCliente, setRevelarSelectCliente] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    const [dummy, setDummy] = useState(null);
    const [tituloJanela, setTituloJanela] = useState('Cadastro');
    const [icones, setIcones] = useState(true);
    const [iconesConfirma, setIconesConfirma] = useState(true);
    const [exibirSenha, setExibirSenha] = useState('password');
    const [exibirSenhaConfirmacao, setExibirSenhaConfirmacao] = useState('password');
    const [clickFora, setClickFora] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [valorCampo, setValorCampo] = useState();
    const modal = useRef();
    const despacho = useDispatch();
    const [selected, setSelected] = useState()

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);
    
    useEffect(() => {
        setSpinAtivo(true)
        if (props.tituloJanela === 'Editar'){
            //TROCA O TITULO DA JANELA
            setTituloJanela('Editar')
            //CARREGA OS CAMPOS COM OS VALORES ORIGINAIS
            setCdCliente(props.cdCliente)
            {props.cdCliente &&
                setDsCliente(props.dsCliente)
            }
            // setCdPerfil(props.cdPerfil)
            // setDsPerfil(props.dsPerfil)
            setCdUsuario(props.cdUsuario)
            setDsUsuario(props.dsUsuario)
            setDsEmail(props.dsEmail)
            setDsSobrenome(props.dsSobrenome)
            setDsTelefone(props.dsTelefone)
            setDsCpf(props.dsCpf)
            setFlAtivo(props.flAtivo)
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhCliente('Original: '+props.dsCliente)
            setPhUsuario('Original: '+props.dsUsuario)
            setPhEmail('Original: '+props.dsEmail)
            setPhSobrenome('Original: '+props.dsSobrenome)
            setPhTelefone('Original: '+props.dsTelefone)
            setPhCpf('Original: '+props.dsCpf)
            setPhMensagemSucesso('editado')
        }
        if (props.tituloJanela === 'Cadastrar'){
            setTituloJanela('Cadastrar')
            //CARREGA OS PLACEHOLDERS COM VALORES ORIGINAIS
            setPhCliente('Selecione')
            setPhUsuario('Nome do Usuário')
            setPhEmail('email@exemplo.com')
            setPhMensagemSucesso('cadastrado')
            setPhSobrenome('Sobrenome do Usuário')
            setPhTelefone('(00)000000-00000')
            setPhCpf('000.000.000-00')
        }
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    useEffect(() => {
        async function renderClientes(){
            // setSpinAtivo(true)
            const {data} = await api.get('/m1/consultar/cliente');
                if(data.dados.length > 0){
                    let objetoClientes = data.dados.map((item,i) => {
                        return {value: item.ds_pav_cliente, label: item.ds_pav_cliente, codigoCliente: item.cd_pav_cliente}
                    })
                    arrayClientes = objetoClientes
                }
        }
        renderClientes()
    }, [])



    //FAZ O MANEJO 
    function handleSalvar(){
        if(dsSenha !== dsConfirmacao) {
            setMensagemErro('Senhas não conferem')
            setRevelarMensagemErro(true)
            return;
        }

        if (tituloJanela === 'Cadastrar'){
            ColetaClickstream(1373) // dropAcoesRevelarNovoUsuarioCadastrar
            handleCadastrar()
        }else{
            ColetaClickstream(1374) // dropAcoesRevelarNovoUsuarioEditar
            handleAlterar()
        }
    }

    async function handleAlterar(){
        setSpinAtivo(true)
        await api.post('/m1/alterar/usuario', {
            "dados":{
                'ds_pav_usuario': dsUsuario.trim(),
                'ds_email': dsEmail.trim(),
                'ds_senha': dsSenha.trim(),
                "cd_pav_cliente": cdCliente,
                // "cd_pav_perfil": cdPerfil, 
                'ds_telefone': dsTelefone.trim(), 
                'ds_cpf': dsCpf.trim(), 
                'ds_pav_usuario_sobrenome': dsSobrenome.trim(), 
                'cd_pav_usuario_updated': decryptJWT("codigo"),
                'fl_ativo': flAtivo,
            },
            "condicao":{
                'cd_pav_usuario': cdUsuario,
            }
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    async function handleCadastrar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/usuario', {
            'ds_pav_usuario': dsUsuario.trim(),
            'ds_email': dsEmail.trim(),
            'ds_senha': dsSenha.trim(),
            "cd_pav_cliente": cdCliente,
            // "cd_pav_perfil": cdPerfil,
            'ds_telefone': dsTelefone.trim(), 
            'ds_cpf': dsCpf.trim(), 
            'ds_pav_usuario_sobrenome': dsSobrenome.trim(), 
            'cd_pav_usuario_created': decryptJWT("codigo"),
            'fl_ativo': flAtivo,
        })
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro(error.response.data.info)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        });
    }

    function handleCancelar(){
        ColetaClickstream(1372) // dropAcoesRevelarNovoUsuarioCancelar
        setRevelarMensagemSucesso(false)
        setRevelarMensagemErro(false)
        despacho(revelarNovoUsuario(false))
        despacho(revelarEditarUsuario(false))
        props.setRevelarEdicao(false)
    }

    function handleEscondeSenha() {
        if(exibirSenha === 'password')
            setExibirSenha('text')
        return <OlhoDesativado />
    }
    
    function handleExibirSenha() {
        if(exibirSenha === 'text')
            setExibirSenha('password')
        return <OlhoAtivo />
    }
    
    function handleEscondeSenhaConfirmacao() {
        if(exibirSenhaConfirmacao === 'password')
            setExibirSenhaConfirmacao('text')
        return <OlhoDesativado />
    }
    
    function handleExibirSenhaConfirmacao() {
        if(exibirSenhaConfirmacao === 'text')
            setExibirSenhaConfirmacao('password')
        return <OlhoAtivo />
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
            }
            handleCancelar()
        }
    }

    function alterarSwitch(e){
        if(e === true){
            setFlAtivo(1)
        }else{
            setFlAtivo(0)
        }
    }

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        setValorCampo(inputValue);
        return inputValue;
      };

    const onChange = (selectedOptions) => {setSelected(selectedOptions)};

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-cpf"){
                setDsCpf(valor)
            }
            if(id === "campo-texto-telefone"){
                setDsTelefone(valor)
            }
        }
    }

    return(
        <Form aria-autocomplete="OFF" ref={modal} onSubmit={handleSubmit(() => handleSalvar())}>
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <div className="modal-cadastro-usuario fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">{tituloJanela} usuário</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-usuario-1">
                            <div className="coluna-cadastro-usuario-5">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Cliente</div>
                                    </Form.Label>
                                    {revelarSelectCliente === false &&
                                        <Button className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectCliente(!revelarSelectCliente)}>
                                            <div className="fonte-cor-1 label-campo">{dsCliente}</div>
                                            <img className={"campo-select-icone-desativado nao-selecionavel"} src={setaDropdown()} alt="drop" />
                                        </Button>
                                    }
                                    {revelarSelectCliente === true &&
                                        <SelectGenerico
                                            paramRota={"cliente_virtueyes"}
                                            paramCampo1={null}
                                            paramCampo2={null}
                                            paramCampo3={null}
                                            paramCampo4={null}
                                            setCodigo={setCdCliente}
                                            setDescricao1={setDsCliente}
                                            setDescricao2={setDummy}
                                            setDescricao3={setDummy}
                                            setDescricao4={setDummy}
                                            setRevelarSelect={setRevelarSelectCliente}
                                            placeholder={setDsCliente}
                                            sufixo={''}
                                        >
                                        </SelectGenerico>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-usuario-1">
                            <div className="coluna-cadastro-usuario-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Nome</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Nome do Usuário</Tooltip>}>
                                        <Form.Control autocomplete="off" name="dsUsuario" value={dsUsuario} className="campo-texto-cor-3 campo-texto" type="text" placeholder={phUsuario === null || phUsuario === 'null'? "" : phUsuario} onChange={e => setDsUsuario(e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100">
                                        </Form.Control>
                                    </OverlayTrigger>
                                    {errors.dsUsuario && <AlertaCampoObrigatorio />}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-usuario-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Sobrenome</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Sobreome do Usuário</Tooltip>}>
                                        <Form.Control autocomplete="off" name="dsSobrenome" value={dsSobrenome}  className="campo-texto-cor-3 campo-texto" type="text" placeholder={"Digite o sobrenome"/* phSobrenome == null || phSobrenome == 'null'? "" : phSobrenome */} onChange={e => setDsSobrenome(e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})} maxlength="100">
                                        </Form.Control>
                                    </OverlayTrigger>
                                    {errors.dsSobrenome && <AlertaCampoObrigatorio />}
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-usuario-1">
                            <div className="coluna-cadastro-usuario-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">CPF</div>
                                    </Form.Label>
                                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Insira o CPF do usuário</Tooltip>}>
                                        <Form.Control name="dsCpf" value={dsCpf} className="campo-texto-cor-3 campo-texto" type="text" placeholder={phCpf != null ? "000.000.000-00" : phCpf} onChange={e => setDsCpf(e.target.value)}
                                           minLength="11" maxlength="11"/>
                                    </OverlayTrigger>
                                    {errors.dscpf && <AlertaErroCampo>O CPF deve ser informado no seguinte formato 000.000.000-00</AlertaErroCampo>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-usuario-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Telefone</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Telefone para contato</Tooltip>}>
                                        <Form.Control name="dsTelefone" value={dsTelefone}  className="campo-texto-cor-3 campo-texto" type="text" placeholder={"(00)00000-0000"} onChange={e => setDsTelefone(e.target.value)} 
                                        ref={register({validate: {minimo: value => value.length > 1}})} minLength="11" maxlength="11"/>
                                    </OverlayTrigger>
                                    {errors.dsTelefone && <AlertaCampoObrigatorio />}
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-usuario-1">
                            <div className="coluna-cadastro-usuario-3">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">E-mail</div>
                                    </Form.Label>
                                    <OverlayTrigger  delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>E-mail utilizado para efetuar o login</Tooltip>}>
                                        <Form.Control name="dsEmail" value={dsEmail}  className="campo-texto-cor-3 campo-texto" type="text" placeholder={phEmail} onChange={e => setDsEmail(e.target.value)} 
                                        ref={register({validate: {minimo: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) == true}})} maxlength="100"/>
                                    </OverlayTrigger>
                                    {errors.dsEmail && <AlertaErroCampo>Insira um email valido.</AlertaErroCampo>}
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-usuario-4">
                                <Form.Label>
                                    <div className="fonte-cor-1 label-campo">Ativo</div>
                                </Form.Label>
                                <div className="container-switch-ativo-cadastro-usuario">
                                    <div class="flipswitch">
                                        <input type="checkbox" defaultChecked={flAtivo == 1 ? true : false} name="flipswitch" class="flipswitch-cb" id="switchAtivo" onClick={(e) => alterarSwitch(e.target.checked)}/>
                                        <label class="flipswitch-label" for="switchAtivo">
                                            <div class="flipswitch-inner flipswitch-cor-1"></div>
                                            <div class="flipswitch-switch"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <div className="grupo-cadastro-usuario-1">
                            <div className="coluna-cadastro-usuario-1">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Senha</div>
                                    </Form.Label>
                                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Senha</Tooltip>}>
                                        <Form.Control name="dsSenha" value={dsSenha}  className="campo-texto-cor-3 campo-texto" type={exibirSenha} placeholder={phSenha} onChange={e => setDsSenha(e.target.value)} 
                                        ref={register({ required: true, min: 6, max:20 })} minLength="6" maxLength="20"/>
                                    </OverlayTrigger>
                                    {errors.dsSenha && <AlertaCampoObrigatorio />}
                                    <Button variant="outline-ligh" onClick={() => setIcones(!icones)} className="btn-senha-cadastro-usuario">
                                        {icones ? handleExibirSenha() : handleEscondeSenha()}
                                    </Button>
                                </Form.Group>
                            </div>
                            <div className="coluna-cadastro-usuario-2">
                                <Form.Group>
                                    <Form.Label>
                                        <div className="fonte-cor-1 label-campo">Confirmar senha</div> 
                                    </Form.Label>
                                    <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Confirmar Senha</Tooltip>}>
                                        <Form.Control name="dsConfirmacao" value={dsConfirmacao}  className="campo-texto-cor-3 campo-texto" type={exibirSenhaConfirmacao} placeholder={phConfirmacao} onChange={e => setDsConfirmacao(e.target.value)} 
                                        ref={register({ required: true, min: 6, max:20 })} minLength="6" maxLength="20"/>
                                    </OverlayTrigger>
                                    {errors.dsConfirmacao && <AlertaCampoObrigatorio />}
                                    <Button variant="outline-ligh" onClick={() => setIconesConfirma(!iconesConfirma)} className="btn-senha-cadastro-usuario">
                                        {iconesConfirma ? handleExibirSenhaConfirmacao() : handleEscondeSenhaConfirmacao()}
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                            <div className="fonte-cor-4">Confirmar</div>
                        </Button>
                        <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                            <div className="fonte-cor-2">Cancelar</div>
                        </Button>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Usuário "+dsUsuario+" "+phMensagemSucesso+"."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}

// -- ---------------------------------------
function AlertaCampoObrigatorio() {
    return <AlertaErroCampo>O campo é obrigatorio.</AlertaErroCampo>
}

const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

function AlertaErroCampo({children}) {
    return <p className="fonte-cor-3 label-alerta">{iconeAlerta} {children}</p>
}

// -- ---------------------------------------
function OlhoAtivo() {
    return <img src={olhoLigado()} alt="olho ligado"/>
}

function OlhoDesativado() {
    return <img src={olhoDesligado()} alt="olho desligado"/>
}

