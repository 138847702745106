//IMPORTAÇÕES
import React, { useState } from 'react';/*eslint-disable*/
import {Button} from 'react-bootstrap';/*eslint-disable*/
//IMPORTAÇÕES ICONES
import {fecharJanela} from '../../utils/alternanciaIcones'
//ESTILIZACAO
import '../../css/componentesModais/barraDadosCliente/barraDadosCliente.css'
import './barraTabelaLegenda.css';

function BarraTabelaLegenda(props){
    const { filtros, totalItens, parqueTodoSelecionado } = props;

    if(totalItens <=50) return null;

    const [show, setShow] = useState(true);
    const qtdItensPag = props.getTotalItensNaPagina()

    const getMensagem = () => {
        let mensagem = `Todas as ${parqueTodoSelecionado ? totalItens : qtdItensPag} linhas `; 
        if(!parqueTodoSelecionado) {
            mensagem += 'nesta página';
        } else {
            if(filtros) {
                mensagem += 'filtradas';
            } else {
                mensagem += 'do parque';
            }
        }
        mensagem += ' estão selecionadas.';
        return mensagem;
    }

    const getButtonLabel = () => {
        return parqueTodoSelecionado ? 'LIMPAR SELEÇÃO' : `Selecionar todas as ${totalItens} linhas ${filtros ? 'filtradas' : 'do parque'}`
    }

    function handleChangeSelecao() {
        const novoValor = !parqueTodoSelecionado;
        props.onClick(novoValor);
    }

    function fechaBarraEDesselecionaTodosItens() {
        setShow(false)
        const checkBoxVerdeTh = document.getElementsByClassName('selection-cell-header')
        checkBoxVerdeTh[0].click()
    }

    if(!show || totalItens <= 0) return null;

    return(
        <div className="barra-dados-cliente fundo-cor-5">
            <div className="tabela-legenda-div">
                <span id="legenda-mensagem" className="fonte-cor-1">{getMensagem()}</span>
                {(qtdItensPag < totalItens) && <button id="legenda-botao" onClick={handleChangeSelecao}>{getButtonLabel()}</button>}
            </div>
        
            {!parqueTodoSelecionado && 
            <div className="barra-dados-caixa-fechar"
                onClick={fechaBarraEDesselecionaTodosItens}
            >
                <Button className="botao-fechar-janela" variant="danger">
                    <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                </Button>
            </div>
            }
        </div>
    )
}

export default BarraTabelaLegenda;