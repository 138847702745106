import React  from 'react';
import logoLogin from '../../icones/logo-algar-login.svg';
import {Image} from 'react-bootstrap';



export default function LoadingPage() {
   

    return (

        <div id="WrapperTelaLogin" className="WrapperTelaLogin2">

            <div className="container-green-up">
            </div>
            <div className="img-algar">
                <Image className="logoAlgarLoading" src={logoLogin} alt="logoAlgar"/>
            </div>
            
            <div className="container-green-down">
            </div>
      </div>
    )
}