import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api, api_s3} from '../../../../conexoes/api';/*eslint-disable*/
import { useForm } from "react-hook-form";/*eslint-disable*/
import {Button, Form, Col, Tooltip, OverlayTrigger, Image, Dropdown, Accordion } from 'react-bootstrap';
import Dropzone from "react-dropzone";/*eslint-disable*/
import decryptJWT from '../../../../utils/decryptJWT'
import {CircularProgressbar} from "react-circular-progressbar";
import { MdCheckCircle, MdError } from "react-icons/md";
import { uniqueId } from 'lodash';
import XLSX from 'xlsx';
// ICONES
import { elipseVerde, fecharJanela, importarArquivo } from '../../../../utils/alternanciaIcones'
// MODAIS
import MensagemSucesso from '../../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../../componentesModais/mensagens/mensagemAtencao'
// SPINNER
import SpinerVeye from '../../../spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch, useSelector } from 'react-redux';
import {revelarNovoPedidoImportado} from "../../../../redux/actions/index";
import { exportarXlsx } from '../../../../utils/exportarArquivo.js';

const dadosJSONXLS = [
    [
      "MSISDN",
      "ICCID",
      "OPERADORA",
      "PLANO MB",
      "STATUS",
      "IMSI",
    ],
    [
      "'",
      "'",
      "'",
      "'",
      "'",
      "'",
    ]
]
  

export default function NovoTemplate(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    // HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();

    //HOOKS MODAIS
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [mensagemMensagemAtencao, setMensagemMensagemAtencao] = useState('')
    const [mensagemMensagemAtencaoPragrafo1, setMensagemMensagemAtencaoPragrafo1] = useState('')
    //OUTROS HOOKS
    const [linhaRemover, setLinhaRemover] = useState('');
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [arquivosUpados, setArquivosUpados] = useState([]);
    const [dadosArquivo, setDadosArquivo] = useState([]);
    const [dsDescricao, setDsDescricao] = useState('');
    const [simcardsInvalidos, setSimcardsInvalidos] = useState();
    const despacho = useDispatch();
    const modal = useRef();
    
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])


    function handlerRealizarDonwload(){
        const exportar = document.getElementById('baixar-relatorio')
        exportar.click()
        setSimcardsInvalidos([])
    }
 
    async function handleEnviar(){
        setSpinAtivo(true)
        await api.post('/m1/cadastrar/pedido_importado',{
            uuid_insert:dadosArquivo,
            uuid_deleted:[],
            cd_pav_evento_parametro_tipo: 6,
            cd_pav_usuario_created:1700,
            cd_pav_cliente:decryptJWT('codigoCliente'),
            ds_descricao:dsDescricao
        })
        .then(async function (response) {
            if(response.data.status == 200){
                if(response.data.dados.eventoParametro){
                    await api.post("/m1/consultar/evento_parametro",{
                        cd_pav_evento_parametro:response.data.dados.eventoParametro
                    })
                        .then((resposta)=>{
                            if(resposta.data.status == 200){
                                setSimcardsInvalidos(resposta.data.dados[0].ds_body)
                                setArquivosUpados([])
                                setDadosArquivo([])
                                setMensagemMensagemAtencao('Algumas linhas estão com problema')
                                setMensagemMensagemAtencaoPragrafo1("Deseja fazer o download do arquivo com as linhas ?")
                                setRevelarMensagemAtencao(true)
                            }
                        })
                }
                else{
                    setRevelarMensagemSucesso(true)
                }
            }
            else{
                setRevelarMensagemErro(true)
            }
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error)
            setRevelarMensagemErro(true)
            setSpinAtivo(false)
        })
    }



    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(confirmacao === true ){
            handlerRealizarDonwload()
        }
    }

    function handleCancelar(){
        despacho(revelarNovoPedidoImportado(false))
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
            props.setRenderizar(!props.renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleUpload(files) {
        const arquivosUpadosTemp = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
            uploaded: true,
        }));
        
        setArquivosUpados(arquivosUpados.concat(arquivosUpadosTemp))
        arquivosUpadosTemp.forEach(processUpload);
    };

    function renderDragMessage(isDragActive){
        if (!isDragActive) {
          return <div className="fonte-branca-tema-branco UploadMessage"> Importar </div>;
        }
        return <div className="UploadMessage" type="success"> Solte aqui </div>;
    };

    

    async function processUpload(uploadedFile){
        setSpinAtivo(true)
        let arquivos = uploadedFile
        let data = new FormData();
        data.append('file', arquivos.file);
        data.append('cd_usuario', decryptJWT('codigo'));
        data.append('cd_pav_evento_parametro_tipo', 6);

        await api_s3.post("/s3/leitura/arquivo", data)
            .then(response => {
                if (response.data.status === 200) {
                    setSpinAtivo(false)
                    let dadosArquivoTemp = dadosArquivo; 
                    dadosArquivoTemp.push(response.data.idInserido)
                    setDadosArquivo(dadosArquivoTemp)
                }
                else{
                    setSpinAtivo(false)
                }            
            })
            .catch((error) => {
                setSpinAtivo(false)
            });
    };
    
    
    function exportarXlsLinhasNaoEncontradas(){
        exportarXlsx(dadosJSONXLS, "template.xls")
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit(() => handleEnviar())}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <a className='d-none' id='baixar-relatorio' href={simcardsInvalidos} target='_blank'/>
            <div className="modal-novo-pedido fundo-cor-6">
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Novo pedido</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {arquivosUpados.length == 1?
                            <Button className="botaoImportar"
                            >
                                {renderDragMessage(false)}
                                <Image className="imagemImportar" src={importarArquivo()} />
                            </Button>
                            :
                            <Dropzone onDropAccepted={handleUpload}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <Button className="botaoImportar"
                                        {...getRootProps()}
                                        isDragActive={isDragActive}
                                    >
                                        <input {...getInputProps()} />
                                        {renderDragMessage(isDragActive)}
                                        <Image className="imagemImportar" src={importarArquivo()} />
                                    </Button>
                                )}
                            </Dropzone>
                        }
                    </Col>
                    <Col>
                        <button 
                            id='exportar-linhas-nao-encontradas' 
                            className="botaoTemplate bt-dropdown-template" 
                            onClick={() => exportarXlsLinhasNaoEncontradas()}
                        >
                            <div className="UploadMessage fonte-cor-1"> Template </div>
                        </button> 
                    </Col>
                    <Col>
                        {arquivosUpados.length && (
                            <ListaArquivos files={arquivosUpados} onDelete={(e) => handleDelete(e)} />
                        )}
                    </Col>
                </Form.Row>
                
                <Form.Row className="mensagem-container-botao">
                    <Col>
                        <Form.Label>
                            <div className="fonte-cor-1 label-campo">Descrição</div>
                        </Form.Label>
                        <Form.Control 
                            name="motivo" 
                            className="campo-texto-cor-3 campo-texto"
                            maxLength="2000" 
                            value={dsDescricao}
                            placeholder="Escreva aqui uma descrição" 
                            ref={register({ required: true, min: 3, max: 2000 })} 
                            onChange={e => setDsDescricao(e.target.value)}
                        />
                    </Col>
                </Form.Row>
                <Form.Row  className="mensagem-container-botao">
                    <Col>
                        { arquivosUpados.length > 0 ?

                            <>
                                <Button  type="submit" className="botao-confirmar" variant="success" size="sm">
                                        <div className="fonte-cor-4">Confirmar</div>
                                </Button>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                                </>
                            :
                            <>
                                <OverlayTrigger  delay={{ show: 500, hide: 250 }}placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Adicione ao menos 1 item ao pedido e preencha os campos obrigatórios</Tooltip>}>
                                    <Button className="botao-confirmar" variant="success" size="sm">
                                        <div className="fonte-cor-4">Confirmar</div>
                                    </Button>
                                </OverlayTrigger>
                                <Button className="botao-cancelar" variant="success" size="sm" onClick={() => handleCancelar()}>
                                    <div className="fonte-cor-2">Cancelar</div>
                                </Button>
                            </>
                        }
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Pedido efetuado com sucesso."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro!"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={mensagemMensagemAtencao}
                            mensagemParagrafo1={mensagemMensagemAtencaoPragrafo1}
                            mensagemBotao={"Donwload"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}


// funcao para mostrar na tela a lista de arquivos importados 
function ListaArquivos ({ files, onDelete })  {
    return (
        <div className="Container">
            {files.map((uploadedFile,i) => (
            <p className="tituloLista"> Arquivo selecionado
                <li className="lista" key={uploadedFile.id}>
                    <p className="listaNome">{uploadedFile.name}</p>
                    <div>
                        {uploadedFile.uploaded && <MdCheckCircle size={24} color="#36D896"/>}
                        {uploadedFile.error && <MdError size={24} color="#e57878"/>}
                    
                    </div>
                </li>
            </p>
            ))}
        </div>
)}