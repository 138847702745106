//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import moment from 'moment';/*eslint-disable*/
import 'moment-timezone';/*eslint-disable*/
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'/*eslint-disable*/
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';

import { Image, Button} from 'react-bootstrap';/*eslint-disable*/
import { useDispatch, useSelector, connect } from 'react-redux';
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
import {formatarMilhar} from '../../../utils/formatarMilhar'
import DetalhamentoPedido from './detalhamentoPedido/detalhamentoPedido'
//COMPONENTES
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import NovoPedido from './novoPedido/novoPedido'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//ESTILIZAÇÃO
import '../../../css/painelFaturas/fatPedidos/tabela.css'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import { setaDropdown } from '../../../utils/alternanciaIcones'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

//REDUX
import {revelarMostrarEmDispositivos, revelarExportarDetalhamento,
    mudarManejador, limpouBusca, realizouBusca, desabilitarAcoes, desabilitarAcoesCliente, mudarFiltros, revelarBarraFiltragem, realizouFiltro,
    filtrosArrayBarra, limpadorFiltros, revelarExcluirFatPedidos} from "../../../redux/actions/index"
    
const valorLimite = 3
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let arrayFornecedor = []

function TabelaPedidosSimcard(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [pedido, setPedido] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [mensagemErro, setMensagemErro] = useState('')
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)

    const [cdPedido, setCdPedido] = useState();
    const [dsPedido, setDsPedido] = useState();
    const [cdFornecedor, setCdFornecedor] = useState();
    const [dsFornecedor, setDsFornecedor] = useState();
    const [cdOperadora, setCdOperadora] = useState();
    const [dsOperadora, setDsOperadora] = useState();
    const [cdPlano, setCdPlano] = useState();
    const [dsPlano, setDsPlano] = useState();
    const [cdConta, setCdConta] = useState();
    const [dsConta, setDsConta] = useState();
    const [cdCicloVida, setCdCicloVida] = useState();
    const [dsCicloVida, setDsCicloVida] = useState();
    const [cdTempoPermanencia, setCdTempoPermanencia] = useState();
    const [dsTempoPermanencia, setDsTempoPermanencia] = useState();
    const [numeroNota, setNumeroNota] = useState();
    const [numeroPedido, setNumeroPedido] = useState();
    const [dataEmissaoNota, setDataEmissaoNota] = useState("");
    const [dataPedido, setdataPedido] = useState("");
    const [dataAtivacao, setDataAtivacao] = useState("");
    const [permissaoDescricao, setPermissaoDescricao] = useState();

    const [quantidadeTradein, setQuantidadeTradein] = useState();
    const [quantidadeSimcard, setQuantidadeSimcard] = useState();

    const [n0_cliente_erp_antigo, setN0_cliente_erp_antigo] = useState(decryptJWT("codigoClienteErpAntigo"))
    const [cd_pav_cliente, setCd_pav_cliente] = useState(decryptJWT("codigoCliente"))


    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let itensVar = []

    //REDUX VARIÁVEIS
    const despacho = useDispatch();

    let filtros = useSelector(state => state.filtrosState.filtros);
    
    let novoFatPedidos = useSelector(state => state.faturasState.revelarNovoFatPedidos);
    let editarFatPedidos = useSelector(state => state.faturasState.revelarEditarFatPedidos);
    let excluirFatPedidos = useSelector(state => state.faturasState.revelarExcluirFatPedidos);

    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    
    let temFiltros = false;
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(609) // 'fatPedidosTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);
    
    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])
    
    //Acao Mostrar Em Dispositivo 

    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("faturasPedidos"));
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        async function carregarTabela(){
            // //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const body = {
                        paginaAtual: contador,
                        carregarLimit: valorLimite,
                        buscar:conteudoBusca,
                        contar: false,
                        contas: decryptJWT("fatContas")
                    }
                    const { data } = await api.post('/m1/consultar/fat_pedido', body);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const body = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    contar: false,
                    contas: decryptJWT("fatContas"),
                    filtros: filtros,
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post('/m1/filtrar/fat_pedido', body);

                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const body = {
                        paginaAtual: contador,
                        carregarLimit: valorLimite,
                        buscar:conteudoBusca,
                        contar: false,
                        contas: decryptJWT("fatContas")
                    }
                    const { data } = await api.post('/m1/consultar/fat_pedido', body); 

                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
    
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            const body = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                contar: true,
                contas: decryptJWT("fatContas")
            }
            apiData = await api.post('/m1/consultar/fat_pedido', body); 
        }else{
            const body = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                contar: true,
                contas: decryptJWT("fatContas"),
                filtros: filtros,
            }
            apiData = await api.post('/m1/filtrar/fat_pedido', body);
        }
        itensVar = []
        if(apiData.data.dados){
            totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
            totalItens = apiData.data.dados[0].CONTADOR;
            for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }
     
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            setRenderizar(!renderizar)
            restaurarConfigsIniciaisPagina()
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    const colunas = [
        {
            dataField: 'NPEDIDO',
            headerClasses: 'nao-selecionavel',
            text: 'N° do pedido',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: "DT_PEDIDO",
            headerClasses: 'nao-selecionavel',
            text: 'Data pedido',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != "0000-00-00 00:00:00" && cell != null && cell !="Invalid date"?moment(cell).format('DD/MM/YYYY'):'-'}</p>;
            }
        },
        {
            dataField: 'DS_FORNECEDOR',
            headerClasses: 'nao-selecionavel',
            text: 'Fornecedor',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DS_OPERADORA',
            headerClasses: 'nao-selecionavel',
            text: 'Operadora',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'VALOR_PLANO',
            headerClasses: 'nao-selecionavel',
            text: 'Valor do plano',
            sort: true,
            
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-pedidosSimcard"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-pedidosSimcard">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard"> 0.00 </p></> : <><p className="valor-rs-pedidosSimcard">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'DS_CONTA',
            headerClasses: 'nao-selecionavel',
            text: 'Conta',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DS_NF',
            headerClasses: 'nao-selecionavel',
            text: ' N° da NF',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'QTD_SIMCARD',
            headerClasses: 'nao-selecionavel',
            text: 'Quantidade de SIM cards',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        // {
        //     dataField: 'QTD_TRADE_IN',
        //     headerClasses: 'nao-selecionavel',
        //     text: 'Quantidade de trade-in',
        //     sort: true,
        //     formatter: (cell, row) => {
        //         const valor = parseFloat(cell);
        //         return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        //     }
        // },
        {
            dataField: 'DT_EMISSAO_NF',
            headerClasses: 'nao-selecionavel',
            text: 'Data emissão NF',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != "0000-00-00 00:00:00" && cell != null && cell !="Invalid date"?moment(cell).format('DD/MM/YYYY'):'-'}</p>;
            }
        },
        {
            dataField: 'DT_ATIVACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Data de ativação',
            sort: true,
            formatter: (cell, row) => {
                // return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
                return <p className="fonte-cor-1 label-tabela-1">{cell != "0000-00-00 00:00:00" && cell != null && cell !="Invalid date"?moment(cell).format('DD/MM/YYYY'):'-'}</p>;

            }
        },
        {
            dataField: 'PRAZO_PERMANENCIA',
            headerClasses: 'nao-selecionavel',
            text: 'Prazo de permanência',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != "0000-00-00 00:00:00" && cell != null && cell !="Invalid date"?moment(cell).format('DD/MM/YYYY'):'-'}</p>;
            }
        },
    ]
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // setEnvioSelecionado(row)
        }
    }
    
    
    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    useEffect(()=>{
        if(excluirFatPedidos){
            setRevelarMensagemAtencao(true)
        }
    },[excluirFatPedidos])

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            despacho(revelarExcluirFatPedidos(false))
            if(confirmacao === true){
                handleDeletar()
            }
        }
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_pedido?cd_fat_pedido='+cdPedido)
        .then(function (response) {
            setRenderizar(!renderizar)
            despacho(revelarExcluirFatPedidos(false))
            handleMensagemSucesso(false)
            setRevelarMensagemAtencao(false)
            setSpinAtivo(false)

        })
        .catch(function (error) {
            setMensagemErro("Erro ao excluir empresa!")
            despacho(revelarExcluirFatPedidos(false))
            handleMensagemErro(false)
            setRevelarMensagemAtencao(false)
            setSpinAtivo(false)
        })
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarCliente(
                    row.CD_FAT_PEDIDO,
                    row.CD_FORNECEDOR,
                    row.DS_FORNECEDOR,
                    row.CD_OPERADORA,
                    row.DS_OPERADORA,
                    row.CD_PLANO,
                    row.DS_PLANO,
                    row.CD_CONTA,
                    row.DS_CONTA,
                    row.CD_CICLO_VIDA,
                    row.DS_CICLO_VIDA,
                    row.CD_PERMANENCIA,
                    row.CD_PERMANENCIA,
                    row.DS_NF,
                    row.NPEDIDO,
                    row.DT_EMISSAO_NF,
                    row.DT_PEDIDO,
                    row.DT_ATIVACAO
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarCliente(CdPedido,CdFornecedor,DsFornecedor,CdOperadora,DsOperadora,CdPlano,DsPlano,CdConta,DsConta,CdCicloVida,DsCicloVida,CdTempoPermanencia,DsTempoPermanencia,NumeroNota,NumeroPedido,DataEmissaoNota,dataPedido,DataAtivacao){
        setCdPedido(CdPedido)
        setCdFornecedor(CdFornecedor)
        setDsFornecedor(DsFornecedor)
        setCdOperadora(CdOperadora)
        setDsOperadora(DsOperadora)
        setCdPlano(CdPlano)
        setDsPlano(DsPlano)
        setCdConta(CdConta)
        setDsConta(DsConta)
        setCdCicloVida(CdCicloVida)
        setDsCicloVida(DsCicloVida)
        setCdTempoPermanencia(CdTempoPermanencia)
        setDsTempoPermanencia(DsTempoPermanencia + " meses")
        setNumeroNota(NumeroNota)
        setNumeroPedido(NumeroPedido)
        setDataEmissaoNota(DataEmissaoNota)
        setdataPedido(dataPedido)
        setDataAtivacao(DataAtivacao)
    }

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={"expand-row-tabela-fatPedidos"}>
                <DetalhamentoPedido cd_fat_pedido={row.CD_FAT_PEDIDO}/>
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };
    
    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
         
        
            {novoFatPedidos === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-tabela-novo-pedido-container">
                        <NovoPedido
                            tituloJanela={"Cadastrar"}
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        >   
                        </NovoPedido>
                    </div>
                </div>
            }
            {revelarMensagemAtencao === true &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+numeroPedido }
                />
            }
            
            
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Pedido excluído."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }

            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                hover={true}
                                condensed={true}
                                keyField='CD_FAT_PEDIDO' 
                                data={itens} 
                                columns={colunas}
                                rowEvents={eventosLinhas}
                                selectRow={selecaoLinhas}
                                expandRow={itemExpandido}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                {...paginationTableProps}
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    -
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaPedidosSimcard));