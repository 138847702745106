//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Button, ButtonToolbar } from 'react-bootstrap';

import {api} from '../../../conexoes/api';/*eslint-disable*/

//COMPONENTES 
import CadastrarVersao from './cadastro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
// import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
// import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import ModalDetalhesVersao from '../../releaseNotes/ModalDetalhesVersao';
// import decryptJWT from '../../../utils/decryptJWT'

//REDUX
import { //revelarExcluirUsuario, revelarNovoUsuario, 
    mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, desabilitarAcoes } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

// COLETA CLICKSTREAM
// import ColetaClickstream from '../../clickstreamAnalise/clickstreamColeta.js'
import { formatarData } from '../../../utils/formatadorDataTabela';

import eyeBlue from '../../../icones/eyeBlue.svg'
import editarVerde from '../../../icones/editarVerde.svg'

//ESTILIZACAO
import '../../../css/painelAdministrativo/pavUsuario/pavUsuario.css'
import './tabela.css';
import { lixeira, lixeiraVermelho } from '../../../utils/alternanciaIcones';

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let cadastrouOuDeletou = false;

function Grid(props){
    // HOKS DA TELA
    let storeCdVersao = -1;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdVersionamento, setCdVersionamento] = useState('');
    const [dsVersao, setDsVersao] = useState('');
    // const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENESAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //HOOKS MODAL
    // const [renderizarCadastro, setRenderizarCadastro] = useState(false)
    // const [renderizarEdicao, setRenderizarEdicao] = useState(false)
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    const [revelarEdicao, setRevelarEdicao] = useState(false)
    const [deletarVersao, setDeletarVersao] = useState();

    const [revelarMenuReleaseNotes, setRevelarMenuReleaseNotes] = useState(false)

    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    // let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    // let NovoUsuario =  useSelector(state => state.manejadorState.revelarNovoUsuario);
    // let EditarUsuario = useSelector(state => state.manejadorState.revelarEditarUsuario);
    // let ExcluirUsuario = useSelector(state => state.manejadorState.revelarExcluirUsuario);

    let temFiltros = false;

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            // console.log('fazer ColetaClickstream, 120 É O DE USUÁRIOS')
            // ColetaClickstream(120) // 'pavUsuarioTabelaGrid'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("versionamento"))
        despacho(desabilitarAcoes(true));

        async function carregarTabela(){

             //clicou no botão de limpar a busca geral e retorna tudo ao estado original
             if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }   

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }   
             
            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();

                    const queryString = `?paginaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}`;

                    const { data } = await api.get('/m1/filtrar/versionamento'+queryString);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/versionamento", dados); 
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0 || cadastrouOuDeletou){
                    await contarItens();
                    if(cadastrouOuDeletou) cadastrouOuDeletou = false;

                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {

                    const queryString = '?paginaAtual='+contador+'\
                                        &carregarLimit='+valorLimite;


                    const { data } = await api.get('/m1/filtrar/versionamento'+queryString); 

                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 

                const queryString = '?contar=true&buscar='+conteudoBusca;

                apiData = await api.get('/m1/filtrar/versionamento'+queryString);

            }else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true,
                }
                apiData = await api.post("/m1/filtrar/versionamento", dados);
            }
            itensVar = []

            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    let colunas = [
    {
        dataField: 'ds_release',
        text: 'Versão',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    },
    {
        dataField: 'dt_created_at',
        text: 'Data',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            if(!cell){
                return <p className="fonte-cor-1 label-tabela-1">-</p>
            }else{
                return formatarData(cell,true);
                // return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        }
    },
    {
        dataField: 'cd_pav_release',
        text: 'Visualizar',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            // if(!cell){
            //     return <p className="fonte-cor-1 label-tabela-1">-</p>
            // }else{
                return <Button className="div-visualizar-versao" 
                    onClick={() => {
                        setRevelarMenuReleaseNotes(true);
                        setCdVersionamento(cell)
                    }}> 
                    <img className="btn-visualizar-versao" alt="visualizar detalhes" src={eyeBlue}/>
                </Button> 
                // return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            // }
        }
    },
]

    const colunasAdm = [{
            dataField: 'responsavel',
            text: 'Responsável',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                if(!cell){
                    return <p className="fonte-cor-1 label-tabela-1">-</p>
                }else{
                    return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
                }
            }
        },
        {
            dataField: 'cd_pav_release',
            text: 'Ações',
            sort: true,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                // if(!cell){
                //     return <p className="fonte-cor-1 label-tabela-1">-</p>
                // }else{
                    return <ButtonToolbar 
                        className="btn-toolbar-versao"
                    >
                        <Button className="btn-editar-versao"
                            onClick={() => handleExpandirEdicao(cell)}
                        >
                            <img alt="editar" src={editarVerde}/>
                        </Button> 
                        <Button className="btn-apagar-versao"
                            onClick={() => handleMensagemAtencao(false, cell, row.ds_release)}
                        >
                            <img alt="lixeiraVermelho" src={lixeira()}/>
                        </Button>
                    </ButtonToolbar>
                // }
            }
        },
    ]

    if(props.permissoesAdm) {
        colunas = [...colunas, ...colunasAdm];
    }
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // ...
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    // function colunaAcoes(){
    //     return(
    //         <p className="acoes">Ações</p>
    //     );
    // }

    async function handleDeletar(cdExcluir){
        setSpinAtivo(true)
        await api.get('/m1/deletar/versionamento?cd_pav_release='+cdExcluir) //+cdVersionamento)
        .then(function (response) {
            setSpinAtivo(false)
            handleMensagemSucesso(false)
            // despacho(revelarExcluirUsuario(false))
            cadastrouOuDeletou = true;
        })
        .catch(function (error) {
            setSpinAtivo(false)
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            // despacho(revelarExcluirUsuario(false))
        })
    }
    
    // function handleEditar(){
    //     setRevelarEdicao(true)
    //     setRenderizarEdicao(!renderizarEdicao)
    // }

    // function handleCancelar(){
    //     setRevelarMensagemAtencao(false)
    //     setRevelarMensagemErro(false)
    //     setRevelarMensagemSucesso(false)
    //     // setRevelarCadastro(false)
    //     setRevelarEdicao(false)
    //     revelarNovoUsuario(false)
    // }

    function handleMensagemAtencao(confirmacao, cd_pav_release, versao){
        setDsVersao(versao);

        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
        }
        if(!confirmacao){
            setDeletarVersao(cd_pav_release)
        }
        if(confirmacao && revelarMensagemAtencao){
            handleDeletar(deletarVersao)
        }
        setRenderizar(!renderizar)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
        }
        if(confirmacao === true){
            setRenderizar(!renderizar)
        }
    }

    function handleExpandirEdicao(Param){
        setCdVersionamento(Param)
        if(Param != storeCdVersao){
            setRevelarEdicao(false)
            // setRevelarConfiguracao(false)
            // setNomeGrupo('Selecione')
        }
        storeCdVersao = Param
        if(revelarEdicao === true){
            setRevelarEdicao(false)
            // setRevelarConfiguracao(false)
            // setNomeGrupo('Selecione')
        }else{
            setRevelarEdicao(true)
            // setRevelarConfiguracao(false)
        }
    }

    return(
        <>
        {/* {visualizarDados === true &&
            <BarraDadosCliente/>
        } */}

        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
          
        {revelarMenuReleaseNotes === true &&
                <div className="componente-modal-overlay-novidades">
                    <div className="componente-modal-acao-novidades-container">
                        <ModalDetalhesVersao
                            setRevelarMenuReleaseNotes={setRevelarMenuReleaseNotes}
                            cd_pav_release={cdVersionamento}
                        />
                    </div>
                </div>
            }

            {revelarCadastro === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-usuario-container">
                        <CadastrarVersao
                            fecharModal={() => setRevelarCadastro(false)}
                            tituloJanela={'Cadastrar'}
                            setRevelarCadastro={setRevelarCadastro}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cadastrou={() => cadastrouOuDeletou = true}
                        />
                    </div>
                </div>
            }
            {revelarEdicao && // EditarUsuario === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-usuario-container">
                        <CadastrarVersao
                            fecharModal={() => setRevelarEdicao(false)}
                            tituloJanela={'Editar'}
                            cdVersionamento={cdVersionamento}
                            // dsVersao={dsVersao}
                            // dtCreated={dtCreated}
                            setRevelarCadastro={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        />
                    </div>
                </div>
            }
            {/* { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            } */}

            {props.permissoesAdm && 
            <Button onClick={() => setRevelarCadastro(true)} className="botao-confirmar btn-nova-versao" variant="success" size="sm">
                <div className="fonte-cor-4">CRIAR NOVA VERSÃO</div>
            </Button>}
            
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_release'
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                // selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Versão deletada."}
                    mensagemBotao={"Continuar"}
                />
            }
            {revelarMensagemAtencao && //ExcluirUsuario &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar versão "+dsVersao}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));