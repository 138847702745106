import XLSX from 'xlsx';

export function exportarXlsx(dados, nomeArquivo, titulo="SheetJS") {
    const ws = XLSX.utils.aoa_to_sheet(dados);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, titulo);
    XLSX.writeFile(wb, nomeArquivo)
}

export function exportarXlsLinhasNaoEncontradas(dadosParaDownload) { 
    if(dadosParaDownload?.length < 2) return; // para não gerar arquivo vazio (somente com cabeçalho Linha e ICCID)
    exportarXlsx(dadosParaDownload, "Linhas_nao_encontradas_na_filtragem.xlsx")
}

export function exportarXlsLinhasInvalidas(dadosParaDownload, nomeArquivo) {
    if(dadosParaDownload?.length < 1) return; // para não gerar arquivo vazio (somente com cabeçalho Linha)
    exportarXlsx(dadosParaDownload, nomeArquivo+".xlsx")
}

export async function montarXlsLinhasInvalidasAcao(dados, erro) {
    const dadosParaDownloadLinhasInvalidas = [['Linha', 'Motivo']]; // mudar isso? useState(?)

    if(!dados) return;

    await Promise.all(
        dados.map(({ msisdn: LINHA, mensagem: MOTIVO }) => {
            if(LINHA === undefined) LINHA = '   Vazio';

            let exportacaoLinhasAuxXls = [                    
                LINHA + '',
                MOTIVO + '',
            ]
            dadosParaDownloadLinhasInvalidas.push(exportacaoLinhasAuxXls)
            return true;
        })
    )

    const nomeArquivo = erro ? 'Linhas_erro' : 'Linhas_invalidas';
    exportarXlsLinhasInvalidas(dadosParaDownloadLinhasInvalidas, nomeArquivo);
}

export async function montarXlsLinhasInvalidasAcaoAtribuir(dados ) {
    const dadosParaDownloadLinhasInvalidas = [['Linhas já atribuidas', 'Nome do Cliente']];

    if(!dados) return;

    await Promise.all(
        dados.map(({ linha: LINHA, nome_cliente: NOME_CLIENTE }) => {
            if(LINHA === undefined) LINHA = '   Vazio';

            let exportacaoLinhasAuxXls = [
                LINHA + '',
                NOME_CLIENTE + '',

            ]
            dadosParaDownloadLinhasInvalidas.push(exportacaoLinhasAuxXls)
            return true;
        })
    )

    const nomeArquivo = 'Linhas_invalidas';
    exportarXlsLinhasInvalidas(dadosParaDownloadLinhasInvalidas, nomeArquivo);
}


// ---------------------------------------------------------------------------------------------
export async function getListaLinhasNaoEncontradas({dataLinhasNaoEncontradas, simcards, dspSimcard=false}) {
    const objKeys = {    
        columnKey: 'iccid',
        bdColumn: 'ICCID',
        responseItemKey: 'ICCID'
    }

    return getDadosNaoEncontradosNaColunaDoArquivo({dataLinhasNaoEncontradas, simcards, objKeys, dspSimcard});
}

export async function getListaIccidsNaoEncontrados({dataLinhasNaoEncontradas, simcards, dspSimcard}) {
    const objKeys = {
        columnKey: 'msisdn',
        bdColumn: 'CALLERID',
        responseItemKey: 'LINHA'
    }

    return getDadosNaoEncontradosNaColunaDoArquivo({dataLinhasNaoEncontradas, simcards, objKeys, dspSimcard});
}

const tratarMsisdn = (arrMsisdn) => {
    return arrMsisdn.map(msisdn => {
        let linhaStr = (typeof msisdn === 'string') ? msisdn : msisdn.toString();
        if(linhaStr.length !== 11 && linhaStr.substring(0, 2) === '55') {
            return linhaStr.substring(2)
        } else {
            return linhaStr
        }
    })
}

async function getDadosNaoEncontradosNaColunaDoArquivo({dataLinhasNaoEncontradas, simcards, objKeys, dspSimcard}) {
    const {columnKey, bdColumn, responseItemKey} = objKeys;
    let listaNaoEncontrados;

    const firstItem = dataLinhasNaoEncontradas?.dados[0];
    // Caso exista algum registro não encontrado, para iccid/linha, ai sim monta o arquivo xls 
    if (firstItem && firstItem[columnKey] && simcards.status === 200) {
        // Filtra apenas os iccids/msidsns do arquivo XLS enviado
        let listaDadosDaColuna = dataLinhasNaoEncontradas.dados.map(function(_, key){
            return dataLinhasNaoEncontradas.dados[key][columnKey]
        })
        // Lista os ICCIDS/CALLERID ENCONTRADOS no BD
        let itensEncontrados = simcards.dados.map(function(_, key){
            const item = simcards.dados[key][bdColumn];

            if(item?.trim) return item.trim();
            return item;
        })

        if(dspSimcard) {
            listaDadosDaColuna = tratarMsisdn(listaDadosDaColuna);
            itensEncontrados = tratarMsisdn(itensEncontrados);
        }

        // Filtro para retirar do array enviado por arquivo, os iccids/msisdn encontrados 
        if(itensEncontrados !== undefined){
            listaNaoEncontrados = listaDadosDaColuna.filter((x) => itensEncontrados.indexOf(x) === -1)

            if(!dspSimcard) {
                listaNaoEncontrados = tratarMsisdn(listaNaoEncontrados)
            }

            // Monta a lista de iccid/linha não encontrado
            let lista = listaNaoEncontrados.map(function(item){
                return {[responseItemKey] : item}
            })

            return lista;
        }
    }
}

export async function montarXlsLinhasNaoEncontradas(dados) {
    if(!dados) return;
    const dadosPush = await handleMontarXlsLinhasNaoEncontradas(dados);

    if(dadosPush?.length) {
        return dadosPush;
    }
    return false;
}

export async function handleMontarXlsLinhasNaoEncontradas(dados) {
    const dadosParaDownloadLinhasNaoEncontradas = [];

    await Promise.all(
        dados.map((item) => {
            if(item.LINHA === undefined) item.LINHA = '   Vazio';
            if(item.ICCID === undefined) item.ICCID = '   Vazio';

            let exportacaoLinhasAuxXls = [                    
                item.LINHA + '',
                "'" + item.ICCID
            ]
            dadosParaDownloadLinhasNaoEncontradas.push(exportacaoLinhasAuxXls)
            return true;
        })
    )
    return dadosParaDownloadLinhasNaoEncontradas;
}