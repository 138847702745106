import React, { useState, useEffect } from "react";

import '../../css/pavFiltros/filtros.css'
import { Form, Col, Button } from "react-bootstrap";/*eslint-disable*/
import { setaDropdown } from '../../utils/alternanciaIcones';

import { useDispatch, useSelector } from 'react-redux';
import {filtrosArrayBarra, revelarBarraFiltragem, recarregarBarraFiltragem, maximoTagsExibidas} from '../../redux/actions'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

function Filtros(props) {
    const [nome,setNome] = useState('')
    const [cnpj,setCnpj] = useState('')
    const [tipoPessoa,setTipoPessoa] = useState('')
    const [cpf,setCpf] = useState('')
    const [email,setEmail] = useState('')
    const [razaoSocial,setRazaoSocial] = useState('')
    const [telefone,setTelefone] = useState('')

    const despacho = useDispatch();
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    let filtros = useSelector(state => state.filtrosState.filtros);
    let arrayFiltrosSimcard = []


    function hendlerGetdadosFiltros(){
        if(!filtros.vazio){
            //seta os valores dos inputs
            setNome(filtros.ds_nome)
            setCnpj(filtros.ds_cnpj)
            setTipoPessoa(filtros.ds_pav_tipo_pessoa)
            setCpf(filtros.ds_cpf)
            setEmail(filtros.ds_email)
            setRazaoSocial(filtros.ds_pav_cliente)
            setTelefone(filtros.ds_telefone)
        }
    }
    useEffect(() => {
        hendlerGetdadosFiltros()
    }, []);

    function handleFuncaoEstadosRedux(){
        ColetaClickstream(1321) /*pavClienteTabelaGridDropFiltrosFiltrar*/
        if(nome !== '' && nome)arrayFiltrosSimcard.push("Nome: "+nome+"?ds_nome")
        if(tipoPessoa !== '' && tipoPessoa)arrayFiltrosSimcard.push("Tipo de pessoa: "+tipoPessoa+"?ds_pav_tipo_pessoa")
        if(cnpj !== '' && cnpj)arrayFiltrosSimcard.push("CNPJ: "+cnpj+"?ds_cnpj")
        if(cpf !== '' && cpf)arrayFiltrosSimcard.push("CPF: "+cpf+"?ds_cpf")
        if(razaoSocial !== '' && razaoSocial)arrayFiltrosSimcard.push("Razao social: "+razaoSocial+"?ds_pav_cliente")
        if(email !== '' && email)arrayFiltrosSimcard.push("Email: "+email+"?ds_email")
        if(telefone !== '' && telefone)arrayFiltrosSimcard.push("Telefone: "+telefone+"?ds_telefone")

        despacho(maximoTagsExibidas(props.setRevelarDropFiltros))
        despacho(filtrosArrayBarra(arrayFiltrosSimcard))
        despacho(revelarBarraFiltragem(true))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //Check se todas as variaveis estao limpas
        function handlerCheckarTudoLimpo(verificador = []){
            if(nome == '') verificador.push(1)
            if(cnpj =='') verificador.push(1)
            if(tipoPessoa =='') verificador.push(1)
            if(cpf =='') verificador.push(1)
            if(email =='') verificador.push(1)
            if(razaoSocial =='') verificador.push(1)
            if(telefone =='') verificador.push(1)
            //tamanho igual a quantidade de variaveis
            if(verificador.length === 7) return false
            else return true
        }

        async function handlerAtivarBotoes(){
            //se for alterado algo vai abilitar os botoes
            if(props.primeiraVez && !props.foiLimpado && await handlerCheckarTudoLimpo()) 
                props.setFoiSelecionado(true);
            else props.setFoiSelecionado(false);
        }
        
        //limpa os dados de todas as variaveis
        if(props.foiLimpado){
            setCnpj('')
            setNome('')
            setTipoPessoa('')
            setCpf('')
            setEmail('')
            setRazaoSocial('')
            setTelefone('')
            props.setFoiLimpado(false)
        }

        handlerAtivarBotoes()
        props.setPrimeiraVez(true)
    }, [nome,cnpj,tipoPessoa,cpf,email,razaoSocial,telefone,props.foiLimpado]);

    function validarNumero(id, valor){
        const filtro = /^[0-9]+$/;
        if (valor === '' || filtro.test(valor)) {
            if(id === "campo-texto-cnpj"){
                setCnpj(valor)
            }  
            if(id === "campo-texto-cpf"){
                setCpf(valor)
            }
            if(id === "campo-texto-telefone"){
                setTelefone(valor)
            }
        }
    }

    return (
        <Form ref={props.modal} onSubmit={props.enviarFiltros} className="drop-acoes-container">
                <div className="campo-select-triangulo-menor-cor-2"></div>
                <div className="drop-acoes-corpo fundo-cor-7">
                    <div className="drop-acoes-lista-menu">
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-nome","imagem-filtro-nome"); ColetaClickstream(1314) /*pavClienteTabelaGridDropFiltrosNome*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Nome </Form.Label>
                            <img id={"imagem-filtro-nome"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-nome"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_nome"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                placeholder="Nome do cliente"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-tipo-pessoa","imagem-filtro-tipo-pessoa"); ColetaClickstream(1315) /*pavClienteTabelaGridDropFiltrosTipoDePessoa*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Tipo de pessoa </Form.Label>
                            <img id={"imagem-filtro-tipo-pessoa"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-tipo-pessoa"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_pav_tipo_pessoa"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                placeholder="Tipo de pessoa"
                                value={tipoPessoa}
                                onChange={(e) => setTipoPessoa(e.target.value)}
                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-cnpj","imagem-filtro-cnpj"); ColetaClickstream(1316) /*pavClienteTabelaGridDropFiltrosCNPJ*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">CNPJ </Form.Label>
                            <img id={"imagem-filtro-cnpj"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-cnpj"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_cnpj"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="14"
                                placeholder="CNPJ do cliente"
                                value={cnpj}
                                onChange={(e) => validarNumero("campo-texto-cnpj", e.target.value)}

                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-cpf","imagem-filtro-cpf"); ColetaClickstream(1317) /*pavClienteTabelaGridDropFiltrosCPF*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">CPF </Form.Label>
                            <img id={"imagem-filtro-cpf"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-cpf"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_cpf"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="11"
                                placeholder="CPF do cliente"
                                value={cpf}
                                onChange={(e) => validarNumero("campo-texto-cpf", e.target.value)}
                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-razao-social","imagem-filtro-razao-social"); ColetaClickstream(1318) /*pavClienteTabelaGridDropFiltrosRazaoSocial*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Razão social </Form.Label>
                            <img id={"imagem-filtro-razao-social"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-razao-social"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_pav_cliente"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                placeholder="Razão Social do cliente"
                                value={razaoSocial}
                                onChange={(e) => setRazaoSocial(e.target.value)}
                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-email","imagem-filtro-email"); ColetaClickstream(1319) /*pavClienteTabelaGridDropFiltrosEmail*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">E-mail </Form.Label>
                            <img id={"imagem-filtro-email"}className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        <div id={"filtro-email"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <Form.Control
                                name="ds_email"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="45"
                                placeholder="E-mail do cliente"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                        </div>
                        {/* ------------------- */}
                        
                        <div className="drop-filtros-menu" onClick={e => {props.dropFiltros(e, "filtro-telefone","imagem-filtro-telefone"); ColetaClickstream(1320) /*pavClienteTabelaGridDropFiltrosTelefone*/}}>
                            <Form.Label className="drop-filtros-menu-label fonte-cor-1 label-filtros-seta">Telefone </Form.Label>
                            <img id={"imagem-filtro-telefone"} className="drop-acoes-menu-icone-desativado nao-selecionavel" src={setaDropdown()} />
                        </div>
                        <div id={"filtro-telefone"}  className="drop-filtros-lista-submenu dropdown-desativado-filtros">
                            <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                            <Form.Control
                                name="ds_telefone"
                                className="campo-texto-cor-3 campo-texto-filtro"
                                type="text"
                                maxLength="12" // TODO: Validar. Por algum motivo conseguiram cadastrar telefone com espaço, ex: "11 251236551"
                                placeholder="Telefone"
                                value={telefone}
                                onChange={(e) => validarNumero("campo-texto-telefone", e.target.value)}
                            />
                        </div>
                        {/* ------------------- */}
                        <div className="drop-filtros-divisora-final campo-select-divisor-cor-1"></div>
                        <div className="botao-central-filtros">
                            <Form.Row>
                                <Col>
                                    <Button disabled={props.foiSelecionado?false:true} type="submit" onClick={() => handleFuncaoEstadosRedux()} className={props.foiSelecionado?"botao-aplicar-filtro-habilitado fonte-cor-4":"botao-aplicar-filtro-disable"} variant="default">
                                        Filtrar
                                    </Button>
                                    <Button disabled={props.foiSelecionado?false:true} type="button" onClick={props.limparFiltros} className={props.foiSelecionado?"botao-limpar-filtro-habilitado fonte-cor-2" :"botao-limpar-filtro-disable"} variant="default">
                                        Limpar
                                    </Button>
                                </Col>
                            </Form.Row>
                        </div>
                    </div>
                </div>
            </Form>
    );
}

export default Filtros;
