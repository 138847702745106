/** LISTA DE CLIENTES DO CLIENTE DA TELA DO BACK-OFFICE 
 * 
 * ULTIMA ALTERAÇÃO: 2021-04-30
 * POR: JOAO VICTOR SCHOEN MUNHOZ
 * MOTIVO: IMPLEMENTAÇÃO DA TABELA GENÉRICA PARA ADICIONAR LAZY LOADING AO COMPONENTE
 */


//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col } from 'react-bootstrap';/*eslint-disable*/
import TabelaGenerica from '../../../../componentesModais/tabela/tabela'
// import TabelaGenerica from '../../../componentesModais/tabela/tabela'
import {api} from '../../../../../conexoes/api'
import decryptJWT from '../../../../../utils/decryptJWT'

//ICONES
import { elipseVerde } from "../../../../../utils/alternanciaIcones";
//MENSAGENS
import MensagemErro from '../../../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../../../componentesModais/mensagens/mensagemSucesso'
// SPINNER
import SpinerVeye from '../../../../../components/spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch,useSelector } from 'react-redux';
import { revelarAlterarAdicionarLogin,revelarDoppelgangerCliente, mudarDoppleGangerCliente, visualizarDadosRefresh } from "../../../../../redux/actions/index";

import AlterarLogin from '../../../../painelVirtueyes/alterarLogin/alterarLogin'

//VARIÁVEIS DE SESSÃO
let offset = 0
let uniqueKeys = []

export default function InformacoesLogin(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [cdCliente, setCdCliente] = useState()
    const [dsCliente, setDsCliente] = useState('NULL');
    const [renderizar, setRenderizar] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Por favor tente novamente mais tarde.")
    const [nome, setNome] = useState()
    const [sobrenome, setSobrenome] = useState()
    const [cpf, setCpf] = useState()
    const [cnpj, setCnpj] = useState()
    const [email, setEmail] = useState()
    const [telefone, setTelefone] = useState()
    const [cdPerfil, setCdPerfil] = useState()
    const [dsPerfil, setDsPerfil] = useState()
    const [cdUsuario, setCdUsuario] = useState()
    const despacho = useDispatch();
    //VARIAVEIS DE PAGINACAO CLIENTE DO CLIENTE
    const limitePagina = 7
    const [initialLoad,setInitialLoad] = useState(false)
    const [paginaAtual,setPaginaAtual] = useState(1)
    const [ordenador,setOrdenador] = useState("")
    //CONTEUDO TABELA
    const [itens, setItens] = useState([])
    const [numeroItens,setNumeroItens] = useState(-1)
    //MENSAGEM DE TELA VAZIA
    const [emptyResponseMessage,setEmptyResponseMessage] = useState("")

    let reduxRevelarAlterarAdicionarLogin =  useSelector(state => state.virtueyesState.revelarAlterarAdicionarLogin);
    let revelarDoppelgangerClienteVar =  useSelector(state => state.virtueyesState.revelarDoppelgangerCliente);
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);

    useEffect(() => {
        setSpinAtivo(true)
        async function handleCarregarInformacoesLogin(){
            //CARREGAMENTO INICIAL DO COMPONENTE
            offset = (paginaAtual - 1) * limitePagina
            if(initialLoad === false)
            {
                let resposta = await api.post('/m1/consultar/painel_virtueyes_detalhamento_cliente_clientes_do_cliente_tabela',{
                    "codigo_cliente":props.cdCliente,
                    "limit":limitePagina,
                    "offset":offset,
                    "ordenador":ordenador,
                    "dataSet":uniqueKeys
                })
                //CRIAÇÃO DE DATASET PARA ACESSO RÁPIDO UTILIZANDO UNIQUE KEYS 
                resposta.data.dados.map((item)=>{
                    uniqueKeys.push(item.CD_PAV_CLIENTE)
                })
                if(uniqueKeys.length === 0){
                    setEmptyResponseMessage("Este cliente nao possui clientes")
                }
                setNumeroItens(uniqueKeys.length)
                setInitialLoad(true)
            }
            //ROTINA DE PAGINAÇÃO
            let resposta = await api.post('/m1/consultar/painel_virtueyes_detalhamento_cliente_clientes_do_cliente_tabela',{
                "codigo_cliente":props.cdCliente,
                "limit":limitePagina,
                "offset":offset,
                "ordenador":ordenador,
                "dataSet":uniqueKeys
            })
            setItens(resposta.data.dados)
            setSpinAtivo(false)
        }
        handleCarregarInformacoesLogin();
    }, [props.cdCliente, paginaAtual])

    useEffect(() => {
        if(cdCliente > 0 && revelarDoppelgangerClienteVar ===  true){
            handleDoppelgangerCliente()
        }
    }, [revelarDoppelgangerClienteVar])

    async function handleDoppelgangerCliente(cdCliente, noCdCliente, ds_nome,n0_nivel){
        setCdCliente(cdCliente)
        setDsCliente(ds_nome)
        if(cdCliente !== undefined && noCdCliente !== undefined ){
            setSpinAtivo(true)
            let fatContas = []
            const contasUsuario = await api.get("m1/consultar/fat_conta/contas_usuario?cd_pav_usuario="+decryptJWT('codigo'))
            await Promise.all (contasUsuario.data.dados.map((item)=>{
                fatContas.push(item.cd_fat_conta)
            }))
            if(contasUsuario.data.dados.length == 0){
                fatContas = [0]
            }
            const request = "/m1/servicos/alterar_token?login="+decryptJWT('login')+"&email="+decryptJWT('email')+"&codigo="+decryptJWT('codigo')+
                        "&codigoCliente="+cdCliente+"&codigoClienteErpAntigo="+noCdCliente+"&codigoClienteTexto="+noCdCliente+
                        "&nomeDeUsuario="+decryptJWT('nomeDeUsuario')+"&perfil="+decryptJWT('perfil')+"&cdSmsContaUsuario="+decryptJWT('cdSmsContaUsuario')+
                            "&id="+decryptJWT("id")+"&fatContas="+fatContas+"&clienteNivel="+n0_nivel+"&tipoPerfil=" +decryptJWT("tipoPerfil")
                            +"&clientePrimario=" +decryptJWT("clientePrimario")
                
            await api.get(request)
            .then(function(response){
                localStorage.setItem('@veye-Token', response.data.dados.token)
            })
            .catch(function(error){
            })
            setRevelarMensagemSucesso(true)
        }
        setSpinAtivo(false)
        despacho(mudarDoppleGangerCliente(false))
    }

    function handleAbrirEdicaoUsuario(){
        despacho(revelarAlterarAdicionarLogin(true))
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNome(row.ds_pav_usuario)
            setSobrenome(row.ds_pav_usuario_sobrenome)
            setEmail(row.ds_email)
            setTelefone(row.ds_telefone)
            setCdPerfil(row.cd_pav_perfil)
            setDsPerfil(row.ds_pav_perfil)
            setCdUsuario(row.cd_pav_usuario)
            setCpf(row.ds_cpf)
            setCnpj(row.ds_cnpj)
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDoppelgangerCliente(false))
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar))
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }
    
    return(
        <div className="container-informacoes-login">
            {reduxRevelarAlterarAdicionarLogin === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-adicionar-login-container">
                        <AlterarLogin
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            codigo_cliente={props.noCdCliente}
                            nome={nome}
                            sobrenome={sobrenome}
                            email={email}
                            telefone={telefone}
                            cdPerfil={cdPerfil}
                            dsPerfil={dsPerfil}
                            cdUsuario={cdUsuario}
                            cpf={cpf}
                            cnpj={cnpj}
                        />
                    </div>
                </div>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>}
            <div className="container-informacoes-login-telas">
                <div className="container-informacoes-login-resumo fundo-cor-7">
                    <Form.Row>
                        <Col>
                            <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                            <Form.Label>
                                <div className="fonte-cor-1 label-janela">Clientes</div>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                    { itens != '' && itens != undefined && itens != null &&
                        <TabelaGenerica  
                            tabela={"componente_virtueyes_detalhamento_cliente-clientes"}
                            ordenador={ordenador}
                            setOrdenador={setOrdenador}
                            limitePagina={limitePagina}
                            paginaAtual={paginaAtual}
                            setPaginaAtual={setPaginaAtual}
                            itens={itens}
                            totalItens={numeroItens}
                            />
                    }
                    {   initialLoad === true &&                        
                        emptyResponseMessage != "" &&
                        <div className="fonte-cor-1">{emptyResponseMessage}</div>                        
                    }
                                        
                </div>
            </div>
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"O cliente "+dsCliente+","}
                            mensagemParagrafo2={"cód. "+cdCliente+" foi carregado."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
        </div>
    )
}