/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react"; /*eslint-disable*/
import { api, api_s3 } from "../../../conexoes/api";/*eslint-disable*/
import Dropzone from "react-dropzone";
import { uniqueId } from "lodash";
import decryptJWT from "../../../utils/decryptJWT";
// import { CSVLink } from 'react-csv'
import { ReactComponent as SvgArquivo } from "../../../icones/misc/arquivo.svg"; 
import { ReactComponent as SvgDownload } from "../../../icones/misc/download.svg";
import { ReactComponent as SvgUpload } from "../../../icones/misc/upload.svg";
import { ReactComponent as SvgLixeira } from "../../../icones/misc/lixeira.svg";
import "./uploader.css";
import { exportarXlsx } from "../../../utils/exportarArquivo";

export default function Uploader(props) {
    const [arquivosCarregados, setArquivosCarregados] = useState([])
    const [listaArquivosCarregados, setListaArquivosCarregados] = useState([])
    const [dadosArquivo, setDadosArquivo] = useState([])
    const [revelarTemplate, setRevelarTemplate] = useState(false)
    const botaoTemplateUploader = useRef()

    //MONITORA O BOTÃO DE TEMPLATE
    const functionMonitorarArea = e => {
        if (!botaoTemplateUploader.current.contains(e.target)) {
            setRevelarTemplate(false)
        }
        else{
            setRevelarTemplate(true)
        }
    }

    //INICIA O MONITORAMENTO DO BOTÃO DE TEMPLATE
    useEffect(() => {
        document.addEventListener('mousedown', functionMonitorarArea)
        return () => document.removeEventListener('mousedown', functionMonitorarArea)
    })

    //CARREGA OS ARQUIVOS NO FRONT-END
    function functionCarregarArquivo(arquivos) {
        const auxArquivos = arquivos.map((item) => ({
            item,
            "id": uniqueId(),
            "name": item.name,
            "progress": 0,
            "error": false,
            "url": null,
            "uploaded": true,
        }));
        
        setArquivosCarregados(auxArquivos)

        auxArquivos.forEach(functionProcessarArquivo)
    };

    //ENVIA OS ARQUIVOS PARA A S3 PARA PROCESSAMENTO SERVER-SIDE
    async function functionProcessarArquivo(uploaded){
        props.setCarregando(true)
        let data = new FormData();
        data.append('file', uploaded.item);
        data.append('cd_usuario', decryptJWT('codigo'));
        data.append('onlyReadFile', true);
        // data.append('cd_pav_evento_parametro_tipo', 6);
        
        await api_s3.post("/s3/leitura/arquivo", data) 
        .then(async response => {
            if (response.data.status === 200) {
                let dadosArquivoTemp = dadosArquivo
                dadosArquivoTemp.push(1)
                // dadosArquivoTemp.push(response.data.idInserido)
                
                setDadosArquivo(dadosArquivoTemp)
                
                if(props.setDadosArquivo !== undefined){
                    props.setDadosArquivo(dadosArquivoTemp)
                }

                // await api.post("/m1/consultar/evento_parametro", {
                //     "bi_uuid": dadosArquivoTemp[0]
                // })
                // .then(async (response) => {
                    let linhas = []

                    //if(response.status === 200){ 
                        // let auxLinhas = JSON.parse(response.data.dados[0].ds_body)
                        let auxLinhas = response.data.bodyJson;

                        function getObjectKey(strKey, obj) { // faz -> 'TEXTO' = 'texto' = 'Texto'
                            const arrKeys = Object.keys(obj);
                            const filteredItem = arrKeys.filter(item => item.toLowerCase() === strKey)
                            return filteredItem[0]
                        }

                        if(!auxLinhas.length) return;

                        if(props.linha === true){
                            const keyLinha = getObjectKey('linha', auxLinhas[0]);
                            const keyICCID = getObjectKey('iccid', auxLinhas[0]);
                            const keyICCID_ANTIGO = getObjectKey('iccid_antigo', auxLinhas[0]);
                            const keyICCID_NOVO = getObjectKey('iccid_novo', auxLinhas[0]);

                            await Promise.all(auxLinhas.map((item)=>{
                                const auxLinha = item[keyLinha];
                                const auxICCID = item[keyICCID];
                                const auxICCID_ANTIGO = item[keyICCID_ANTIGO];
                                const auxICCID_NOVO = item[keyICCID_NOVO];

                                const objLinha = {}
                                if(auxLinha) {
                                    objLinha.linha = auxLinha;
                                }
                                if(auxICCID) {
                                    objLinha.iccid = auxICCID;
                                }
                                if(auxICCID_ANTIGO) {
                                    objLinha.iccid_antigo = auxICCID_ANTIGO;
                                }
                                if(auxICCID_NOVO) {
                                    objLinha.iccid_novo = auxICCID_NOVO;
                                }

                                linhas.push(objLinha) 
                                // linhas.push({'linha':auxLinha, 'iccid':auxICCID}) 
                            }))
                        }else{
                            const keyICCID = getObjectKey('iccid', auxLinhas[0]);
                            await Promise.all(auxLinhas.map((item)=>{
                                const auxICCID = item[keyICCID];
                                linhas.push({'iccid':auxICCID}) 
                            }))
                        }

                        if(props.validacao !== undefined && props.validacao !== false){
                            await props.validacao(linhas)
                        }

                        if(props.callback !== undefined){
                            props.callback(linhas)
                        }
                    // }
                // })
                props.setCarregando(false)
            }
            // props.setCarregando(false)
        })
        .catch((error) => {
            props.setCarregando(false)
        });
    };

    //REMOVE O ARQUIVO DESIGNADO DA LISTA RENDERIZADA NO FRONT-END
    function functionRemoverArquivo(arquivo){
        arquivosCarregados.forEach((item, i) => {
            if(item.id === arquivo){
                props.setDadosArquivo([])
                setDadosArquivo([])
                arquivosCarregados.splice(i, 1)
                setDadosArquivo([])
                props.callback('remover')
                return
            }
        })

        functionListaArquivos()
    }

    //MONTA A LISTA DE ARQUIVOS CARREGADOS QUE É EXIBIDA NO FRONT-END
    function functionListaArquivos(){
        let auxLista = arquivosCarregados.map((item) => {
            return(
                <li className="item-lista-uploader-arquivos"
                        key={item.id}
                >
                    {/* <SvgArquivo className="icone-item-lista-uploader-arquivos icon-ativo"/> */}
                    <SvgLixeira className="icone-remover-item-lista-uploader-arquivos icon-alerta"
                        onClick={() => {
                            functionRemoverArquivo(item.id)
                        }}
                    />
                    <span className="label-campo-modal-cadastro">
                        {item.name} - {parseFloat(item.item.size/1024).toFixed(2)} KB
                    </span>
                </li>
            )
        })

        setListaArquivosCarregados(auxLista)
    }

    //RODA QUANDO UM ARQUIVO É CARREGADO
    useEffect(() => {
        if(arquivosCarregados.length > 0){
            functionListaArquivos()
        }
    },[arquivosCarregados])

    //EXPORTA TEMPLATE EM FORMATO XLS
    function functionExportarTemplateXLS(){
        exportarXlsx(props.template.XLS, "template.xls")
        setRevelarTemplate(false)
    }

    return (
        <div className="container-uploader-arquivos">
            {/* <CSVLink className="d-none" id="baixaCSV"
                data={props.template.CSV}
                filename="template.csv"
            /> */}
            <div className="container-botoes-uploader-arquivos">
                <Dropzone className="container-botao-upload-uploader-arquivos" 
                    onDropAccepted={(e) => {
                        functionCarregarArquivo(e)
                    }}
                >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <>
                            <button disabled={props.disabled} className={props.disabled===false?"botao-primario-sistema botao-uploado-upload-arquivos":"botao-primario-sistema botao-uploado-upload-desabilitado"}
                                {...getRootProps()}
                                isDragActive={isDragActive}
                            >
                                <input {...getInputProps()} />
                                Upload
                            </button>
                            <SvgUpload className={props.disabled===false?"icone-botao-upload icon-normal":"icone-botao-upload-desativado icon-normal"}/>
                        </>
                    )}
                </Dropzone>
                <div className="container-botao-template-uploader-arquivos" ref={botaoTemplateUploader}>
                    <div className={"botao-template-container"}>
                        <button className="botao-template-upload-arquivos campo-texto-cor-3">
                            Template
                        </button>
                        <SvgDownload className="icone-botao-template icon-ativo"/>
                    </div>
                    { revelarTemplate === true &&
                        <>
                            <div className={"botao-template-list-container-triangle campo-select-triangulo-cor-1"}/>
                            <div className={"botao-template-list-container campo-texto-cor-3"}>
                                <ul className={"botao-template-list"}>
                                    {/* <li className={"botao-template-list-item"}
                                        onClick={() => {
                                            document.getElementById('baixaCSV').click()
                                            setRevelarTemplate(false)
                                        }}
                                    >
                                        <label class="botao-template-item-checkbox-container">
                                            <span className={"botao-template-list-item-span"}>
                                                CSV
                                            </span>
                                        </label>
                                    </li> */}
                                    <div className={"botao-template-list-item-dividing-line campo-select-divisor-cor-1"}/>
                                    <li className={"botao-template-list-item"}
                                        onClick={() => functionExportarTemplateXLS()}
                                    >
                                        <label class="botao-template-item-checkbox-container">
                                            <span className={"botao-template-list-item-span"}>
                                                XLS
                                            </span>
                                        </label>
                                    </li>
                                    <div className={"botao-template-list-item-dividing-line campo-select-divisor-cor-1"}/>
                                </ul>
                            </div>
                        </>
                    }
                </div>
            </div>
            {listaArquivosCarregados.length > 0 &&
                <div className="container-lista-uploader-arquivos"> 
                    <ul className="lista-uploader-arquivos">
                        {listaArquivosCarregados}
                    </ul>
                </div>
            }
        </div>
    )
}