import React, {useState, useEffect} from 'react';/*eslint-disable*/
import { useHistory,useLocation } from 'react-router-dom';/*eslint-disable*/
import { Button, Image, Form, InputGroup, FormControl, Col, Carousel} from 'react-bootstrap';
import Lottie from 'react-lottie';
import emailValidator from 'email-validator';
import decryptJWT from '../../utils/decryptJWT'
import logoVirtueyes from '../../icones/iconesVersaoEscura/logo_virtueyes_branco.svg'
import logoViverRastreamento from '../../icones/logo/rastreamento.svg'
import logo from '../../icones/iconesVersaoClara/logo_veye_novo_escuro.svg'
import olhoAtivo from '../../icones/iconesVersaoEscura/icone-olho-ligado-cinza.svg';
import olhoDesativo from '../../icones/iconesVersaoEscura/icone-olho-desligado-cinza.svg';
import imagem1 from '../../icones/imagem_login.jpg';
import fundoVerde from '../../icones/fundo_verde.svg';
import Keycloak from 'keycloak-js'
// ICONES ALGAR
import fundoLogin from '../../icones/fundo-login-algar.jpg';
import setasLogin from '../../icones/setas-login.svg';
import logoLogin from '../../icones/logo-algar-login.svg';
import poweredLogin from '../../icones/powered-by-veye-2.svg';
import AlgarLoadingPage from './loadingPage';

import '../../css/login/login.css';
import MensagemErro from '../componentesModais/mensagens/mensagemErro'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'

import { login } from "../../servicos/auth";
import {colunasFaturas,colunasSimcard} from '../../utils/colunas'
import {logoVeye} from "../../utils/alternanciaIcones";
//REQUIRE AXIOS

//useMediaQuery
import { useMediaQuery } from 'react-responsive'
import { alterarColunasSimcard, cadastrarColunasSimcard, getColunasFaturas, getColunasSimcard, getEmailRecuperacao, getInformacoesAdicionaisCustomizadasClientes, getListaParceiros, postServicosDadosLogin, postServicosDadosLoginAlgar, postUsuarioRecuperacao, tokenRecuperacaoOk } from './requisicoes';

let colunasAux = [];
let colunasFaturasAux = [];





export default function TelaLogin(props) {
    const isMobile = useMediaQuery({maxWidth: 829});
    const [icons, setIcons] = useState(true);
    const [iconsNovaSenha, setIconsNovaSenha] = useState(true);
    const [iconsConfirmarNovaSenha, setIconsConfirmarNovaSenha] = useState(true);
    const [mostrarEsconder, setMostrarEsconder] = useState("password");
    const [mostrarEsconderNovaSenha, setMostrarEsconderNovaSenha] = useState("password");
    const [mostrarEsconderConfirmarNovaSenha, setMostrarEsconderConfirmarNovaSenha] = useState("password");
    const [carregando, setCarregando] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [senhaError, setSenhaError] = useState("");
    const history = useHistory();
    const [spinAtivo, setSpinAtivo] = useState(false);
    const [emailRecuperarSenha, setEmailRecuperarSenha] = useState('');
    const [mostrarRecuperarSenha, setMostrarRecuperarSenha] = useState('padrao');
    const [emailUrl, setEmailUrl] = useState('');
    const [tokenUrl, setTokenUrl] = useState('');
    const [mostrarEmailSenha, setMostrarEmailSenha] = useState(true);
    const [revelarMensagemTokenInvalido, setRevelarMensagemTokenInvalido] = useState(false);
    const [definirLogin, setDefinirLogin] = useState('veye');
    const [parceiroData, setParceiroData] = useState();
    const [parceiro, setParceiro] = useState('Veye')
    const [logoParceiro, setLogoParceiro] = useState(logo);
    const [logoParceiroCarrossel, setLogoParceiroCarrossel] = useState();





    //VALIDAÇÃO NECESSÁRIA PARA O LOGIN ALGAR FUNCIONAR

    let sso = props.location.sso? true: false
    let algar = props.location.algar? true: false

    if(sso){

        localStorage.setItem("SSO", sso);

    }else if (algar){

        localStorage.setItem("ALGAR", algar);

    }else{

        let ssoTemp = localStorage.getItem("SSO");

        if(ssoTemp != undefined && ssoTemp != '' && ssoTemp != null){

            sso = true;

        }else{

            let algarTemp = localStorage.getItem("ALGAR");

            if(algarTemp != undefined && algarTemp != '' && algarTemp != null){

                algar = true;

            }
        }
    }

    //UseEffect que Verifica o hostname do URL
    useEffect(() => {
        let parceiroData
        getListaParceiros(window.location.host).then((response) =>{
            parceiroData = response;
            if (parceiroData.dados) {
                setParceiroData(parceiroData.dados);
                setParceiro(parceiroData.dados.NomeDoParceiro)
                setLogoParceiro(parceiroData.dados.Logo);
                localStorage.setItem('fl_chat', parceiroData.dados.ChatBot)
            } else {
                setParceiro('Veye')
            }
            setCarregando(true)
        })

    },[]);

    async function redefinirSenhaEffect(){
        if(window.location.href.indexOf("token") != -1){
            let verificaUrl = window.location.href.split('/');
            let verificaUrl2 = verificaUrl[3].split('&');
            let verificaUrlToken = verificaUrl2[0].split(':');
            let verificaUrlEmail = verificaUrl2[1].split(':');
            setTokenUrl(verificaUrlToken[1]);
            setEmailUrl(verificaUrlEmail[1]);
            const validacaoTokenEmail =  await tokenRecuperacaoOk(verificaUrlToken[1],verificaUrlEmail[1])
            if(validacaoTokenEmail === false){
                setRevelarMensagemTokenInvalido(true)
            }else{
            setMostrarRecuperarSenha('novaSenha')
            setMostrarEmailSenha(false)
            }
        }
    }

    useEffect(() => {
         redefinirSenhaEffect()
            algarSso()



    },[]);

    async function algarSso(){

        if(sso){
            const keycloak = Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`);
            keycloak.init({onLoad: 'login-required'}).then(authenticated => {
                /* setAuthenticated(authenticated),
                setKeycloakData(keycloak) */
               let user = keycloak.idTokenParsed.email

               loginAlgar(user)

            })
        }
    }


    async function handlerConsultarColunasPersonalizadas(){
        await getInformacoesAdicionaisCustomizadasClientes()
        .then(function (response) {
            colunasSimcard[40].nome = response.data.dados[0].TITULO
            colunasSimcard[41].nome = response.data.dados[1].TITULO
            colunasSimcard[42].nome = response.data.dados[2].TITULO
        })
    }

    function alterarSenha(e, setValue) {
        const newValue = e.target.value;
        setValue(newValue);
    }

    useEffect(() => {
        validaSenha();
    }, [novaSenha, confirmarNovaSenha])

    function validaSenha() {
        if(novaSenha.length >= 4 && novaSenha == confirmarNovaSenha) {
            setSenhaError('')
            return true;
        }
        else{
            if(novaSenha !== confirmarNovaSenha) {
                setSenhaError("senhaDiferente")
            }
            else {
                setSenhaError('senhaErroGenerico')
            }
            return false;
        }
    }

    // pega o parametro atribuido pela variavel setMostrarRecuperarSenha('') e muda as telas
    async function RedefinirSenha(){
        if (validaSenha()){
            await postUsuarioRecuperacao({ tokenUrl, novaSenha, emailUrl })
            .then(function (response) {
                setMostrarRecuperarSenha('senha_redefinida')
            })
            .catch(function (error) {
            })

        }
    }

    async function loginAlgar(user){

        setSpinAtivo(true)
        await postServicosDadosLoginAlgar(user)
        .then(async function (response) {
            await login(response.data.dados);
            await history.push('/veye', {
                'permissao' : response.data.dados.permissao,
                state: { detail: 'some_value' }

            })
            localStorage.setItem('codigoClienteErpAntigo',response.data.dados.usuario.codigoClienteErpAntigo)
            localStorage.setItem('clientePrimario',decryptJWT ('clientePrimario'))

            await handleAtualizarColunasSimcardAlgar(user)
            await handleAtualizarColunasFaturasAlgar(user)
            await atualizarColunasMongoAlgar(user)
            await cadastroColunasMongoAlgar(user)

            await handlerConsultarColunasPersonalizadas()
            if(localStorage.getItem("clientePrimario") == 2455){
                window.location.reload()
            }
        })
        .catch(async function (error) {
            await history.push('/algarError');
             setSpinAtivo(false)
            setLoginError(true)
        });
    }

    async function efetuarLogin(e){
        if(localStorage.hasOwnProperty("keyOperadora")){
            localStorage.removeItem("keyOperadora");
        }
        if(localStorage.hasOwnProperty("keyStatusContrato")){
            localStorage.removeItem("keyStatusContrato");
        }
        setLoginError("");

        if(!email || !password){
            setLoginError("Preencha e-mail e senha!");
        }else {
            setSpinAtivo(true)

            grecaptcha.enterprise.ready(async () => {
                const chavePublica = '6Ldfx5cpAAAAABK8SEa_sHyuDN_7hMOjV0CPtQ6A'; // OBS: quando alterar aqui tem que alterar em /public/index.html também

              const token = await grecaptcha.enterprise.execute(chavePublica, {action: 'login'});

              await postServicosDadosLogin({ email, password, token })
              .then(async function (response) {
                  await login(response.data.dados);
                  await history.push('/veye', {
                      'permissao' : response.data.dados.permissao
                  })
                  localStorage.setItem('codigoClienteErpAntigo',response.data.dados.usuario.codigoClienteErpAntigo)
                  localStorage.setItem('clientePrimario',decryptJWT ('clientePrimario'))
                  localStorage.setItem('clienteOriginal', decryptJWT ('clienteOriginal'))

                  if(parceiro === 'Veye'){
                      localStorage.setItem('fl_chat', response.data.dados.usuario.fl_chat)
                  }


                  await handleAtualizarColunasSimcard()
                  await handleAtualizarColunasFaturas()
                  await atualizarColunasMongo()
                  await cadastroColunasMongo()
                  await handlerConsultarColunasPersonalizadas()
                  if(localStorage.getItem("clientePrimario")){
                      window.location.reload()
                  }
              })
              .catch(function (error) {
                  setSpinAtivo(false)
                  setLoginError(true)
              });
            });
        }
    }

    function removerColunasDuplicadasConectividade(colunas){
        let arrayNovo = [];
        colunas.forEach((item) => {
            let duplicados  = arrayNovo.findIndex(itemDuplicado => {
                return item.id == itemDuplicado.id;
            }) > -1;

            if(duplicados === false) {
                arrayNovo.push(item)
                colunasAux = arrayNovo
            }
        });
    }

    function removerColunasDuplicadasFaturas(colunas){
        let arrayNovo = [];
        colunas.forEach((item) => {
            let duplicados  = arrayNovo.findIndex(itemDuplicado => {
                return item.id == itemDuplicado.id;
            }) > -1;

            if(duplicados === false) {
                arrayNovo.push(item)
                colunasFaturasAux = arrayNovo

            }
        });
    }

    async function atualizarColunasMongo(){
        await removerColunasDuplicadasConectividade(colunasAux)
        await removerColunasDuplicadasFaturas(colunasFaturasAux)

        if(colunasAux && colunasFaturasAux){
            ColetaClickstream(1439) // login
            await alterarColunasSimcard({ email, colunasAux, colunasFaturasAux })
        }
    }

    async function atualizarColunasMongoAlgar(user){
        await removerColunasDuplicadasConectividade(colunasAux)
        await removerColunasDuplicadasFaturas(colunasFaturasAux)

        if(colunasAux && colunasFaturasAux){
            ColetaClickstream(1439) // login

            await alterarColunasSimcard({ email: user, colunasAux, colunasFaturasAux })
        }
    }

    async function handleAtualizarColunasSimcard() {
        const usuarioColunas = await getColunasSimcard(email);

        const dadosColunasSimcard = usuarioColunas.data.dados.colunas;

        if(dadosColunasSimcard){ //POSTO ESSE IF POR CONTA DO TEMPO DE EXECUÇÃO QUE CRASHAVA O SISTEMA NO PRIMEIRO LOGIN DO USUÁRIO CRIADO PELO MENU VIRTUEYES.
            colunasSimcard.map((colunaLogin, i) => {
                const found = dadosColunasSimcard.some(el => el.id == colunaLogin.id)
                if(!found){
                    dadosColunasSimcard.splice(i, 0, colunaLogin)
                }
                dadosColunasSimcard.map((coluna, i) => {
                    if(colunaLogin.id == coluna.id){
                        if(colunaLogin.nome != coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasSimcard.indexOf(colunaLogin);
                        let colunaIndex = dadosColunasSimcard.indexOf(coluna);
                        if(colunaLoginIndex != colunaIndex){
                            dadosColunasSimcard.splice(colunaIndex, 1)
                            dadosColunasSimcard.splice(colunaLoginIndex, 0, coluna)
                        }
                        //ATUALIZA O VALOR DE nivel_acesso
                        coluna.nivel_acesso = colunaLogin.nivel_acesso
                    }
                })
                let posicaoNomeIncorreto;
                const colunasTemp = JSON.parse(JSON.stringify(dadosColunasSimcard));
                colunasTemp.splice(40,3)
                const foundNome = colunasTemp.some((el,ei) => {
                    posicaoNomeIncorreto = ei;
                    return el.nome == colunaLogin.nome
                })

                if(!foundNome){
                    dadosColunasSimcard[posicaoNomeIncorreto].nome = colunaLogin.nome
                }
            })

            dadosColunasSimcard.map((coluna, i) => {
                const found = colunasSimcard.some(el => el.id == coluna.id)
                if(!found){
                    dadosColunasSimcard.splice(i, 1)
                }
            })

            colunasAux = dadosColunasSimcard
            organizarColunasSimcard(colunasAux)
        }else{
        }
    }

    async function handleAtualizarColunasSimcardAlgar(user) {
        const usuarioColunas = await getColunasSimcard(user);
        const dadosColunasSimcard = usuarioColunas.data.dados.colunas;

    if(dadosColunasSimcard){ //POSTO ESSE IF POR CONTA DO TEMPO DE EXECUÇÃO QUE CRASHAVA O SISTEMA NO PRIMEIRO LOGIN DO USUÁRIO CRIADO PELO MENU VIRTUEYES.
        colunasSimcard.map((colunaLogin, i) => {
            const found = dadosColunasSimcard.some(el => el.id == colunaLogin.id)
            if(!found){
                dadosColunasSimcard.splice(i, 0, colunaLogin)
            }
            dadosColunasSimcard.map((coluna, i) => {
                if(colunaLogin.id == coluna.id){
                    if(colunaLogin.nome != coluna.nome){
                        coluna.nome = colunaLogin.nome
                    }
                    let colunaLoginIndex = colunasSimcard.indexOf(colunaLogin);
                    let colunaIndex = dadosColunasSimcard.indexOf(coluna);
                    if(colunaLoginIndex != colunaIndex){
                        dadosColunasSimcard.splice(colunaIndex, 1)
                        dadosColunasSimcard.splice(colunaLoginIndex, 0, coluna)
                    }
                    //ATUALIZA O VALOR DE nivel_acesso
                    coluna.nivel_acesso = colunaLogin.nivel_acesso
                }
            })
            let posicaoNomeIncorreto;
            const colunasTemp = JSON.parse(JSON.stringify(dadosColunasSimcard));
            colunasTemp.splice(40,3)
            const foundNome = colunasTemp.some((el,ei) => {
                posicaoNomeIncorreto = ei;
                return el.nome == colunaLogin.nome
            })

            if(!foundNome){
                dadosColunasSimcard[posicaoNomeIncorreto].nome = colunaLogin.nome
            }
        })

        dadosColunasSimcard.map((coluna, i) => {
            const found = colunasSimcard.some(el => el.id == coluna.id)
            if(!found){
                dadosColunasSimcard.splice(i, 1)
            }
        })

        colunasAux = dadosColunasSimcard
        organizarColunasSimcard(colunasAux)
    }else{
    }
}

    function organizarColunasSimcard(arrayColunas){
        colunasSimcard.map((colunasMock) => {
            arrayColunas.map((colunaLogin)=>{
                if(colunaLogin.id === colunasMock.id && colunaLogin.ordem !== colunasMock.ordem){
                    colunasMock.ordem = colunaLogin.ordem
                }
            })
        })
    }

    async function handleAtualizarColunasFaturas() {
        const usuarioColunas = await getColunasFaturas(email);

        const dadosColunasFaturas = usuarioColunas?.data?.dados?.colunasFaturas;

        if(dadosColunasFaturas) {
            colunasFaturas.map((colunaLogin, i) => {
                const found = dadosColunasFaturas.some(el => el.id == colunaLogin.id)
                if(!found){
                    dadosColunasFaturas.splice(i, 0, colunaLogin)
                }
                dadosColunasFaturas.map((coluna, i) => {
                    if(colunaLogin.id == coluna.id){
                        if(colunaLogin.nome != coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasFaturas.indexOf(colunaLogin);
                        let colunaIndex = dadosColunasFaturas.indexOf(coluna);
                        if(colunaLoginIndex != colunaIndex){
                            dadosColunasFaturas.splice(colunaIndex, 1)
                            dadosColunasFaturas.splice(colunaLoginIndex, 0, coluna)
                        }
                    }
                })
            })

            dadosColunasFaturas.map((coluna, i) => {
                const found = colunasFaturas.some(el => el.id == coluna.id)
                if(!found){
                    dadosColunasFaturas.splice(i, 1)
                }
            })

            colunasFaturasAux = dadosColunasFaturas
            organizarColunasFaturas(colunasFaturasAux)
        }else{
        }

    }

    async function handleAtualizarColunasFaturasAlgar(user) {
        const usuarioColunas = await getColunasFaturas(user);

        const dadosColunasFaturas = usuarioColunas?.data?.dados?.colunasFaturas;

        if(dadosColunasFaturas) {
            colunasFaturas.map((colunaLogin, i) => {
                const found = dadosColunasFaturas.some(el => el.id == colunaLogin.id)
                if(!found){
                    dadosColunasFaturas.splice(i, 0, colunaLogin)
                }
                dadosColunasFaturas.map((coluna, i) => {
                    if(colunaLogin.id == coluna.id){
                        if(colunaLogin.nome != coluna.nome){
                            coluna.nome = colunaLogin.nome
                        }
                        let colunaLoginIndex = colunasFaturas.indexOf(colunaLogin);
                        let colunaIndex = dadosColunasFaturas.indexOf(coluna);
                        if(colunaLoginIndex != colunaIndex){
                            dadosColunasFaturas.splice(colunaIndex, 1)
                            dadosColunasFaturas.splice(colunaLoginIndex, 0, coluna)
                        }
                    }
                })
            })

            dadosColunasFaturas.map((coluna, i) => {
                const found = colunasFaturas.some(el => el.id == coluna.id)
                if(!found){
                    dadosColunasFaturas.splice(i, 1)
                }
            })

            colunasFaturasAux = dadosColunasFaturas
            organizarColunasFaturas(colunasFaturasAux)
        }else{
        }

    }

    function organizarColunasFaturas(arrayColunas){
        colunasFaturas.map((colunasMock) => {
            arrayColunas.map((colunaLogin)=>{
                if(colunaLogin.id === colunasMock.id && colunaLogin.ordem !== colunasMock.ordem){
                    colunasMock.ordem = colunaLogin.ordem
                }
            })
        })
    }

    function validarEmail(email) {
        let validade = emailValidator.validate(email);
        setEmailError(!validade)
        
        return validade;
    }

    async function enviarEmail() {
        // let validade = validarEmail(emailRecuperarSenha);
        // if(!validade) return;

        setSpinAtivo(true)
        await getEmailRecuperacao(emailRecuperarSenha)

        setEmailError(false)
        setSpinAtivo(false)
        setMostrarRecuperarSenha('confirmacao_email')

        setEmailRecuperarSenha('')
    }

    async function cadastroColunasMongo(){
        await cadastrarColunasSimcard({ email, colunasSimcard, colunasFaturas })

    }

    async function cadastroColunasMongoAlgar(email){
        await cadastrarColunasSimcard({ email, colunasSimcard, colunasFaturas })
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
        }
    };

    function iconVizualizar() {
        if(mostrarEsconder === 'password')
            setMostrarEsconder('text')
        return <img className="olhoAtivo" src={olhoAtivo}/> ;
    }

    function iconNaoVizualizar() {
        if(mostrarEsconder === 'text')
            setMostrarEsconder('password')
        return <img className="olhoDesativo" src={olhoDesativo}/>;
    }

    function iconVizualizarNovaSenha() {
        if(mostrarEsconderNovaSenha === 'password')
            setMostrarEsconderNovaSenha('text')
        return <img className="olhoAtivo" src={olhoAtivo}/> ;
    }

    function iconNaoVizualizarNovaSenha() {
        if(mostrarEsconderNovaSenha === 'text')
            setMostrarEsconderNovaSenha('password')
        return <img className="olhoDesativo" src={olhoDesativo}/>;
    }

    function iconVizualizarConfirmarNovaSenha() {
        if(mostrarEsconderConfirmarNovaSenha === 'password')
            setMostrarEsconderConfirmarNovaSenha('text')
        return <img className="olhoAtivo" src={olhoAtivo}/> ;
    }

    function iconNaoVizualizarConfirmarNovaSenha() {
        if(mostrarEsconderConfirmarNovaSenha === 'text')
            setMostrarEsconderConfirmarNovaSenha('password')
        return <img className="olhoDesativo" src={olhoDesativo}/>;
    }

    function iconVizualizarNovaSenhaAlgar() {
        if(mostrarEsconderNovaSenha === 'password')
            setMostrarEsconderNovaSenha('text')
        return <img className="olhoAtivo" src={olhoAtivo}/> ;
    }

    function iconNaoVizualizarNovaSenhaAlgar() {
        if(mostrarEsconderNovaSenha === 'text')
            setMostrarEsconderNovaSenha('password')
        return <img className="olhoDesativo" src={olhoDesativo}/>;
    }

    function iconVizualizarConfirmarNovaSenhaAlgar() {
        if(mostrarEsconderConfirmarNovaSenha === 'password')
            setMostrarEsconderConfirmarNovaSenha('text')
        return <img className="olhoAtivo" src={olhoAtivo}/> ;
    }

    function iconNaoVizualizarConfirmarNovaSenhaAlgar() {
        if(mostrarEsconderConfirmarNovaSenha === 'text')
            setMostrarEsconderConfirmarNovaSenha('password')
        return <img className="olhoDesativo" src={olhoDesativo}/>;
    }

    function botaoPressionado(event) {
        if(event.key === "Enter"){
            efetuarLogin(event)
        }
    }

    function IrParaLogin(){
        setMostrarRecuperarSenha('padrao')
        setMostrarEmailSenha(true)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemTokenInvalido === false){
            setRevelarMensagemTokenInvalido(true)
        }else{
            setRevelarMensagemTokenInvalido(false)
        }
    }


    return (
           <>
            {carregando ?
                <>
                    {algar?
                        <div id="WrapperTelaLogin" className="WrapperTelaLogin">
                            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                            { revelarMensagemTokenInvalido &&
                                <div className="componente-modal-overlay">
                                    <div className="componente-modal">
                                        <MensagemErro
                                            handleMensagemErro={handleMensagemErro}
                                            mensagemTitulo={"Token inválido!"}
                                            mensagemBotao={"Retornar"}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="container-principal-imagens-algar">
                                <div className="container-campos-login">
                                    <div className="container-campos-login-algar">
                                        <Image className="logoAlgar" src={logoLogin} alt="logoAlgar"/>
                                        {mostrarRecuperarSenha === 'padrao' &&
                                            <>
                                                <div className="campos-texto-login">
                                                    <div className="container-email-algar">
                                                        <label className="label-campos-login-algar">E-mail</label>
                                                        <Form.Control
                                                            className="campo-email-login-algar"
                                                            type="email"
                                                            placeholder="E-mail"
                                                            onChange={e =>setEmail(e.target.value)}
                                                            value = {email}
                                                            onKeyDown={e => botaoPressionado(e)}
                                                            name="campoEmail"
                                                            id="campoEmail"
                                                        />
                                                        {loginError &&
                                                            <TextoDadosInvalidos />
                                                        }
                                                    </div>
                                                    <div className="container-campo-senha-algar">
                                                        <div className="container-senha-algar">
                                                            <label className="label-campos-login-algar">Senha</label>
                                                            <FormControl
                                                                className="campo-senha-login-algar"
                                                                placeholder="Senha"
                                                                type={mostrarEsconder}
                                                                onChange={e => setPassword(e.target.value)}
                                                                value = {password}
                                                                onKeyDown={e => botaoPressionado(e)}
                                                                name="campoSenha"
                                                                id="campoSenha"
                                                                maxLength="20"
                                                            />
                                                            <div variant="" className="ver-senha-algar" onClick={() => setIcons(!icons)}>
                                                                    {icons ? iconNaoVizualizar() : iconVizualizar()}
                                                            </div>
                                                        </div>
                                                        {/* {loginError &&
                                                            <TextoSenhaIncorreta />
                                                        } */}
                                                    </div>
                                                    <div className="container-texto-esqueci-senha">
                                                        <Button className="link-recuperar-senha-algar" onClick={() => setMostrarRecuperarSenha('recuperar_senha')}> Esqueci minha senha </Button>
                                                    </div>
                                                </div>
                                                <div className="container-entrar-algar">
                                                    <Button className="botao-entrar-algar" onClick={efetuarLogin} onKeyDown={e => botaoPressionado(e)}>Entrar</Button>
                                                </div>
                                                <a href="https://site.veye.com.br">
                                                    <div className="container-imagem-powered">
                                                        <Image className="imagem-powered-veye" src={poweredLogin} alt="poweredLogin" />
                                                    </div>
                                                </a>

                                            </>
                                        }
                                        {mostrarRecuperarSenha === 'recuperar_senha' &&
                                            <div className="container-recuperar-algar">
                                                <div className="container-texto-recuperar-senha-algar-1">
                                                    <p className="texto-recuperar-senha-algar-1"> Esqueceu sua senha? </p>
                                                </div>
                                                <div className="container-texto-recuperar-senha-algar-2">
                                                    <span className="texto-recuperar-senha-algar-2"> Enviaremos um e-mail para você redefinir sua senha. Insira o seu e-mail de cadastro abaixo. </span>
                                                </div>
                                                <div className="container-campo-texto-recuperar-senha">
                                                    <label className="label-campos-login-algar">E-mail</label>
                                                    <Form.Control
                                                        className="campo-email-login-algar"
                                                        type="email"
                                                        placeholder="Digite seu e-mail"
                                                        onChange={e =>setEmailRecuperarSenha(e.target.value)}
                                                    />
                                                    {emailError &&
                                                        <h6 className="mensagem-erro-login-algar"> Formato de e-mail inválido. </h6>
                                                    }
                                                </div>
                                                <div className="container-enviar-algar">
                                                {!emailError ?
                                                    <Button className="botao-entrar-algar" onClick={() => enviarEmail()}> Enviar </Button>
                                                :
                                                    <Button className="botao-entrar-algar-desabilitado"> Enviar </Button>
                                                }
                                                </div>
                                            </div>
                                        }

                                        {mostrarRecuperarSenha === 'confirmacao_email' &&
                                            <MensagemConfirmacaoEmail
                                                setMostrarRecuperarSenha={setMostrarRecuperarSenha}
                                                classNames={{
                                                    container: "container-recuperar-algar",
                                                    divTitulo: "container-texto-recuperar-senha-algar-1",
                                                    titulo: "texto-recuperar-senha-algar-1",
                                                    divDescricao: "container-texto-recuperar-senha-algar-2",
                                                    descricao: "texto-recuperar-senha-algar-2",
                                                    divBotao: "container-enviar-algar",
                                                    botao: "botao-entrar-algar"
                                                }}
                                            />
                                        }
                                        {mostrarRecuperarSenha === 'novaSenha' &&
                                            <div className="container-recuperar-algar">
                                                <div className="container-texto-recuperar-senha-algar-1">
                                                    <p className="texto-recuperar-senha-algar-1"> Defina sua nova senha</p>
                                                </div>
                                                <div className="container-texto-recuperar-senha-algar-2">
                                                    <span className="texto-recuperar-senha-algar-2"> Escolha uma senha entre 4 e 20 caracteres. </span>
                                                </div>
                                                <div className="container-senha-algar">
                                                    <label className="label-campos-login-algar">Senha</label>
                                                    <FormControl
                                                        className="campo-senha-login-algar"
                                                        placeholder="Nova senha"
                                                        type={mostrarEsconderNovaSenha}
                                                        onChange={e => alterarSenha(e, setNovaSenha)}
                                                        maxLength="20"
                                                        minLength="4"
                                                        name="campoNovaSenha"
                                                        id="campoNovaSenha"
                                                    />
                                                    <div className="ver-senha-algar" variant="" onClick={() => setIconsNovaSenha(!iconsNovaSenha)}>
                                                        {iconsNovaSenha ? iconNaoVizualizarNovaSenhaAlgar() : iconVizualizarNovaSenhaAlgar()}
                                                    </div>
                                                </div>

                                                <div className="container-senha-algar">
                                                    <label className="label-campos-login-algar">Senha</label>
                                                    <FormControl
                                                        className="campo-senha-login-algar"
                                                        placeholder="Confirmar nova senha"
                                                        type={mostrarEsconderConfirmarNovaSenha}
                                                        onChange={e => alterarSenha(e, setConfirmarNovaSenha)}
                                                        maxLength="20"
                                                        minLength="4"
                                                        name="campoNovaSenha"
                                                        id="campoNovaSenha"
                                                    />
                                                    <div className="ver-senha-algar" variant="" onClick={() => setIconsConfirmarNovaSenha(!iconsConfirmarNovaSenha)}>
                                                        {iconsConfirmarNovaSenha ? iconNaoVizualizarConfirmarNovaSenhaAlgar() : iconVizualizarConfirmarNovaSenhaAlgar()}
                                                    </div>
                                                </div>
                                                {senhaError == "senhaDiferente" &&
                                                    <h6 className="mensagem-erro-senha-diferente"> As senhas não estão iguais. Por favor, insira a mesma combinação nos 2 campos. </h6>
                                                }

                                                <div className="container-enviar-algar">
                                                    <Button className="botao-entrar-algar" variant="outline-light" onClick={RedefinirSenha}> Concluir </Button>
                                                </div>
                                            </div>
                                        }
                                        {mostrarRecuperarSenha === 'senha_redefinida' &&
                                            <div className="container-recuperar-algar">
                                                <div className="container-texto-recuperar-senha-algar-1">
                                                    <p className="texto-recuperar-senha-algar-1"> Senha definida com sucesso! </p>
                                                </div>
                                                <div className="container-texto-recuperar-senha-algar-2">
                                                    <span className="texto-recuperar-senha-algar-2"> Você já pode usar sua nova senha para efetuar seu login. </span>
                                                </div>
                                                <div className="container-enviar-algar">
                                                    <Button className="botao-entrar-algar" onClick={() => IrParaLogin()}> Ir para o login </Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="container-imagem-fundo">
                                    <Image className="imagem-fundo-setas" src={setasLogin} alt="fundoSetas"/>
                                    <Image className="imagem-fundo" src={fundoLogin} alt="fundoAlgar"/>
                                </div>
                            </div>
                        </div>
                    :sso?
                    <>
                    <AlgarLoadingPage/>

                    <div >
                            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                    </div>
                    </>
                    :
                        <div id="WrapperTelaLogin" className="WrapperTelaLogin">
                            { revelarMensagemTokenInvalido &&
                                <div className="componente-modal-overlay">
                                    <div className="componente-modal">
                                        <MensagemErro
                                            handleMensagemErro={handleMensagemErro}
                                            mensagemTitulo={"Token inválido!"}
                                            mensagemBotao={"Retornar"}
                                        />
                                    </div>
                                </div>
                            }
                            <div id="CorpoTelaLogin" className={mostrarRecuperarSenha === "novaSenha" ? "CorpoTelaLoginNovaSenha" :"CorpoTelaLogin"}>
                                <Form.Row className={mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida"  ? "container-form-row-cabecalho-nova-senha" : "container-form-row-cabecalho"}>
                                    <Col>
                                        <div className={ mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida" ? "container-principal-nova-senha" : "container-principal"}>
                                            <div className={ mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida" ? "container-logo-nova-senha" : "container-logo"}>
                                                {
                                                    //NESTE CASO PRECISA SER != 'veye'
                                                    parceiro !== 'Veye' ?
                                                    <Image className={ mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida" ? "logoVeye-nova-senha" : "logoParceiro"} src={logoParceiro} alt="Parceiro"/>
                                                        :
                                                    <Image className={ mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida" ? "logoVeye-nova-senha" : "logoVeye"} src={logoVeye()} alt="V.eye"/>
                                                }
                                                {parceiro !== 'Veye' ?
                                                    <div></div>
                                                    :
                                                    <div className={ mostrarRecuperarSenha === "novaSenha" ||
                                                    mostrarRecuperarSenha === "senha_redefinida" ? "texto-logo-nova-senha" : "texto-logo"}>Gestão inteligente de conectividade </div>
                                                }
                                            </div>
                                            {mostrarEmailSenha === true &&
                                                <div className="container-campo-email-senha">
                                                    <div className="container-email">
                                                        <Form.Control
                                                        className="campo-email"
                                                        type="email"
                                                        placeholder="E-mail"
                                                        onChange={e =>setEmail(e.target.value)}
                                                        value = {email}
                                                        onKeyDown={e => botaoPressionado(e)}
                                                        name="campoEmail"
                                                        id="campoEmail"
                                                        />
                                                        {loginError &&
                                                            <TextoDadosInvalidos />
                                                        }
                                                    </div>
                                                    <div className="container-campo-senha">
                                                        <InputGroup className="container-senha">
                                                            <FormControl
                                                            className="campo-senha"
                                                            placeholder="Senha"
                                                            type={mostrarEsconder}
                                                            onChange={e => setPassword(e.target.value)}
                                                            value = {password}
                                                            onKeyDown={e => botaoPressionado(e)}
                                                            name="campoSenha"
                                                            id="campoSenha"
                                                            maxLength="20"
                                                            />
                                                            <Button variant="" className="verSenha" onClick={() => setIcons(!icons)}>
                                                                    {icons ? iconNaoVizualizar() : iconVizualizar()}
                                                            </Button>
                                                        </InputGroup>
                                                        {/* {loginError &&
                                                            <TextoSenhaIncorreta />
                                                        } */}
                                                        <div className="container-recuperar-senha">
                                                            <Button style={parceiro !== 'Veye'? {color: parceiroData?.cor_botoes} : {color: '#36D896'}} className="LinkRecuperarSenha" onClick={() => setMostrarRecuperarSenha('recuperar_senha')}> Esqueci minha senha </Button>
                                                        </div>
                                                    </div>
                                                    <div className="container-entrar">
                                                        <Button style={parceiro !== 'Veye'? {backgroundColor: parceiroData?.cor_botoes} : {backgroundColor: '#36D896'}} className="btnEntrar" variant="outline-light" onClick={efetuarLogin} onKeyDown={e => botaoPressionado(e)}>Entrar</Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="container-form-row-imagens">
                                    <Col className="col-imagens">
                                        <div className="container-principal-imagens">
                                            <div className="container-carousel">
                                                <Carousel bsPrefix='carousel' controls={false} indicators={false} interval={2000}>
                                                    <Carousel.Item className="container-itens">
                                                        <Image
                                                            style={{height: '1056px'}}
                                                            width={'1500px'}
                                                        className={'imagem-carousel'}
                                                        src={parceiroData?.usar_imagem_fundo_padrao === 1 ? imagem1 : parceiro !== 'Veye'? parceiroData?.link_imagem_fundo : imagem1}
                                                        />
                                                        <Carousel.Caption>
                                                            {/* ICONE SERA USADO FUTURAMENTE PARA DAR PLAY EM VIDEOS */}
                                                            {/* <Image className="iconeVideo" src={iconeVideo}/> */}
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                </Carousel>
                                            </div>
                                            <div

                                                style={isMobile? {backgroundColor: parceiroData?.cor_fundo} : {}}
                                                 className={mostrarRecuperarSenha === "novaSenha"
                                                 || mostrarRecuperarSenha === "senha_redefinida"
                                                     ? "container-fundo-verde-nova-senha"
                                                     : "container-fundo-verde"}>
                                                <Carousel bsPrefix='carousel' controls={false} indicators={false} interval={1}>
                                                    <Carousel.Item>
                                                        {/*<Image*/}
                                                        {/*className={mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida" ? "imagem-carousel-fundo-verde" : ""}*/}
                                                        {/*src={fundoVerde}*/}
                                                        {/*style={parceiro === 'Veye' ? {fill: parceiroData?.cor_fundo} : {fill: 'unset'}}*/}
                                                        {/*/>*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1194.557" height="1065" viewBox="0 0 1194.557 1065">
                                                            <path fill={parceiro !== 'Veye'? parceiroData?.cor_fundo :'rgb(54,216,150)'} fill-rule="evenodd" d="M128.45876289 458.38917526C222.88144329 136.1443299 282.34976569 0 282.34976569 0h912.20693534v1065H0s34.03608247-284.36597938 128.45876289-606.61082474z"/>
                                                        </svg>

                                                        <Carousel.Caption className="conteudo-carousel">
                                                            {mostrarRecuperarSenha === 'padrao' &&
                                                                <div className="container-textos">
                                                                    {
                                                                        parceiro !== 'Veye' ?
                                                                            <div></div>
                                                                            :
                                                                            <Image className="logoVirtueyes"
                                                                                   src={logoVirtueyes} alt=""/>
                                                                    }
                                                                    <div className="container-texto-1">
                                                                        <p style={parceiro !== 'Veye' ? {color: parceiroData?.cor_letra} : {}} className="texto-1"> Bem-vindo à Gestão Inteligente de Conectividade</p>
                                                                    </div>
                                                                    { parceiro === 'Veye' ?
                                                                        <>
                                                                            <div className="container-texto-2">
                                                                                <p className="texto-3"> Com a plataforma V.eye você eleva sua</p>
                                                                                <p className="texto-3"> experiência em gestão de faturas, </p>
                                                                                <p className="texto-3"> parque de SIMCards e configuração </p>
                                                                                <p className="texto-3"> de equipamentos via SMS.</p>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>

                                                                    }
                                                                </div>
                                                            }
                                                            {mostrarRecuperarSenha === 'recuperar_senha' &&
                                                                <div className="container-textos container-textos-recuperar">
                                                                    <div className="container-texto-recuperar-senha">
                                                                        <p className="texto-recuperar-senha"> Esqueceu sua senha? </p>
                                                                    </div>
                                                                    <div className=" container-texto-2 container-texto-2-recuperar">
                                                                        <span style={parceiro == 'Veye' ? {} : {color: parceiroData?.cor_letra}} className="texto-3">
                                                                            Digite seu e-mail abaixo e enviaremos um link para redefinir sua senha.
                                                                        </span>
                                                                    </div>

                                                                    <CampoEmailRecuperarSenha
                                                                        parceiro={parceiro}
                                                                        setEmailRecuperarSenha={setEmailRecuperarSenha}
                                                                        parceiroData={parceiroData}
                                                                        emailRecuperarSenha={emailRecuperarSenha}
                                                                        emailError={emailError}
                                                                        validarEmail={validarEmail}
                                                                        enviarEmail={enviarEmail}
                                                                    /> 
                                                                </div>
                                                            }
                                                            {mostrarRecuperarSenha === 'confirmacao_email' &&
                                                                <MensagemConfirmacaoEmail
                                                                    setMostrarRecuperarSenha={setMostrarRecuperarSenha}
                                                                    classNames={{
                                                                        container: "container-textos container-verifique-email",
                                                                        divTitulo: "container-texto-recuperar-senha",
                                                                        titulo: "texto-confimacao-texto-1",
                                                                        divDescricao: "container-confirmacao-texto-2",
                                                                        descricao: "texto-3",
                                                                        botao: "btnVoltarHomepage",
                                                                    }}
                                                                />
                                                            }
                                                            {mostrarRecuperarSenha === 'novaSenha' &&
                                                                <div className="container-textos container-textos-nova-senha">
                                                                    <div className="container-texto-1">
                                                                        <p className="texto-nova-senha"> Defina sua nova senha</p>
                                                                    </div>
                                                                    <div className="container-texto-2-nova-senha">
                                                                        <span className="texto-3"> Escolha uma senha entre 4 e 20 caracteres. </span>
                                                                    </div>
                                                                    <InputGroup className="container-nova-senha">
                                                                        <FormControl
                                                                        className="campo-texto-nova-senha nova-senha-1"
                                                                        placeholder="Nova senha"
                                                                        type={mostrarEsconderNovaSenha}
                                                                        onChange={e => alterarSenha(e, setNovaSenha)}
                                                                        maxLength="20"
                                                                        minLength="4"
                                                                        name="campoNovaSenha"
                                                                        id="campoNovaSenha"
                                                                        />
                                                                        <Button className="ver-senha-nova-senha ver-nova-senha-1" variant="" onClick={() => setIconsNovaSenha(!iconsNovaSenha)}>
                                                                            {iconsNovaSenha ? iconNaoVizualizarNovaSenha() : iconVizualizarNovaSenha()}
                                                                        </Button>
                                                                    </InputGroup>

                                                                    <InputGroup className="container-nova-senha">
                                                                        <FormControl
                                                                        className="campo-texto-nova-senha nova-senha-2"
                                                                        placeholder="Confirmar nova senha"
                                                                        type={mostrarEsconderConfirmarNovaSenha}
                                                                        onChange={e => alterarSenha(e, setConfirmarNovaSenha)}
                                                                        maxLength="20"
                                                                        minLength="4"
                                                                        name="campoNovaSenha"
                                                                        id="campoNovaSenha"
                                                                        />
                                                                        <Button className="ver-senha-nova-senha ver-nova-senha-2" variant="" onClick={() => setIconsConfirmarNovaSenha(!iconsConfirmarNovaSenha)}>
                                                                            {iconsConfirmarNovaSenha ? iconNaoVizualizarConfirmarNovaSenha() : iconVizualizarConfirmarNovaSenha()}
                                                                        </Button>
                                                                    </InputGroup>
                                                                        {senhaError == "senhaDiferente" &&
                                                                            <h6 className="mensagem-erro-senha-diferente"> As senhas não estão iguais. Por favor, insira a mesma combinação nos 2 campos. </h6>
                                                                        }

                                                                        {senhaError || (!novaSenha || (novaSenha !== confirmarNovaSenha)) 
                                                                        ?
                                                                            <Button disabled className="btnRedefinir" variant="outline-light"> Concluir </Button>
                                                                        :
                                                                            <Button className="btnRedefinir" variant="outline-light" onClick={RedefinirSenha}> Concluir </Button>
                                                                        }
                                                                </div>

                                                            }
                                                            {mostrarRecuperarSenha === 'senha_redefinida' &&
                                                                <div className="container-textos container-textos-redefinida">
                                                                    <div className="container-texto-1">
                                                                        <p className="texto-senha-redefinida"> Senha definida com sucesso! </p>
                                                                    </div>
                                                                    <div className="container-texto-2 container-texto-2-redefinida">
                                                                        <div className="container-2-texto-2-redefinida" >
                                                                            <span className="texto-3"> Você já pode usar sua nova senha para efetuar seu login.</span>
                                                                        </div>
                                                                        <Button id="SenhaRdefinidaSucesso" className="btnIrLogin" variant="outline-light" onClick={() => IrParaLogin()}> Ir para o login </Button>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* BOTOES DE DOWNLOAD DO APP QUE SERA FEITO FUTURAMENTE */}
                                                            {/* <div className="grupo-botoes">
                                                                <Button variant="" className="btnBaixar btnAndroid" onClick={
                                                                    (): void => {
                                                                        window.location.href='https://play.google.com/store/apps/details?id=br.com.virtueyes.newveye';
                                                                    }}>
                                                                    <Image className="" src={android}/>
                                                                </Button>

                                                                <Button variant="" className="btnBaixar btnApple" onClick={
                                                                    (): void => {
                                                                        window.location.href='https://apps.apple.com/br/app/v-eye/id1382295987';
                                                                    }}>
                                                                    <Image className="" src={apple} />
                                                                </Button>
                                                            </div> */}
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                </Carousel>
                                                <div className={mostrarRecuperarSenha === "novaSenha" || mostrarRecuperarSenha === "senha_redefinida" ? "container-nova-senha-fixa" : "container-nova-senha-fixa-sem-tamanho"}>
                                                    <Image className="imagem-carousel-nova-senha-fixa" src={imagem1} />
                                                </div>
                                            </div>
                                            <div style={parceiro !== 'Veye'?{backgroundColor: parceiroData?.cor_fundo} : {}} className="fundo-verde-2"></div>
                                        </div>
                                    </Col>
                                </Form.Row>
                                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                            </div>
                        </div>
                    }
                </>
            :
            <Lottie name="animacao" options={defaultOptions} />
            }
        </>
    );
}

function TextoDadosInvalidos() {
    return <h6 className="mensagem-erro-login-vermelho">Por favor, digite um e-mail ou uma senha válida</h6>
}

// function TextoSenhaIncorreta() {
//     return <h6 className="mensagem-erro-login-algar"> Senha incorreta</h6>
// }

function CampoEmailRecuperarSenha({ parceiro, setEmailRecuperarSenha, parceiroData, emailRecuperarSenha, emailError, enviarEmail, validarEmail }) {
    let buttonStyle = {};
    let emailFieldStyle = {};

    if(parceiro !== 'Veye') {
        buttonStyle = {backgroundColor: parceiroData?.cor_botoes, color: parceiroData?.cor_letra};
        emailFieldStyle = {backgroundColor: parceiroData?.cor_fundo, color: parceiroData?.cor_letra};
    }

    return (
        <>
            <Form.Control
                style={emailFieldStyle}
                className="campo-email-recuperar-senha"
                type="email"
                placeholder="Digite seu e-mail"
                onChange={e => {
                    const value = e.target.value
                    setEmailRecuperarSenha(value)
                    validarEmail(value)
                }}
            />

            {emailError &&
                <h6 className="mensagem-erro-recuperar-senha"> Formato de e-mail inválido. </h6>
            }

            {(emailError || emailRecuperarSenha === '')
                ?
                    <Button disabled style={buttonStyle} className="btnEnviar" variant="outline-light" > Enviar </Button>
                :
                <Button 
                    style={buttonStyle} className="btnEnviar" variant="outline-light" 
                    onClick={() => enviarEmail()}
                >
                    Enviar
                </Button>
            }
        </>
    )
}

function MensagemConfirmacaoEmail({ classNames, setMostrarRecuperarSenha }) {
    return (
        <div className={classNames.container}>
            <div className={classNames.divTitulo}>
                <p className={classNames.titulo}> Link enviado. </p>
            </div>
            <div className={classNames.divDescricao}>
                <span className={classNames.descricao}> Caso este e-mail esteja cadastrado em nossa base de dados, será enviado um link de recuperação. Verifique sua caixa de e-mail. </span>
            </div>

            <hr className='linha-divisoria-MensagemConfirmacaoEmail'/>
            <span className={`${classNames.descricao} text-rodape-MensagemConfirmacaoEmail`}>
                Caso não tenha recebido o e-mail com o link, verifique sua caixa de spam.
            </span>

            {classNames.divBotao ?
                <div className={classNames.divBotao}>
                    <BotaoVoltar classNameBtn={classNames.botao} setMostrarRecuperarSenha={setMostrarRecuperarSenha} />
                </div>
                :
                <BotaoVoltar classNameBtn={classNames.botao} setMostrarRecuperarSenha={setMostrarRecuperarSenha} />
            }
        </div>
    )
}

function BotaoVoltar({ classNameBtn, setMostrarRecuperarSenha }) {
    return (
        <Button className={classNameBtn} variant="outline-light" 
            onClick={() => setMostrarRecuperarSenha('padrao')}
        >
            Voltar a página inicial
        </Button>                                            
    )
}