const ESTADO_INICIAL = {
    filtros: {vazio: 'vazio'},
    filtrosArrayBarra: [],
    revelarBarraFiltragem: false,
    recarregarBarraFiltragem: false,
    limparFiltros: true,
    itens: [],
    renderizarOperadora: false,
    maximoTagsExibidas: false,
};

export const filtrosReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'MUDAR_FILTROS') {
        return {
            ...state, 
            filtros: action.parametro
        }
    }
    else if (action.type === "MAXIMO_TAGS_EXIBIDAS") {
        return {
            ...state,
            maximoTagsExibidas: action.parametro
        }
    }
    else if (action.type === 'ADICIONAR_FILTRO_SIMCARD') {
        return {
            ...state, 
            filtrosArrayBarra: action.parametro
        }
    }
    else if (action.type === 'MUDAR_ITENS_SIMCARD') {
        return {
            ...state, 
            itens: action.parametro
        }
    }
    else if (action.type === "REVELAR_BARRA_FILTRAGEM") {
        return {
            ...state,
            revelarBarraFiltragem: action.parametro
        }
    }
    else if (action.type === "RECARREGAR_BARRA_FILTRAGEM") {
        return {
            ...state,
            recarregarBarraFiltragem: action.parametro
        }
    }
    else if (action.type === "RENDERIZA_OPERADORA") {
        return {
            ...state,
            renderizarOperadora: action.parametro
        }
    }
    else if (action.type === "LIMPAR_FILTROS") {
        return {
            ...state,
            limparFiltros: action.parametro
        }
    }
    else {
        return state;
    }
}