//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Image, Button} from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
import {formatarMilhar} from '../../../utils/formatarMilhar'
//COMPONENTES
import CadastroServico from './cadastro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
//IMPORTAÇÕES ICONES
import { setaDropdown } from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, revelarBarraFiltragem, realizouFiltro, mudarFiltros, mudarOrdenador, revelarEditarServico, revelarExcluirServico, desabilitarAcoes } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatServico/fatServico.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaPlanos(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS VARIÁVEIS
    const [cdServico, setCdServico] = useState()
    const [dsServico, setDsServico] = useState()
    const [cdCategoria, setCdCategoria] = useState()
    const [dsCategoria, setDsCategoria] = useState()
    const [cdTipo, setCdTipo] = useState()
    const [dsTipo, setDsTipo] = useState()
    const [cdUnidade, setCdUnidade] = useState()
    const [dsUnidade, setDsUnidade] = useState()
    const [n2Valor, setN2Valor] = useState()
    const [dsQuantidade, setDsQuantidade] = useState()
    //HOOKS MENSAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let novoServico = useSelector(state => state.faturasState.revelarNovoServico);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let editarServico = useSelector(state => state.faturasState.revelarEditarServico);
    let excluirServico = useSelector(state => state.faturasState.revelarExcluirServico);
    let temFiltros = false;

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(677) // 'fatServicoTabela' 
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("faturasServicos"))
        despacho(desabilitarAcoes(true))
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/fat_servico?paginaAtual='+contador+'&ordenador='+ordenador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&cd_pav_usuario='+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    'paguinaAtual': contador,
                    'carregarLimit': valorLimite,
                    'buscar': conteudoBusca,
                    'filtros': filtros,
                    'ordenador': ordenador,
                    'cd_pav_usuario' : decryptJWT('codigo')
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fat_servico", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fat_servico?paginaAtual='+contador+'&ordenador='+ordenador+'&carregarLimit='+valorLimite+'&cd_pav_usuario='+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
        if (contador === i){
            let k = i
            for(let j=0;j<data.length;j++){
            itensVar[k] = data[j]
            k++
            }
        }
        if(i == (totalItens-1)){
            setItens(JSON.parse(JSON.stringify(itensVar)))
        }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/fat_servico?contar="true"&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+decryptJWT('codigo'));
            }else{
                const dados = {
                    'paguinaAtual': contador,
                    'carregarLimit': valorLimite,
                    'buscar': conteudoBusca,
                    'filtros': filtros,
                    'contar': true,
                    'ordenador': ordenador,
                    'cd_pav_usuario' : decryptJWT('codigo')
                }
                apiData = await api.post("/m1/filtrar/fat_servico", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    
    const colunas = [{
        dataField: 'cd_fat_servico',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_fat_servico",'cd_fat_servico')}>
        Cód. serviço
        <Image id="cd_fat_servicocd_fat_servico" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_fat_servico',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_fat_servico",'ds_fat_servico')}>
        Serviço
        <Image id="ds_fat_servicods_fat_servico" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_categoria',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_categoria",'ds_categoria')}>
        Categoria
        <Image id="ds_categoriads_categoria" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_quantidade',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_quantidade",'ds_quantidade')}>
        Quantidade
        <Image id="ds_quantidadeds_quantidade" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_unidade',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_unidade",'ds_unidade')}>
        Unidade
        <Image id="ds_unidadeds_unidade" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_tipo',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_tipo",'ds_tipo')}>
        Tipo
        <Image id="ds_tipods_tipo" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'n2_valor',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n2_valor",'n2_valor')}>
        Valor
        <Image id="n2_valorn2_valor" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">R$ {cell?formatarMilhar(cell):'0.00'}</p>;
        }
    }
    ]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdServico(row.cd_fat_servico)
                setDsServico(row.ds_fat_servico)
                setCdCategoria(row.cd_categoria)
                setDsCategoria(row.ds_categoria)
                setCdTipo(row.cd_tipo)
                setDsTipo(row.ds_tipo)
                setCdUnidade(row.cd_unidade)
                setDsUnidade(row.ds_unidade)
                setN2Valor(row.n2_valor)
                setDsQuantidade(row.ds_quantidade)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    async function handleDeletar(){
        ColetaClickstream(1293) // 'fatServicoTabelaDropAcoesExcluirServicoExcluir'
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_servico?cd_fat_servico='+cdServico+'&cd_pav_usuario='+decryptJWT('codigo'))
        .then(function (response) {
            if(response.data.status === 600){
                setMensagemErro(response.data.info)
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirServico(false))
                handleMensagemErro(false)
                setSpinAtivo(false)
            }else{
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirServico(false))
                handleMensagemSucesso(false)
                setRenderizar(!renderizar)
                setSpinAtivo(false)
            }
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(excluirServico === true){
            setRevelarMensagemAtencao(true)
        }
    },[excluirServico])

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                setRevelarMensagemAtencao(false)
                handleDeletar()
            } else {
                ColetaClickstream(1292) // 'fatServicoTabelaDropAcoesExcluirServicoCancelar'
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirServico(false))
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            { novoServico === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-tela-container">
                        <CadastroServico
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroServico>
                    </div>
                </div>
            }
            { editarServico === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-tela-container">
                        <CadastroServico
                            tituloJanela={'Editar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdServico={cdServico}
                            dsServico={dsServico}
                            cdCategoria={cdCategoria}
                            dsCategoria={dsCategoria}
                            cdTipo={cdTipo}
                            dsTipo={dsTipo}
                            cdUnidade={cdUnidade}
                            dsUnidade={dsUnidade}
                            n2Valor={n2Valor}
                            dsQuantidade={dsQuantidade}
                        >
                        </CadastroServico>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_fat_servico' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={selecaoLinhas}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Serviço "+ dsServico +" excluído."}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemAtencao &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Excluir Serviço"}
                    mensagemParagrafo1={"Tem certeza que deseja excluir o serviço "+ dsServico +"?"}
                    mensagemBotao={"Excluir"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaPlanos));