//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {formatarData} from '../../../../utils/formatadorDataTabela';/*eslint-disable*/
import { useDispatch, useSelector } from 'react-redux';
import { mudarOrdenador, mudarManejador, limpouBusca, realizouBusca, limpadorFiltros } from "../../../../redux/actions/index.js";
import {api} from '../../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Image } from 'react-bootstrap';
//ICONES
import { setaDropdown } from '../../../../utils/alternanciaIcones'
//COMPONENTES
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import BarraDadosCliente from '../../../componentesModais/barraDadosCliente/barraDadosCliente'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []

export default function Grid(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);  // eslint-disable-next-line
    //HOOKS REDUX
    const despacho = useDispatch();
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("imeiLog"));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }      

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && !temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/log/consultar/dsp_imei_info_log?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&ordenador='+ordenador+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    ordenador: ordenador,
                    buscar: conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/dsp_imei_info_log",dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/dsp_imei_info_log?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&ordenador='+ordenador+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }
            
        }
        setSpinAtivo(false)
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])
    
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
        if (contador === i){
            let k = i
            for(let j=0;j<data.length;j++){
            itensVar[k] = data[j]
            k++
            }
        }
        if(i == (totalItens-1)){
            setItens(JSON.parse(JSON.stringify(itensVar)))
        }
        }
    }
  
    async function contarItens(){
        let apiData = []
        let arrayItens = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) {
                apiData = await api.get('/m1/consultar/dsp_imei_info_log?contar="true"&buscar='+conteudoBusca+'&ordenador='+ordenador);
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    ordenador: ordenador,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/dsp_imei_info_log", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }
   
    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const colunas = [
        {
            dataField: 'cd_dsp_imei_info_log',
            headerClasses: 'nao-selecionavel',
            text: "Cód. log",
            hidden: true,
        },
        {
            dataField: 'ds_request',
            text:
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_request",'ds_request')}>
                    Request
                    <Image id="ds_requestds_request" className='d-none' src={setaDropdown()}/>
                </button>,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell ? cell : '-'}</p>;
            }
        },
        {
            dataField: 'ds_response',
            text:
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_response",'ds_response')}>
                    Response
                    <Image id="ds_responseds_response" className='d-none' src={setaDropdown()}/>
                </button>,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell ? cell : '-'}</p>;
            }
        },
        {
            dataField: 'ds_imei',
            text:
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_imei",'ds_imei')}>
                    IMEI
                    <Image id="ds_imeids_imei" className='d-none' src={setaDropdown()}/>
                </button>,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell ? cell : '-'}</p>;
            }
        },
        {
            dataField: 'dt_datahora',
            text:
                <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_datahora",'dt_datahora')}>
                    Data/hora
                    <Image id="dt_datahoradt_datahora" className='d-none' src={setaDropdown()}/>
                </button>,
            headerClasses: 'nao-selecionavel',
            formatter: (cell, row) => {                         
                return formatarData(cell,false);
            }
        }
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    return(
        <div className='container-tela'>
            { visualizarDados === true && <BarraDadosCliente/> }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='ID' 
                                data={itens} 
                                columns={colunas} 
                                // rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}