//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {Image, Button} from 'react-bootstrap';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarDoppelgangerCliente, visualizarDadosRefresh, mudarOrdenador, mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, visualizarDados, mudarFiltros, revelarBarraFiltragem, realizouFiltro,revelarExcluirConta } from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import NovaConta from './novaConta/novaConta'

import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import {setaDropdown} from '../../../utils/alternanciaIcones';
import DetalhamentoConta from './detalhamento/detalhamentoConta';
//ESTILIZAÇÃO
import '../../../css/painelFaturas/fatContas/tabela.css'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

const valorLimite = 3
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaClientesVirtueyes(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(0);
    const [valorLimite, setValorLimite] = useState(50);  

    const [cdConta, setCdConta] = useState(-1);
    const [empresaTitular, setEmpresaTitular] = useState()
    const [inicioCiclo, setInicioCiclo] = useState("")
    const [numeroConta, setNumeroConta] = useState()
    const [prazoPagamento, setPrazoPagamento] = useState()
    const [simcards, setSimcards] = useState()
    const [vencimento, setVencimento] = useState("")
    const [fornecedor, setFornecedor] = useState()
    const [operadora, setOperadora] = useState()

    const [mensagemErro, setMensagemErro] = useState('')
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    let itensVar = []

    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let estadoVisualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let revelarNovaConta =  useSelector(state => state.faturasState.revelarNovaConta);
    let revelarEditarConta =  useSelector(state => state.faturasState.revelarEditarConta);
    let excluirConta =  useSelector(state => state.faturasState.revelarExcluirConta);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    const despacho = useDispatch();
    const cd_pav_usuario = decryptJWT('codigo')
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(620) // 'fatContasTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("fatConta"));
        despacho(desabilitarAcoes(true));

        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario
                    const { data } = await api.get('/m1/consultar/fat_conta?'+queryString);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    ordenador: ordenador,
                    cd_pav_usuario: cd_pav_usuario
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fat_conta", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario
                    const { data } = await api.get('/m1/consultar/fat_conta?'+queryString);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
        contarItens()

    }, [paginaAtual, conteudoBusca, ordenador, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get('/m1/consultar/fat_conta?contar="true"&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario);
        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                filtros: filtros,
                ordenador: ordenador,
                contar: true,
                cd_pav_usuario: cd_pav_usuario
            }
            apiData = await api.post("/m1/filtrar/fat_conta", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_conta?cd_fat_conta='+cdConta)
        .then(function (response) {
            if(response.data.status === 600){
                setMensagemErro(response.data.info)
                despacho(revelarExcluirConta(false))
                handleMensagemErro(false)
                setSpinAtivo(false)
            }else{
                setRenderizar(!renderizar)
                despacho(revelarExcluirConta(false))
                handleMensagemSucesso(false)
                setSpinAtivo(false)
            }
        })
        .catch(function (error) {
            setMensagemErro("Erro ao excluir conta!")
            despacho(revelarExcluirConta(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            despacho(revelarExcluirConta(false))
            if(confirmacao === true){
                ColetaClickstream(1284) // 'fatContasTabelaRevelarExcluirContaExcluir'
                handleDeletar()
            } else {
                ColetaClickstream(1285) // 'fatContasTabelaRevelarExcluirContaCancelar'
            }
        }
    }

    const colunas = [
        {
            dataField: 'ds_conta',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_conta",'ds_conta')}>
            Número da conta
            <Image id="ds_contads_conta" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'n0_dia_inicial_ciclo',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_dia_inicial_ciclo",'n0_dia_inicial_ciclo')}>
            Inicio do ciclo
            <Image id="n0_dia_inicial_ciclon0_dia_inicial_ciclo" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? "Dia " + cell : "-"}</p>;
            }
        },
        {
            dataField: 'n0_dia_vencimento',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_dia_vencimento",'n0_dia_vencimento')}>
            Vencimento
            <Image id="n0_dia_vencimenton0_dia_vencimento" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? "Dia " + cell : "-"}</p>;
            }
        },
        {
            dataField: 'n0_meses_sequentes',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_meses_sequentes",'n0_meses_sequentes')}>
            Prazo de pagamento
            <Image id="n0_meses_sequentesn0_meses_sequentes" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell == '' || cell == null || cell == undefined ? "-" : cell  + " mês (es)"}</p>;
            }
        },
        {
            dataField: 'ds_apelido',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_apelido",'ds_apelido')}>
            Operadora
            <Image id="ds_apelidods_apelido" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell == '' || cell == null || cell == undefined ? "-" : cell}</p>;
            }
        },
        {
            dataField: 'ds_nome_fantasia',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_nome_fantasia",'ds_nome_fantasia')}>
            Empresa titular
            <Image id="ds_nome_fantasiads_nome_fantasia" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell == '' || cell == null || cell == undefined ? "-" : cell}</p>;
            }
        },
        {
            dataField: 'n0_simcards',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_simcards",'n0_simcards')}>
            SIM cards cadastrados
            <Image id="n0_simcardsn0_simcards" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : "-"}</p>;
            }
        },
    ]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarConta(
                    row.cd_fat_conta,
                    row.ds_conta, 
                    row.ds_nome_fantasia,
                    row.n0_dia_inicial_ciclo,
                    row.n0_meses_sequentes,
                    row.n0_dia_vencimento,
                    row.ds_fat_fornecedor,
                    row.ds_apelido
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarConta(codigo,numeroConta, empresa_titular, inicio_ciclo, prazo_pagamento, vencimento, fornecedor, operadora){
        setCdConta(codigo)
        setNumeroConta(numeroConta)
        setEmpresaTitular(empresa_titular)
        setInicioCiclo(inicio_ciclo)
        // setOperadora(operadora)
        setPrazoPagamento(prazo_pagamento)
        // setSimcards(simcards)
        setVencimento(vencimento)
        setFornecedor(fornecedor)
        setOperadora(operadora)
        despacho(desabilitarAcoes(false));

    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setCdConta(row.codigo);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDoppelgangerCliente(false))
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar))
            if(confirmacao === true){
                setRenderizar(!renderizar)
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <>
            <div className={estadoExpansaoMenu===true?"expand-row-fat-contas-1":"expand-row-fat-contas-2"}>
                <DetalhamentoConta cd_fat_conta={row.cd_fat_conta} />
            </div>
            </>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    return(
        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"A conta "+ numeroConta +" foi excluído."}
                            mensagemBotao={"Continuar"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarNovaConta === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-historico-acesso-container">
                        <NovaConta
                            tituloJanela="Cadastrar"
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            {revelarEditarConta === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-adicionar-login-container">
                        <NovaConta
                            tituloJanela="Editar"
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            cdConta={cdConta}
                            numeroConta={numeroConta}
                            inicioCiclo={inicioCiclo}
                            dsFornecedor={fornecedor}
                            dsEmpresa={empresaTitular}
                            dsOperadora={operadora}
                            vencimento={vencimento}
                            prazoPagamento={prazoPagamento}
                          
                        />
                    </div>
                </div>
            }
              {excluirConta === true &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+ numeroConta}
                />
            }

            {estadoVisualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_fat_conta' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                expandRow={itemExpandido}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesVirtueyes));