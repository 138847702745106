/*********** CORES DOS TEMAS DO SISTEMA ***********/
/* manter cores nomeadas de modo genérico,        */
/* criando a cor equivalente no tema oposto com   */
/* o mesmo nome.                                  */
/* Sempre verifique se a cor que você procura     */
/* já existe. Se possível, instale a extensão     */
/* "Color Highlight", para visualizar a cor       */
/* representada por cada # no código abaixo.      */
/**** MATEUS FOLLETTO - 15/05/2020 - VIRTUEYES ****/

export const claroTema = {
  "cor1":  "#FFFFFF",
  "cor2":  "#ECF0EE",
  "cor3":  "#DFE3E2",
  "cor4":  "#D0D4D3",
  "cor5":  "#EDF2F0",
  "cor6":  "#E0E6E3",
  "cor7":  "#BCC1BF",
  "cor8":  "#656867",
  "cor9":  "#000000",
  "cor10": "#4A9DF0",
  "cor11": "#36D896",
  "cor12": "#CD2D37",
  "cor13": "#BCC1BF",
  "cor14": "#FFFFFF",
  "cor15": "#E4E4E4",
  "cor16": "#F3F3F3",
  "cor17": "#ECF0EE",
  "cor18": "rgb(200,200,200,0.5)",
  "cor19": "#060606",
  "cor20": "#1C1E1D",
  "cor21": "#000000",
  "cor22": "#D2DBD8",
  "cor23": "#DFE3E2",
  "cor24": "#D1D4D4",
  "cor25": "#C5CAC8",
  "cor26": "#DFE3E2",
  "cor27": "#FFFFFF",
  "cor28": "#DFE3E2",
  "cor29": "#505352",
  "cor30": "#C5CAC8",
  "cor31": "#ECF0EE",
  "cor32": "#f0f0f0",
  "cor33": "#fafafa",
  "cor34": "#d9d9d9",
  "cor35": "#999999",
  "cor36": "#000000",
  "cor37": "#FFFFFF",
  "cor38": "#33CC99",
  "cor39": "#4F6275",
  "cor40": "#ECF0EE",
  "cor41": "#243B53",
  "cor42": "#DFE3E2",
  "cor43": "#33CC99",
  "cor44": "#FFFFFF",
  "cor45": "#000000",
  "cor46": "#243B53",
  "cor47": "rgba(0, 0, 0, 0.26)",
  "cor48": "#4F6275",
  "cor49": "#19293A",
  "cor50": "#808080",
  "cor51": "#aca3a3",
  "checkedImage": "url('/icones/checked_vclara.svg')",
"menuPrincipal": "#ECF0EE",
"campoTexto": "#000000",
"menuPrincipalFalhaOperadora": "#FFFFFF",
"bg": "#FFFFFF",
"permissao": "#ECF0EE",
"selecionadoFonteIconeMenu": "#36D896",
"selecionadoBgMenu": "#DFE3E2",
"buscaFiltroAcoesScrollPaginacao": "#DFE3E2",
"cinzaEscuroForte": "#EDF2F0",
"cinzaClaro": "#EDF2F0",
"campoTextoCinza": "#EDF2F0",
"cabecalhoTabela": "#D0D4D3",
"linha1Table": "#EDF2F0",
"linha2Table": "#E0E6E3",
"selecionadaLinha": "#4A9DF0",
"hoverMouse": "#BCC1BF",
"fonteCabecalho": "#000000",
"fonteLinha": "#3D3E3D",
"hoverFonteLinha": "#000000",
"selecionadoFonteLinha": "#000000",
"barraConsumo1": "#36D896",
"barraConsumo2": "#CD2D37",
"backgroud": "#FFFFFF",
"cabecalhoBackgroud": "#ECF0EE",
"scrollBar": "#ECF0EE",
"botaoTemplate": "#FFFFFF",
"slider": "#E4E4E4",
"thumb": "#ECF0EE",
"sombra": "rgb(200,200,200,0.5)",
"corFundo": "#FFF",
"hoverDisable": "#B9BFBD",
"checkboxColor": "#FFF",
"hr": "#FFFFFF",
"timeline": "#D9D9D9",
"linhaDivisora": "#BCC1BF",
"barraConsumo": "#FAFAFA"
}

export const escuroTema = {
"cor1":  "#000000",
"cor2":  "#3D4853",
"cor3":  "#343736",
"cor4":  "#121312",
"cor5":  "#252827",
"cor6":  "#1A1C1B",
"cor7":  "#505352",
"cor8":  "#BDBDBD",
"cor9":  "#FFFFFF",
"cor10": "#2A8EC6",
"cor11": "#36D896",
"cor12": "#CD2D37",
"cor13": "#000000",
"cor14": "#000000",
"cor15": "#1A1C1B",
"cor16": "#252827",
"cor17": "#1C1E1D",
"cor18": "rgb(30,30,30,0.5)",
"cor19": "#E0E0E0",
"cor20": "#ECF0EE",
"cor21": "#252827",
"cor22": "#474A49",
"cor23": "#252626",
"cor24": "#4F5351",
"cor25": "#5B605D",
"cor26": "#252626",
"cor27": "#343736",
"cor28": "#000000",
"cor29": "#505352",
"cor30": "#343736",
"cor31": "#343736",
"cor32": "#333635",
"cor33": "#393B3A",
"cor34": "#2a2b2a",
"cor35": "#494a49",
"cor36": "#000000",
"cor37": "#FFFFFF",
"cor38": "#33CC99",
"cor39": "#FFFFFF",
"cor40": "#2E2E2E",
"cor41": "#FFFFFF",
"cor42": "#1E1E1E",
"cor43": "#33CC99",
"cor44": "#000000",
"cor45": "#FFFFFF",
"cor46": "#FFFFFF",
"cor47": "#F8FAFB",
"cor48": "#D9E2EC",
"cor49": "#F8FAFB",
"cor50": "#6d6d6d",
"cor51": "#353535",
"checkedImage": "url('/icones/checked.svg')",
"menuPrincipal": "#3D4853",
"campoTexto": "#3D4853",
"bg": "#1E2126",
"permissao": "#1E2126",
"selecionadoFonteIconeMenu": "#36d896",
"selecionadoBgMenu": "#343736",
"buscaFiltroAcoesScrollPaginacao": "#343736",
"cinzaEscuroForte": "#252827",
"campoTextoCinza": "#252827",
"cabecalhoTabela": "#121312",
"linha1Table": "#252827",
"linha2Table": "#1A1C1B",
"selecionadaLinha": "#2A8EC6",
"hoverMouse": "#505352",
"fonteCabecalho": "#ffffff",
"fonteLinha": "#BDBDBD",
"hoverFonteLinha": "#FFFFFF",
"selecionadoFonteLinha": "#FFFFFF",
"barraConsumo1": "#36D896",
"barraConsumo2": "#CD2D37",
"backgroud": "#000000",
"cabecalhoBackgroud": "#1C1E1D",
"scrollBar": "#343736",
"botaoTemplate": "#343736",
"imputCalendario": "#252827",
"slider": "#343736",
"thumb": "#343736",
"sombra": "rgb(30,30,30,0.5)",
"corFundo": "#000",
"hoverDisable": "#505352",
"checkboxColor": "#000",
"hr": "#333",
"timeline": "#252827",
"linhaDivisora": "#000",
"barraConsumo": "#000000"
}

export const algarClaroTema = {
"cor1":  "#FFFFFF",
"cor2":  "#ECF0EE",
"cor3":  "#DFE3E2",
"cor4":  "#D0D4D3",
"cor5":  "#EDF2F0",
"cor6":  "#E0E6E3",
"cor7":  "#BCC1BF",
"cor8":  "#656867",
"cor9":  "#000000",
"cor10": "#4A9DF0",
"cor11": "#00A650",
"cor12": "#CD2D37",
"cor13": "#BCC1BF",
"cor14": "#FFFFFF",
"cor15": "#E4E4E4",
"cor16": "#F3F3F3",
"cor17": "#ECF0EE",
"cor18": "rgb(200,200,200,0.5)",
"cor19": "#060606",
"cor20": "#1C1E1D",
"cor21": "#000000",
"cor22": "#D2DBD8",
"cor23": "#DFE3E2",
"cor24": "#D1D4D4",
"cor25": "#C5CAC8",
"cor26": "#DFE3E2",
"cor27": "#FFFFFF",
"cor28": "#DFE3E2",
"cor29": "#505352",
"cor30": "#C5CAC8",
"cor31": "#ECF0EE",
"cor32": "#f0f0f0",
"cor33": "#fafafa",
"cor34": "#d9d9d9",
"cor35": "#999999",
"cor36": "#FFCB05",
"cor50": "#808080",
"checkedImage": "url('/icones/checked_vclara.svg')",
"menuPrincipal": "#ECF0EE",
"campoTexto": "#000000",
"menuPrincipalFalhaOperadora": "#FFFFFF",
"bg": "#FFFFFF",
"permissao": "#ECF0EE",
"selecionadoFonteIconeMenu": "#00A650",
"selecionadoBgMenu": "#DFE3E2",
"buscaFiltroAcoesScrollPaginacao": "#DFE3E2",
"cinzaEscuroForte": "#EDF2F0",
"cinzaClaro": "#EDF2F0",
"campoTextoCinza": "#EDF2F0",
"cabecalhoTabela": "#D0D4D3",
"linha1Table": "#EDF2F0",
"linha2Table": "#E0E6E3",
"selecionadaLinha": "#4A9DF0",
"hoverMouse": "#BCC1BF",
"fonteCabecalho": "#000000",
"fonteLinha": "#3D3E3D",
"hoverFonteLinha": "#000000",
"selecionadoFonteLinha": "#000000",
"barraConsumo1": "#00A650",
"barraConsumo2": "#CD2D37",
"backgroud": "#FFFFFF",
"cabecalhoBackgroud": "#ECF0EE",
"scrollBar": "#ECF0EE",
"botaoTemplate": "#FFFFFF",
"slider": "#E4E4E4",
"thumb": "#ECF0EE",
"sombra": "rgb(200,200,200,0.5)",
"corFundo": "#FFF",
"hoverDisable": "#B9BFBD",
"checkboxColor": "#FFF",
"hr": "#FFFFFF",
"timeline": "#D9D9D9",
"linhaDivisora": "#BCC1BF",
"barraConsumo": "#FAFAFA"
}

export const algarEscuroTema = {
"cor1":  "#000000",
"cor2":  "#3D4853",
"cor3":  "#343736",
"cor4":  "#121312",
"cor5":  "#252827",
"cor6":  "#1A1C1B",
"cor7":  "#505352",
"cor8":  "#BDBDBD",
"cor9":  "#FFFFFF",
"cor10": "#2A8EC6",
"cor11": "#00A650",
"cor12": "#CD2D37",
"cor13": "#000000",
"cor14": "#000000",
"cor15": "#1A1C1B",
"cor16": "#252827",
"cor17": "#1C1E1D",
"cor18": "rgb(30,30,30,0.5)",
"cor19": "#E0E0E0",
"cor20": "#ECF0EE",
"cor21": "#252827",
"cor22": "#474A49",
"cor23": "#252626",
"cor24": "#4F5351",
"cor25": "#5B605D",
"cor26": "#252626",
"cor27": "#343736",
"cor28": "#000000",
"cor29": "#505352",
"cor30": "#343736",
"cor31": "#343736",
"cor32": "#333635",
"cor33": "#393B3A",
"cor34": "#2a2b2a",
"cor35": "#494a49",
"cor36": "#FFCB05",
"cor50": "#6d6d6d",

"checkedImage": "url('/icones/checked.svg')",
"menuPrincipal": "#3D4853",
"campoTexto": "#3D4853",
"bg":"#1E2126",
"permissao": "#1E2126",
"selecionadoFonteIconeMenu": "#00A650",
"selecionadoBgMenu": "#343736",
"buscaFiltroAcoesScrollPaginacao": "#343736",
"cinzaEscuroForte": "#252827",
"campoTextoCinza": "#252827",
"cabecalhoTabela": "#121312",
"linha1Table": "#252827",
"linha2Table": "#1A1C1B",
"selecionadaLinha": "#2A8EC6",
"hoverMouse": "#505352",
"fonteCabecalho": "#ffffff",
"fonteLinha": "#BDBDBD",
"hoverFonteLinha": "#FFFFFF",
"selecionadoFonteLinha": "#FFFFFF",
"barraConsumo1": "#00A650",
"barraConsumo2": "#CD2D37",
"backgroud": "#000000",
"cabecalhoBackgroud": "#1C1E1D",
"scrollBar": "#343736",
"botaoTemplate": "#343736",
"imputCalendario": "#252827",
"slider": "#343736",
"thumb": "#343736",
"sombra": "rgb(30,30,30,0.5)",
"corFundo": "#000",
"hoverDisable": "#505352",
"checkboxColor": "#000",
"hr": "#333",
"timeline": "#252827",
"linhaDivisora": "#000",
"barraConsumo": "#000000"
}