//IMPORTAÇÕES
import React, { useEffect, useRef } from 'react';/*eslint-disable*/
import { separadorLinhaBusca,modoSelecaoSimcard} from "../redux/actions/index.js";/*eslint-disable*/
import { Form } from "react-bootstrap";
import {api} from '../conexoes/api';// eslint-disable-next-line
import decryptJWT from '../utils/decryptJWT'
import { useDispatch, useSelector } from 'react-redux';

export default function DropFiltros(props) {
    //HOOKS MODAL
    const modal = useRef();
    //HOOKS REDUX
    const despacho = useDispatch();
    let reduxModoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    //HOOKS

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha)
        }
    };
    
    useEffect(() => {
        async function pegarSeparador(){
            const separador = await api.get('/m1/consultar/preferencias?cd_pav_usuario='+decryptJWT('codigo')).then(
                function(resposta){
                    return resposta.data.dados[0];
                }
            ).catch(
                function(erro){
                    throw erro.stack;
                }
            )
            despacho(separadorLinhaBusca(separador.busca_separador))
        }

        pegarSeparador();

        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function alterarSeparador(separador){
        await api.post('/m1/alterar/preferencias', {
            "dados": {
                'busca_separador': separador
            },
            "condicao": {
                'cd_pav_usuario' : decryptJWT('codigo')
            }
        }).then(
            function(resposta){
                return resposta.data.dados;
            }
        ).catch(
            function(erro){
                throw erro.stack;
            }
        )
        despacho(separadorLinhaBusca(separador))
    }

    function handlerCheck(nome){
        if(separadorLinha != ''){
            if(separadorLinha == nome){
                return true
            }else{
                return false
            }
        }
    }

    return (
        <Form ref={modal} className="drop-separador-linha-container">
            <div className="campo-select-triangulo-cor-2 triangulo-separador-linha-simcard-modal"></div>
            <div className="drop-acoes-corpo fundo-cor-7">
                    <label className="label-radio-separador-linha fonte-cor-5" onClick={() => { alterarSeparador("|") ; props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha)}}>| (Pipe)
                        <input defaultChecked={handlerCheck("|")} type="radio" id="Pipe" name="SEPARADOR-LINHA"/>
                        <span className="checkbox-filtros campo-texto-cor-3"></span>
                    </label>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <label className="label-radio-separador-linha fonte-cor-5" onClick={() => { alterarSeparador("enter") ; props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha)}}>\n (Enter)
                        <input defaultChecked={handlerCheck("enter")} type="radio" id="Enter" name="SEPARADOR-LINHA"/>
                        <span className="checkbox-filtros campo-texto-cor-3"></span>
                    </label>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <label className="label-radio-separador-linha fonte-cor-5" onClick={() => { alterarSeparador(",") ; props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha)}}>, (Virgula)
                        <input defaultChecked={handlerCheck(",")} type="radio" id="Virgula" name="SEPARADOR-LINHA"/>
                        <span className="checkbox-filtros campo-texto-cor-3"></span>
                    </label>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <label className="label-radio-separador-linha fonte-cor-5" onClick={() => { alterarSeparador(";") ; props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha)}}>; (Ponto e virgula)
                        <input defaultChecked={handlerCheck(";")} type="radio" id="Pontovirgula" name="SEPARADOR-LINHA"/>
                        <span className="checkbox-filtros campo-texto-cor-3"></span>
                    </label>
                    <div className="drop-filtros-divisora campo-select-divisor-cor-1"></div>
                    <label className="label-radio-separador-linha fonte-cor-5" onClick={() => { alterarSeparador("espaco") ; props.setRevelarSeperadorLinha(!props.revelarSeperadorLinha)}}>Espaço
                        <input defaultChecked={handlerCheck("espaco")} type="radio" id="espaco" name="SEPARADOR-LINHA"/>
                        <span className="checkbox-filtros campo-texto-cor-3"></span>
                    </label>
            </div>
        </Form>
    )
}