//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../../utils/formatadorDataTabela'
import { CSVLink } from 'react-csv'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, desabilitarAcoes, desabilitarAcoesCliente, limpadorFiltros, limpouBusca, realizouBusca, solicitacaCancelarVirtueyes,revelarSolicitacaExportarVirtueyes, mudarFiltros, revelarBarraFiltragem, realizouFiltro} from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import BloquearCliente from './bloquearCliente/bloquearCliente'
import RemoverBloqueio from './removerBloqueio/removerBloqueio'
import EnviarAviso from './enviarAviso/enviarAviso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//ESTILIZAÇÃO
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let dadosSelecionados = [];
let itensVar = [];

function TabelaBloqueio(props){
    //HOOKS TABELA
    const [id, setId] = useState("");
    const [tipo, setTipo] = useState("");
    const [status, setStatus] = useState("");
    const [motivo, setMotivo] = useState("");
    const [dataSolicitacao, setDataSolicitacao] = useState("");

    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);  
    const [noCdCliente, setNoCdCliente] = useState(-1);
    const [cdCliente, setCdCliente] = useState(-1);
    const [dsCliente, setDsCliente] = useState('NULL');
    const [dadosParaDownload, setDadosParaDownload] = useState([])
    const [mensagemErro, setMensagemErro] = useState('')
    const [mensagemAtencao, setMensagemAtencao] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [spinAtivo, setSpinAtivo] = useState(true);
    const despacho = useDispatch();

    let temFiltros = false;
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    
    const solicitacaBloquearVirtueyes = useSelector(state => state.virtueyesState.solicitacaBloquearVirtueyes);
    const solicitacaRemoverBloqueioVirtueyes = useSelector(state => state.virtueyesState.solicitacaRemoverBloqueioVirtueyes);
    const solicitacaEnviarAvisoVirtueyes = useSelector(state => state.virtueyesState.solicitacaEnviarAvisoVirtueyes);
    const solicitacaExportarVirtueyes = useSelector(state => state.virtueyesState.solicitacaExportarVirtueyes);

    // SPINNER
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(447) // 'solicitacaoBloqueioTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() =>{
        if(solicitacaExportarVirtueyes){
            handlerOrdenarDadosExportar()
        }
    },[solicitacaExportarVirtueyes])

    async function handlerOrdenarDadosExportar(){
        await Promise.all(
            dadosSelecionados.map((item_dadosSelecionados,i)=>{
                dadosParaDownload.push({
                    'Cód.cliente':item_dadosSelecionados.n0_codigo_cliente,
                    'Razão social':item_dadosSelecionados.ds_pav_cliente,
                    // Comentado pois a michele maciel requisitou
                    // "Nome fantasia":item_dadosSelecionados.ds_nome_fantasia,
                    // "E-mail":item_dadosSelecionados.ds_email,
                    // "Telefone":item_dadosSelecionados.ds_telefone,
                    // 'Usuário':item_dadosSelecionados.ds_pav_usuario_email,
                    "Data":item_dadosSelecionados.dt_created_at,
                    "Status":item_dadosSelecionados.fl_acao === 1?"Bloqueado":"Ativo",
                })
                if(item_dadosSelecionados.fl_acao == 1){
                    dadosParaDownload[i]['Status'] = "Bloqueado"
                }
                else{
                    dadosParaDownload[i]['Status'] = "Ativo"
                }
            })
        ).then(()=>{
            setDadosParaDownload(JSON.parse(JSON.stringify(dadosParaDownload)))
        })
        setTimeout(() => {
            //aciona o event click para o ativar o download do arquivo
            const exportar = document.getElementById('exportar-detalhamento-bloqueio')
            exportar.click();
            // despacho(revelarSolicitacaExportarVirtueyes(false));
        }, 250);
    }


    useEffect(() => {
        setSpinAtivo(true) 
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        despacho(mudarManejador("solicitacaoBloqueio"));
        dadosSelecionados = []
        async function carregarTabela(){
            // //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/cliente_bloqueio?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&fl_acao=1');
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/cliente_bloqueio", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/cliente_bloqueio?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&fl_acao=1');
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    const colunas = [{
            dataField: 'cd_pav_cliente',
            headerClasses: 'nao-selecionavel',
            text: 'Cód. cliente',
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'n0_codigo_cliente',
            headerClasses: 'nao-selecionavel',
            text: 'Cód. cliente',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_pav_cliente',
            headerClasses: 'nao-selecionavel',
            text: 'Razão social',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_nome_fantasia',
            headerClasses: 'nao-selecionavel',
            text: 'Nome fantasia',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_email',
            headerClasses: 'nao-selecionavel',
            text: 'E-mail',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_telefone',
            headerClasses: 'nao-selecionavel',
            text: 'Telefone',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'fl_acao',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                if(cell === 1){
                    return <p className="fonte-cor-1 label-tabela-1">Bloqueado</p>;
                }
                else if(cell === 0){
                    return <p className="fonte-cor-1 label-tabela-1">Ativo</p>;
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">-</p>;
                }
            }
        },
        {
            dataField: 'ds_pav_usuario_email',
            headerClasses: 'nao-selecionavel',
            text: 'Usuário',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'dt_created_at',
            headerClasses: 'nao-selecionavel',
            text: 'Data',
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },
    ]

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const selecaoLinhas = {
        mode: 'checkbox' ,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionar(row.cd_pav_cliente_bloqueio)
            }else{
                handleDesselecionar(row.cd_pav_cliente_bloqueio)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(isSelect){
                handleSelecionarTodos()
            }else{
                handleDesselecionarTodos()
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: 'row-index-bigger-than-2101'
    };

    function handleSelecionarTodos(){
        ColetaClickstream(454) // 'solicitacaoBloqueioTabelaHandleSelecionarTodos'
        itens.map((item,i)=>{
            if(itens[i].cd_pav_cliente_bloqueio){
                dadosSelecionados.push(itens[i]);
            }
        })
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
        
    }

    function handleDesselecionarTodos(){
        ColetaClickstream(455) // 'solicitacaoBloqueioTabelaHandleDesselecionarTodos'
        dadosSelecionados = []
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
    }

    function handleSelecionar(simcard){
        ColetaClickstream(456) // 'solicitacaoBloqueioTabelaHandleSelecionar'
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].cd_pav_cliente_bloqueio === simcard){
                dadosSelecionados.push(itens[i]);
                break;
            }
        }
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    function handleDesselecionar(simcard){
        for (let i = 0; i < dadosSelecionados.length; i++) {
            if (dadosSelecionados[i].cd_pav_cliente_bloqueio === simcard){
                dadosSelecionados.splice(i, 1);
                break;
            }
        }
        if(dadosSelecionados.length == 0){
            despacho(desabilitarAcoes(true));
            despacho(desabilitarAcoesCliente(true));
        }
    }


    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) { 
            apiData = await api.get('/m1/consultar/cliente_bloqueio?contar=true&buscar='+conteudoBusca+'&fl_acao=1');

        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                contar:true,
                buscar:conteudoBusca,
                filtros: filtros
            }
            apiData = await api.post("/m1/filtrar/cliente_bloqueio", dados);
        }
        if(apiData.data.dados){
            totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
            totalItens = apiData.data.dados[0].CONTADOR;
            for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
        }
        if(confirmacao){
            setRenderizar(!renderizar)
        }
    }
    return(
        <div className='container-tela'>            
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={() =>   setRevelarMensagemErro(!revelarMensagemErro)}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={() =>   setRevelarMensagemAtencao(!revelarMensagemAtencao)}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo3={mensagemAtencao}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { solicitacaBloquearVirtueyes === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-operadora">
                        <BloquearCliente renderizar={renderizar} setRenderizar={setRenderizar}/>
                    </div>
                </div>
            }
            { solicitacaRemoverBloqueioVirtueyes === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-operadora">
                        <RemoverBloqueio dadosSelecionados={dadosSelecionados} renderizar={renderizar} setRenderizar={setRenderizar}/>
                    </div>
                </div>
            }
            { solicitacaEnviarAvisoVirtueyes === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-operadora">
                        <EnviarAviso renderizar={renderizar} setRenderizar={setRenderizar}/>
                    </div>
                </div>
            }
            <CSVLink id='exportar-detalhamento-bloqueio' filename={"Bloqueio.csv"} className="d-none" data={dadosParaDownload}/>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_cliente_bloqueio' 
                                data={itens} 
                                columns={colunas} 
                                selectRow={ selecaoLinhas }
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaBloqueio));