import {api} from '../conexoes/api';/*eslint-disable*/
import decryptJWT from '../utils/decryptJWT'/*eslint-disable*/



export const TOKEN_KEY = "@veye-Token";
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (autenticacao) => {
  localStorage.setItem(TOKEN_KEY, autenticacao.token);
  localStorage.setItem('recebeuMensagemLogin', false)
};
export const logout = () => {
  const localTema = localStorage.getItem('tema');
  localStorage.clear();
  localStorage.setItem('tema', localTema)
};
export async function isAuthenticated(){
  try{
    const {data} = await api.post("/m1/servicos/dados/validacao_token", {
      'token' : localStorage.getItem(TOKEN_KEY)
    })/* .then(function (Response) {
    })
    .catch(function (error) {
      logout();
    }); */
  }catch (error) {
    await api.post('/m1/servicos/dados/logout', {
      id: decryptJWT("id"),
    }).then(function (Response) {
    })
    .catch(function (error) {
      if(decryptJWT('@veye-Token')){
        window.location.reload();
      }

      logout();
    });
  }
}
