//IMPORTAÇÕES
import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
// ICONES
import { setaDropdown } from "../../../utils/alternanciaIcones";

export default function SelectMotivo(props) {
  //HOOKS MODAL
  const modal = useRef();

  function motivo(motivoCancelamento) {
    props.setInputRevelado(motivoCancelamento);
    props.setDsMotivo(motivoCancelamento);
    switch (motivoCancelamento) {
      case 'Sim card com defeito': 
          props.setADE_CODCAT('00000001');
          props.setADE_CODCAU('00000011');
        break;
        case 'Cliente cancelado': 
          props.setADE_CODCAT('00000001');
          props.setADE_CODCAU('00000004');
        break;
        case 'Falta de demanda': 
          props.setADE_CODCAT('00000001');
          props.setADE_CODCAU('00000002');
        break;
        case 'Preço':
          props.setADE_CODCAT('00000001');
          props.setADE_CODCAU('00000006');
        break;
        case 'Outros':
          props.setADE_CODCAT('00000001');
          props.setADE_CODCAU('00000005');
        break;
        default: 
          props.setADE_CODCAT('');
          props.setADE_CODCAU('');
        break;
    }
    handleCancelar();
  }

  function handleCancelar() {
    props.setRevelarSelectMotivo(false);
  }

  return (
    <Form ref={modal}>
      <Button
        name="motivoCancelamento"
        className="campo-select-motivo-margin campo-texto-cor-3 campo-select"
        onClick={() => props.setRevelarSelectMotivo(false)}
      >
        <div className="fonte-cor-1 label-campo">{props.motivo}</div>
        <img
          className="campo-select-icone-ativado"
          src={setaDropdown()}
          alt="drop"
        />
      </Button>
      <div className="campo-select-triangulo-cor-1 campo-select-triangulo"></div>
      <div className="campo-texto-cor-3 campo-select-corpo">
        <ul className="campo-select-lista">
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo("Sim card com defeito")}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Sim card com defeito
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo("Cliente cancelado")}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Cliente cancelado
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo("Falta de demanda")}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Falta de demanda
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo("Preço")}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Preço
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
          <li>
            <div
              className="campo-select-opcao campo-select-opcao-1"
              onClick={() => motivo("Outros")}
            >
              <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">
                Outros
              </span>
            </div>
            <div className="campo-select-divisor-cor-1 campo-select-divisor"></div>
          </li>
        </ul>
      </div>
    </Form>
  );
}