//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Button, Form } from 'react-bootstrap';/*eslint-disable*/
// ICONES
import {setaDropdown} from '../../../utils/alternanciaIcones';
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'

/*  COMO USAR
    Importe a modal para dentro do componente que irá fazer uso dela.
    ex: import SelectUfCheckbox from '../../componentesModais/selects/selectUfCheckbox'

    Crie um hook apropriado para esconder e exibir a mensagem quando necessário.
    ex: const [revelarSelectUfCheckbox, setRevelarSelectUfCheckbox] = useState(false)

    Após isso, posicione-a dentro do return do seu componente e passe os props para
    customizar o conteúdo da mensagem da seguinte forma. É interessante utilizar um
    <Button/> com uma função onClick, dentro de um >Form.Group/> para que o campo
    se mantenha similar aos campos de texto normais.
    ex: <Form.Group>
            <Form.Label>
                <div className="fonte-cor-1 label-campo">UfCheckbox {iconeAsterisco}</div>
            </Form.Label>
            { revelarSelectUfCheckbox === false &&
                <Button name="cdUfCheckbox" className="campo-texto-cor-3 campo-select" onClick={() => setRevelarSelectUfCheckbox(!revelarSelectUfCheckbox)}>
                    <div className="fonte-cor-1 label-campo">{dsEstadoCheckbox}</div>
                    <img className="campo-select-icone-desativado" src={setaDropdown()} alt="drop" />
                </Button>
            }
            { revelarSelectUfCheckbox === true &&
                <SelectUfCheckbox
                    setRevelarSelectUfCheckbox={setRevelarSelectUfCheckbox} //Hook para fechar a modal
                    setCdUfCheckbox={setCdUfCheckbox} //Código do UfCheckbox
                    setdsEstadoCheckbox={setdsEstadoCheckbox} //Nome do UfCheckbox
                    dsEstadoCheckbox={dsEstadoCheckbox} 
                    phUfCheckbox={phUfCheckbox} //Placeholder para mostrar o valor original em caso de edição
                >
                </SelectUfCheckbox>
            }
        </Form.Group>*/
  
export default function SelectUfCheckbox(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS MODAL
    const [lsUfCheckboxs, setLsUfCheckboxs] = useState([])
    const [clickFora, setClickFora] = useState(false);
    const modal = useRef();
    let todosUsuarios = []

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    const handleClickDentro = () => setClickFora(false);

    function handleUfCheckbox(codigo, label, checkbox){
        if(codigo === -1){
            handleSelecionarTodos(checkbox)
        }
       else{
        if(checkbox === true){
                    props.arrayCdEstados.push({cd_pav_uf: codigo, ch_sigla: label})
                }else{
                    props.arrayCdEstados.forEach(function(e, index){
                        if(codigo == e.cd_pav_uf){
                            props.arrayCdEstados.splice(index, 1);
                        }
                    })
                }
            }
    }

    function handleSelecionarTodos(checked){
        const length = props.arrayCdEstados.length
        let i =0
        while(i < length){
            props.arrayCdEstados.pop()
            i++
        }
  
        if(checked === true){
            todosUsuarios.forEach(function(estado,i){
                props.arrayCdEstados.push({cd_pav_uf: estado.cd_pav_uf, ch_sigla: estado.ch_sigla})
            })
        }
    }


    function handleCancelar(){
        props.setRevelarSelectUfCheckbox(false)
    }

    useEffect(() => {
        setSpinAtivo(true)
        async function renderUfCheckboxs(){
            const {data} = await api.get('/m1/consultar/uf');
            data.dados.unshift({"cd_pav_uf":-1,"ch_sigla":"Todos"})
            todosUsuarios=data.dados    
            
            if(data.dados.length > 0){
                let objetoUfCheckboxs = data.dados.map((item,i) => {
                    return (
                        <li key={"menu-"+item.cd_pav_uf}>
                            <div className="campo-select-opcao-checkbox campo-select-opcao-1">
                                <label class="container-checkbox-item-select">
                                    <input id={item.cd_pav_uf} type="checkbox" defaultChecked={handleMarcarCheckboxes(item.cd_pav_uf) ? true : false} onChange={e => handleUfCheckbox(item.cd_pav_uf, item.ch_sigla, e.target.checked)}/>
                                    <span class="chekmark-checkbox-item-select"></span>
                                </label>
                                <span className="fonte-cor-1 nao-selecionavel">{item.ch_sigla}</span>
                            </div>
                            {data.dados[i+1]!=undefined&&<div className="campo-select-divisor-cor-1 campo-select-divisor"></div>}
                        </li>
                    );
                })
                setLsUfCheckboxs(objetoUfCheckboxs)
            }else{
                let objetoUfCheckboxs = () => {
                    return (
                        <li>
                            <div disabled className="campo-select-opcao campo-select-opcao-1">
                                <span className="fonte-cor-1 fonte-campo campo-select-opcao-label nao-selecionavel">Nenhuma informação encontrada</span>
                            </div>
                        </li>
                    );
                }
                setLsUfCheckboxs(objetoUfCheckboxs)
            }
            setSpinAtivo(false)
        }
        renderUfCheckboxs()
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    function handleMarcarCheckboxes(codigo){
        let verificacao = false
        props.arrayCdEstados.forEach(function(e, index){
            if(codigo === e.cd_pav_uf){
                verificacao = true
            }
        })
        return verificacao
    }

    return(
        <Form ref={modal}>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }  
            <Button name="cdTipoPessoa" className="campo-texto-cor-3 campo-select" onClick={() => handleCancelar()}>
                <div className="fonte-cor-1 label-campo">{props.dsEstadoCheckbox}</div>
                <img className="campo-select-icone-ativado" src={setaDropdown()} alt="drop" />
            </Button>
            <div className="campo-select-triangulo-cor-1 campo-select-triangulo triangulo-select-absoluto"></div>
            <div className="campo-texto-cor-3 campo-select-perfis-checkbox-corpo">
                <ul className="campo-select-lista">
                    {lsUfCheckboxs}
                </ul>
            </div>
        </Form>
    )
}