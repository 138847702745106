//IMPORTAÇÕES
import React, {useState, useEffect, useRef, Component} from 'react';/*eslint-disable*/
import { api } from '../../conexoes/api';/*eslint-disable*/
// import { useForm } from 'react-hook-form';/*eslint-disable*/
import { Button, Form, Col } from 'react-bootstrap';
import { NavLink, withRouter } from "react-router-dom";
import {
    Label, Cell, Bar, Pie, PieChart, BarChart, Tooltip, ResponsiveContainer, CartesianGrid, Legend, YAxis
    // Sector, AreaChart, Area, XAxis, YAxis, CartesianGrid, Legend
} from 'recharts';
// import moment from 'moment';
import { formatarMilhar, formatarMilharSemDecimais } from '../../utils/formatarMilhar'
import decryptJWT from '../../utils/decryptJWT'
import { exportarImagemDashSimcards } from './exportarImagem'
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento'
//ICONES
import { //elipseVerde, sinalMaiorQueVerde, pedidos, 
    reticenciasVerde, setaDropdown } from '../../utils/alternanciaIcones'
//SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//BARRA DE FILTRAGEM
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { visualizarDados, mudarManejador, revelarBarraFiltragem, limpadorFiltros, realizouFiltro, mudarFiltros, filtrosArrayBarra, recarregarBarraFiltragem
//  ,revelarMostrarEmDispositivos
} from "../../redux/actions/index";
//ESTLIZAÇÃO
import '../../css/painelDashboard/dashboard.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
import ConversorBytes from '../../utils/conversorBytes';
import { isPortal } from '../../utils/nivelCliente.js';
import { DashArrayCoresDefault, corDash as cor, handleGetColorByIdx } from '../../utils/dashboard.js';
import Chart from 'react-apexcharts'

let arrayCores = []

if (decryptJWT("clientePrimario") == 2455) {
    arrayCores = [
        cor.verde, ...DashArrayCoresDefault,
    ]
} else {
    arrayCores = [
        cor.verdeClaro, ...DashArrayCoresDefault,
    ]
}

const getColorByIdx = (idx) => handleGetColorByIdx(arrayCores, idx);

function Dasboard(props) {
    //OUTROS HOOKS
    const despacho = useDispatch();
    // let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados = useSelector(state => state.virtueyesState.visualizarDados);
    // let revelarMostrarEmDispositivo = useSelector(state => state.pedidosState.revelarMostrarEmDispositivos);
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    // let filtrosReducer = useSelector(state => state.filtrosState.filtros);
    const [spinAtivo, setSpinAtivo] = useState(true);
    const localTema = localStorage.getItem('tema');

    // HOOK DO TOTAL DE SIM CARDS
    const [totalSimcards, setTotalSimcards] = useState(0)

    // Filtros para o card de Ciclos (único que precisa mudar de acordo com os filtros)
    const [filtroOperadora, setFiltroOperadora] = useState(false)
    const [filtroOperadoraPlano, setFiltroOperadoraPlano] = useState(false)

    // HOOKS DOS GRAFICOS DE CONSUMO
    const [arrayConsumoTotal, setArrayConsumoTotal] = useState([])
    const [tooltipConsumoTotal, setTooltipConsumoTotal] = useState(0)
    const [percentualTotalContratado, setPercentualTotalContratado] = useState(0)
    const [percentualPrevisaoConsumo, setPercentualPrevisaoConsumo] = useState(0)
    const [percentualConsumoTotal, setPercentualConsumoTotal] = useState(0)
    const [percentualTotalAtivo, setPercentualTotalAtivo] = useState(0)
    
    const [tooltipTotalContratado, setTooltipTotalContratado] = useState(0)
    const [tooltipTotalAtivo, setTooltipTotalAtivo] = useState(0)
    const [tooltipPrevisaoConsumo, setTooltipPrevisaoConsumo] = useState(0)

    // const [menuOpcoesConsumo, setMenuOpcoesConsumo] = useState()
    const [revelarOpcoesConsumo, setRevelarOpcoesConsumo] = useState(false)

    // HOOKS DOS GRAFICOS DE CONEXOES
    const [revelarOpcoesConexoes, setRevelarOpcoesConexoes] = useState(false)

    // HOOKS DOS GRAFICOS DE SIMCARDS STATUS
    const [arraySimcardsStatus, setArraySimcardsStatus] = useState([])
    const [percentualSimcardsStatus, setPercentualSimcardsStatus] = useState(0)
    const [tooltipSimcardsStatus, setTooltipSimcardsStatus] = useState(0)
    const [revelarOpcoesSimcardsStatus, setRevelarOpcoesSimcardsStatus] = useState(false)

    // HOOKS DOS GRAFICOS DE SIMCARDS ONLINE
    const [arraySimcardsOnline, setArraySimcardsOnline] = useState([])
    const [percentualSimcardsOnline, setPercentualSimcardsOnline] = useState(0)
    const [tooltipSimcardsOnline, setTooltipSimcardsOnline] = useState(0)
    const [revelarOpcoesSimcardsOnline, setRevelarOpcoesSimcardsOnline] = useState(false)

    // HOOKS DOS GRAFICOS DE SIMCARDS OFFLINE
    const [arraySimcardsOffline, setArraySimcardsOffline] = useState([])
    const [percentualSimcardsOffline, setPercentualSimcardsOffline] = useState(0)
    const [tooltipSimcardsOffline, setTooltipSimcardsOffline] = useState(0)
    const [revelarOpcoesSimcardsOffline, setRevelarOpcoesSimcardsOffline] = useState(false)

    // HOOKS DOS GRAFICOS DE SIMCARDS SEM COMUNICAR
    const [arraySemComunicar24h, setArraySemComunicar24h] = useState([])
    const [percentualSemComunicar24h, setPercentualSemComunicar24h] = useState(0)
    const [tooltipSemComunicar24h, setTooltipSemComunicar24h] = useState(0)
    const [arraySemComunicar24h30d, setArraySemComunicar24h30d] = useState([])
    const [percentualSemComunicar24h30d, setPercentualSemComunicar24h30d] = useState(0)
    const [tooltipSemComunicar24h30d, setTooltipSemComunicar24h30d] = useState(0)
    const [arraySemComunicar30d60d, setArraySemComunicar30d60d] = useState([])
    const [percentualSemComunicar30d60d, setPercentualSemComunicar30d60d] = useState(0)
    const [tooltipSemComunicar30d60d, setTooltipSemComunicar30d60d] = useState(0)
    const [arraySemComunicar60d, setArraySemComunicar60d] = useState([])
    const [percentualSemComunicar60d, setPercentualSemComunicar60d] = useState(0)
    const [tooltipSemComunicar60d, setTooltipSemComunicar60d] = useState(0)
    const [arraySimcardsEmEstoque, setArraySimcardsEmEstoque] = useState([])
    const [percentualSimcardsEmEstoque, setPercentualSimcardsEmEstoque] = useState(0)
    const [tooltipSimcardsEmEstoque, setTooltipSimcardsEmEstoque] = useState(0)
    const [tooltipSimcardsSemComunicar, setTooltipSimcardsSemComunicar] = useState(0)
    const [revelarOpcoesSimcardsSemComunicar, setRevelarOpcoesSimcardsSemComunicar] = useState(false)

    // HOOKS DOS GRAFICOS DE TOTAL SIMCARDS OPERADORAS
    const [graficoTotalSimcardsOperadoraMax, setGraficoTotalSimcardsOperadoraMax] = useState(0)
    const [graficoTotalSimcardsOperadora, setGraficoTotalSimcardsOperadora] = useState([])
    const [legendaTotalSimcardsOperadora, setLegendaTotalSimcardsOperadora] = useState([])
    const [dadosTotalSimcardsOperadora, setDadosTotalSimcardsOperadora] = useState()
    const [revelarOpcoesTotalSimcardsOperadora, setRevelarOpcoesTotalSimcardsOperadora] = useState(false)
    const [percentualSimcardsOperadoras, setPercentualSimcardsOperadoras] = useState(0)

    // HOOKS DOS GRÁFICOS TECNOLOGIAS
    const [graficoTotalTecnologias, setGraficoTotalTecnologias] = useState(0)
    const [graficoTotalTecnologiasIndividuais, setGraficoTotalTecnologiasIndividuais] = useState([])
    const [legendaTotalTecnologias, setLegendaTotalTecnologias] = useState([])
    const [dadosTotalTecnologias, setDadosTotalTecnologias] = useState()
    const [revelarOpcoesTecnologias, setRevelarOpcoesTecnologias] = useState(false)
    const [percentualTecnologias, setPercentualTecnologias] = useState(0)

    // HOOKS DOS GRAFICOS DE STATUS DE CONTRATO
    const [graficoStatusContratoMax, setGraficoStatusContratoMax] = useState(0)
    const [graficoStatusContrato, setGraficoStatusContrato] = useState([])
    const [legendaStatusContrato, setLegendaStatusContrato] = useState([])
    const [dadosStatusContrato, setDadosStatusContrato] = useState()
    const [revelarOpcoesStatusContrato, setRevelarOpcoesStatusContrato] = useState(false)


    //HOOKS DO APEXCHART
    const [chartConsumoData , setChartConsumoData] = useState()
    const [chartFillColors, setChartFillColors] = useState();
    const [valueTotals, setValueTotals] = useState();

    //HOOK DO PERÍODO
    const [periodo, setPeriodo] = useState(0)

    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    const cdCliente = decryptJWT('clientePrimario')
    const clienteAlgar = (cdCliente == 2455);
    const cor1 = clienteAlgar ? cor.verde : cor.verdeClaro;
    const cor2 = clienteAlgar ? cor.verde : cor.verdeClaro;

    const portalCliente = isPortal();


    useEffect(() => {
        if (coletaUsabilidade) {
            ColetaClickstream(525) // 'painelDashboardDashboard'
            setColetaUsabilidade(false)
        }
    }, [])

    /* CONTROLA O CLICK FORA DOS MENUS DE OPCOES ( três pontinho verdes ) */
    function handleAbrirMenuOpcoes(identificador) {
        switch (identificador) {
            case 'consumo':
                setRevelarOpcoesConsumo(!revelarOpcoesConsumo)
                break;
            case 'conexoes':
                setRevelarOpcoesConexoes(!revelarOpcoesConexoes)
                break;
            case 'simcardsStatus':
                setRevelarOpcoesSimcardsStatus(!revelarOpcoesSimcardsStatus)
                break;
            case 'simcardsOnline':
                setRevelarOpcoesSimcardsOnline(!revelarOpcoesSimcardsOnline)
                break;
            case 'simcardsOffline':
                setRevelarOpcoesSimcardsOffline(!revelarOpcoesSimcardsOffline)
                break;
            case 'simcardsSemComnuicar':
                setRevelarOpcoesSimcardsSemComunicar(!revelarOpcoesSimcardsSemComunicar)
                break;
            case 'simcardsOperadoras':
                setRevelarOpcoesTotalSimcardsOperadora(!revelarOpcoesTotalSimcardsOperadora)
                break;
            case 'statusContrato':
                setRevelarOpcoesStatusContrato(!revelarOpcoesStatusContrato)
                break;
            case 'tecnologias':
                setRevelarOpcoesTecnologias(!revelarOpcoesTecnologias)
                break;
            case 'cicloAtual':
                setRevelarOpcoesCicloAtual(!revelarOpcoesCicloAtual)
                break;
        }
    }

    /* GERA A LABEL COM O VALOR TOTAL DENTRO DOS GRAFICOS COMPOSTOS */
    function LabelGraficosPizzaComposto({ viewBox, valorPercentual }) {
        const { cx, cy } = viewBox;
        let auxTooltipTotal
        const auxValPerc = parseFloat(valorPercentual);

        if (auxValPerc > 2000000) {
            auxTooltipTotal = parseFloat(valorPercentual / 1000000).toFixed(1) + " milhões"
        } else if (auxValPerc > 1000000) {
            auxTooltipTotal = parseFloat(valorPercentual / 1000000).toFixed(1) + " milhão"
        } else if (auxValPerc > 1000) {
            auxTooltipTotal = parseFloat(valorPercentual / 1000).toFixed(1) + " mil"
        } else {
            auxTooltipTotal = formatarMilharSemDecimais(valorPercentual)
        }
        return (
            <>
                <text className="texto-titulo-legenda-grafico-pizza-grande-dash" x={cx} y="40%" fill={localTema === 'claro' || localTema === 'algarClaro' ? cor.preto : cor.branco} textAnchor="middle" dominantBaseline="central">
                    Total
                </text>
                <text className="label-percentual-graficos-pizza-composto-dash" x={cx} y="50%" fill={localTema === 'claro' || localTema === 'algarClaro' ? cor.preto : cor.branco} textAnchor="middle" dominantBaseline="central">
                    {auxTooltipTotal}
                </text>
            </>
        )
    }

    /* GERA A LABEL DE % DOS GRAFICOS EM GRUPOS DE 4 */
    function LaleGraficosPizzaGrupoQuatro({ viewBox, valorPercentual }) {
        const { cx, cy } = viewBox;
        return (
            <text className="label-percentual-graficos-pizza-grupo-quatro" x={cx} y="50%" fill={localTema === 'claro' || localTema === 'algarClaro' ? cor.preto : cor.branco} textAnchor="middle" dominantBaseline="central">
                {valorPercentual + "%"}
            </text>
        )
    }

    /* PREENCHE OS ARRAYS USADOS PARA CARREGAR OS GRAFICOS */
    function handlePreencherGraficoPizza(primeiroValor, segundoValor, cor) {
        return [{ "value": parseFloat(primeiroValor.toFixed(2)), "cor": cor },
        { "value": parseFloat((segundoValor - primeiroValor).toFixed(2)), "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro }]
    }

    /* CALCULA O % EXIBIDO NO CENTRO DOS GRAFICOS */
    function handlePreencherPercentualGraficoPizza(primeiroValor, segundoValor) {
        return (
            ((primeiroValor / segundoValor) * 100).toFixed(0)
        )
    }

    const TooltipGraficoPizzaSemComunicar = ({ active, payload, arrDados }) => {
        let percentual = null;
        if (totalSimcards === 0) {
            percentual = 100;
        }
        else if (active && payload !== null && payload.length > 0) {
                percentual = `${parseFloat((payload[0].payload.value / tooltipSimcardsSemComunicar) * 100).toFixed(2)}`
        }
        else if(arrDados?.length === 0) {
            percentual = 0;
        }

        if(percentual === null) return null;

        return (
            <div className="container-tooltip-grafico-pizza">
                <p className="label-tooltip-grafico-pizza">{percentual}%</p>
            </div>
        );
    }

    /* CALCULA A TOOLTIP (porcentagem) DE HOVER DOS GRAFICOS */
    const TooltipGraficoPizza = ({ active, payload, arrDados }) => {
        let percentual = null;

        if (totalSimcards === 0) {
            percentual = 100;
        }
        else if (active && payload !== null && payload.length > 0) {
                percentual = `${parseFloat((payload[0].payload.value / totalSimcards) * 100).toFixed(2)}`
        }
        else if(arrDados?.length === 0) {
            percentual = 0;
        }

        if(percentual === null) return null;

        return (
            <div className="container-tooltip-grafico-pizza">
                <p className="label-tooltip-grafico-pizza">{percentual}%</p>
            </div>
        );

        return null;
    }

    /* CALCULA A TOOLTIP (porcentagem) DE HOVER DOS GRAFICOS */
    const TooltipGraficoBarra = ({ active, payload }) => {
        if (tooltipTotalContratado === 0) {
            return (
                <div className="container-tooltip-grafico-barra">
                    <p className="label-tooltip-grafico-barra">100%</p>
                </div>
            );
        } else {
            if (active && payload !== null && payload.length > 0) {
                return (
                    <div className="container-tooltip-grafico-barra">
                        <p className="label-tooltip-grafico-barra">{`${parseFloat((payload[0].value / tooltipTotalContratado) * 100).toFixed(2)}`}%</p>
                    </div>
                );
            }
        }
        return null;
    }

    /* CONTROLA A EXPANSAO E CONTRACAO DOS DROPDOWNS DOS MENUS DE OPCOES */
    function dropMenuOpcoes(event, elementoId, imagemId) {
        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(imagemId);

        if (imagem.classList.contains("menu-opcoes-icone-desativado")) {
            imagem.classList.remove("menu-opcoes-icone-desativado")
            imagem.classList.add("menu-opcoes-icone-ativado")
        } else if (imagem.classList.contains("menu-opcoes-icone-ativado")) {
            imagem.classList.remove("menu-opcoes-icone-ativado")
            imagem.classList.add("menu-opcoes-icone-desativado")
        }

        if (elemento.classList.contains("dropdown-desativado")) {
            elemento.classList.remove("dropdown-desativado")
            elemento.classList.add("dropdown-ativado")
        } else if (elemento.classList.contains("dropdown-ativado")) {
            elemento.classList.remove("dropdown-ativado")
            elemento.classList.add("dropdown-desativado")
        }
    }

    // Função criada para auxiliar o filtro por
    // status do contrato (que usa %like% e pega ativo e pre-ativo na busca)
    function tratarString(str1) {
        str1 = str1.toLowerCase()
        const firstLetterUpper = str1.substr(0, 1).toUpperCase()
        const strRest = str1.substr(1, str1.length - 1)
        return firstLetterUpper + strRest
    }

    /* Mostra em dispositivos o que foi selecionado no menu-drop-down (3 pontinhos verdes) */
    async function mostrarEmDispositivos(filtro) {
        despacho(mudarFiltros(filtro))
        
        switch(filtro.ULTIMA_CONEXAO){            
            case 'online':
                despacho(filtrosArrayBarra(["Status:" + " Online?ULTIMA_CONEXAO"]))
                break;
            case 'estoque':
                despacho(filtrosArrayBarra(["Status:" + " Estoque?ULTIMA_CONEXAO"]))
                break;
            case 'ultima_conexao':
                despacho(filtrosArrayBarra(["Status:" + " Offline?ULTIMA_CONEXAO"]))
                break;
        }
        switch (filtro.SEM_COMUNICAR) {
            case 'sem_comunicacao_24h':
                despacho(filtrosArrayBarra(["Sem comunicar:" + " 24 horas?SEM_COMUNICAR"]))
                break;
            case 'sem_comunicacao_24h_30d':
                despacho(filtrosArrayBarra(["Sem comunicar:" + " Entre 24 horas e 30 dias?SEM_COMUNICAR"]))
                break;
            case 'sem_comunicacao_30d_60d':
                despacho(filtrosArrayBarra(["Sem comunicar:" + " Entre 30 e 60 dias?SEM_COMUNICAR"]))
                break;
            case 'sem_comunicacao_60d':
                despacho(filtrosArrayBarra(["Sem comunicar:" + " Mais de 60 dias?SEM_COMUNICAR"]))
                break;
        }
        if (filtro.OPERADORA) {
            let operadoraString

            switch (filtro.OPERADORA) {
                case 1:
                    operadoraString = 'Claro'
                    break;
                case 2:
                    operadoraString = 'Vivo'
                    break;
                case 4:
                    operadoraString = 'Tim'
                    break;
                case 40:
                    operadoraString = 'Oi'
                    break;
                case 41:
                    operadoraString = 'Algar'
                    break;
                case 72:
                    operadoraString = 'Arqia'
                    break;
                case 76:
                    operadoraString = 'Virtueyes'
                    break;
                default:
                    break;
            }

            despacho(filtrosArrayBarra(["Operadora: " + operadoraString + "?OPERADORA"]))
        }

        if(filtro.OPERADORA_PLANO){
            despacho(filtrosArrayBarra(["Operadora Plano: "+filtro.OPERADORA_PLANO+"?OPERADORA_PLANO"]))
        }
        if(filtro.STATUS_CONTRATO){
            const strStatusContrato = tratarString(filtro.STATUS_CONTRATO)
            despacho(filtrosArrayBarra(["Situação do contrato: " + strStatusContrato + "?STATUS_CONTRATO"]))
        }
        if (filtro.PLANO) {
            despacho(filtrosArrayBarra(["Plano: " + filtro.PLANO + " MB?PLANO"]))
        }
        if (filtro.TECNOLOGIA) {
            let strTecnologia = filtro.TECNOLOGIA
            strTecnologia === 'OUTRAS' ? strTecnologia = 'Outras' : filtro.TECNOLOGIA
            strTecnologia === 'NB' ? strTecnologia = 'NB-IoT' : filtro.TECNOLOGIA
            despacho(filtrosArrayBarra(["Tecnologia: " + strTecnologia + "?TECNOLOGIA"]))
        }
        despacho(revelarBarraFiltragem(true))
        despacho(limpadorFiltros(false))
        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
    }

    useEffect(() => {
        //clicou no botão para filtro
        if (realizouFiltragem) {
            despacho(realizouFiltro(false));
        }

        despacho(mudarManejador("dashboardGeral"));
        setSpinAtivo(true)

        const coresBotoes = {
            botao0: "fundo-cor-dash-selecionado",
            botao1: "fundo-cor-5",
            botao2: "fundo-cor-5"
        }
        //setCorBotaoAlternarDash(coresBotoes)
        async function handleCarregarGraficosDashboard() {
            let dadosDash

            if(!props.filtros.vazio){
                let filtros = props.filtros;

                const dados = {
                    periodo:periodo,
                    filtros
                }
                const { data } = await api.post('/m1/filtrar/dashboard_todos', dados)
                dadosDash = data.dados
            } else {
                const { data } = await api.get('/m1/consultar/dashboard_todos?periodo=' + periodo)
                dadosDash = data.dados
            }

            /* SIM CARDS TOTAL */
            if(dadosDash?.n0_simcards_max !== undefined) {
                setTotalSimcards(dadosDash.n0_simcards_max)
            } else {
                setSpinAtivo(false)
                return;
            }
            
            /* SIM CARDS STATUS */
            if (dadosDash.n0_simcards_online === 0 && dadosDash.n0_simcards_offline === 0 && dadosDash.n0_simcards_em_estoque === 0) {
                setArraySimcardsStatus([{ "value": 100, "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro }])
                setPercentualSimcardsStatus(0)
            } else {
                setArraySimcardsStatus([{ "value": dadosDash.n0_simcards_online, "cor": cor1 },
                { "value": (dadosDash.n0_simcards_offline - dadosDash.n0_simcards_em_estoque), "cor": cor.vermelho },
                { "value": dadosDash.n0_simcards_em_estoque, "cor": cor.azul }]),
                    setPercentualSimcardsStatus(handlePreencherPercentualGraficoPizza(dadosDash.n0_simcards_max, dadosDash.n0_simcards_max))
            }

            setTooltipSimcardsStatus((dadosDash.n0_simcards_max))

            /* SIMCARDS ONLINE */
            if (dadosDash.n0_simcards_online === 0) {
                setArraySimcardsOnline([{ "value": 100, "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro }])
                setPercentualSimcardsOnline(0)
            } else {
                setArraySimcardsOnline(handlePreencherGraficoPizza(dadosDash.n0_simcards_online, dadosDash.n0_simcards_max, cor1))
                setPercentualSimcardsOnline(handlePreencherPercentualGraficoPizza(dadosDash.n0_simcards_online, dadosDash.n0_simcards_max))
            }
            setTooltipSimcardsOnline(dadosDash.n0_simcards_online)

            /* SIMCARDS OFFLINE */
            if (dadosDash.n0_simcards_offline === 0) {
                setArraySimcardsOffline([{ "value": 100, "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro }])
                setPercentualSimcardsOffline(0)
            } else {
                setArraySimcardsOffline(handlePreencherGraficoPizza((dadosDash.n0_simcards_offline - dadosDash.n0_simcards_em_estoque), dadosDash.n0_simcards_max, cor.vermelho))
                setPercentualSimcardsOffline(handlePreencherPercentualGraficoPizza((dadosDash.n0_simcards_offline - dadosDash.n0_simcards_em_estoque), dadosDash.n0_simcards_max))
            }
            setTooltipSimcardsOffline((dadosDash.n0_simcards_offline - dadosDash.n0_simcards_em_estoque))

            /* SIMCARDS SEM COMUNICAR */
            // if (dadosDash.n0_sem_comunicacao_24h === 0 && dadosDash.n0_sem_comunicacao_24h_30d === 0 && dadosDash.n0_sem_comunicacao_30d_60d === 0 && dadosDash.n0_sem_comunicacao_60d === 0) {
                // setArraySemComunicar24h([{ "value": 100, "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro }]) 
            // } else {
                setArraySemComunicar24h([{ "value": dadosDash.n0_sem_comunicacao_24h, "cor": cor1 },
                { "value": dadosDash.n0_sem_comunicacao_24h_30d, "cor": cor.amarelo },
                { "value": dadosDash.n0_sem_comunicacao_30d_60d, "cor": cor.vermelho },
                { "value": dadosDash.n0_sem_comunicacao_60d, "cor": cor.laranja },
                { "value": dadosDash.n0_simcards_em_estoque, "cor": cor.azul }])
            // }

            setPercentualSemComunicar24h(handlePreencherPercentualGraficoPizza(dadosDash.n0_sem_comunicacao_24h, dadosDash.n0_simcards_max))
            setTooltipSemComunicar24h(dadosDash.n0_sem_comunicacao_24h)

            setArraySemComunicar24h30d(handlePreencherGraficoPizza(dadosDash.n0_sem_comunicacao_24h_30d, dadosDash.n0_simcards_max, cor.amarelo))
            setPercentualSemComunicar24h30d(handlePreencherPercentualGraficoPizza(dadosDash.n0_sem_comunicacao_24h_30d, dadosDash.n0_simcards_max))
            setTooltipSemComunicar24h30d(dadosDash.n0_sem_comunicacao_24h_30d)

            setArraySemComunicar30d60d(handlePreencherGraficoPizza(dadosDash.n0_sem_comunicacao_30d_60d, dadosDash.n0_simcards_max, cor.laranja))
            setPercentualSemComunicar30d60d(handlePreencherPercentualGraficoPizza(dadosDash.n0_sem_comunicacao_30d_60d, dadosDash.n0_simcards_max))
            setTooltipSemComunicar30d60d(dadosDash.n0_sem_comunicacao_30d_60d)

            setArraySemComunicar60d(handlePreencherGraficoPizza(dadosDash.n0_sem_comunicacao_60d, dadosDash.n0_simcards_max, cor.vermelho))
            setPercentualSemComunicar60d(handlePreencherPercentualGraficoPizza(dadosDash.n0_sem_comunicacao_60d, dadosDash.n0_simcards_max))
            setTooltipSemComunicar60d(dadosDash.n0_sem_comunicacao_60d)

            setArraySimcardsEmEstoque(handlePreencherGraficoPizza(dadosDash.n0_simcards_em_estoque, dadosDash.n0_simcards_max, cor.azul))
            setPercentualSimcardsEmEstoque(handlePreencherPercentualGraficoPizza(dadosDash.n0_simcards_em_estoque, dadosDash.n0_simcards_max))
            setTooltipSimcardsEmEstoque(dadosDash.n0_simcards_em_estoque)

            setTooltipSimcardsSemComunicar((dadosDash.n0_sem_comunicacao_24h + dadosDash.n0_sem_comunicacao_24h_30d + dadosDash.n0_sem_comunicacao_30d_60d + dadosDash.n0_sem_comunicacao_60d + dadosDash.n0_simcards_em_estoque))

            const formatarDados = (size) => {
                const fromUnit = 'MB';
                const toUnit = 'GB';
                if(size <= 1024) return `${formatarMilhar(size)} ${fromUnit}`; // 1000 => '1.000,00 MB'

                return formatarMilhar(ConversorBytes(size, fromUnit, toUnit)) + ' ' + toUnit; // 10000 => '9,77 GB'
            }

            /* GRAFICOS DE CONSUMO */
            // percentual - total contratado
            //setPercentualTotalContratado(clienteAlgar ?[{"value": 100, "cor": cor.verde}]:[{"value": 100, "cor": cor.verdeClaro}])
            setTooltipTotalContratado(formatarDados(dadosDash.n2_planos_total))
            setTooltipTotalAtivo(formatarDados(dadosDash.n2_planos_total_ativo))
            setTooltipPrevisaoConsumo(formatarDados(dadosDash.n2_previsao_consumo))
            setTooltipConsumoTotal(formatarDados(dadosDash.n2_consumo_total))

            // consumo total
            // setPercentualConsumoTotal(handlePreencherPercentualGraficoPizza(dadosDash.n2_planos_total, dadosDash.n2_consumo_total))
            // setPercentualTotalAtivo(handlePreencherPercentualGraficoPizza(dadosDash.n2_planos_total, dadosDash.n2_planos_total_ativo))
            // setPercentualPrevisaoConsumo(handlePreencherPercentualGraficoPizza(dadosDash.n2_planos_total, dadosDash.n2_previsao_consumo))

            const getBarValue = (val) => {
                const perc = val * 100 / dadosDash.n2_planos_total;
                return (perc > 0 && perc < 1) ? 1 : perc; // para aparecer no gráfico de barras
            }

            const percPlanosTotalAtivo = getBarValue(dadosDash.n2_planos_total_ativo);
            const percConsumoTotal = getBarValue(dadosDash.n2_consumo_total);
            const percPlanosTotal = getBarValue(dadosDash.n2_planos_total);
            const percPrevisaoConsumo = getBarValue(dadosDash.n2_previsao_consumo);

            setPercentualConsumoTotal(percConsumoTotal)
            setPercentualTotalAtivo(percPlanosTotalAtivo)
            setPercentualPrevisaoConsumo(percPrevisaoConsumo)
            setPercentualTotalContratado(100)

            if (percPlanosTotalAtivo == 0 && (percConsumoTotal == 0 && percPrevisaoConsumo == 0)) {
                setArrayConsumoTotal([{ "value": percPlanosTotal, "fill": cor.azul }])
                //setPercentualConsumoTotal(0)
            }else if (!props.filtros.OPERADORA || !props.filtros.OPERADORA_PLANO) { // Adicionado em 14/04/2023 - Como Melhoria
                setArrayConsumoTotal([{"value": percPlanosTotal, "fill": cor.azul, "percent": percentualTotalContratado},
                {"value": percPlanosTotalAtivo, "fill": cor2, "percent": percentualTotalAtivo}])
                setFiltroOperadora(false);
                setFiltroOperadoraPlano(false);
                //setPercentualConsumoTotal(percPlanosTotal)                        
            }else{
                setArrayConsumoTotal([{"value": percPlanosTotal, "fill": cor.azul, "percent": percentualTotalContratado},
                    {"value": percPlanosTotalAtivo, "fill": cor2, "percent": percentualTotalAtivo},
                    {"value": percPrevisaoConsumo, "fill": cor.amarelo, "percent": percentualPrevisaoConsumo},
                    {"value": percConsumoTotal, "fill": cor.vermelho, "percent": percentualConsumoTotal}])
                setFiltroOperadora(true);
                setFiltroOperadoraPlano(true);                        
                //setPercentualConsumoTotal(percPlanosTotal)                        
            }

            /* GRAFICOS DE OPERADORAS */
            setDadosTotalSimcardsOperadora(JSON.parse(JSON.stringify(dadosDash.operadoras)))

            /* GRAFICOS DE CONTRATOS */
            setDadosStatusContrato(JSON.parse(JSON.stringify(dadosDash.contratos)))

            /* CARD DE TOTAL DE OPERADORAS */
            if (dadosDash.operadoras.length !== 0) {
                const totalSimcardsOperadoras = JSON.parse(JSON.stringify(dadosDash.operadoras))
                let quantidadeOperadoras = totalSimcardsOperadoras.length
                let arrayGraficosTotalSimcardsOperadora = []
                let contadorRenderTotalSimcardsOperadora = 0
                let manejadorLabel = ""
                for (let y = 0; y < quantidadeOperadoras; y++) {
                    if (totalSimcardsOperadoras[contadorRenderTotalSimcardsOperadora] != undefined) {
                        manejadorLabel = totalSimcardsOperadoras[contadorRenderTotalSimcardsOperadora].label
                        arrayGraficosTotalSimcardsOperadora.push({
                            "value": totalSimcardsOperadoras[contadorRenderTotalSimcardsOperadora].quantidade,
                            "cor": getColorByIdx(contadorRenderTotalSimcardsOperadora),
                            "label": manejadorLabel.slice(0, 1).toUpperCase() + manejadorLabel.slice(1, manejadorLabel.length).toLowerCase(),
                        }
                        )
                        contadorRenderTotalSimcardsOperadora++
                    }
                }
                let objetoLegendaTotalSimcardsOperadora = arrayGraficosTotalSimcardsOperadora.map((itemContrato, idx) => {
                    return (
                        <LinhaLegenda key={idx} itemContrato={itemContrato} clienteAlgar={clienteAlgar} />
                    );
                })
                setGraficoTotalSimcardsOperadoraMax(dadosDash.n0_simcards_max)
                setGraficoTotalSimcardsOperadora(JSON.parse(JSON.stringify(arrayGraficosTotalSimcardsOperadora)))
                setLegendaTotalSimcardsOperadora(objetoLegendaTotalSimcardsOperadora)
                setPercentualSimcardsOperadoras(handlePreencherPercentualGraficoPizza(dadosDash.operadoras.length, dadosDash.operadoras.length))
            } else {
                let arrayGraficosTotalSimcardsOperadora = [{
                    "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro,
                    "label": "Vazio",
                    "value": 0
                }]
                let objetoLegendaTotalSimcardsOperadora = arrayGraficosTotalSimcardsOperadora.map((itemContrato, idx) => {
                    return (
                        <LinhaLegenda key={idx} itemContrato={itemContrato} />
                    );
                })
                setGraficoTotalSimcardsOperadoraMax(0)
                setGraficoTotalSimcardsOperadora([{
                    "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro,
                    "value": 100
                }])
                setLegendaTotalSimcardsOperadora(objetoLegendaTotalSimcardsOperadora)
            }

            /*CARD TECNOLOGIAS */
            if (dadosDash.tecnologias.length !== 0) {
                const totalTecnologias = JSON.parse(JSON.stringify(dadosDash.tecnologias))
                let quantidadeTecnologias = totalTecnologias.length
                let totalLable = 0
                let arrayGraficosTecnologias = []
                let contadorRenderTecnologias = 0
                let manejadorLabel = ""
                for (let y = 0; y < quantidadeTecnologias; y++) {
                    if (totalTecnologias[contadorRenderTecnologias] != undefined) {
                        manejadorLabel = totalTecnologias[contadorRenderTecnologias].label

                        // Retira o lowerCase para 2G-3G-4G.... e mantém para outras
                        if (manejadorLabel == "OUTRAS") {
                            manejadorLabel = manejadorLabel.slice(0, 1).toUpperCase() + manejadorLabel.slice(1, manejadorLabel.length).toLowerCase()
                        } else if (manejadorLabel == "NB") {
                            manejadorLabel = manejadorLabel.toUpperCase() + '-IoT'
                        } else {
                            manejadorLabel = manejadorLabel.slice(0, 1).toUpperCase() + manejadorLabel.slice(1, manejadorLabel.length)
                        }

                        arrayGraficosTecnologias.push({
                            "value": totalTecnologias[contadorRenderTecnologias].quantidade,
                            "cor": getColorByIdx(contadorRenderTecnologias),
                            "label": manejadorLabel,
                        }
                        )
                        totalLable += totalTecnologias[contadorRenderTecnologias].quantidade
                        contadorRenderTecnologias++

                    }
                }
                let objetoLegendaTecnologias = arrayGraficosTecnologias.map((itemContrato, idx) => {
                    return (
                        <LinhaLegenda key={idx} itemContrato={itemContrato} clienteAlgar={clienteAlgar} />
                    );
                })
                setGraficoTotalTecnologias(totalLable)
                setGraficoTotalTecnologiasIndividuais(JSON.parse(JSON.stringify(arrayGraficosTecnologias)))
                setLegendaTotalTecnologias(objetoLegendaTecnologias)
                setPercentualTecnologias(handlePreencherPercentualGraficoPizza(dadosDash.tecnologias.length, dadosDash.tecnologias.length))
            } else {
                let arrayGraficosTecnologias = [{
                    "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro,
                    "label": "Vazio",
                    "value": 0
                }]
                let objetoLegendaTecnologias = arrayGraficosTecnologias.map((itemContrato, idx) => {
                    return (
                        <LinhaLegenda key={idx} itemContrato={itemContrato} />
                    );
                })
                setGraficoTotalTecnologias(0)
                setGraficoTotalTecnologiasIndividuais([{
                    "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro,
                    "value": 100
                }])
                setLegendaTotalTecnologias(objetoLegendaTecnologias)
            }
            setDadosTotalTecnologias(JSON.parse(JSON.stringify(dadosDash.tecnologias)))

            function LinhaLegenda({clienteAlgar, itemContrato}) {
                return <div className="linha-legenda-operadoras">
                    <div className="coluna-legenda-operadoras-1">
                        <div className="esfera-legenda-dashboard" style={{ backgroundColor: clienteAlgar ? cor.verde : itemContrato.cor }} />
                        <div className="coluna-legenda-operadoras-2">
                            <span className="fonte-cor-1 texto-legenda-operadoras">{itemContrato.label}</span>
                        </div>
                    </div>
                    <div className="coluna-legenda-operadoras-3"><span className="fonte-cor-1 texto-legenda-operadoras-numero">{formatarMilharSemDecimais(itemContrato.value)}</span></div>
                </div>
            }

            /* CARD DE STATUS DO CONTRATO */
            if (dadosDash.contratos.length !== 0) {
                const statusContratos = JSON.parse(JSON.stringify(dadosDash.contratos))

                let quantidadeContratos = statusContratos.length
                let arrayGraficosStatusContrato = []
                let contadorRenderStatusContrato = 0
                let manejadorLabel = ""
                for (let y = 0; y < quantidadeContratos; y++) {
                    if (statusContratos[contadorRenderStatusContrato] != undefined) {
                        manejadorLabel = statusContratos[contadorRenderStatusContrato].label

                        if (manejadorLabel == 0 || manejadorLabel == undefined) {
                            manejadorLabel = 'INDISPONÍVEL'
                            arrayGraficosStatusContrato.push({
                                //"cor": getColorByIdx(3), 
                                "label": manejadorLabel.slice(0, 1).toUpperCase() + manejadorLabel.slice(1, manejadorLabel.length).toLowerCase(),
                            }
                            )
                        } else {
                            if (manejadorLabel === 'DEMONSTRACAO') {
                                manejadorLabel = 'Demonstração'
                            }
                            arrayGraficosStatusContrato.push({
                                "value": statusContratos[contadorRenderStatusContrato].quantidade,
                                "cor": getColorByIdx(contadorRenderStatusContrato),
                                "label": manejadorLabel.slice(0, 1).toUpperCase() + manejadorLabel.slice(1, manejadorLabel.length).toLowerCase(),
                            }
                            )
                            contadorRenderStatusContrato++
                        }
                    }
                }
                let objetoLegendaStatusContrato = arrayGraficosStatusContrato.map((itemContrato, idx) => {
                    return (
                        <div className="linha-legenda-status-contrato" key={idx}>
                            <div className="coluna-legenda-status-contrato-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: itemContrato.cor }} /></div>
                            <div className="coluna-legenda-status-contrato-2"><span className="fonte-cor-1 texto-legenda-status-contrato">{itemContrato.label}</span></div>
                            <div className="coluna-legenda-status-contrato-3"><span className="fonte-cor-1 texto-legenda-status-contrato-numero">{formatarMilharSemDecimais(itemContrato.value)}</span></div>
                        </div>
                    )
                })
                setGraficoStatusContratoMax(dadosDash.n0_simcards_max)
                setGraficoStatusContrato(JSON.parse(JSON.stringify(arrayGraficosStatusContrato)))
                setLegendaStatusContrato(objetoLegendaStatusContrato)
            } else {
                let arrayGraficosStatusContrato = [{
                    "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro,
                    "label": "Vazio",
                    "value": 0
                }]
                let objetoLegendaStatusContrato = arrayGraficosStatusContrato.map((itemContrato, idx) => {
                    return (
                        <div className="linha-legenda-status-contrato" key={idx}>
                            <div className="coluna-legenda-status-contrato-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: itemContrato.cor }} /></div>
                            <div className="coluna-legenda-status-contrato-2"><span className="fonte-cor-1 texto-legenda-status-contrato">{itemContrato.label}</span></div>
                            <div className="coluna-legenda-status-contrato-3"><span className="fonte-cor-1 texto-legenda-status-contrato-numero">{formatarMilharSemDecimais(itemContrato.value)}</span></div>
                        </div>
                    );
                })
                setGraficoStatusContratoMax(0)
                setGraficoStatusContrato([{ "cor": localTema === 'claro' || localTema === 'algarClaro' ? cor.cinza : cor.cinzaEscuro, "value": 100 }])
                setLegendaStatusContrato(objetoLegendaStatusContrato)
            }

            setSpinAtivo(false)
        }
        handleCarregarGraficosDashboard()


    }, [periodo, props.filtros])

    useEffect(() => {
        if (props.revelarBarraFiltro === true) {
            despacho(revelarBarraFiltragem(false))
            setSpinAtivo(true)
        }
        if (props.filtros.vazio != 'vazio') {
            despacho(mudarFiltros({ vazio: 'vazio' }))
            despacho(limpadorFiltros(true))
            setSpinAtivo(true)
        }
    }, [])

    //gabiarra para redirecionar para a dash de faturas caso o cliente possua apenas o modulo faturas
    useEffect(() => {
        handleVerificarPermissao()
    }, [permissaoElemento])

    async function handleVerificarPermissao() {
        ColetaClickstream(526) // 'painelDashboardDashboardHandleVerificarPermissao'
        const permissaoDispositivos = await validadorPermissaoElemento(permissaoElemento, "veye/dashboard/dispositivos")
        const permissaoFaturas = await validadorPermissaoElemento(permissaoElemento, "veye/dashboard/faturas")
        if (permissaoDispositivos === false && permissaoFaturas === true) {
            props.history.push('/veye/faturas/dashboard')
        }
    }

    function GraficoSimcardsStatus() {
        return <div className="container-dashboard-fundo-1  fundo-cor-5" id="grafico-simcards-status">
            <CardHeader id='1' key='1' titulo="SIM cards Status" nomeOpcao="simcardsStatus" />

            <div className="grupo-dash-grafico-pizza-grande">
                {revelarOpcoesSimcardsStatus === true &&
                    <div className="menu-opcoes-dashboard-fundo">
                        <RenderMenuOpcoesSimcardsStatus
                            tipo="online"
                            exportar="exportarSimcardsStatus"
                            setRevelarOpcoesSimcardsStatus={setRevelarOpcoesSimcardsStatus}
                            revelarOpcoesSimcardsStatus={revelarOpcoesSimcardsStatus}
                            mostrarEmDispositivos={mostrarEmDispositivos}
                            dropMenuOpcoes={dropMenuOpcoes}
                        />
                    </div>
                }
                <div className="container-dash-grafico-pizza-grande">
                    <MyPieChart key='1'
                        data={arraySimcardsStatus}
                        valorPercentual={tooltipSimcardsStatus}
                        MyTooltip={TooltipGraficoPizza}
                    />
                </div>
                <div className="container-legenda-status">
                    <div className="linha-legenda-status">
                        <div className="coluna-legenda-status-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor1 }} /></div>
                        <div className="coluna-legenda-status-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">OnLine</span></div>
                        <div className="coluna-legenda-status-3"><span className="fonte-cor-1 texto-legenda-box-status">{formatarMilharSemDecimais(tooltipSimcardsOnline)}</span></div>
                    </div>
                    <div className="linha-legenda-status">
                        <div className="coluna-legenda-status-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor.vermelho }} /></div>
                        <div className="coluna-legenda-status-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">OffLine</span></div>
                        <div className="coluna-legenda-status-3"><span className="fonte-cor-1 texto-legenda-box-status">{formatarMilharSemDecimais(tooltipSimcardsOffline)}</span></div>
                    </div>
                    <div className="linha-legenda-status">
                        <div className="coluna-legenda-status-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor.azul }} /></div>
                        <div className="coluna-legenda-status-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Estoque</span></div>
                        <div className="coluna-legenda-status-3"><span className="fonte-cor-1 texto-legenda-box-status">{formatarMilharSemDecimais(tooltipSimcardsEmEstoque)}</span></div>
                    </div>

                </div>
            </div>
        </div>
    }

    function GraficoSimcardsSemComunicar() {
        return <div className="container-dashboard-fundo-1 fundo-cor-5" id="grafico-simcards-sem-comunicar">
            <CardHeader titulo="SIM cards sem comunicar" nomeOpcao="simcardsSemComnuicar" />

            <div className="grupo-dash-grafico-pizza-grande">
                {revelarOpcoesSimcardsSemComunicar === true &&
                    <div className="menu-opcoes-dashboard-fundo">
                        <RenderMenuOpcoesSimcardSemComunicar
                            setRevelarOpcoesSimcardsSemComunicar={setRevelarOpcoesSimcardsSemComunicar}
                            revelarOpcoesSimcardsSemComunicar={revelarOpcoesSimcardsSemComunicar}
                            exportar="exportarSimcardsSemComunicar"
                            mostrarEmDispositivos={mostrarEmDispositivos}
                            dropMenuOpcoes={dropMenuOpcoes}
                        />
                    </div>
                }
                <div className="container-dash-simcards-sem-comunicar">
                    <div className="container-dash-grafico-pizza-sem-comunicar">
                        <div className="caixa-grafico-pizza-sem-comunicar">
                            <MyPieChart key='2'
                                data={arraySemComunicar24h}
                                valorPercentual={tooltipSimcardsSemComunicar}
                                MyTooltip={TooltipGraficoPizzaSemComunicar}
                            />
                        </div>
                    </div>
                    <div className="container-legenda-sem-comunicar">
                        <div className="linha-dash-legenda-sem-comunicar">
                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor1 }} /></div>
                            <div className="coluna-legenda-sc-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Nas últimas 24 horas</span></div>
                            <div className="coluna-legenda-sc-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-unico-2">{formatarMilharSemDecimais(tooltipSemComunicar24h)}</span></div>
                        </div>
                        <div className="linha-dash-legenda-sem-comunicar">
                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor.amarelo }} /></div>
                            <div className="coluna-legenda-sc-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Entre 24 h e 30 Dias</span></div>
                            <div className="coluna-legenda-sc-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-unico-2">{formatarMilharSemDecimais(tooltipSemComunicar24h30d)}</span></div>
                        </div>
                        <div className="linha-dash-legenda-sem-comunicar">
                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor.laranja }} /></div>
                            <div className="coluna-legenda-sc-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Entre 30 e 60 dias</span></div>
                            <div className="coluna-legenda-sc-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-unico-2">{formatarMilharSemDecimais(tooltipSemComunicar30d60d)}</span></div>
                        </div>
                        <div className="linha-dash-legenda-sem-comunicar">
                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor.vermelho }} /></div>
                            <div className="coluna-legenda-sc-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Mais de 60 dias</span></div>
                            <div className="coluna-legenda-sc-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-unico-2">{formatarMilharSemDecimais(tooltipSemComunicar60d)}</span></div>
                        </div>
                        <div className="linha-dash-legenda-sem-comunicar">
                            <div className="coluna-legenda-sem-comunicar-1"><div className="esfera-legenda-dashboard" style={{ backgroundColor: cor.azul }} /></div>
                            <div className="coluna-legenda-sc-sem-comunicar-2"><span className="fonte-cor-1 texto-legenda-sem-comunicar">Em estoque</span></div>
                            <div className="coluna-legenda-sc-sem-comunicar-3"><span className="fonte-cor-1 texto-legenda-sem-comunicar-numero-unico-2">{formatarMilharSemDecimais(tooltipSimcardsEmEstoque)}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }


    function GraficoSimcardsConsumoCiclo() {
        return <div className={`container-dashboard-fundo-3 fundo-cor-5 ${portalCliente ? 'dashboard-ajuste-portal-cliente': ''}`} id="grafico-simcards-consumo">
            <CardHeader titulo="Consumo nos ciclos" nomeOpcao="consumo" />

            <div className="grupo-dash-grafico-pizza-grande">
                {revelarOpcoesConsumo === true &&
                    <div className="menu-opcoes-dashboard-fundo">
                        <RenderMenuOpcoesConsumo
                            setRevelarOpcoesConsumo={setRevelarOpcoesConsumo}
                            revelarOpcoesConsumo={revelarOpcoesConsumo}
                            exportar="exportarConsumo"
                            // mostrarEmDispositivos={mostrarEmDispositivos}
                            dropMenuOpcoes={dropMenuOpcoes}
                        />
                    </div>
                }

                {portalCliente && chartConsumoData ?

                    <div className="container-simcards-consumo-portal">
                        <div className="container-grafico-pizza-consumo-portal">
                            <div className="caixa-grafico-pizza-consumo-portal">
                                    <ApexChartConsumo style={{fontSize: '20px !important'}}></ApexChartConsumo>
                            </div>
                        </div>
                        <div style={{display: 'none'}} className="container-legenda-consumo">
                            <LinhaLegendaConsumo key='leg-c1'
                                                 bgColor={cor.azul}
                                                 titulo='Total contratado no ciclo'
                                                 tooltip={tooltipTotalContratado}
                            />
                            <LinhaLegendaConsumo key='leg-c2'
                                                 bgColor={cor2}
                                                 titulo='Total Ativo no ciclo'
                                                 tooltip={tooltipTotalAtivo}
                            />
                            {filtroOperadora === true && filtroOperadoraPlano === true &&
                                <>
                                    <LinhaLegendaConsumo key='leg-c3'
                                                         bgColor={cor.amarelo}
                                                         titulo='Previsão de consumo no plano'
                                                         tooltip={tooltipPrevisaoConsumo}
                                    />
                                    <LinhaLegendaConsumo key='leg-c4'
                                                         bgColor={cor.vermelho}
                                                         titulo='Consumo total no plano'
                                                         tooltip={tooltipConsumoTotal}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    :
                    <div className="container-simcards-consumo">
                        <div className="container-grafico-pizza-consumo">
                            <div className="caixa-grafico-pizza-consumo">
                                <ResponsiveContainer height="100%" width="100%">
                                    <BarChart data={arrayConsumoTotal}>
                                        {/*<Tooltip/>*/}
                                        {filtroOperadora === true && filtroOperadoraPlano === true &&
                                            <Bar barSize={100} dataKey="value" fill="cor" />
                                        }
                                        {filtroOperadora === false && filtroOperadoraPlano === false &&
                                            <Bar barSize={220} dataKey="value" fill="cor" />
                                        }
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="container-legenda-consumo">
                            <LinhaLegendaConsumo key='leg-c1'
                                                 bgColor={cor.azul}
                                                 titulo='Total contratado no ciclo'
                                                 tooltip={tooltipTotalContratado}
                            />
                            <LinhaLegendaConsumo key='leg-c2'
                                                 bgColor={cor2}
                                                 titulo='Total Ativo no ciclo'
                                                 tooltip={tooltipTotalAtivo}
                            />
                            {filtroOperadora === true && filtroOperadoraPlano === true &&
                                <>
                                    <LinhaLegendaConsumo key='leg-c3'
                                                         bgColor={cor.amarelo}
                                                         titulo='Previsão de consumo no plano'
                                                         tooltip={tooltipPrevisaoConsumo}
                                    />
                                    <LinhaLegendaConsumo key='leg-c4'
                                                         bgColor={cor.vermelho}
                                                         titulo='Consumo total no plano'
                                                         tooltip={tooltipConsumoTotal}
                                    />
                                </>
                            }
                        </div>
                    </div>
                }


            </div>
        </div>
    }

    function GraficoSimcardsOperadoras() {
        return <div className="container-dashboard-fundo-1 fundo-cor-5" id="grafico-simcards-operadoras">
            <CardHeader titulo="Operadoras" nomeOpcao="simcardsOperadoras" />

            <div className="grupo-dash-grafico-pizza-grande">
                {revelarOpcoesTotalSimcardsOperadora === true &&
                    <div className="menu-opcoes-dashboard-fundo">
                        <RenderMenuOpcoesMapeado
                            setRevelarOpcoesTotalSimcardsOperadora={setRevelarOpcoesTotalSimcardsOperadora}
                            revelarOpcoesTotalSimcardsOperadora={revelarOpcoesTotalSimcardsOperadora}
                            mostrarEmDispositivos={mostrarEmDispositivos}
                            dropMenuOpcoes={dropMenuOpcoes}
                            arrayJSON={dadosTotalSimcardsOperadora}
                            campo="operadoras"
                            exportar="exportarOperadoras"
                        />
                    </div>
                }
                <div className="container-operadoras">
                    <div className="container-grafico-pizza-operadoras">
                        <div className="caixa-grafico-pizza-operadoras">
                            <MyPieChart key='3'
                                data={graficoTotalSimcardsOperadora}
                                valorPercentual={graficoTotalSimcardsOperadoraMax}
                                MyTooltip={TooltipGraficoPizza}
                            />
                        </div>
                    </div>
                    <div className="container-legenda-operadoras">
                        {legendaTotalSimcardsOperadora}
                    </div>
                </div>
            </div>
        </div>
    }

    function GraficoTecnologias () {
        return <div className="container-dashboard-fundo-1 fundo-cor-5" id="grafico-tecnologias">
            <CardHeader titulo="Tecnologias" nomeOpcao="tecnologias" />

            <div className="grupo-dash-grafico-pizza-grande">
                {revelarOpcoesTecnologias === true &&
                    <div className="menu-opcoes-dashboard-fundo">
                        <RenderMenuOpcoesMapeado
                            setRevelarOpcoesTecnologias={setRevelarOpcoesTecnologias}
                            revelarOpcoesTecnologias={revelarOpcoesTecnologias}
                            mostrarEmDispositivos={mostrarEmDispositivos}
                            dropMenuOpcoes={dropMenuOpcoes}
                            arrayJSON={dadosTotalTecnologias}
                            campo="tecnologias"
                            exportar="exportarTecnologias"
                        />
                    </div>
                }
                <div className="container-operadoras">
                    <div className="container-grafico-pizza-tecnologias">
                        <div className="caixa-grafico-pizza-operadoras">
                            <MyPieChart key='4'
                                data={graficoTotalTecnologiasIndividuais}
                                valorPercentual={graficoTotalTecnologias}
                                MyTooltip={() => <TooltipGraficoPizza arrDados={dadosTotalTecnologias} />} 
                            />
                        </div>
                    </div>
                    <div className="container-legenda-operadoras">
                        {legendaTotalTecnologias}
                    </div>
                </div>
            </div>
        </div>
    }


    useEffect(() => {
        prepareChartData();
    }, [arrayConsumoTotal]);


    function prepareChartData(){
        let dataChart = [
            {value: percentualTotalContratado, fill: cor.azul, label: "Total contratado"},
            {value: percentualTotalAtivo, fill: cor2, label: "Total ativo"},
            {value: percentualPrevisaoConsumo, fill: cor.amarelo, label: "Previsão de consumo"},
            {value: percentualConsumoTotal, fill: cor.vermelho, label: "Consumo total"}
        ]
        let chartDataFillColors = []
        let chartData = []
        let chartValueTotals = [{data: tooltipTotalContratado}, {data: tooltipTotalAtivo}, {data: tooltipPrevisaoConsumo}, {data: tooltipConsumoTotal}]
        setValueTotals(chartValueTotals)

        chartDataFillColors = [...dataChart.map((item, index) => {
            return item.fill
        })]
        setChartFillColors(chartDataFillColors)

        dataChart.forEach((item, index) => {
            chartData.push({
                name: item.label,
                data:[{
                    x: item.label,
                    y: item.value
                }]

            })
        })
        setChartConsumoData(chartData);

    }


    class ApexChartConsumo extends Component {
        constructor(props) {
            super(props);

            this.state = {

                series: chartConsumoData,
                options: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                            barHeigth: '90%',
                        },

                    },
                    legend: {
                        fontSize: '18px !important',
                        labels: {
                            colors: localTema === 'escuro'? '#fff' : '#000',
                        },
                        itemMargin:{
                            horizontal: 50
                        },
                        markers:{
                            width: 20,
                            height: 20,
                            radius: 50,
                            offsetX: -10,
                        },
                    },
                    stroke: {
                        colors: ["transparent"],
                        width: 10
                    },
                    dataLabels:{
                        enabled: true,
                        dropShadow: {
                            enabled: true,
                        },
                        style: {
                            colors: ['#fff'],
                            fontSize: '20px'
                        },
                        formatter: function (val, opt){
                            return `${valueTotals[opt.seriesIndex].data} (${val == 100 ? val : val.toFixed(2)}%)`

                        }
                    },
                    colors: chartFillColors,
                    xaxis: {
                        type: 'category',
                        max: 100,
                        style: {
                            fontSize: '18px',
                            color: '#fff'
                        },
                        axisBorder: {
                            color: localTema === 'escuro' ? '#fff' : '#000'
                        },
                        labels: {
                            style: {
                                fontSize: '16px !important',
                                cssClass: localTema === 'escuro' ? 'apexcharts-xaxis-label-noturno' : 'apexcharts-xaxis-label'
                            },
                            offsetY: 2,
                            formatter: function (val, opt){
                                return `${val}%`
                            }

                        }
                    },
                    yaxis: {
                      show: false,
                    }
                },
            };
        }
        render() {
            return (
                <div className={'chart-container'}>
                    <div className={'chart-wrapper'} id="chart">
                        <Chart options={this.state.options} series={this.state.series} type="bar" height={'100%'}/>
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }

    function GraficoStatusContrato() {
        return <div className="container-dashboard-fundo-3 fundo-cor-5" id="grafico-status-contrato">
            <CardHeader titulo="Status do contrato" nomeOpcao="statusContrato" />

            <div className="grupo-dash-grafico-pizza-grande">
                {revelarOpcoesStatusContrato === true &&
                    <div className="menu-opcoes-dashboard-fundo">
                        <RenderMenuOpcoesMapeado
                            setRevelarOpcoesStatusContrato={setRevelarOpcoesStatusContrato}
                            revelarOpcoesStatusContrato={revelarOpcoesStatusContrato}
                            mostrarEmDispositivos={mostrarEmDispositivos}
                            dropMenuOpcoes={dropMenuOpcoes}
                            arrayJSON={dadosStatusContrato}
                            campo="contratos"
                            exportar="exportarStatusDeContrato"
                        />
                    </div>
                }
                <div className="container-status-contrato">
                    <div className="container-grafico-pizza-status-contrato">
                        <div className="caixa-grafico-pizza-status-contrato">
                            <MyPieChart key='5'
                                data={graficoStatusContrato}
                                valorPercentual={graficoStatusContratoMax}
                                MyTooltip={TooltipGraficoPizza}
                            />
                        </div>
                    </div>
                    <div className="container-legenda-status-contrato">
                        {legendaStatusContrato}
                    </div>
                </div>
            </div>
        </div>
    }

    function CardHeader({titulo, nomeOpcao}) {
        return <Form.Row>
            <Col>
                <Form.Label className="titulo-grafico-dashboard">
                    <div className="fonte-cor-1 label-janela-dashboard-2">{titulo}</div>
                </Form.Label>

                {!portalCliente && 
                <Button className="bota-opcoes" variant="danger" onClick={() => handleAbrirMenuOpcoes(nomeOpcao)}>
                    <img className="bota-opcoes-icone" src={reticenciasVerde()} alt="opções" />
                </Button>}
            </Col>
        </Form.Row>
    }

    function MyPieChart({data, valorPercentual, MyTooltip}) {
        return <ResponsiveContainer height="100%" width="100%">
            <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Pie
                    dataKey='value'
                    data={data}
                    innerRadius="60%"
                    outerRadius="80%"
                    fill={cor.cinzaEscuro}
                    paddingAngle={0}
                    stroke={0}
                >
                    <Label content={<LabelGraficosPizzaComposto valorPercentual={valorPercentual} />} />
                    {
                        data.map((entry, index) => <Cell fill={entry.cor} key={index} />)
                    }
                </Pie>
                <Tooltip content={<MyTooltip />} />
            </PieChart>
        </ResponsiveContainer>
    }

    function LinhaLegendaConsumo({bgColor, titulo, tooltip}) {
        return <div className="linha-legenda-consumo">
            <div className="coluna-legenda-consumo-1"><div className="esfera-legenda-dashboard" style={{backgroundColor: bgColor}} /></div>
            <div className="coluna-legenda-consumo-2"><span className="fonte-cor-1 texto-legenda-consumo">{titulo}</span></div>
            <div className="coluna-legenda-consumo-3"><span className="fonte-cor-1 texto-legenda-consumo-numero-unico-2">{tooltip}</span></div>
        </div>
    }

    function DashCardsRow({children}) {
        return <div className={props.revelarBarraFiltro === false ? "container-dashboard-fundo" : "container-dashboard-fundo-4"}>
            {children}
        </div>
    }

    return (
        <div className="container-dashboard-tela">
            {visualizarDados === true &&
                <BarraDadosCliente />
            }
            {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
            {props.revelarBarraFiltro === true &&
                <BarraFiltragem />
            }

            <DashCardsRow>
                <>
                    <GraficoSimcardsStatus />
                    <GraficoSimcardsSemComunicar />
                    {portalCliente ? <GraficoSimcardsOperadoras /> : <GraficoSimcardsConsumoCiclo />}
                </>
            </DashCardsRow>
            <DashCardsRow>
                <>
                {portalCliente ? <GraficoSimcardsConsumoCiclo /> : <GraficoSimcardsOperadoras />}
                    <GraficoTecnologias />
                    {!portalCliente && <GraficoStatusContrato />}
                </>
            </DashCardsRow>
        </div>
    )
}

/* CRIA MENU DE OPCOES PARA GRAFICOS DE STATUS DE TRAFEGO (QUE NUNCA MUDAM) */
function RenderMenuOpcoesMapeado(props) {
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar() {
        if (props.revelarOpcoesTecnologias) {
            props.setRevelarOpcoesTecnologias(!props.revelarOpcoesTecnologias)
        }
        if (props.revelarOpcoesTotalSimcardsOperadora) {

            props.setRevelarOpcoesTotalSimcardsOperadora(!props.revelarOpcoesTotalSimcardsOperadora)
        }
        if (props.revelarOpcoesStatusContrato) {
            props.setRevelarOpcoesStatusContrato(!props.revelarOpcoesStatusContrato)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    const getSubLabel = (menuLabel, campo) => {
        const transformPart2 = ['contratos', 'operadoras'].includes(campo) || menuLabel === 'OUTRAS';
        if ( menuLabel === 'DEMONSTRACAO') {
            return 'Demonstração'
        } else {
            const part1 = menuLabel.slice(0, 1).toUpperCase()
            let part2 = menuLabel.slice(1, menuLabel.length)
            return part1 + (transformPart2 ? part2.toLowerCase() : part2 )
        }
    }

    /* MAPEIA O ARRAY JSON COM OS CAMPOS NECESSARIOS */
    let objetoMenu = props.arrayJSON.filter(menu => menu.nomeCampo !== null).map((menu) => {

        const label = getSubLabel(menu.label, props.campo);

        if (props.campo === "contratos") {
            return (
                <FilterOption key={"menu-" + menu.contratos+label} mostrarEmDispositivos={props.mostrarEmDispositivos}
                    item={{ clickParam: { STATUS_CONTRATO: menu.label }, label }}
                />
            );
        }
        if (props.campo === "operadoras") {
            return (
                <FilterOption key={"menu-" + menu.operadoras+label} mostrarEmDispositivos={props.mostrarEmDispositivos}
                    item={{ clickParam: { OPERADORA: menu.cd_operadora }, label }}
                />
            );
        }
        if (props.campo === "tecnologias") {
            let label = menu.label;
            // if(label === 'OUTRAS') {
            //     label = label;
            // }
            if(label == 'NB') {
                label = label + '-IoT'
            }
            // if(label != 'OUTRAS' && label != 'NB') {
            //     label = label;
            // }

            return (
                <FilterOption key={"menu-" + menu.tecnologias+label} mostrarEmDispositivos={props.mostrarEmDispositivos}
                    item={{ clickParam: { TECNOLOGIA: menu.label }, label }}
                />
            );
        }
    })

    return (
        <CardDropAcoes modal={modal}>
            <li>
                <DropdownDispositivos dropMenuOpcoes={props.dropMenuOpcoes} />
                <ul id={"menu-mostrar-em-dispositivos"} className="drop-acoes-lista-submenu dropdown-desativado">
                    {objetoMenu}
                </ul>
            </li>
            <ExportarImagem exportar={props.exportar} />
        </CardDropAcoes>
    )
}

/* CRIA MENU DE OPCOES PARA GRAFICOS DE SIMCARDS STATUS */
function RenderMenuOpcoesSimcardsStatus(props) {
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar() {
        if (props.revelarOpcoesSimcardsStatus) {
            props.setRevelarOpcoesSimcardsStatus(!props.revelarOpcoesSimcardsStatus)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return (
        <CardDropAcoes modal={modal}>
            <ListaMostrarEmDispositivos tipo='status' dropMenuOpcoes={props.dropMenuOpcoes} mostrarEmDispositivos={props.mostrarEmDispositivos} />
            <ExportarImagem exportar={props.exportar} />
        </CardDropAcoes>
    )
}

/* CRIA MENU DE OPCOES PARA GRAFICOS DE SIMCARD SEM COMUNICAR (QUE NUNCA MUDAM) */
function RenderMenuOpcoesSimcardSemComunicar(props) {
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar() {
        if (props.revelarOpcoesSimcardsSemComunicar) {
            props.setRevelarOpcoesSimcardsSemComunicar(!props.revelarOpcoesSimcardsSemComunicar)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return (
        <CardDropAcoes modal={modal}>
            <ListaMostrarEmDispositivos tipo='semComunicar' dropMenuOpcoes={props.dropMenuOpcoes} mostrarEmDispositivos={props.mostrarEmDispositivos} />
            <ExportarImagem exportar={props.exportar} />
        </CardDropAcoes>
    )
}

/* CRIA MENU DE OPCOES PARA O CARD CONSUMO */
function RenderMenuOpcoesConsumo(props) {
    const modal = useRef();
    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    function handleCancelar() {
        if (props.revelarOpcoesConsumo) {
            props.setRevelarOpcoesConsumo(!props.revelarOpcoesConsumo)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    return (
        <CardDropAcoes modal={modal}>
            <ExportarImagem exportar={props.exportar} />
        </CardDropAcoes>
    )
}

const filtrosParaDispositivos = {
    status: [
        { clickParam: {ULTIMA_CONEXAO: 'online'}, label: 'OnLine', },
        { clickParam: {ULTIMA_CONEXAO: 'ultima_conexao'}, label: 'OffLine', },
        { clickParam: {ULTIMA_CONEXAO: 'estoque'}, label: 'Estoque' }
    ],
    semComunicar: [
        { clickParam: {SEM_COMUNICAR: 'sem_comunicacao_24h'}, label: 'Nas últimas 24 horas', },
        { clickParam: {SEM_COMUNICAR: 'sem_comunicacao_24h_30d'}, label: 'Entre 24 horas e 30 dias', },
        { clickParam: {SEM_COMUNICAR: 'sem_comunicacao_30d_60d'}, label: 'Entre 30 a 60 dias', },
        { clickParam: {SEM_COMUNICAR: 'sem_comunicacao_60d'}, label: 'Mais de 60 dias', },
        { clickParam: {ULTIMA_CONEXAO: 'estoque'}, label: 'Em estoque' }
    ],
}

const Divisor = () => <div className="menu-opcoes-divisora campo-select-divisor-cor-1"></div>;

const MenuOpcoesSubLabel = ({label}) => <span className="menu-opcoes-sub-label fonte-cor-1">{label}</span>;

function CardDropAcoes({modal, children}) {
    return (
        <Form ref={modal} className="menu-opcoes-container">
            <div className="campo-select-triangulo-cor-2 menu-opcoes-triangulo"></div>
            <div className="menu-opcoes-corpo fundo-cor-3">
                <ul className="drop-acoes-lista-menu">
                    {children}
                </ul>
            </div>
        </Form>
    )
}

function DropdownDispositivos(props) {
    return (
        <>
        <div className="drop-acoes-menu item-drop-acoes-hover" onClick={e => props.dropMenuOpcoes(e, "menu-mostrar-em-dispositivos", "imagem-menu-mostrar-em-dispositivos")}>
            <span className="drop-acoes-menu-label fonte-cor-1">Mostrar em dispositivos</span>
            <img id={"imagem-menu-mostrar-em-dispositivos"} className="menu-opcoes-icone-desativado nao-selecionavel" src={setaDropdown()} alt="drop" />
        </div>
        <Divisor />
        </>
    )
}

function ExportarImagem(props) {
    return (
        <li>
            <div className="drop-acoes-menu item-drop-acoes-hover">
                <span className="drop-acoes-menu-label fonte-cor-1" onClick={() => exportarImagemDashSimcards(props.exportar)}>Exportar imagem</span>
            </div>
        </li>
    )
}

function FilterOption({item, ...props}) {
    return (
        <li>
            <NavLink className="drop-acoes-menu item-drop-acoes-hover" onClick={() => props.mostrarEmDispositivos(item.clickParam)} to={"/veye/dispositivos"}>
                <MenuOpcoesSubLabel label={item.label} />
            </NavLink>
            <Divisor />
        </li>
    )
}

function ListaMostrarEmDispositivos({tipo, ...props}) {
    return (
        <li>
            <DropdownDispositivos {...props} />
            <ul id={"menu-mostrar-em-dispositivos"} className="drop-acoes-lista-submenu dropdown-desativado">
                {filtrosParaDispositivos[tipo].map((item, idx) => {
                    return <FilterOption item={item} {...props} key={idx} />
                })}
            </ul>
        </li>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca,
         filtros: state.filtrosState.filtros,
         revelarBarraFiltro: state.filtrosState.revelarBarraFiltragem };
} 

export default withRouter(connect(mapStateToProps)(Dasboard))