//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {formatarData} from '../../../utils/formatadorDataTabela'/*eslint-disable*/
import { useDispatch, useSelector, connect } from 'react-redux';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
//ICONES
//COMPONENTES
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import NovoTemplate from './novoTemplate/novoTemplate'
import EditarTemplate from './editarTempalte/editarTemplate'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//ESTILIZAÇÃO
import '../../../css/painelSms/smsTemplate/tabela.css'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

//REDUX
import { limpadorFiltros, revelarExcluirTemplateSms, mudarManejador, limpouBusca, realizouBusca, desabilitarAcoes, desabilitarAcoesCliente, mudarFiltros, revelarBarraFiltragem, realizouFiltro  } from "../../../redux/actions/index"
import utilsSms from '../../../utils/sms';

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []

function SmsTemplate(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [cdTemplateSms, setCdTemplateSms] = useState(-1)
    const [dsNome, setDsNome] = useState('');
    const [cdPermissao, setCdPermissao] = useState('');
    const [dsPermissao, setDsPermissao] = useState('');
    const [dsConteudo, setDsConteudo] = useState('');
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50); // eslint-disable-next-line
    const [templateSms, setTemplateSms] = useState([])  // eslint-disable-next-line
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [mensagemErro, setMensagemErro] = useState('')
    const [cdSmsContaUsuario, setCdSmsContaUsuario] = useState(undefined);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);

    //REDUX VARIÁVEIS
    const despacho = useDispatch();
    let filtros = useSelector(state => state.filtrosState.filtros);

    let revelarNovoTemplateSms =  useSelector(state => state.smsState.revelarNovoTemplateSms);
    let revelarEditarTemplateSms =  useSelector(state => state.smsState.revelarEditarTemplateSms);
    let revelarExcluirTemplateSmsState =  useSelector(state => state.smsState.revelarExcluirTemplateSms)
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let temFiltros = false;
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(473) // 'smsTemplateTabela'
            setColetaUsabilidade(false)
        }
    },[])


    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        if(props.filtros.vazio){
            despacho(revelarBarraFiltragem(false))
            if(!filtros.vazio){
                despacho(mudarFiltros({vazio: 'vazio'}))
            }
            despacho(limpadorFiltros(true))
        }
    }, [])

    async function handleDeletarTemplate(){
        ColetaClickstream(1442) // SMSTemplateDropAcoesExcluir
        setSpinAtivo(true)
        const query = utilsSms.getNewQueryGet('cd_sms_template='+cdTemplateSms);
        await api.get('/m1/deletar/smsTemplate?'+query)
        .then(function (response) {
            setRevelarMensagemSucesso(true)
            despacho(revelarExcluirTemplateSms(false))
            setSpinAtivo(false)
        })      
        .catch(function (error) {
            setMensagemErro(error)
            setRevelarMensagemErro(true)
            despacho(revelarExcluirTemplateSms(false))
            setSpinAtivo(false)
        });
    }

    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("smsTemplate"));
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        async function carregarTabela(){
            setCdSmsContaUsuario(decryptJWT('cdSmsContaUsuario'))

            // clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }   
            
            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && !temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();

                    const query = utilsSms.getNewQueryGet('paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    const { data } = await api.get('/m1/consultar/smsTemplate?'+query); 
                    inserirData(data.dados)
                }
            }else if(temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = utilsSms.getNewBodyPost({
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    buscar:conteudoBusca,
                })

                if (controladorPagina <= paginaAtual) {
                    setItens([])
                    const { data } = await api.post("/m1/filtrar/smsTemplate",dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }   
                if (controladorPagina <= paginaAtual) {
                    const query = utilsSms.getNewQueryGet('paginaAtual='+contador+'&carregarLimit='+valorLimite)
                    const { data } = await api.get('/m1/consultar/smsTemplate?'+query); 
                    inserirData(data.dados)
                }
            }
        }
        carregarTabela();
        setSpinAtivo(false)
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }


    async function contarItens(){
        let arrayItens = []
        let apiData = [];
        if (controladorPagina <= paginaAtual && props.filtros.vazio) { 
            const query = utilsSms.getNewQueryGet('contar=true&buscar='+conteudoBusca)
            apiData = await api.get('/m1/consultar/smsTemplate?'+query);  
        }else{
            const dados = utilsSms.getNewBodyPost({
                paginaAtual: contador,
                carregarLimit: valorLimite,
                filtros: filtros,
                buscar:conteudoBusca,
                contar: true
            })
            apiData = await api.post("/m1/filtrar/smsTemplate", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }
     
    const colunas = [{
        dataField: 'cd_sms_template',
        text: 'ID',
        hidden:true,
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: "ds_sms_template",
        headerClasses: 'nao-selecionavel',
        text: 'Nome',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: "dt_created_at",
        headerClasses: 'nao-selecionavel',
        text: 'Data',
        sort: true,
        formatter: (cell, row) => {
            if(cell){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'dt_updated_at',
        headerClasses: 'nao-selecionavel',
        text: 'Última modificação',
        sort: true,
        formatter: (cell, row) => {
            let data = cell ? cell : row.dt_created_at;
            if(data){
                data = data.replace('T', ' ').split('.')
                if(data.length > 0){
                   data = data[0]
                }
            }
            return formatarData(data);
        }
    },
    {
        dataField: 'fl_acesso_geral',
        headerClasses: 'nao-selecionavel',
        text: 'Permissão',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell === 1 ? "Todos usuários" : "Somente eu"}</p>;
        }
    },
    {
        dataField: 'ds_conteudo',
        headerClasses: 'nao-selecionavel',
        text: 'Conteúdo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    }
    ]

    // const eventosLinhas = {
    //     onClick: (e, row, rowIndex) => {
    //         setTemplateSms(row.cd_sms_template)
    //         setCdTemplateSms(row.cd_sms_template)
    //         setDsNome(row.ds_sms_template)
    //         setCdPermissao(row.fl_acesso_geral)
    //         setDsPermissao(row.ds_permissao)
    //         setDsConteudo(row.ds_conteudo)
    //     }
    // }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                setTemplateSms(row)
                setCdTemplateSms(row.cd_sms_template)
                setDsNome(row.ds_sms_template)
                setCdPermissao(row.fl_acesso_geral)
                if(row.fl_acesso_geral === 0){
                    setDsPermissao("Todos usuários")
                }else{
                    setDsPermissao("Somente eu")
                }
                setDsConteudo(row.ds_conteudo)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            setRenderizar(!renderizar)
            restaurarConfigsIniciaisPagina()
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            despacho(revelarExcluirTemplateSms(false))
            if(confirmacao === true){
                handleDeletarTemplate()
            }
        }
    }

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            {revelarNovoTemplateSms === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-tabela-template-sms-container">
                        <NovoTemplate
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            cdSmsContaUsuario={cdSmsContaUsuario}
                        >
                        </NovoTemplate>
                    </div>
                </div>
            }
            {revelarEditarTemplateSms === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-tabela-template-sms-container">
                        <EditarTemplate
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                            dsNome={dsNome}
                            cdPermissao={cdPermissao}
                            dsPermissao={dsPermissao}
                            dsConteudo={dsConteudo}
                            cdTemplateSms={cdTemplateSms}
                        >
                        </EditarTemplate>
                    </div>
                </div>
            }
        
            { revelarMensagemSucesso === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Template excluído."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            }
            { revelarExcluirTemplateSmsState === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo1={"Tem certeza que deseja excluir este template?"}
                            mensagemBotao={"Confirmar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemBotao={"Tentar de novo"}
                        />
                    </div>
                </div>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='cd_sms_template' 
                                data={itens} 
                                columns={colunas} 
                                // rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                selectRow={ selecaoLinhas }
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(SmsTemplate));