//IMPORTAÇÕES

import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../conexoes/api';/*eslint-disable*/
import { Image, Button} from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import 'moment-timezone';
import moment from 'moment';
import {formatarData} from '../../utils/formatadorDataTabela'
import decryptJWT from '../../utils/decryptJWT';
import {formatarMilhar} from '../../utils/formatarMilhar'
import DetalhamentoFinanceiro from './detalhamentoFinanceiro';
import { CSVLink } from 'react-csv'
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento'
//IMPORTAÇÕES ICONES
import { setaDropdown } from '../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX

import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpadorFiltros, mudarPermissaoElementoAcao, desabilitarAcoes, desabilitarAcoesCliente, limpouBusca, realizouBusca, visualizarDados, mudarFiltros, revelarBarraFiltragem, realizouFiltro, revelarExportarDetalhamentoFinanceiro, revelarDownloadBoleto } from "../../redux/actions/index";

//IMPORTAÇÕES COMPONENTES
import MensagemSucesso from '../../components/componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../components/componentesModais/mensagens/mensagemErro'
import PaginacaoTabela from '../../utils/paginacaoTabela'
import BarraDadosCliente from '../../components/componentesModais/barraDadosCliente/barraDadosCliente'
import GirarSetaDropdown from '../../utils/girarSetaDropdown'
//ESTILIZAÇÃO
import '../../css/painelFinanceiro/tabela.css'
// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

const valorLimite = 3
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaClientesVirtueyes(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [envioSelecionado, setEnvioSelecionado] = useState([]);
    const [mensagemErro, setMensagemErro] = useState('');
    const [dadosParaDownload, setDadosParaDownload] = useState([]);
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
    const [n0_cliente_erp_antigo, setN0_cliente_erp_antigo] = useState(decryptJWT("codigoClienteTexto"));
    const [cd_pav_cliente, setCd_pav_cliente] = useState(decryptJWT("codigoCliente"));
    const [permissaoDescricao, setPermissaoDescricao] = useState();
    const [linkBoleto, setLinkBoleto] = useState("");

    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let revelarExportarDetalhamentoItens =  useSelector(state => state.financeiroState.revelarExportarDetalhamentoFinanceiro);
    let revelarDownloadBoletoItens =  useSelector(state => state.financeiroState.revelarDownloadBoleto);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let temFiltros = false;
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    /// SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let itensVar = []

    const despacho = useDispatch();

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(490) // 'painelFinanceiroTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(()=>{
        async function handlerVerificarPermissaoAcao(){
            despacho(mudarPermissaoElementoAcao( !await validadorPermissaoElemento(permissaoElemento,"veye/usuarios/acoes") ))
            setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento,"veye/financeiro/detalhamento"))
        }
        handlerVerificarPermissaoAcao()
    }, [permissaoElemento])


    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("financeiro"));
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));

        async function carregarTabela(){
            // //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/financeiro?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/financeiro", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/financeiro?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
    
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) { 
            apiData = await api.get('/m1/consultar/financeiro?contar=true&buscar='+conteudoBusca);
        }else{
            const dados = {
                paguinaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                contar:true,
                filtros: filtros
            }
            apiData = await api.post("/m1/filtrar/financeiro", dados);
        }
        itensVar = []
        if(apiData.data.dados){
            totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
            totalItens = apiData.data.dados[0].CONTADOR;
            for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }


    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu===true?"expand-row-tabela-financeiro-1":"expand-row-tabela-financeiro-2"}>
                <DetalhamentoFinanceiro fatura={row}/>
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: permissaoDescricao,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    const colunas = [
        {
            dataField: 'FATURA',
            text: 'Pedido',
            sort: true,
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
            headerStyle: (colum, colIndex) => {
                return { width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: "EMISSAO",
            headerClasses: 'nao-selecionavel',
            text: 'Emissão',
            sort: true,
            formatter: (cell, row) => {
                if(cell){
                    cell = cell.replace('T', ' ').split('.')
                    if(cell.length > 0){
                        cell = cell[0]
                    }
                }
                return formatarData(cell,true);
            }
        },
        {
            dataField: 'VENCIMENTO',
            headerClasses: 'nao-selecionavel',
            text: 'Vencimento',
            sort: true,
            formatter: (cell, row) => {
                if(cell){
                    cell = cell.replace('T', ' ').split('.')
                    if(cell.length > 0){
                        cell = cell[0]
                    }
                }
                return formatarData(cell,true);
            }
        },
        {
            dataField: 'VALOR',
            headerClasses: 'nao-selecionavel',
            text: 'Valor',
            sort: true,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-financeiro"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro"> 0.00 </p></> : <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'SALDO',
            headerClasses: 'nao-selecionavel',
            text: 'Saldo',
            sort: true,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-financeiro"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro"> 0.00 </p></> : <><p className="valor-rs-financeiro">R$</p> <p className="fonte-cor-1 valor-financeiro"> {formatarMilhar(valor)}</p></>} </p>;

            }
        },
        {
            dataField: 'BANCO_NOME',
            headerClasses: 'nao-selecionavel',
            text: 'Banco',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'STATUS',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]

    if(revelarExportarDetalhamentoItens){
        handlerConsultarItensFinanceiro()
    }

    if(revelarDownloadBoletoItens){
        handlerDownloadBoleto()
    }

    async function handlerDownloadBoleto(){
        if(envioSelecionado.STATUS == "Pago"){
            setRevelarMensagemErro(true)
            despacho(revelarDownloadBoleto(false));
        }else{
            const boleto = await api.get('/m1/consultar/financeiro_detalhamento?codigo='+envioSelecionado.BANCO_COD+"&n0_cliente="+envioSelecionado.CLIENTE+'&boleto='+true+'&agencia='+envioSelecionado.AGENCIA+'&conta='+envioSelecionado.CONTA+'&subcta='+envioSelecionado.SUBCONTA+'&prefixo='+envioSelecionado.PREFIXO+'&num='+envioSelecionado.FATURA+'&emissao='+envioSelecionado.EMISSAO+'&loja='+envioSelecionado.LOJA+'&tipo='+envioSelecionado.TIPO)
                .then(async function (response) {
                    return response.data;
                })
                .catch(function (error) {
                })
            //timeOut, pois e necessario um tempo para os dados serem setados
            setTimeout(() => {
                
                if(boleto.status == 200){
                    const linkSource = `data:application/pdf;base64,${boleto.dados}`;
                    const downloadLink = document.createElement("a");
                    const fileName = envioSelecionado.BANCO_COD+"/"+envioSelecionado.AGENCIA+"/"+envioSelecionado.EMISSAO+".pdf";
    
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();                    
                }
            }, 250);
            despacho(revelarDownloadBoleto(false));
        }
    }
    
    async function handlerConsultarItensFinanceiro(){
        despacho(revelarExportarDetalhamentoFinanceiro(false));
        setSpinAtivo(true);
        await api.get('/m1/consultar/financeiro_detalhamento?codigo='+envioSelecionado.FATURA+"&n0_cliente="+envioSelecionado.CLIENTE+'&vencimento='+envioSelecionado.VENCIMENTO+'&boleto='+false)
            .then(async function (response) {
                let vlrTotal = envioSelecionado.VALOR;
                let aux1 = parseInt(vlrTotal, 10);
                aux1 = Number(vlrTotal);
                aux1 = +vlrTotal;
                aux1 = vlrTotal - 0;
                aux1 = parseFloat(aux1).toFixed(2).replace('.',',')
                let linha = [{
                    "Fatura" : envioSelecionado.FATURA,
                    "Emissão" : moment(envioSelecionado.EMISSAO).format("DD/MM/YYYY"),
                    "Vencimento" : moment(envioSelecionado.VENCIMENTO).format("DD/MM/YYYY"),
                    "Total SIM cards" : envioSelecionado.QUANTIDADE,
                    "Valor total" : 'R$ '+aux1,
                    "Banco" : envioSelecionado.BANCO_NOME,
                    "Situação" : envioSelecionado.STATUS,
                    " ": "",
                    "  " : "",
                    "    ": "",
                    "     ": "",
                },{
                    "Fatura" : "",
                    "Emissão" : "",
                    "Vencimento" : "",
                    "Total SIM cards" : "",
                    "Valor total" : "",
                    "Banco" : "",
                    "Situação" : "",
                    " ": "",
                    "  " : "",
                    "    ": "",
                    "     ": "",
                }]
                let detalhamento = response.data.dados;
                detalhamento.map((item, i)=>{
                    let auxDataCompra 
                    let auxDataFim
                    let auxDataInicio
                    let auxDataSolicitacao
                    
                    if(item.DATA_COMPRA === "Data de Compra"){
                        auxDataCompra = "Data de Compra"
                    }else if(item.DATA_COMPRA === "Valor Total"){
                        auxDataCompra = "Valor Total"
                    }else if(item.DATA_COMPRA !== undefined && item.DATA_COMPRA !== "" && item.DATA_COMPRA !== null && item.DATA_COMPRA !== "Data de Compra"){
                        auxDataCompra = moment(item.DATA_COMPRA.toString()).format("DD/MM/YYYY") 
                    }else{
                        auxDataCompra = ""
                    }

                    if(item.DATA_FIM_PRO_RATA === "Data de Fim"){
                        auxDataFim = "Data de Fim"
                    }else if(item.DATA_FIM_PRO_RATA !== undefined && item.DATA_FIM_PRO_RATA !== "" && item.DATA_FIM_PRO_RATA !== null && item.DATA_FIM_PRO_RATA !== "Data de Fim"){
                        auxDataFim = moment(item.DATA_FIM_PRO_RATA.toString()).format("DD/MM/YYYY") 
                    }else{
                        auxDataFim = ""
                    }

                    if(item.DATA_INI_PRO_RATA === "Data de Inicio"){
                        auxDataInicio = "Data de Inicio"
                    }else if(item.DATA_INI_PRO_RATA !== undefined && item.DATA_INI_PRO_RATA !== "" && item.DATA_INI_PRO_RATA !== null && item.DATA_INI_PRO_RATA !== "Data de Inicio"){
                        auxDataInicio = moment(item.DATA_INI_PRO_RATA.toString()).format("DD/MM/YYYY") 
                    }else{
                        auxDataInicio = ""
                    }

                    if(item.DATA_SOLICITACAO === "Data de Solicitação"){
                        auxDataSolicitacao = "Data de Solicitação"
                    }else if(item.DATA_SOLICITACAO !== undefined && item.DATA_SOLICITACAO !== "" && item.DATA_SOLICITACAO !== null && item.DATA_SOLICITACAO !== "Data de Solicitação"){
                        auxDataSolicitacao = moment(item.DATA_SOLICITACAO.toString()).format("DD/MM/YYYY") 
                    }else{
                        auxDataSolicitacao = ""
                    }
                    

                    linha.push({
                        "Fatura" :item.OPERADORA, 
                        "Emissão" : item.ICCID || item.ICCID != '' ? item.ICCID : item.ICCID ,
                        "Vencimento" : item.LINHA || item.LINHA != '' ?  item.LINHA : item.LINHA,
                        "Total SIMCards" : item.PLANO || item.PLANO != '' ? item.PLANO : item.PLANO,
                        "Valor Total" : auxDataCompra,
                        "Banco" : auxDataInicio,
                        "Situação" : auxDataFim,
                        " " : item.DIAS_PRO_RATA ? item.DIAS_PRO_RATA : "",
                        "  " : item.VALOR_COBRADO || item.VALOR_COBRADO != "0,00" ? item.VALOR_COBRADO : "",
                        "    " : item.QTD != null ? item.QTD : "",
                        "     ": auxDataSolicitacao,
                        "Serviço Adicional" : item.SERVICO_ADICIONAL
                    })
                })
                if(response.data.status == 200){
                    setDadosParaDownload(linha);
                }
            })
            .catch(function (error) {
            })
        //timeOut, pois e necessario um tempo para os dados serem setados
        setTimeout(() => {
            //aciona o event click para o ativar o download do arquivo
            const exportar = document.getElementById('exportar-detalhamento')
            exportar.click();
        }, 250)
        setSpinAtivo(false);
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
               setEnvioSelecionado(row)
               despacho(desabilitarAcoes(false));
               despacho(desabilitarAcoesCliente(true));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setEnvioSelecionado(row)
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }
    
    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            setRenderizar(!renderizar)
            restaurarConfigsIniciaisPagina()
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={() =>  setRevelarMensagemSucesso(!revelarMensagemSucesso)}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"O cliente,"}
                            mensagemParagrafo2={"cód. foi carregado."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Não é possível executar essa ação"}
                            mensagemParagrafo1={"O status deste documenta está como ``Pago``!"}
                            mensagemParagrafo2={"portanto não é possível fazer o download do boleto."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }

            <CSVLink id='exportar-detalhamento' filename={"financeiro-fatura"+envioSelecionado.FATURA+".csv"} className="d-none" data={dadosParaDownload} separator={";"}/>

            <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='FATURA'
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                expandRow={itemExpandido}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesVirtueyes));