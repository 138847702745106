 //IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {formatarData} from '../../../utils/formatadorDataTabela'
import {api} from '../../../conexoes/api';
import { Image } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv'
import XLSX from 'xlsx';
import decryptJWT from '../../../utils/decryptJWT'
import {formatarMilhar} from '../../../utils/formatarMilhar'

//IMPORTAÇÕES REDUX
import { setaDropdown } from '../../../utils/alternanciaIcones'
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarRelatorioResumoFebraban, revelarRelatorioFebraban, revelarDoppelgangerCliente, visualizarDadosRefresh, mudarOrdenador, mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, revelarExcluirFebraban } from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'

import ModalImportarFebraban from './novoFebraban/importarFebrabans'
import ModalAssociarClientes from './associarFatura/associarFatura'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
//ESTILIZAÇÃO
import '../../../css/painelFaturas/fatFebraban/tabela.css'
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import { exportarXlsx } from '../../../utils/exportarArquivo.js';

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaClientesVirtueyes(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [cdFatFebraban, setCdFatFebraban] = useState(-1);
    const [cdFatFatura, setCdFatFatura] = useState();
    const [dsConta, setDsConta] = useState(-1);
    const [linhaSelecionada, setLinhaSelecionada] = useState();
    const [mensagemErro, setMensagemErro] = useState('')
    const [nomeArquivo, setNomeArquivo] = useState()
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [dadosParaDownloadCsv, setDadosParaDownloadCsv] = useState([]);
    const [headersCsv, setHeadersCsv] = useState([{label:'Conta', key:'ds_conta'},{label:'Mês de Referência', key:'ds_mes_referencia'},{label:'Data de Importação', key:'dt_importacao'},{label:'Nome do Arquivo', key:'ds_arquivo_nome'},{label:'Valor Cobrado' , key:'auxValorCobrado'},{label:'Operadora', key:'ds_operadora'},{label:'Responsável', key:'ds_email'},{label:'Tempo de Importação', key:'dt_tempo_importacao'},{label:'Resultado da Conciliação', key:'ds_resultado_conciliacao'}])
    const [dadosParaDownloadXlsXlsx, setDadosParaDownloadXlsXlsx] = useState([['Conta','Mês de Referência','Data de Importação','Nome do Arquivo','Valor Cobrado','Operadora','Responsável','Tempo de Importação','Resultado da Conciliação']])
    
    const [mensagemInformacaoTitulo, setMensagemInformacaoTitulo] = useState('')
    const [mensagemInformacaoCorpo, setMensagemInformacaoCorpo] = useState('')
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    let itensVar = []

    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let estadoVisualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let importarFebrabans =  useSelector(state => state.faturasState.revelarImportarFebrabans);
    let excluirFebraban =  useSelector(state => state.faturasState.revelarExcluirFebraban);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let associarFatura = useSelector(state => state.faturasState.revelarAssociarFatura);
    let controladorRelatorioFebraban = useSelector(state => state.faturasState.revelarRelatorioFebraban);
    let controladorRelatorioResumoFebraban = useSelector(state => state.faturasState.revelarRelatorioResumoFebraban);

    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const despacho = useDispatch();
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(745) // 'fatFebrabanTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(()=>{
        async function buscarDadosRelatorioFaturas(){
            if(controladorRelatorioResumoFebraban === "XLS" || controladorRelatorioResumoFebraban ===  "XLSX" || controladorRelatorioResumoFebraban ===  "CSV"){
                const dados = {
                    codigo_cliente: decryptJWT('codigoCliente'),
                    codigo_usuario: decryptJWT('codigo'),
                    descricao: 'Relatório de resumo de febraban',
                    tipo_arquivo: controladorRelatorioResumoFebraban,
                    tipo_relatorio: 21,
                    cdFatFebraban: cdFatFebraban
                }
                api.post('/m1/cadastrar/relatorios',dados)
                setMensagemInformacaoTitulo("O arquivo está sendo gerado")
                setMensagemInformacaoCorpo("Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível")
                handleMensagemInformacao(true)
                despacho(revelarRelatorioResumoFebraban(false))
            }else{
                despacho(revelarRelatorioResumoFebraban(false))
            }
        }
        buscarDadosRelatorioFaturas()
    }, [controladorRelatorioResumoFebraban])

    useEffect(()=>{
        async function buscarDadosRelatorioFebraban(){
            if(controladorRelatorioFebraban === "XLS" || controladorRelatorioFebraban ===  "XLSX" || controladorRelatorioFebraban ===  "CSV"){
                setSpinAtivo(true)
                const dadosFebraban = await api.post('/m1/filtrar/fatFebraban',{
                    'temFiltros': temFiltros,
                    'filtros': filtros,
                    'ordenador' : ordenador,
                    'cd_pav_usuario': decryptJWT('codigo')
                })
                let exportacaoLinhasAux = []
                if(controladorRelatorioFebraban === 'XLS' || controladorRelatorioFebraban === 'XLSX'){
                    dadosFebraban.data.dados.map((item, i)=>{
                        let auxValorCobrado
                        if(item.n2_valor_cobrado != undefined && item.n2_valor_cobrado != null){
                            auxValorCobrado = formatarMilhar(item.n2_valor_cobrado)
                        }else{
                            auxValorCobrado = '0,00'
                        }
                        let exportacaoLinhasAuxXls = [
                            item.ds_conta+"",
                            item.ds_mes_referencia+"",
                            item.dt_importacao+"",
                            item.ds_arquivo_nome+"",
                            "R$ "+auxValorCobrado+"",
                            item.ds_operadora+"",
                            item.ds_email+"",
                            item.dt_tempo_importacao+"",
                            item.ds_resultado_conciliacao+""
                        ]
                        dadosParaDownloadXlsXlsx.push(exportacaoLinhasAuxXls)
                    });
                    setTimeout(() => {
                        //aciona o event click para o ativar o download do arquivo
                        const exportar = document.getElementById('exportar-linhas-xls')
                        exportar.click();
                    }, 250);
                    setSpinAtivo(false)
                }else{
                    dadosFebraban.data.dados.map((item, i)=>{
                        let auxValorCobrado
                        if(item.n2_valor_cobrado != undefined && item.n2_valor_cobrado != null){
                            auxValorCobrado = formatarMilhar(item.n2_valor_cobrado)
                        }else{
                            auxValorCobrado = '0,00'
                        }
                        
                        exportacaoLinhasAux.push({
                            'Conta' : item.ds_conta+"",
                            'Mês de Referência' : item.ds_mes_referencia+"",
                            'Data de Importação' : item.dt_importacao+"",
                            'Nome do Arquivo' : item.ds_arquivo_nome+"",
                            'Valor Cobrado' : "R$ "+auxValorCobrado+"",
                            'Operadora' : item.ds_operadora+"",
                            'Responsável' : item.ds_email+"",
                            'Tempo de Importação' : item.dt_tempo_importacao+"",
                            'Resultado da Conciliação' : item.ds_resultado_conciliacao+""
                        })
                    });
                    setDadosParaDownloadCsv(exportacaoLinhasAux);
                    setTimeout(() => {
                        //aciona o event click para o ativar o download do arquivo
                        const exportar = document.getElementById('exportar-linhas-csv')
                        exportar.click();
                    }, 250);
                    setSpinAtivo(false)
                    despacho(revelarRelatorioFebraban(false))
                }
            }else{
                despacho(revelarRelatorioFebraban(false))
            }
        }
        buscarDadosRelatorioFebraban()
    }, [controladorRelatorioFebraban])

    function exportarXlsXlsx(){
        let nomeDoArquivo = "Febraban.xlsx";
        if(controladorRelatorioFebraban === 'XLS'){
            nomeDoArquivo = "Febraban.xls";
        }
        exportarXlsx(dadosParaDownloadXlsXlsx, nomeDoArquivo)

        despacho(revelarRelatorioFebraban(false))
    }

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("fatFebraban"));
        despacho(desabilitarAcoes(true));
        
        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fatFebraban?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&separadorLinha='+separadorLinha+'&cd_pav_usuario='+decryptJWT('codigo'));
                    await contarItens();
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    cd_pav_usuario: decryptJWT('codigo')
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fatFebraban", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fatFebraban?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&cd_pav_usuario='+decryptJWT('codigo'));
                    inserirData(data.dados)
                }
            }
        }
        carregarTabela();
        contarItens()

    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get('/m1/consultar/fatFebraban?contar="true"&buscar='+conteudoBusca+'&separadorLinha='+separadorLinha+'&cd_pav_usuario='+decryptJWT('codigo'));
        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                filtros: filtros,
                contar: true,
                cd_pav_usuario: decryptJWT('codigo')

            }
            apiData = await api.post("/m1/filtrar/fatFebraban", dados);
        }

        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const colunas = [
        {
            dataField: 'ds_conta',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_conta",'ds_conta')}>
            Conta
            <Image id="ds_contads_conta" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === '' || cell === null || cell === undefined ?  "-" : cell}</p>;
            }
        },
        {
            dataField: 'ds_mes_referencia',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_mes_referencia",'ds_mes_referencia')}>
            Referência
            <Image id="ds_mes_referenciads_mes_referencia" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === '' || cell === null || cell === undefined ?  "-" : cell}</p>;
            }
        },
        {
            dataField: 'dt_importacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_importacao",'dt_importacao')}>
            Data importação
            <Image id="dt_importacaodt_importacao" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{formatarData(cell)}</p>;
            }
        },
        {
            dataField: 'ds_arquivo_nome',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_arquivo_nome",'ds_arquivo_nome')}>
            Nome
            <Image id="ds_arquivo_nomeds_arquivo_nome" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === '' || cell === null || cell === undefined ?  "-" : cell}</p>;
            }
        },
        {
            dataField: 'n2_valor_cobrado',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n2_valor_cobrado",'n2_valor_cobrado')}>
            Valor cobrado
            <Image id="n2_valor_cobradon2_valor_cobrado" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1">R$ {(!valor || isNaN(valor)) ? '0.00' : formatarMilhar(valor)} </p>;
            }
        },
        {
            dataField: 'ds_operadora',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_operadora",'ds_operadora')}>
            Operadora
            <Image id="ds_operadorads_operadora" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === '' || cell === null || cell === undefined ?  "-" : cell}</p>;
            }
        },
        {
            dataField: 'ds_email',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_email",'ds_email')}>
            Responsável
            <Image id="ds_emailds_email" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === '' || cell === null || cell === undefined ?  "-" : cell}</p>;
            }
        },
        {
            dataField: 'dt_tempo_importacao',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_tempo_importacao",'dt_tempo_importacao')}>
            Tempo importação
            <Image id="dt_tempo_importacaodt_tempo_importacao" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_resultado_conciliacao',
            headerClasses: 'nao-selecionavel',
            text: 'Resultado Conciliação',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_resultado_conciliacao",'ds_resultado_conciliacao')}>
            Resultado conciliação
            <Image id="ds_resultado_conciliacaods_resultado_conciliacao" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === '' || cell === null || cell === undefined ?  "-" : cell}</p>;
            }
        },
    ]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                setLinhaSelecionada(row);
                handleSelecionarCliente(
                    row.cd_fat_arquivo_febraban,
                    row.ds_conta,
                    row.ds_arquivo_nome,
                    row.cd_fat_fatura,
                )
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarCliente(codigo, conta, nomeArquivo,cd_fat_fatura){
        setCdFatFebraban(codigo)
        setDsConta(conta)
        setNomeArquivo(nomeArquivo)
        setCdFatFatura(cd_fat_fatura);
        despacho(desabilitarAcoes(false));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setCdFatFebraban(row.cd_fat_arquivo_febraban);
            setCdFatFatura(row.cd_fat_fatura);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }


    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDoppelgangerCliente(false))
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar))
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            despacho(revelarExcluirFebraban(false))
            if(confirmacao === true){
                handleDeletar()
            }
        }
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/fatFebraban?cd_fat_arquivo_febraban='+cdFatFebraban+'&cd_pav_usuario='+decryptJWT('codigo')+"&cd_fat_fatura="+cdFatFatura)
        .then(function (response) {
            setRenderizar(!renderizar)
            despacho(revelarExcluirFebraban(false))
            handleMensagemSucesso(false)
            setSpinAtivo(false)

        })
        .catch(function (error) {
            setMensagemErro("Erro ao excluir empresa!")
            despacho(revelarExcluirFebraban(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    return(
        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            <CSVLink id='exportar-linhas-csv' headers={headersCsv} filename={"Febraban.csv"} className="d-none" data={dadosParaDownloadCsv} separator={";"}/>
            <button id='exportar-linhas-xls' className='d-none' onClick={() => exportarXlsXlsx()}>Export</button>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={dsConta === null? "O Febraban " + nomeArquivo : "O Febraban " + dsConta}
                            mensagemParagrafo2={"foi excluido."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemInformacao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}  
                            mensagemTitulo={mensagemInformacaoTitulo}
                            mensagemParagrafo1={mensagemInformacaoCorpo}
                            mensagemBotao={"Voltar"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {excluirFebraban === true &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={dsConta === null? "Deletar " + nomeArquivo: "Deletar " + dsConta}
                />
            }
            {importarFebrabans === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-historico-acesso-container">
                        <ModalImportarFebraban
                            linhaSelecionada={linhaSelecionada}
                            tituloJanela="Concluir"
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            {associarFatura === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-acao-adicionar-login-container">
                        <ModalAssociarClientes
                            cdFatFebraban={cdFatFebraban}
                            nomeArquivo={nomeArquivo}
                            tituloJanela="Associar"
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        />
                    </div>
                </div>
            }
            {estadoVisualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_fat_arquivo_febraban' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesVirtueyes));