//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {api, api_papeis} from '../../../conexoes/api';/*eslint-disable*/
import { Card, Button, Form, ButtonToolbar, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {Image} from 'react-bootstrap';
//ICONES
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import {lixeiraVermelho, editarVerde, passarPaguinaDireita, passarPaguinaEsquerda} from '../../../utils/alternanciaIcones';
import iconeSucesso from "../../../icones/icone-swal-success.png";
import iconeFalha from "../../../icones/icone-swal-fail.png";
//COMPONENTES
import CadastroCliente from './cadastro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import { elipseVerde, elipseVermelho } from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { visualizarDados, mudarManejador, limpadorFiltros, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, desabilitarAcoes, revelarNovoCliente, revelarEditarCliente, revelarExcluirCliente } from "../../../redux/actions/index";
//SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavCliente/pavCliente.css'
import '../../../css/painelPermissaoRBAC/perfil/tabela.css'
import '../../../css/painelPermissaoRBAC/cadPapeis/pavPemissao.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import {getSelectedRows} from "react-bootstrap-table-next/lib/src/store/selection";
// GERENCIADOR ACESSO
// import gerenciadorAcesso from '../../../utils/gerenciadorAcesso'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

let retorno = [
    {
        modulo: 'Dashboard',
        id: 1,
        subcategorias: [
            {
                categoria: 'Painel geral',
                papeis: [
                    {
                        id: 1,
                        nome: 'dashboard_exportar_imagem',
                        descricao: 'Exportar imagem'
                    }, {
                        id: 2,
                        nome: 'dashboard_mostrar_dispositivos',
                        descricao: 'Mostrar em dispositivos',
                    }

                ]
            },{
                categoria: 'Painel SMS',
                papeis: [
                    {
                        id: 3,
                        nome: 'dashboard_mostrar_sms',
                        descricao: 'Mostrar em dispositivos'
                    }, {
                        id: 4,
                        nome: 'dashboard_qualquer_coisa',
                        descricao: 'Qualquer coisa',
                    }

                ]
            }

        ]

    },
    {
        modulo: 'Dispositivos',
        id: '2',
        subcategorias: [
            {
                categoria: 'Ações',
                papeis: [
                    {
                        id: 5,
                        nome: 'dashboard_exportar_imagem',
                        descricao: 'Exportar imagem'
                    }, {
                        id: 6,
                        nome: 'dashboard_mostrar_dispositivos',
                        descricao: 'Mostrar em dispositivos',
                    }

                ]
            },{
                categoria: 'Coluna faturas',
                papeis: [
                    {
                        id: 6,
                        nome: 'dashboard_mostrar_sms',
                        descricao: 'Mostrar em dispositivos'
                    }, {
                        id: 7,
                        nome: 'dashboard_qualquer_coisa',
                        descricao: 'Qualquer coisa',
                    }

                ]
            }

        ]

    },
]



function Grid(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    let [totalContador, setTotalContador] = useState(0)
    //HOOKS VARIÁVEIS



    const [nomePapel ,setNomePapel] = useState("");
    const [codigoPapel ,setCodigoPapel] = useState("");
    const [idPapel ,setIdPapel] = useState("");
    const [descricaoPapel ,setDescricaoPapel] = useState("");

    const [dsNomeCliente ,setDsNomeCliente] = useState("");
    const [dsIDCliente, setDsIDCliente] = useState("");
    const [dsIDParceiro, setdsIDParceiro] = useState("");

    const [dsSubdominio, setSubdominio] = useState("");
    const [dsImagem, setDsImagem] = useState("");
    const [chatbotAtivo, setChatbotAtivo] = React.useState (false)
    const [isSelect, setIsSelect] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});



    //HOOKS MENSAGENS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS MODAL
    const [renderizarCadastro, setRenderizarCadastro] = useState(false)
    const [renderizarEdicao, setRenderizarEdicao] = useState(false)
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    const [revelarEdicao, setRevelarEdicao] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let separadorLinhas = useSelector(state => state.manejadorState.separadorLinha);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let NovoCliente =  useSelector(state => state.manejadorState.revelarNovoCliente);
    let EditarCliente =  useSelector(state => state.manejadorState.revelarEditarCliente);
    let ExcluirCliente =  useSelector(state => state.manejadorState.revelarExcluirCliente);
    const manejador = useSelector(state => state.manejadorState.manejador)

    let temFiltros = false;
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {

        if(coletaUsabilidade) {
            ColetaClickstream(113) // 'pavClienteTabelaGrid'
            setColetaUsabilidade(false)
        }
    },[])


    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])


    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("cadastroPermissionamento"));

        async function getEditedRow(selectedRow, rowArray) {
            let newSelectedRow = rowArray.find(row => row.CdPapel === selectedRow.selectedRow.CdPapel)

            setPropData(newSelectedRow)
            return newSelectedRow;
        }
        async function carregarTabela(){
            const { data } = await api_papeis.get('api/v2/papers/read?contagem=true&pagina='+paginaAtual+'&linhasPorPagina='+valorLimite);
            // totalContador = data.contagem;

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }


            function parseSeparadorLinha(separador) {
                if(separador === 'espaco'){
                    return " ";
                } else if (separador === 'enter'){
                    return "\n"
                } else {
                    return separador
                }
            }

            function createArrayBusca(conteudoBusca) {
                let novoSeparador = parseSeparadorLinha(separadorLinhas)
                conteudoBusca = conteudoBusca.split(novoSeparador)

                return conteudoBusca;
            }

            //CASO O FILTRO ESTEJA VAZIO E HAJA CONTEUDO DE BUSCA
            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    await contarItens();
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    let conteudoBarraBusca = []
                    conteudoBarraBusca = createArrayBusca(conteudoBusca)
                    const { data } = await api_papeis.get('/api/v2/papers/read?contagem=true&pagina='+paginaAtual+'&linhasPorPagina='+valorLimite+'&busca=['+conteudoBarraBusca+']');
                    // const { data } = await api_papeis.get('/m1/consultar/cliente_parceiro?pagina='+paginaAtual+'&linhasPorPagina='+valorLimite+'&busca=['+conteudoBarraBusca+']');
                    totalContador = data.item_count;
                    await contarItens()
                    inserirData(data.papers)
                }
                //CASO NÃO HAJA CONTEÚDO DE BUSCA MAS O FILTRO NÃO ESTEJA VAZIO
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/cliente", dados);
                    inserirData(data.dados)
                }
                //APENAS EM CASOS ONDE O FILTRO ESTÁ VAZIO E NÃO HÁ CONTEÚDO NA BUSCA
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api_papeis.get('api/v2/papers/read?contagem=true&pagina='+paginaAtual+'&linhasPorPagina='+valorLimite);
                    // const { data } = await api.get('/m1/consultar/cliente_parceiro?pagina='+paginaAtual+'&linhasPorPagina='+valorLimite);
                    totalContador = data.item_count
                    await contarItens()
                    inserirData(data.papers)
                }
            }

            if (Object.keys(selectedRow).length) {
                if (selectedRow.selectedPage === paginaAtual) {
                    selectedRow.selectedRow = await getEditedRow(selectedRow, data.papers)
                    if(selectedRow.selectedRow){
                        despacho(desabilitarAcoes(false));
                    }else {
                        despacho(desabilitarAcoes(true))
                    }
                } else if (selectedRow.selectedPage !== paginaAtual) {
                    setSelectedRow({});
                    despacho(desabilitarAcoes(true));
                }

            } else {
                despacho(desabilitarAcoes(true));
            }

            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                // apiData = await api.get('/m1/consultar/cliente?contar=true&buscar='+conteudoBusca);
                apiData = totalContador

            } else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true

                }
                apiData = totalContador;

            }
            totalItens = apiData;
            itensVar = []
            if(apiData){
                totalPaginas = Math.ceil(apiData / valorLimite)
                for( var i = 0 ; i < apiData; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }


    const colunas = [
        {
            dataField: 'CdPapel',
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
            text: 'ID',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-20 label-tabela-nova-1">{cell}</p>;
            }
        },
        {
            dataField: 'Codigo',
            headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
            text: 'Código',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '130px'}
            },
            formatter: (cell, row) => {
                return <p className="fonte-cor-20 label-tabela-nova-1">{cell}</p>;
            }
        },
        {
            dataField: 'Nome',
            headerClasses: 'nao-selecionavel',
            text: 'Nome do Papel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-20 label-tabela-nova-1">{cell}</p>;
            }
        },
        {
            dataField: 'Descricao',
            headerClasses: 'nao-selecionavel',
            text: 'Descrição',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-20 label-tabela-nova-1">{cell}</p>;
            }
        }
    ]

    function setPropData(row) {
        if(row){
            setNomePapel(row.Nome);
            setCodigoPapel(row.Codigo);
            setDescricaoPapel(row.Descricao)
            setIdPapel(row.CdPapel)
            // setSubdominio(row.Subdominio);
            // setDsImagem(row.Logo);
            // setChatbotAtivo(row.ChatBot);
            // setdsIDParceiro(row.ID);
        }

    }

    const selecaoLinhas = {
        //TODO testar a solução do selected[selectedRow].
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
                if(isSelect){setSelectedRow({
                    selectedRow: row,
                    selectedIndex: rowIndex,
                    selectedPage: paginaAtual
                })

                    setPropData(row)
                    despacho(desabilitarAcoes(false));
                }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                    <label className="label-checkbox-cliente-virtueyes-nova-tabela fonte-cor-1">
                        <input type={mode} id="CLIENTEVEYE" name="CLIENTE"/>
                        <span className="checkbox-cliente-virtueyes-nova-tabela radio-button-cor-1"></span>
                    </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // setCdCliente(row.cd_pav_cliente);
            // setDsCliente(row.ds_pav_cliente);
            // setDsCpf(row.ds_cpf);
            // setDsCnpj(row.ds_cnpj);
            // setCdPessoa(row.cd_pav_pessoa);
            // setDsNomeFantasia(row.ds_nome_fantasia);
            // setDsEmail(row.ds_email);
            // setDsTelefone(row.ds_telefone);
            // setCdTipoPessoa(row.cd_pav_tipo_pessoa);
            // setDsTipoPessoa(row.ds_pav_tipo_pessoa)
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function colunaAcoes(){
        return(
            <p className="acoes">Ações</p>
        );
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api_papeis.get('/api/v2/papers/delete?id='+idPapel)
        .then(function (response) {
            setRenderizar(!renderizar)
            handleMensagemSucesso(false)
            setSelectedRow({})
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.erro)
            despacho(revelarExcluirCliente(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    function handleMensagemAtencao(confirmacao) {
        if (confirmacao === true) {
            ColetaClickstream(1313); // dropAcoesRevelarExcluirClienteExcluir
            despacho(revelarExcluirCliente(false))
            handleDeletar()
        } else {
            ColetaClickstream(1024) // dropAcoesRevelarExcluirClienteCancelar
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { NovoCliente === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-papel-container">
                        <CadastroCliente
                            tituloJanela={'Cadastrar'}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroCliente>
                    </div>
                </div>
            }
            { EditarCliente === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal-cadastro-cliente-container">
                        <CadastroCliente
                            tituloJanela={'Editar'}

                            nomePapel={nomePapel}
                            descricaoPapel={descricaoPapel}
                            codigoPapel={codigoPapel}
                            idPapel={idPapel}

                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            >
                        </CadastroCliente>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }

                <PaginationProvider pagination={Paginador(opcoesPaginacao)}>
                    {
                        ({
                             paginationProps,
                             paginationTableProps
                         }) => (
                            <>
                                <BootstrapTable
                                    classes="tabela-nova"
                                    condensed={true}
                                    keyField='CdPapel'
                                    data={itens}
                                    condensed={true}
                                    columns={colunas}
                                    rowEvents={eventosLinhas}
                                    selectRow={ selecaoLinhas }
                                    noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                    { ...paginationTableProps }
                                />
                                <PaginacaoTabela
                                    setPaginaAtual={setPaginaAtual}
                                    totalPaginas={totalPaginas}
                                    totalItens={totalItens}
                                    valorLimite={valorLimite}
                                    paginaAtual={paginaAtual}
                                    page={paginationProps.page}
                                    onPageChange={paginationProps.onPageChange}
                                />
                            </>
                        )
                    }
                </PaginationProvider>

                <text className="itens-exibidos-tabela fonte-cor-1">
                    Mostrando
                    <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    -
                    <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de
                    <span className="label-tabela-itens-exibidos">{totalItens}</span>
                </text>

            {/*//FIM DO CÓDIGO ANTIGO COMENTADO*/}



            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"O Papel "+ codigoPapel +" foi deletado."}
                    mensagemBotao={"Continuar"}
                />
            }
            { ExcluirCliente &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+dsNomeCliente}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
}  
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));