//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';
// import { useForm } from "react-hook-form";/*eslint-disable*/
import { mudarFiltros, realizouFiltro } from "../../redux/actions/index.js";/*eslint-disable*/
import { revelarBarraFiltragem } from "../../redux/actions/index";
import { Form, Image, Button } from "react-bootstrap";
import { filtrarVerde } from '../../utils/alternanciaIcones'
//CSS
import '../../css/pavFiltros/filtros.css'
//FILTROS
import FiltroUsuario from "./filtroUsuario";
import FiltroCliente from "./filtroCliente";
import FiltroElemento from "./filtroElemento";
import FiltroModulo from "./filtroModulo";
import FiltroPerfil from "./filtroPerfil";
import FiltroPessoa from "./filtroPessoa";
import FiltroSistema from "./filtroSistema";
import FiltroTela from "./filtroTela";
import FiltroSimCard from "./filtroSimCard";
import FiltroVirtueyesCliente from "./filtroVirtueyesCliente";
import FiltroSolicitacaoCliente from "./filtroSolicitacaoCliente";
import FiltroSolicitacaoOperadora from "./filtroSolicitacaoOperadora";
import FiltroSolicitacaoBloqueio from "./filtroSolicitacaoBloqueio";
import FiltroPedidosSms from "./filtroPedidosSms";
import FiltroModuloSmsMensagens from './filtroModuloSmsMensagens';
import FiltroModuloSmsTemplates from './filtroModuloSmsTemplates';
import FiltroTabelaNovoPedidoSms from './filtroTabelaNovoPedidoSms';
import FiltroModuloPedidosSimcard from './filtroModuloPedidosSimcard';
import FiltroFinanceiro from './filtroFinanceiro';
import FiltroDashboard from './filtroDashboard';
import FiltroMensagens from './filtroMensagens'
import FiltroCentralMensagens from './filtroCentralMensagens'
import FiltroUsuarioCliente from './filtroUsuarioCliente'
import FiltrosFatPedidos from './filtrosFatPedidos'
import FiltrosFatEmpresa from './filtrosFatEmpresa'
import FiltroFatPlanos from './filtroFatPlanos'
import FiltrosCicloVida from './filtrosFaturasCicloVida'
import FiltroFatSimcard from './filtroFatSimcard'
import FiltroFatSolicitacoes from './filtroFatSolicitacoes'
import FiltrosFatContas from './filtrosFatContas'
import FiltrosFatFaturas from './filtrosfatFaturas'
import FiltroFatConciliacao from './filtroFatConciliacao'
import FiltroFatFebraban from './filtrosFatFebraban'
import FiltroFatContestacao from './filtroFatContestacao'
import FiltroDashboardFaturas from './filtroDashboardFaturas'
import FiltroLogAcesso from './filtrosLogAcesso'
import FiltroRequestLog from './filtroRequestLog'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery'
import FiltroAutomacao from './filtroModuloAutomacao';
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'
import { getTempFiltros } from '../../utils/filtros.js';
import moment from "moment";
import {api} from "../../conexoes/api";

export default function DropFiltros(props) {
    const filtros = useSelector(state => state.filtrosState.filtros);
    //HOOKS MODAL
    const modal = useRef();
    //HOOKS REDUX
    const despacho = useDispatch();
    //HOOKS
    const [foiSelecionado,setFoiSelecionado] = useState(false)
    const [primeiraVez,setPrimeiraVez] = useState(false)
    const [foiLimpado,setFoiLimpado] = useState(false)
    const [solicitacaoTipoLista, setSolicitacaoTipoLista] = useState([]);
    // const [caminhoIcones, setCaminhoIcones] = useState('/icones/');
    // const { reset } = useForm();
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    const handleClickFora = e => {
        if(props.manejador == 'fat_solicitacao' || props.manejador == 'con_solicitacao'){
            return;
        }
        if (!modal.current.contains(e.target)) {
            ColetaClickstream(1068) // 'pavFiltrosDropFiltrosHandleClickFora'
            //MOCK DAS PROPRIEDADES DE FECHAMENTO DO MENU AÇÃO. TORNAR FUNCIONAL QUANDO PRONTO.
            props.toggleRevelarDropFiltros(false)
        }
    };
    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(75) // 'pavFiltrosDropFiltros'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        async function renderSolicitacaoTipos() {
            const {data} = await api.get('/m1/consultar/fat_solicitacao_tipo');
            const transformedData = data.dados.map(item => ({
                id: item.cd_fat_solicitacao_tipo,
                label: item.ds_solicitacao_tipo
            }));

            setSolicitacaoTipoLista(transformedData);
        }
        renderSolicitacaoTipos()
    }, []);
    
    useEffect(() => {
        //MOCK DA BUSCA NO BANCO. CORRIGIR E TORNAR FUNCIONAL QUANDO PRONTA ROTA.
        document.addEventListener('mousedown', handleClickFora);
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    const enviarFiltros = (event, filtro_arquivo, newItem=false) => {
        event.preventDefault();
        let tempFiltros = getTempFiltros(event.target, filtro_arquivo);

        despacho(realizouFiltro(true));
        
        if(newItem) {
            tempFiltros = { ...tempFiltros, ...newItem }
        }

        removerPropriedadesPorValor(tempFiltros, 'Selecione');

        if(tempFiltros.DATA_INICIO){
            tempFiltros.DATA_INICIO = moment(tempFiltros.DATA_INICIO, "DD/MM/YYYY hh:mm:ss").toDate();
        }

        if(tempFiltros.DATA_FIM){
            tempFiltros.DATA_FIM = moment(tempFiltros.DATA_FIM, "DD/MM/YYYY hh:mm:ss").toDate();
        }


        despacho(mudarFiltros(tempFiltros));
        despacho(revelarBarraFiltragem(true));
        // this.props.mudarFiltros(tempFiltros); 
        props.toggleRevelarDropFiltros(false)
    };

    function removerPropriedadesPorValor(objeto, valor) {
        for (let propriedade in objeto) {
            if (objeto[propriedade] === valor) {
                delete objeto[propriedade];
            }
        }
    }

    const limparFiltros = () => {
        ColetaClickstream(1191) /*dropFiltrosLimpar*/
        // const formData = new FormData(modal.current);
        // for (var [key] of formData.entries()) {
        //     modal.current[key].value = "";
        // }
        $(modal.current)[0].reset();
        setFoiSelecionado(false)
        setFoiLimpado(true)
        despacho(realizouFiltro(true));

        despacho(mudarFiltros({vazio:'vazio'}));
        despacho(revelarBarraFiltragem(false));
    }

    function dropFiltros(event, elementoId, imagemId){ 
        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(imagemId);
        let imagemClasses = document.querySelectorAll('.drop-acoes-menu-icone-ativado');
        let classes =  document.querySelectorAll('.dropdown-ativado-filtros');

        if(elemento.classList.contains("dropdown-desativado-filtros")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.remove("dropdown-desativado-filtros"));
                classes.forEach(item => item.classList.add("dropdown-ativado-filtros"));

                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-ativado"));
            }
            elemento.classList.add("dropdown-ativado-filtros")
            elemento.classList.remove("dropdown-desativado-filtros")

            imagem.classList.add("drop-acoes-menu-icone-ativado")
            imagem.classList.remove("drop-acoes-menu-icone-desativado")
        }
        // se o filtro estiver ativado e for clicado em cima do mesmo filtro ele fecha
        else if(elemento.classList.contains("dropdown-ativado-filtros")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
                classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));

                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
            }
            elemento.classList.add("dropdown-desativado-filtros")
            elemento.classList.remove("dropdown-ativado-filtros")

            imagem.classList.add("drop-acoes-menu-icone-desativado")
            imagem.classList.remove("drop-acoes-menu-icone-ativado")
        }

        if(elemento.classList.contains("dropdown-ativado-filtros")){
            if(classes.length > 0){
                classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
                classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));

                imagemClasses.forEach(item => item.classList.add("drop-acoes-menu-icone-desativado"));
                imagemClasses.forEach(item => item.classList.remove("drop-acoes-menu-icone-ativado"));
            }
            classes.forEach(item => item.classList.add("dropdown-desativado-filtros"));
            classes.forEach(item => item.classList.remove("dropdown-ativado-filtros"));
        }
    }


    return (
        <div className="posicionador-menu-filtros">
            <Button className="botao-filtros-acoes-cabecalho-sistema fundo-cor-7" onClick={() => props.setRevelarDropFiltros(false)}>
                <Form.Label  className="label-filtros-acoes-cabecalho-sistema fonte-cor-1" >Filtros</Form.Label>
                <Image className="icone-filtros-cabecalho-sistema" src={filtrarVerde()} />
            </Button>
            {props.manejador == "usuario" &&
                <FiltroUsuario modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {(props.manejador == "cliente" || props.manejador ==  "cadastroCliente") &&
                <FiltroCliente modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "elemento" &&
                <FiltroElemento modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "modulo" &&
                <FiltroModulo modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros}/>
            }
            {props.manejador == "perfil" &&
                <FiltroPerfil modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros}/>
            }
            {props.manejador == "pessoa" &&
                <FiltroPessoa modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "sistema" &&
                <FiltroSistema modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros}/>
            }
            {props.manejador == "tela" &&
                <FiltroTela modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros}/>
            }
            {props.manejador == "virtueyesClientes" &&
                <FiltroVirtueyesCliente modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "dspDispositivos" &&
                <FiltroSimCard modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "solicitacaoCliente" &&
                <FiltroSolicitacaoCliente modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros}/>
            }
            {props.manejador == "solicitacaoOperadora" &&
                <FiltroSolicitacaoOperadora modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
             {props.manejador == "solicitacaoReset" &&
                <FiltroSolicitacaoOperadora modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "tabelaPedidosSms" &&
                <FiltroPedidosSms modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "envioSms" &&
                <FiltroModuloSmsMensagens modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "smsTemplate" &&
                <FiltroModuloSmsTemplates modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "pedidosSimcard" &&
                <FiltroModuloPedidosSimcard modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "financeiro" &&
                <FiltroFinanceiro modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "novoPedidoSMS" &&
                <FiltroTabelaNovoPedidoSms modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "dashboardGeral" &&
                <FiltroDashboard modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "moduloAutomacao" &&
                <FiltroAutomacao modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "mensagens" &&
                <FiltroMensagens modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "solicitacaoBloqueio" &&
                <FiltroSolicitacaoBloqueio modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "centralMensagens" &&
                <FiltroCentralMensagens modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros}/>
            }
            {props.manejador == "usuarioCliente" &&
                <FiltroUsuarioCliente modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "fatEmpresa" &&
                <FiltrosFatEmpresa modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "faturasPlanos" &&
                <FiltroFatPlanos modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "faturasCicloVida" &&
                <FiltrosCicloVida modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "fat_solicitacao" &&
                <FiltroFatSolicitacoes modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "con_solicitacao" &&
                <FiltroFatSolicitacoes modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "fatFebraban" &&
                <FiltroFatFebraban modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "fat_contestacao" &&
                <FiltroFatContestacao modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "faturasPedidos" &&
                <FiltrosFatPedidos modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "fatDispositivos" &&
                <FiltroFatSimcard modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} setRevelarDropFiltros={props.setRevelarDropFiltros} />
            }
            {props.manejador == "faturasConciliacao" &&
                <FiltroFatConciliacao modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "fatConta" &&
                <FiltrosFatContas modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "fatFaturas" &&
                <FiltrosFatFaturas modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "dashboardFaturas" &&
                <FiltroDashboardFaturas modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "requestLog" &&
                <FiltroRequestLog modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {props.manejador == "acessoLog" &&
                <FiltroLogAcesso modal={modal} limparFiltros={limparFiltros} dropFiltros={dropFiltros} enviarFiltros={enviarFiltros} foiSelecionado={foiSelecionado} setFoiSelecionado={setFoiSelecionado} primeiraVez={primeiraVez} setPrimeiraVez={setPrimeiraVez} foiLimpado={foiLimpado} setFoiLimpado={setFoiLimpado} />
            }
            {/* } else {
                <FiltroVazio modal={modal}  dropFiltros={dropFiltros} />
            } */}
        </div>
    )
}