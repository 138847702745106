//IMPORTAÇÕES
import React, { useState, useEffect, useRef } from 'react';/*eslint-disable*/
import {api_s3} from '../../conexoes/api';
// import { CSVLink } from 'react-csv'
import XLSX from 'xlsx';

import { useForm } from "react-hook-form";
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdCheckCircle } from "react-icons/md";
import decryptJWT from './../../utils/decryptJWT';

//ICONES
import {importarArquivo, elipseVerde, fecharJanela, exclamacao} from '../../utils/alternanciaIcones';
import iconeTemplate  from '../../icones/exportarBranco.svg'
//REDUX
import { useDispatch, useSelector } from 'react-redux';
//MODAIS
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
// SPINNER
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
//CSS
import '../../css/pavFiltros/filtros.css'

// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

import { revelarImportarFebrabans, revelarAssociarFatura } from '../../redux/actions';
import { exportarXlsx } from '../../utils/exportarArquivo.js';

let dadosArquivo;
let arquivos= [];

export default function BuscaArquivo(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS VALIDAÇÂO
    const { register, handleSubmit, errors } = useForm();
    //HOOKS PRINCIPAIS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    // const [selectedVersaoFebraban, setSelectedVersaoFebraban] = useState("Selecione");
    const [nomeArquivo, setNomeArquivo] = useState('');
    
    //HOOKS MODAIS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    //OUTROS HOOKS
    // const [clickFora, setClickFora] = useState(false);
    const despacho = useDispatch();
    const modal = useRef();
    const manejador = useSelector(state => state.manejadorState.manejador)

    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    const iconeAlerta = <img className="icone-alerta" src={exclamacao()} alt='exclamacao'/>;

    const axios = require('axios');

    const [arrayDadosExportacaoXlsXlsx, setarrayDadosExportacaoXlsXlsx] = useState([['Linha','ICCID'],["'","'"]])

    const handleClickFora = e => {
        if (!modal.current.contains(e.target)) {
            handleCancelar()
        }
    };

    // const handleClickDentro = () => setClickFora(false);

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(1165) /*dropFiltrosFiltroPorArquivo*/
            setColetaUsabilidade(false)
        }
    },[])
    
    useEffect(() => {
        setSpinAtivo(true)
        document.addEventListener('mousedown', handleClickFora);
        setSpinAtivo(false)
        return () => document.removeEventListener('mousedown', handleClickFora);
    }, [])

    async function handleSalvar(e){
        ColetaClickstream(1169) /*dropFiltrosFiltroPorArquivoFiltrar*/
        const target = e.target;
        e.preventDefault();

        setSpinAtivo(true)
        let data = new FormData();
        data.append('file', arquivos);
        data.append('cd_usuario', decryptJWT('codigo'));
        data.append('manejador', manejador);
        await api_s3.post("s3/filtrar/arquivo", data)
            .then(response => {
                if (response.status === 200) {
                    dadosArquivo = response.data.dados
                    dadosArquivo.VALIDADOR_ARQUIVO = true;

                    // evitar erros
                    delete dadosArquivo.__EMPTY;
                    delete dadosArquivo.__EMPTY_1;
                    delete dadosArquivo['#VALUE!'];

                    props.setNomeArquivo(nomeArquivo)
                }
                setSpinAtivo(false)
            })
            .catch((error) => {
                setMensagemErro('Arquivo inválido.')
                setRevelarMensagemErro(true)
                setSpinAtivo(false)
            });

        props?.arrayFiltrosSimcard?.push('Busca por arquivo?BUSCA_ARQUIVO');
        if(props?.handleFuncaoEstadosRedux) await props?.handleFuncaoEstadosRedux();
        
        if(props?.enviarBuscaArquivo){
            await props.enviarBuscaArquivo({target}, dadosArquivo);
        } else if(props?.enviarFiltros) {
            await props.enviarFiltros(e, dadosArquivo);
        } 
        props.setRenderizar(!props.renderizar)
        setSpinAtivo(false)
    }

    async function handleCancelar() {
        ColetaClickstream(1166) /*dropFiltrosFiltroPorArquivoHandleCancelar*/
        setSpinAtivo(false)
        props.setRenderizar(!props.renderizar)
        despacho(revelarImportarFebrabans(false))
        despacho(revelarAssociarFatura(false))
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao ===true){
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            handleCancelar()
        }
        if(confirmacao){
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
        }
        if(confirmacao ===true){
            //ALGUMA FUNÇÃO
        }
    }

      //Funcao para ler os dados do arquivo
    function handleLerArquivo(event){
        let files = event.target.files[0];
        setNomeArquivo(files.name);
        arquivos = files;
    };

    function ListaArquivos ({ files }) {
        return (
            <div className="Container">
                    {files.length != 0 &&
                        <>
                            <p className="titulo-lista-pedidos-fatura fonte-cor-1"> Arquivo selecionado</p>
                            <p className="titulo-lista-pedidos-fatura fonte-cor-1">
                                <li className="lista-pedidos-faturas campo-texto-cor-3">
                                    <p className="lista-nome-pedidos-pedidos">{nomeArquivo}</p>
                                    <div>
                                        <MdCheckCircle size={24} color="#36D896"/>
                                    </div>
                                    <div className="botaoLixeira">
                                        <button className="excluir" onClick={(e) => handleDelete(e, arquivos)}>
                                            <div className="lixeira"/> 
                                        </button>
                                    </div>
                                </li>
                            </p>
                        </>
                    }
            </div>
        )
    }

    async function handleDelete(e, arquivos) {
        e.preventDefault();
        arquivos = []
        setNomeArquivo('')
    };

    const handleClickOutroBotao = (e) =>{
        ColetaClickstream(1167) /*dropFiltrosFiltroPorArquivoImportar*/
        const importar = document.getElementById('importar-filtro-arquivo')
        importar.click();
    }

    function exportarXlsXlsx(){
        ColetaClickstream(1168) /*dropFiltrosFiltroPorArquivoExportarXlsXlsx*/

        exportarXlsx(arrayDadosExportacaoXlsXlsx, "Template.xls")
    }

    return(
        <Form ref={modal} onSubmit={handleSubmit((e) => handleSalvar(e))}>
            <div className={props.rota && props.rota == "filtroSimcard" ? "modal-filtro-arquivo-simcard fundo-cor-6": "modal-filtro-arquivo fundo-cor-6" }>
                { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
                <Form.Row>
                    <Col>
                        <img className="icone-label-janela nao-selecionavel" src={elipseVerde()} alt="elipse"/>
                        <Form.Label>
                            <div className="fonte-cor-1 label-janela">Importar arquivo para busca</div>
                        </Form.Label>
                        <Button className="botao-fechar-janela" variant="danger" onClick={() => handleCancelar()}>
                            <img className="botao-fechar-janela-icone" src={fecharJanela()} alt="fechar-janela"/>
                        </Button>
                    </Col>
                </Form.Row>
                {/* CORPO */}

                <Form.Row className="usuario-dados-corpo-linha">
                    <Col>
                        <Form.Label> <div className="fonte-cor-1 label-campo">Arquivo</div> </Form.Label>
                        <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="bottom" overlay={<Tooltip className={'tooltip-campos-fatura'} id={`tooltip-bottom`}> Importar arquivos </Tooltip>}> 
                            <div>
                                <input id="importar-filtro-arquivo" type="file" onChange={(e) => handleLerArquivo(e)} className="input-filtro-arquivo"/>
                                <Button className="btn-filtro-arquivo" onClick={(e) => handleClickOutroBotao(e)}>
                                    <div className="fonte-cor-4">Importar</div>
                                    <img className="img-filtro-arquivo" src={importarArquivo()} />
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </Col>

                    <Col>
                        <Form.Label> <div className="fonte-cor-1 label-campo">Template</div> </Form.Label>
                        <OverlayTrigger key="top" delay={{show: 500, hide: 250}}placement="bottom" overlay={<Tooltip className={'tooltip-campos-fatura'} id={`tooltip-bottom`}> Template Arquivo </Tooltip>}> 
                            <div>
                                <Button className="btn-filtro-arquivo-template" onClick={() => exportarXlsXlsx()}>
                                    <div className="fonte-cor-4 texto-arquivo-template-2">Template</div>
                                    <img className="img-filtro-arquivo-template" src={ iconeTemplate } />
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Form.Row>
                <ListaArquivos files={nomeArquivo}/>
                <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-filtro-arquivo">{iconeAlerta} Os tipos de arquivos suportados são '.csv e .xlsx'</p>
                <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-filtro-arquivo">{iconeAlerta} O arquivo deve conter um cabeçalho com uma coluna com 'linha' ou 'iccid'</p>

                <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo"/>
                <Form.Row className="espaco-reativar-linha">
                    <Col>
                        <>
                            {arquivos.length != 0 ?
                                <Button type="submit" className="botao-confirmar" variant="success" size="sm" onClick={(e) => handleSalvar(e)}>
                                    <div className="fonte-cor-4">Buscar</div>
                                </Button>
                            :
                                <Button className="botao-confirmar-desabilitado" size="sm" onClick={(e) => handleSalvar(e)}>
                                    <div className="fonte-cor-4">Buscar</div>
                                </Button>
                            }
                            <Button className={"botao-cancelar"} variant="success" size="sm" onClick={() => handleCancelar()}>
                                <div className="fonte-cor-2">Cancelar</div>
                            </Button>
                        </>
                    </Col>
                </Form.Row>
            </div>
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"Sua solicitação foi enviada."}
                            mensagemBotao={"Continuar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemAtencao}
                            mensagemTitulo={"Remover Linha?"}
                            mensagemBotao={"Remover"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
        </Form>
    )
}